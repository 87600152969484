import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';
import Error from '../misc/Error';
import Placeholder from '../../assets/placeholder.png';

import Loading from '../misc/Loading';

class TriggeredAlertItems extends React.Component {

  render() {
    return (
      <FirestoreCollection
        path={`alertItems`}
        sort="createdTime:desc"
        limit={100}
        filter={this.props.slug ? ([['userEmailId', '==', this.props.email], ['slug', '==', this.props.slug]]) : ['userEmailId', '==', this.props.email]}
      >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (isLoading) {
            return <Loading />
          }
          if (!isLoading && data.length === 0) {
            return (
              <div className="heaven-empty-card" style={{marginTop:'22px'}}>
                <i className="fas fa-exclamation-circle heaven-card-title-icon" />No alerts yet!
              </div>
            )
          }
          return (
            <div>
              {data.map(alert => (
                <div key={alert.id} style={{width:'100%',margin:'0px 12px'}}>
                { alert.archived ? <span /> : (
                  <div className="column is-half" style={{display:'inline'}}>
                    <div className="heaven-content-card">
                      <div className="heaven-alert-card-header">
                        <p className="heaven-alert-header-title"><i className="fas fa-bell heaven-card-title-icon heaven-opaque-icon" />Triggered by <i>{alert.alertName}</i> on <Moment format="dddd, MMM Do, YYYY">{alert.createdTime}</Moment></p>
                      </div>
                      <div className="heaven-content-card-content">
                        <div className="journalist-card-content-header">
                          <div className="heaven-contact-card-location">
                            <div className="heaven-avatar-wrapper">
                              {(alert.avatar) ? (
                                <img src={alert.avatar.replace("_normal","")} alt="" className="image request-card-picture" />
                              ) : <span />
                              }
                              {(alert.twitterPhoto) ? (
                                <img src={alert.twitterPhoto.replace("_normal","")} alt="" className="image request-card-picture" />
                              ) : <span />
                              }
                              <img src={Placeholder} alt="Placeholder" className="image heaven-req-avatar-placeholder" />
                            </div>
                          </div>
                        </div>
                        <div className="journalist-req-name-title" style={{marginTop:'-9px',marginBottom:'18px'}}>
                          <div className="journalist-card-name-and-link">
                            <div className="dropdown is-hoverable is-left">
                              <div className="dropdown-trigger">
                                {(alert.name) ? (
                                  <p className="journalist-req-name">{alert.name}<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></p>
                                ) : (alert.twitterDisplayName) ? (
                                  <p className="journalist-req-name">{alert.twitterDisplayName}<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></p>
                                ) : (alert.twitterName) ? (
                                  <p className="journalist-req-name">@{alert.twitterName}<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></p>
                                ) : <span />}
                              </div>
                              <div className="dropdown-menu" id="dropdown-menu4" role="menu" style={{marginTop:'-9px'}}>
                                <ul className="dropdown-content heaven-req-card-dropdown-content">
                                  {(alert.outletText && Array.isArray(alert.outletText) && alert.outletText.length > 0 && alert.kindOfJournalist && alert.outletText[0] !== "") ?
                                    <p className="journalist-card-type-outlet heaven-margin-bottom has-text-weight-bold" style={{ paddingBottom:'0px' }}>
                                      <span className="journalist-card-kind heaven-margin-bottom has-text-weight-bold"><i className="fas fa-id-badge heaven-nav-link-icon"/>{alert.kindOfJournalist}</span> at { alert.outletText.map((outlet, index) => <span key={index} className="journalist-card-outlet heaven-margin-bottom has-text-weight-bold">{index > 0 && ", "}{outlet}</span>)}
                                    </p> : <span /> }
                                  {alert.country && alert.area ? (
                                    <p className="heaven-margin-bottom"><b><i className="fas fa-globe-americas heaven-nav-link-icon"/>{alert.area}, {alert.country}</b></p>
                                  ) : alert.country ? (
                                    <p className="heaven-margin-bottom"><b><i className="fas fa-globe-americas heaven-nav-link-icon"/>{alert.country}</b></p>
                                  ) : alert.twitterLocation ? (
                                    <p className="heaven-margin-bottom"><b><i className="fas fa-globe-americas heaven-nav-link-icon"/>{alert.twitterLocation}</b></p>
                                  ) : <span /> }
                                  {(alert.collectedTwitterBio) ? (
                                    <p className="heaven-req-bio">{decodeURIComponent(alert.collectedTwitterBio)}</p>
                                  ) : (alert.twitterBio) ? (
                                    <p className="heaven-req-bio">{decodeURIComponent(alert.twitterBio)}</p>
                                  ) : <span />
                                  }
                                  {(alert.industry && Array.isArray(alert.industry) && alert.industry[0] !== "") ? (
                                    alert.industry.map((industry, index) =>
                                      <div key={index} className="journalist-card-tag-wrapper">
                                        <div className="heaven-contact-card-tag">
                                          <i className="fas fa-clipboard-list heaven-nav-link-icon"/>
                                          <p className="journalist-card-tag-text">{industry}</p>
                                        </div>
                                      </div>
                                    )
                                  ) : <span /> }
                                  {/*tags conditional*/}
                                  {(alert.tags && Array.isArray(alert.tags) && alert.tags[0] !== "") ? (
                                    alert.tags.map((tag, index) =>
                                      <div key={index} className="journalist-card-tag-wrapper">
                                        <div className="heaven-contact-card-tag">
                                          <i className="fas fa-tags heaven-nav-link-icon"/>
                                          <p className="journalist-card-tag-text">{tag}</p>
                                        </div>
                                      </div>
                                    )
                                  ) : <span /> }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="tweet-card-content" style={{padding:'0px'}}>{decodeURIComponent(alert.content)}</p>
                      </div>
                      <div className="heaven-card-footer">
                        <div className="columns heaven-card-footer-columns">
                          <div className="column is-one-quarters">
                            <div className="heaven-card-footer-buttons">
                              <div className="dropdown is-hoverable is-left">
                                <div className="dropdown-trigger">
                                  <a className="heaven-card-white-button is-pulled-left"><i className="fas fa-address-card heaven-nav-button-icon"/>Contact<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></a>
                                </div>
                                <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                  <ul className="dropdown-content heaven-card-dropdown-content">
                                    {(alert.email) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={"mailto:" + alert.email} target="_blank" rel="noopener noreferrer">
                                        <i className="fas fa-envelope heaven-nav-link-icon"/>{alert.email}
                                      </a>
                                    ) : <span /> }
                                    {(alert.secondaryEmail) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={"mailto:" + alert.secondaryEmail} target="_blank" rel="noopener noreferrer">
                                        <i className="fas fa-envelope heaven-nav-link-icon"/>{alert.secondaryEmail}
                                      </a>
                                    ) : <span /> }
                                    {(alert.phone && alert.phone !== "@") ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" style={{cursor: 'auto'}}>
                                        <i className="fas fa-phone heaven-nav-link-icon"/>{alert.phone.replace("@","")}
                                      </a>
                                    ) : <span /> }
                                    {(alert.twitterName && alert.twitterName.includes("://")) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={"https://www.twitter.com/" + alert.twitterName.substr(alert.twitterName.lastIndexOf('/') + 1)} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-twitter heaven-nav-link-icon"/>Twitter
                                      </a>
                                    ) : (alert.twitterName) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={"https://twitter.com/" + alert.twitterName} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-twitter heaven-nav-link-icon"/>Twitter
                                      </a>
                                    ) : <span /> }
                                    {(alert.linkedinUrl) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={alert.linkedinUrl} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin heaven-nav-link-icon"/>LinkedIn
                                      </a>
                                    ) : <span /> }
                                    {(alert.facebookUrl) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={alert.facebookUrl} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-facebook heaven-nav-link-icon"/>Facebook
                                      </a>
                                    ) : <span /> }
                                    {(alert.website) ? (
                                      <a className="dropdown-item heaven-card-dropdown-item" href={alert.website} target="_blank" rel="noopener noreferrer">
                                        <i className="fas fa-link heaven-nav-link-icon"/>Website
                                      </a>
                                    ) : <span /> }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="column is-three-quarters">
                            <div className="heaven-card-footer-buttons-right">
                              {(!alert.linkToTweet) ? (
                                <a className="heaven-page-cta-button is-pulled-right" style={{padding:'8px 12px'}} href={alert.url} target="_blank">
                                  See article<i className="fas fa-arrow-right heaven-nav-button-icon-right"/>
                                </a>
                              ) : (
                                <a className="heaven-page-cta-button is-pulled-right" style={{padding:'8px 12px'}} href={alert.linkToTweet} target="_blank">
                                  See tweet<i className="fas fa-arrow-right heaven-nav-button-icon-right"/>
                                </a>
                              )}
                              {(alert.secondaryEmail) ? (
                                <a className="heaven-card-white-button is-pulled-right" href={"mailto:" + alert.secondaryEmail}><i className="fas fa-envelope heaven-nav-button-icon"/>Email</a>
                              ) : (alert.email) ? (
                                <a className="heaven-card-white-button is-pulled-right" href={"mailto:" + alert.email}><i className="fas fa-envelope heaven-nav-button-icon"/>Email</a>
                              ) : (!alert.email && !alert.secondaryEmail && alert.twitterName) ? (
                                <a className="heaven-card-white-button is-pulled-right" href={"https://twitter.com/" + alert.twitterName} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter heaven-nav-button-icon"/>Tweet</a>
                              ) : <span /> }
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )}
                </div>
              ))}
            </div>
          )
        }}
      </FirestoreCollection>
    )
  }
}

export default TriggeredAlertItems;
