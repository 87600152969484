import React from 'react';
import Select from 'react-select';
import { TargetTags, Areas, Countries, Beats } from '../../data/selectOptions';

class AlertForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      archived: false,
      TargetTags: this.props.alert ? this.props.alert.TargetTags : [],
      Areas: this.props.alert ? this.props.alert.Areas : [],
      Countries: this.props.alert ? this.props.alert.Countries : [],
      Beats: this.props.alert ? this.props.alert.Beats : [],
    };
  }
  formChangeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleReactSelectChange = name => value => {
    this.setState({
      [name]: value
    })
  }

  onSubmit = event => {
    event.preventDefault()
    const {
      alertName,
      sendTo,
      TargetKeywords,
      Sentiment,
      TargetOutletTier,
    } = event.target.elements

    const {
      TargetTags,
      Areas,
      Countries,
      Beats,
      active,
      archived,
    } = this.state

    const values = {
      alertName: alertName.value,
      SendTo: sendTo.value,
      TargetKeywords: TargetKeywords.value,
      TargetTags: TargetTags,
      Areas: Areas,
      Countries: Countries,
      Beats: Beats,
      Sentiment: Sentiment.value,
      TargetOutletTier: TargetOutletTier.value,
      Status: active,
      archived: archived,
    }
    this.props.onSubmit(values)
  }
  render() {
    return (
      <div className="is-sequence-form">
        <form onSubmit={this.onSubmit}>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <label className="sequence-form-label">What do you want to name your alert?</label>
              <input
                className="sequence-form-input"
                required
                type="text"
                name="alertName"
                defaultValue={this.props.alert ? this.props.alert.alertName : ''}
                placeholder="Alert Name"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <label className="sequence-form-label">What email do you want us to send alerts to? (Please separate multiple emails by commas.)</label>
              <input
                className="sequence-form-input"
                required
                type="text"
                name="sendTo"
                defaultValue={this.props.alert ? this.props.alert.SendTo : ''}
                placeholder="Send To"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Please list the keywords you'd like to target for this alert, separated by commas.
              </p>
              <p className="sequence-form-sublabel">
                If you list more than 1 keyword, only hits that match <span className="is-strong">ALL</span> of the listed keywords will be selected.
              </p>
              <input
                className="sequence-form-input"
                required
                type="text"
                name="TargetKeywords"
                defaultValue={this.props.alert ? this.props.alert.TargetKeywords : ''}
                placeholder="Bitcoin, Ethereum (3 max)"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the topics they cover, list up to 3 topics here<br />
              </p>
              <p className="sequence-form-sublabel">
                This will filter each hit by the journalist's covered topics. For example, if you list "technology" and "San Francisco", it will filter for journalists that cover either technology <span className="is-strong">OR</span> San Francisco.
              </p>
              <Select
                isMulti
                required
                name="TargetTags"
                options={TargetTags}
                defaultValue={this.props.alert ? this.props.alert.TargetTags : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('TargetTags')}
              />

            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the beats they cover, list up to 3 topics here<br />
              </p>
              <p className="sequence-form-sublabel">
                This will filter each hit by the journalist's covered beats. For example, if you list "technology" and "San Francisco", it will filter for journalists that cover either technology <span className="is-strong">OR</span> San Francisco.
              </p>
              <Select
                isMulti
                name="Beats"
                options={Beats}
                defaultValue={this.props.alert ? this.props.alert.Beats : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('Beats')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the area they live/work in<br />
              </p>
              <p className="sequence-form-sublabel">
                An area is either a city, state, or metropolitan area––but not a country. If you list "California" and "Hong Kong", it will filter for journalists that are based in either California <span className="is-strong">OR</span> Hong Kong.
              </p>
              <Select
                isMulti
                name="Areas"
                options={Areas}
                defaultValue={this.props.alert ? this.props.alert.Areas : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('Areas')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the country they live/work in<br />
              </p>
              <p className="sequence-form-sublabel">
                If you list "United Kingdom" and "Morocco", this will filter for journalists that are based in either United Kingdom <span className="is-strong">OR</span> Morocco.
              </p>
              <Select
                isMulti
                name="Countries"
                options={Countries}
                defaultValue={this.props.alert ? this.props.alert.Countries : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('Countries')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Please select the desired sentiment you want your alert to trigger on.
              </p>
              <p className="sequence-form-sublabel">
                Sentiment is essentially a measurement of the emotional positive/negative feeling of the text (using Google's Machine Learning API). Tesla, for example, can use a sentiment trigger here to track when journalists write something negative about them, and automatically email them a response accordingly.<br /><br /><br />
              </p>
                <input
                  type="radio"
                  name="Sentiment"
                  value="positive"
                  className="is-checkradio"
                  defaultChecked={ this.props.alert && this.props.alert.Sentiment === "positive" ? true : false}
                  id="sentimentRadioInput1"
                  onChange={(event, name, value) =>
                    this.formChangeHandler(event, name, value)
                  }
                />
              <label htmlFor="sentimentRadioInput1">
                Positive
              </label>
              <input
                type="radio"
                name="Sentiment"
                value="negative"
                className="is-checkradio"
                defaultChecked={ this.props.alert && this.props.alert.Sentiment === "negative" ? true : false}
                id="sentimentRadioInput2"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="sentimentRadioInput2">
                Negative
              </label>
              <input
                type="radio"
                name="Sentiment"
                value="either"
                className="is-checkradio"
                defaultChecked={ this.props.alert && this.props.alert.Sentiment === "either" ? true : false}
                id="sentimentRadioInput3"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="sentimentRadioInput3">
                Either
              </label>
            </div>
          </div>
          <div className="sequence-form-last-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Please select the tier of media outlet you'd like your alert to trigger on.
              </p>
              <p className="sequence-form-sublabel">
                First-tier news outlets like The New York Times and TechCrunch are global outlets, for example, while smaller blogs and TV and radio stations are local outlets.<br /><br /><br />
              </p>
              <input
                type="radio"
                name="TargetOutletTier"
                value="global"
                className="is-checkradio"
                defaultChecked={ this.props.alert && this.props.alert.TargetOutletTier === "global" ? true : false}
                id="TargetOutletTierRadioInput1"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="TargetOutletTierRadioInput1">
                Global
              </label>
              <input
                type="radio"
                name="TargetOutletTier"
                value="local"
                className="is-checkradio"
                defaultChecked={ this.props.alert && this.props.alert.TargetOutletTier === "local" ? true : false}
                id="TargetOutletTierRadioInput2"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="TargetOutletTierRadioInput2">
                Local
              </label>
              <input
                type="radio"
                name="TargetOutletTier"
                value="both"
                className="is-checkradio"
                id="TargetOutletTierRadioInput3"
                defaultChecked={ this.props.alert && this.props.alert.TargetOutletTier === "both" ? true : false}
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="TargetOutletTierRadioInput3">
                Both
              </label>
            </div>
          </div>
          <div className="sequence-form-button-wrapper">
            <button
              className="heaven-card-cta-button"
              type="submit"
              style={{marginLeft:'0px',fontWeight:'bold'}}
            >
              <i className="fas fa-check heaven-nav-button-icon" />Save alert
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default AlertForm;
