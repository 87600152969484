import React from 'react';

const MailPagination = ({
  offset, limit, count, onPrevious, onNext, style = "", navClass = ""
}) => {
  const start = offset + 1;
  let end = limit + offset;
  if (end >= count) {
    end = count;
  }
  const navClassName = navClass.length == 0 ? "py-3 flex items-center justify-between border-gray-200 sm:px-12" : navClass;
  return (
  // <!-- This example requires Tailwind CSS v2.0+ -->
    <nav className={navClassName} aria-label="Pagination" style={style}>
      <div className="hidden sm:block px-4">
        <p className="text-sm text-gray-700 mr-1">
          Showing
          <span className="font-medium m-1">{start}</span>
          to
          <span className="font-medium m-1">{end}</span>
          of
          <span className="font-medium m-1">{count}</span>
          results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <a
          onClick={onPrevious}
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          onClick={onNext}
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
    </nav>
  );
};

export default MailPagination;
