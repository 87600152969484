import React, { Component } from 'react';
import { FirestoreDocument } from 'react-firestore';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Error from '../misc/Error';
import { Page } from '../../styles/layout';
import Nav from '../layout/directory-nav';
import Placeholder from '../../assets/placeholder.png';
import Footer from '../layout/footer';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.location.state ? this.props.location.state.page : 1,
    }
  }

  render() {
    return (
      <Page>
        <Nav />
        <FirestoreDocument
          path={`journalists/`+ this.props.match.params.slug}
          render={({error, isLoading, data}) => {
            if (isLoading) {
              return <p className="loading has-text-centered is-centered"><span role="img" aria-label="construction">🚧</span> Loading… <span role="img" aria-label="construction">🚧</span></p>
            }
            if (error) {
              return <Error error={error} />
            }
            if (!data.name) {
              return <p className="loading has-text-centered is-centered"><span role="img" aria-label="construction">🚧</span> Page not found <span role="img" aria-label="construction">🚧</span></p>
            }
            return (
              <div>
                {/*React Helmet for metadata*/}
                <Helmet>
                  <title>{data.name + " journalist email and phone number " + (data.outlet_text[0] && data.outlet_text !== "[]" ? data.outlet_text : "") + " – Press Hunt"}</title>
                  <meta name="description" content={"Find " + data.name +"'s email, phone number, and contact info in Press Hunt's database of 580,000 journalists"} />

                  <meta itemprop="name" content={data.name + " journalist email and phone number " + (data.outlet_text[0] && data.outlet_text !== "[]" ? data.outlet_text : "") + " – Press Hunt"} />
                  <meta itemprop="description" content={"Find " + data.name +"'s email, phone number, and contact info in Press Hunt's database of 580,000 journalists"} />
                  <meta itemprop="image" content={data.avatar ? data.avatar : "https://i.imgur.com/uOpUy46.png" } alt={data.name + " journalist photo"} />

                  <meta property="og:url" content={"https://presshunt.co/journalists/" + this.props.match.params.slug} />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content={data.name + " journalist email and phone number " + (data.outlet_text[0] && data.outlet_text !== "[]" ? data.outlet_text : "") + " – Press Hunt"} />
                  <meta property="og:description" content={"Find " + data.name +"'s email, phone number, and contact info in Press Hunt's database of 580,000 journalists"} />
                  <meta property="og:image" content={data.avatar ? data.avatar : "https://i.imgur.com/uOpUy46.png" } alt={data.name + " journalist photo"} />

                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:title" content={data.name + " journalist email and phone number " + (data.outlet_text[0] && data.outlet_text !== "[]" ? data.outlet_text : "") + " – Press Hunt"} />
                  <meta name="twitter:description" content={"Find " + data.name +"'s email, phone number, and contact info in Press Hunt's database of 580,000 journalists"} />
                  <meta name="twitter:image" content={data.avatar ? data.avatar : "https://i.imgur.com/uOpUy46.png" } alt={data.name + " journalist photo"} />

                  <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

                </Helmet>
                <section className="section">
                  <div className="container">
                    <div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
                      <div className="column is-4">
                        <div className="is-profile-left-action-card">
                          <p className="is-action-card-title has-text-white">Subscribe today for full access to our database of 580,000+ journalists</p>
      										<p className="is-action-card-para">Premium data, emails, phone numbers, and search filters are redacted until you subscribe.</p>
                          <p className="is-action-card-para">After subscribing to a paid plan, you can also create media lists of your favorite journalists and export their contact information to CSV! Press Hunt plans start at just $69 and you can upgrade or cancel at any time.</p>
      										<a href="/signup">
      											<button className="button is-blue is-profile-cta-button">Get started for free</button>
      										</a>
                        </div>
                      </div>
                    <div className="column">
                      <div className="is-profile-right-buttons">
                        <div className="columns is-profile-right-buttons-columns">
                          <div className="column is-half is-profile-right-buttons-column">
                            <Link to={{
                              pathname: "/journalists",
                              state: { page: this.state.currentPage }
                            }}>
                              <div className="button is-profile-back-button"><i className="fas fa-chevron-left" style={{opacity:'.25'}}></i>&nbsp;&nbsp;&nbsp;Back to journalist directory</div>
                            </Link>
                          </div>
                          <div className="column is-half is-pulled-right is-profile-right-buttons-column">
                            <a className="button is-new-journalist-button-dir" href="/journalists/new/?ref=profile">Submit new journalist</a>
                          </div>
                        </div>
                      </div>
                      <div className="is-profile-right-card">
                      <div className="search-card-header">
                        {/*avatar image conditional*/}
                        {(data.avatar) ? (<img src={data.avatar} alt="avatar" className="image is-64x64 search-card-picture" />)
                          : <img src={Placeholder} alt="Placeholder" className="image is-64x64 search-card-picture" />
                        }

                        <p className="title is-4 search-card-name">{data.name}</p>
                      </div>
                      <div className="search-card-contact">
                        {/*twitter bio conditional */}
                        {(data.twitter_bio) ? (
                          <p className="twitter-bio">{data.twitter_bio}</p>) : <span />
                        }

                        {/*outlet conditional*/}
                        {(data.outlet_text[0] && data.outlet_text !== "[]") ?  (
                        <p className="is-5 search-card-contact-tag">
                          <span className="icon is-small is-industry-icon">
                            <i className="fas fa-briefcase" aria-hidden="true"></i>
                          </span>
                          {data.outlet_text}
                        </p>) : <span />}

                        {(data.email) ? (
                        <a href="/pricing" className="is-social-button-link">
                          <p className="is-5 search-card-contact-tag">
                            <span className="icon is-small is-industry-icon">
                              <i className="fas fa-envelope" aria-hidden="true"></i>
                            </span>
                            {'premium' + data.email.substring(data.email.lastIndexOf("@"))}
                          </p>
                        </a>) : <span />}
                        {(data.phone) ? (
                        <p className="is-5 search-card-contact-tag is-premium-hidden">
                          <span className="icon is-small is-industry-icon">
                            <i className="fas fa-phone" aria-hidden="true"></i>
                          </span>
                          {'* premium data *'}
                        </p>) : <span />}

                        {/*twitter conditional*/}
                        { data.twitter_name ? 
                        <Link to="/pricing" className="is-social-button-link">
                          <p className="is-5 search-card-contact-tag">
                            <span className="icon is-small is-industry-icon">
                              <i className="fab fa-twitter" aria-hidden="true"></i>
                            </span>
                            {data.twitter_name}
                          </p>
                        </Link> : <span />}
                      </div>

                      <div className="search-card-tags">
                        {/*website conditional*/}
                        {(data.website && (data.website[0].length!==0)) ?  (
                        <p className="is-5 search-card-contact-tag">
                          <span className="icon is-small is-industry-icon">
                            <i className="fas fa-link" aria-hidden="true"></i>
                          </span>
                          <a href={data.website} target="_blank" className="is-social-button-link">Website</a>
                        </p>) : <span />}

                        {/*type conditional*/}
                        {(data.type && (data.type[0].length!==0)) ?  (
                        <p className="is-5 search-card-contact-tag">
                          <span className="icon is-small is-industry-icon">
                            <i className="far fa-address-book" aria-hidden="true"></i>
                          </span>
                          {data.type}
                        </p>) : <span />}

                        </div>
                        </div>

                        <div className="is-profile-right-card">
                          <p className="is-interests-title is-size-5 is-subtitle">Beats</p>
                            {/*industry conditional*/}
                            {(data.industry && (data.industry[0].length!==0) && Array.isArray(data.industry)) ? (
                              data.industry.map((industry) =>
                                <div key={industry} className="is-5 search-card-industry-tag">
                                  <span className="icon is-small is-industry-icon">
                                    <i className="fas fa-industry" aria-hidden="true"></i>
                                  </span>
                                  {industry}
                                </div>
                              )
                            ) : (
                              <span />
                            )}
                          <div className="line-separator"></div>
                          <p className="is-interests-title is-size-5 is-subtitle">Recent Interests</p>
                            {/*tags conditional*/}
                            {(data.tags && (data.tags[0].length!==0)) ? (
                              data.tags.map((tag) =>
                                <div key={tag} className="is-search-card-tag">
                                  <span className="icon is-small is-tag-icon">
                                    <i className="fas fa-tag" aria-hidden="true"></i>
                                  </span>
                                  {tag}
                                </div>
                              )
                            ) : (
                              <span />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <Footer />
              </div>
            );
          }}
        />
      </Page>
    )
  }
}

export default Profile;
