import React, { Component } from 'react';
import { CSVLink } from 'react-csv';

import '../../styles/tailwind.output.css';

class Csv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvArr: [],
    };
  }

  buildCsv() {
    console.log("Build CSV Triggered!")
    const entries = this.props.entries;
    const plan = this.props.plan;
    var entryArr = [];
    var firstName = "";
    var lastName = "";
    var fullName = "";
    if (plan === 3 || plan === 30 || plan === 4 || plan === 40 || plan === 5 || plan === 50 || plan === 6 || plan === 60 || plan === 7 || plan === 70 ) {
      var csvArr = [ ['name', 'first', 'last', 'email', 'phone','media outlet', 'twitter', "website", "outlet domain", "podcast twitter", "podcast instagram", "podcast youtube", "podcast facebook"], ];
      Object.keys(entries).map( key => {
        let entry = entries[key].data();
        if (!entry.csvUpload && entry.name && typeof entry.name === 'string') {
          if (entry.dataType === "broadcaster" && entry.first_name !== "") {
              return (
                firstName = entry.first_name,
                lastName = entry.last_name,
                entryArr.push(firstName + " " + lastName, firstName, lastName, entry.email, entry.phone, entry.outlet_text || entry.author, entry.twitter_name, entry.website || entry.itunes_url, entry.email_domain, entry.twitter ? "https://twitter.com/" + entry.twitter : "", entry.instagram ? "https://instagram.com/" + entry.instagram : "", entry.youtube, entry.facebook ? "https://facebook.com/" + entry.facebook : ""),
                csvArr.push(entryArr),
                entryArr = []         
              )
            } else {
              return (
                firstName = entry.name.substr(0, entry.name.indexOf(" ")),
                lastName = entry.name.split(" ").splice(-1)[0],
                entryArr.push(entry.name, firstName, lastName, entry.email, entry.phone, entry.outlet_text || entry.author, entry.twitter_name, entry.website || entry.itunes_url, entry.email_domain, entry.twitter ? "https://twitter.com/" + entry.twitter : "", entry.instagram ? "https://instagram.com/" + entry.instagram : "", entry.youtube, entry.facebook ? "https://facebook.com/" + entry.facebook : ""),
                csvArr.push(entryArr),
                entryArr = []
              )         
            }
        } if (entry.csvUpload) {
          return (
            fullName = entry.firstName.concat(" ", entry.lastName),
            entryArr.push(fullName, entry.firstName, entry.lastName, entry.email, entry.phone, entry.outlet, entry.twitter, entry.homePageURL, entry.homePageURL),
            csvArr.push(entryArr),
            entryArr = []
          )
        } else {
          return (
            entryArr.push(entry.title, "-", "-", entry.email, entry.phone, entry.outlet_text || entry.author, entry.twitter_name, entry.website || entry.itunes_url, entry.email_domain, entry.twitter ? "https://twitter.com/" + entry.twitter : "", entry.instagram ? "https://instagram.com/" + entry.instagram : "", entry.youtube, entry.facebook ? "https://facebook.com/" + entry.facebook : ""),
            csvArr.push(entryArr),
            entryArr = []
          )
        }
      })
    } else if ( plan === 2 || plan === 20) {
      var csvArr = [ ['name', 'first', 'last', 'email', 'media outlet', 'twitter', "website", "outlet domain", "podcast twitter", "podcast instagram", "podcast youtube", "podcast facebook"], ];
      Object.keys(entries).map( key => {
        let entry = entries[key].data();
        if (!entry.csvUpload && entry.name && typeof entry.name === 'string') {
          if (entry.dataType === "broadcaster" && entry.first_name !== "") {
              return (
                firstName = entry.first_name,
                lastName = entry.last_name,
                entryArr.push(firstName + " " + lastName, firstName, lastName, entry.email, entry.outlet_text || entry.author, entry.twitter_name, entry.website || entry.itunes_url, entry.email_domain, entry.twitter ? "https://twitter.com/" + entry.twitter : "", entry.instagram ? "https://instagram.com/" + entry.instagram : "", entry.youtube, entry.facebook ? "https://facebook.com/" + entry.facebook : ""),
                csvArr.push(entryArr),
                entryArr = []         
              )
            } else {
              return (
                firstName = entry.name.substr(0, entry.name.indexOf(" ")),
                lastName = entry.name.split(" ").splice(-1)[0],
                entryArr.push(entry.name, firstName, lastName, entry.email, entry.outlet_text || entry.author, entry.twitter_name, entry.website || entry.itunes_url, entry.email_domain, entry.twitter ? "https://twitter.com/" + entry.twitter : "", entry.instagram ? "https://instagram.com/" + entry.instagram : "", entry.youtube, entry.facebook ? "https://facebook.com/" + entry.facebook : ""),
                csvArr.push(entryArr),
                entryArr = []
              )         
            }
        } if (entry.csvUpload) {
          return (
            fullName = entry.firstName.concat(" ", entry.lastName),
            entryArr.push(fullName, entry.firstName, entry.lastName, entry.email, entry.outlet, entry.twitter, entry.homePageURL, entry.homePageURL),
            csvArr.push(entryArr),
            entryArr = []
          )
        } else {
          return (
            entryArr.push(entry.title, "-", "-", entry.email, entry.outlet_text || entry.author, entry.twitter_name, entry.website || entry.itunes_url, entry.email_domain, entry.twitter ? "https://twitter.com/" + entry.twitter : "", entry.instagram ? "https://instagram.com/" + entry.instagram : "", entry.youtube, entry.facebook ? "https://facebook.com/" + entry.facebook : ""),
            csvArr.push(entryArr),
            entryArr = []
          )
        }
      })
    }
    this.setState({
      csvArr: csvArr,
    })
  }

  render() {
    const {
      csvArr,
    } = this.state;

    return (

      <CSVLink
        filename={"press-hunt-media-list.csv"}
        onClick={this.buildCsv.bind(this)}
        data={csvArr}
        className="mr-0 text-base bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
        target='_self'
      >
        <svg className="opacity-50 fill-current outline-current h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
          <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v4m0 0l-2-2m2 2l2-2" />
        </svg>
        <span className="ml-2">Download CSV</span>
      </CSVLink>

    );
  }
}

export default Csv;
