import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  Stats,
} from 'react-instantsearch-dom';
import searchClient from '../Dashboard/algoliaCred';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Helmet } from "react-helmet";
import Footer from './footer';
import Hero from '../../assets/requests-hero.svg';
import MobileHero from '../../assets/hero-mobile.svg';
import Nav from '../layout/navigation';
import Users from '../../assets/howler-users.svg';
import Media from '../../assets/media-outlets-prod.svg';
import FreeArticleResult from '../search/FreeHeavenArticleResult';

class ArticleLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      searchTerm: "",
      isJournalistActive: true,
      isPodcastActive: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.randomize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  randomize() {
    var number = Math.floor((Math.random() * 6) + 1);
    switch(number) {
      case 1:
        this.setState({
          searchTerm: "techcrunch.com"
        });
        break;
      case 2:
        this.setState({
          searchTerm: "nytimes.com"
        });
        break;
      case 3:
        this.setState({
          searchTerm: "Fashion"
        });
        break;
      case 4:
        this.setState({
          searchTerm: "Technology"
        });
        break;
      case 5:
        this.setState({
          searchTerm: "Sports"
        });
        break;
      default:
        this.setState({
          searchTerm: "techcrunch.com"
        });
    }
  }

  toggleJournalistNav = () => {
    this.setState(prevState => ({
      isJournalistActive: !prevState.isJournalistActive,
      isPodcastActive: !prevState.isPodcastActive
    }))
  }
  togglePodcastNav = () => {
    this.setState(prevState => ({
      isPodcastActive: !prevState.isPodcastActive,
      isJournalistActive: !prevState.isJournalistActive
    }))
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Get more press by monitoring news articles with alerts and search filters</title>
          <meta name="description" content="Monitor thousands of articles everyday, set PR alerts, and pitch relevant journalists when they write something new" />

          <meta itemprop="name" content="Get more press by monitoring news articles with alerts and search filters" />
          <meta itemprop="description" content="Monitor thousands of articles everyday, set PR alerts, and pitch relevant journalists when they write something new" />
          <meta itemprop="image" content="https://i.imgur.com/dYgCQvu.png" />

          <meta property="og:url" content="https://presshunt.co/news-monitoring" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Get more press by monitoring news articles with alerts and search filters" />
          <meta property="og:description" content="Monitor thousands of articles everyday, set PR alerts, and pitch relevant journalists when they write something new" />
          <meta property="og:image" content="https://i.imgur.com/dYgCQvu.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Get more press by monitoring news articles with alerts and search filters" />
          <meta name="twitter:description" content="Monitor thousands of articles everyday, set PR alerts, and pitch relevant journalists when they write something new" />
          <meta name="twitter:image" content="https://i.imgur.com/dYgCQvu.png" />

          <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

        </Helmet>

        <Nav />

        { this.state.width > 900 ? <img
          src={ Hero }
          className="is-overlay"
          alt="desktop hero"
        /> : <img
          src={ MobileHero }
          className="is-overlay"
          alt="mobile hero"
        />}

        <section className="section has-bg-image is-centered has-text-centered" style={{ height: "28em" }}>
        <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

          <div className="columns">
            <div className="column">
              <div className="container is-overlay has-padding-bottom">
                <div className="hero-div-width">
                  <h1 className="title has-text-white has-text-weight-bold is-hero-title-center is-size-3">
                    Get more press by monitoring news articles with search filters & alerts
                  </h1>
                  <h2 className="subtitle has-text-white has-text-weight-normal is-hero-title-center is-size-4" style={{opacity:'.75'}}>
                    Monitor thousands of articles everyday & pitch relevant journalists when they write something new
                  </h2>
                   <br />
                </div>

                <a className="button is-stripe-hero-exclamation" href="/pricing">Discover journalists&nbsp;&nbsp;<i class="fas fa-arrow-right"></i></a>
                <AnchorLink href="#search" className={ this.state.isActive ? 'button is-hiw-hero' : 'button is-hiw-hero-exclamation'}>Try for free</AnchorLink>
                <p className="has-text-white is-size-6 is-hero-sub">

                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns has-text-centered is-centered">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2 has-padding-bottom">
                  <span role="img" aria-label="magnifying glass">🔎</span>
                </div>
                <div className="title is-size-4 para-title">
                  Search through thousands of news articles everyday complete with journalist contact info
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="notification bell">🔔</span>
                </div>
                <div className="title is-size-4 para-title">
                  Set alerts to get an email whenever a target journalist mentions keywords in their article
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="articles">🗃</span>
                </div>
                <div className="title is-size-4 para-title">
                  Filter articles based on the writer's location, beats, and media outlet
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section is-paddingless">
          <img
            src={ Media }
            style={{width:'100%',zIndex:'0'}}
            alt="media logos"
          />
        </section>

        <section className="section has-background-white" id="search">
          <div className="container is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered" id="pricing">Monitor news from all over the world</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">You can try using Press Hunt for free below. Premium data is redacted until you subscribe.</p>
            </div>
            <div className="has-text-centered">
              <div className="trial-search-wrapper-articles is-centered">
              <div className={this.state.isJournalistActive ? "trial-search-results-container is-centered" : "not-displayed"}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName="articles"
                >
                  <SearchBox
                    autofocus
                    translations={{ placeholder: "Try searching our database for free…" }}
                    defaultRefinement={this.state.searchTerm}
                  />

                  <Configure hitsPerPage={2} />
                  <Hits hitComponent={FreeArticleResult} />
                  <Stats className="has-text-weight-semibold" />
                </InstantSearch>
              </div>
                <a className="button is-new-journalist-button-home" href="/journalists/new/?ref=home">Submit new journalist</a>
              </div>
            </div>

          </div>
        </section>

        <section className="section" id="howitworks">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">How it works</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Getting started with Press Hunt only takes a few seconds!</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="robot">🤖</span>
                </div>
                <div className="title is-size-4 para-title">
                  1. We monitor thousands of news articles everyday, match them to journalists, then display journalist contact & metadata
                </div>

              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="star">⭐️</span>
                </div>
                <div className="title is-size-4 para-title">
                  2. Use search filters to find relevant articles, add your favorite reporters to media lists, and even set alerts to monitor news while you sleep
                </div>

              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="letter">💌</span>
                </div>
                <div className="title is-size-4 para-title">
                  3. Start conversations with your target journalists the second they write about something relevant
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="has-text-centered is-centered">
            <p className="is-size-6 has-text-weight-normal used-by">Our database has been used to run PR campaigns for companies like:</p>
            <img
              src={ Users }
              style={{width:'100%',zIndex:'0'}}
              alt="User logos"
            />
            <a href="https://www.producthunt.com/posts/press-hunt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=123406&theme=dark"} alt="Press Hunt - Find the best journalists to cover you with our DB of 500k 🎉 | Product Hunt Embed"  className="mx-auto" style={{width: "250px", height: "54px", marginTop:'2em'}} />
            </a>
            <a href="https://www.producthunt.com/posts/press-hunt-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt-2-0" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=141052&theme=dark"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
            </a>
            <a href="https://www.producthunt.com/posts/pr-requests?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pr-requests" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180815&theme=dark&period=daily"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
            </a>
          </div>
        </section>
        
        <section className="section has-background-white">
          <div className="container has-text-centered is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">What do you get with Press Hunt’s news monitoring database?</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub"></p>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="articles">🗃</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Thousands of articles aggregated everyday with media contact emails and social media info at your fingertips</p>

                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="magnifying glass">🔎</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Search through our entire article database, and even filter by journalist locations, beats, and media outlets</p>

                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="notification bell">🔔</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Set alerts to get an email notification whenever one of your target journalists mentions keywords in their article</p>

                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="message">💬</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Start conversations & build relationships with reporters, without manually reading every article every journalist writes</p>

                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="crystal ball">🔮</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Discover new journalists based on the articles they’re writing</p>

                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="star">🌟</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Save your favorite journalists to media lists, then pitch them your news stories</p>

                </div>
              </div>
            </div>
            <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i class="fas fa-arrow-right"></i></a>
          </div>
        </section>

        <section className="section">
          <div className="container clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Our users love us</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Our database has been used by dozens of companies from all over the world</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "Press Hunt has a seemingly endless database and tons of little details on each journalist - feels like clearbit for PR. Rounded off with a simple UI, this product is brilliant for anyone trying to get their name out there to relevant journalists."
                </div>
                <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> Founder of ConcreteCapital</p>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "We’ve been using this to reach out to journalists about our hackathons and startup competition at NYU. Much better than researching reporters on Google and finding their emails manually."
                </div>
                <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> President of NYU Innovention Society</p>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "With Press Hunt you can market to the right audience by choosing journalists who write for the people you're trying to reach."
                </div>
                <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> Bitrix24 Tool of the Week</p>
              </div>
            </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i class="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </section>


        <section className="section" id="FAQ">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">FAQ</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">If our FAQ doesn't answer your questions, please reach out to us in our live chat in the bottom right.</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What kind of articles do you have in this database?
                </div>
                <p className="is-size-6">Press Hunt’s news monitoring database has thousands of articles that cover every field you can imagine: sports, fashion, tech, startups, science, crypto, art, theater, etc.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What kind of journalists do you have in this database?
                </div>
                <p className="is-size-6">Press Hunt has hundreds of thousands of journalists that cover every field you can imagine: sports journalists, fashion journalists, tech journalists, local new reporters, startups, science, crypto, art, theater, etc. Our journalists cover everywhere from the USA to China and Europe to Australia––we have journalists on every continent except Antarctica.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What do I do with the journalist emails?
                </div>
                <p className="is-size-6">You can email each journalist one-by-one; or you can add them to your own media list, then export their emails to CSV. You can then take your CSV and upload to mass emailing software like MixMax or Reply.io. Or, you can automate this entire process by using <a href="/sequences" className="is-link">PR Sequences</a>.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What is a media list?
                </div>
                <p className="is-size-6">A media list is simply a list of journalists created for a specific purpose. If you’re announcing a new product launch, you can put together a list of a few hundred journalists in your field that you think will be interested in covering your launch (based on the writing interests tags and other data we’ve provided for you). When you launch, you can send them all emails!</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Why can’t I see journalist emails?
                </div>
                <p className="is-size-6">Our basic package doesn’t come with access to journalist emails––just their social media links. If you’re on a plan that includes emails, and you don’t see some, it is likely because we don’t have emails on record for that journalist or media outlet. This can be due to many reasons, from GDPR or privacy regulations to them not having their email publicly accessible.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  How often do you update this article database?
                </div>
                <p className="is-size-6">Every day we collect and aggregate thousands of articles across the web through various sources and run queries against our journalist database to make sure we have contact info for each of the authors of the articles.</p>
              </div>
            </div>

          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default ArticleLanding;
