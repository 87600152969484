import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';

const BASE_URL = process.env.REACT_APP_PRESSHUNT_API_DOMAIN;

export const getAccessToken = async () => {
  if (moment.tz('GMT+0').diff(localStorage.getItem('accessTokenExpiresAt'), 'seconds') > -1800) {
    await refreshToken();

    return localStorage.getItem('accessToken');
  }

  return localStorage.getItem('accessToken');
};

export const refreshToken = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', 'presshunt');
  params.append('client_secret', 'fskefgtarwdbawydrawpdpaiuiawdtg');
  params.append('refresh_token', refreshToken);

  await fetchUserToken(params, accessToken).then((res) => {
    localStorage.setItem('accessToken', res.data.accessToken);
    localStorage.setItem('accessTokenExpiresAt', res.data.accessTokenExpiresAt);
    localStorage.setItem('refreshToken', res.data.refreshToken);
    localStorage.setItem('refreshTokenExpiresAt', res.data.refreshTokenExpiresAt);
    return res.data.accessToken;
  })
    .catch(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessTokenExpiresAt');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshTokenExpiresAt');
    });
};

export const fetchUserToken = (params, accessToken) => axios.post(`${BASE_URL}/api/v1/users/token`, params,
  {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
