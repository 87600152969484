import styled from 'styled-components'

const DatePickerPopper = styled.div`
  .react-datepicker-popper {
    z-index:20
  }
  .react-datepicker__header {
    background-color: rgba(66, 153, 225, var(--tw-bg-opacity));
  }
  .react-datepicker__current-month {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .react-datepicker__day-name {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .react-datepicker-time__header {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .react-datepicker__navigation-icon::before {
    border-color: #fff;
    top: 12px;
  }
`

export {
  DatePickerPopper,
}