import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  RefinementList,
  Configure,
  ScrollTo,
} from 'react-instantsearch-dom';
import searchClient from './algoliaCred';
import CustomHits from './customHits';
import CustomHitsPerPage from './customHitsPerPage';
import SearchResult from '../search/HeavenArticleResult';
import TrialSearchResult from '../search/FreeHeavenArticleResult';
import UpgradeCard from './upgradeCard';

import Loading from '../misc/Loading';
import NoAuth from '../misc/noAuth';
import HeavenSearchFilters from './journoSearchFilter';

class ArticleDashboard extends Component {
  render() {
    const { auth, subscription, plan, status, lists } = this.props;

    return (
      <div className="max-w-6xl mx-auto py-10 px-4 sm:px-0 lg:px-0">
        {!auth ? (
          <NoAuth />
        ) : (subscription && subscription.tempStripePaymentTokenId) ? (
          <Loading />
        ) : ((auth && !subscription) || (auth && subscription && status !== "active")) ? (
          <section>
            <InstantSearch
              searchClient={searchClient}
              indexName="articles"
            >
              <ScrollTo>
                <div className="columns">
                  <NavLink className="column is-one-thirds heaven-cover-filters" to="/pricing" />
                  <div className="column is-one-thirds">
                    <UpgradeCard />
                    <HeavenSearchFilters locked area="Area" mediaOutlet="source_name" />
                  </div>
                  <div className="column is-two-thirds">
                    <div className="mb-5 float-right hidden md:flex">
                      <CustomHitsPerPage
                        defaultRefinement={3}
                        items={[
                          { value: 3, label: '3 contacts' },
                        ]}
                      />
                      <div className="journalist-search-add-all-div">
                        <NavLink className="heaven-page-cta-button" aria-haspopup="true" aria-controls="dropdown-menu3" to="/pricing">
                          <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-lock heaven-nav-link-icon-lock" style={{color:'white',opacity:'.5'}}/>
                        </NavLink>
                      </div>
                    </div>
                    <SearchBox
                      autofocus
                      translations={{ placeholder: "Search through articles..." }}
                      className="heaven-search-box"
                    />
                    <Configure hitsPerPage={3} />
                    <Hits hitComponent={TrialSearchResult} />
                  </div>
                </div>
              </ScrollTo>
            </InstantSearch>
          </section>
        ) : (subscription && status === "active") ? (
          <section>
            <InstantSearch
              searchClient={searchClient}
              indexName="articles"
            >
              <ScrollTo>
                <div className="columns">
                  <div className="column is-one-thirds">
                    <HeavenSearchFilters area="Area" mediaOutlet="source_name" />
                  </div>
                  <div className="column is-two-thirds">
                    <div className="mb-5 float-right hidden md:flex">
                      <CustomHitsPerPage
                        defaultRefinement={20}
                        items={[
                          { value: 20, label: '20 contacts' },
                          { value: 50, label: '50 contacts' },
                        ]}
                      />
                      {(plan === 2 || plan === 20 || plan === 3 || plan === 30 || plan === 4 || plan === 40 || plan === 5 || plan === 50 || plan === 6 || plan === 60 || plan === 7 || plan === 70) ? (
                        <Pagination
                          className="heaven-top-pagination"
                          translations={{
                            previous: 'Back',
                            next: 'Next',
                          }}
                          padding={0}
                          showLast={false}
                          showFirst={false}
                        />
                      ) : <span /> }
                      {(plan === 2 || plan === 20 || plan === 3 || plan === 30 || plan === 4 || plan === 40 || plan === 5 || plan === 50 || plan === 6 || plan === 60 || plan === 7 || plan === 70) ? (
                        <CustomHits dataType="journalist"/>
                      ) : (
                        <div className="journalist-search-add-all-div">
                          <NavLink className="heaven-page-cta-button" aria-haspopup="true" aria-controls="dropdown-menu3" to="/pricing">
                            <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-lock heaven-nav-link-icon-lock" style={{color:'white',opacity:'.5'}} />
                          </NavLink>
                        </div>
                      )}
                    </div>
                    <SearchBox
                      translations={{ placeholder: "Search through articles..." }}
                      className="heaven-search-box"
                      submit={<img src="/submit.png" alt=""/>}
                      focusShortcuts={['s']}
                    />
                    <Hits hitComponent={SearchResult} />
                    <div className="heaven-bottom-pag-div">
                      <Pagination
                        className="heaven-bottom-pagination"
                        translations={{
                          previous: 'Back',
                          next: 'Next',
                        }}
                        padding={2}
                        showLast={false}
                        showFirst={false}
                      />
                    </div>
                  </div>
                </div>
              </ScrollTo>
            </InstantSearch>
          </section>
        ) : (
          <NoAuth />
        )}
      </div>
    );
  }
}

export default ArticleDashboard;
