import Firebase from 'firebase/app';

const deleteCampaign = id => {
  return Firebase.firestore()
    .collection('campaigns')
    .doc(id)
    .delete()
    .catch( error => {
      alert(`Whoops, couldn't delete the campaign: ${error.message}`)
    })
}

export default deleteCampaign;
