import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FirebaseUser from '../misc/FirebaseUser';
import StripeCheckout from '../subscriptions/StripeCheckout';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Helmet} from "react-helmet";
import Navigation from './boost-nav';
import Footer from './footer';
import Hero from '../../assets/boost-hero.svg';
import MobileHero from '../../assets/boost-hero-mobile.svg';
import Matt from '../../assets/matt-aidem.png';
import Aaron from '../../assets/aaron.png';
import Daniel from '../../assets/daniel.png';
import Write from '../../assets/write.png';
import Media from '../../assets/media-outlets.svg';
import Rumblr from '../../assets/rumblr.png';
import Users from '../../assets/howler-users.svg';
import Error from '../misc/Error';
import Chad from '../../assets/chad.jpeg';
import Nile from '../../assets/nile.jpeg';
import Tim from '../../assets/optemization.jpg';
import Lukas from '../../assets/lukas.jpg';
import Will from '../../assets/will.jpeg';
import Jack from '../../assets/jack-darknet.jpg';

class Boost extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }



  render() {
    console.log(this.state.width);

    return (
      <div>
        <Helmet>

          <title>Get your startup in the news with PR Boost by Press Hunt 🚀</title>
          <meta name="description" content="PR Boost matches you with thousands of journalists for your PR campaign to get your startup featured in the news" />

          <meta itemprop="name" content="Get your startup in the news with PR Boost by Press Hunt 🚀" />
          <meta itemprop="description" content="PR Boost matches you with thousands of journalists for your PR campaign to get your startup featured in the news" />
          <meta itemprop="image" content="https://i.imgur.com/EIQ67IR.png" />

          <meta property="og:url" content="https://presshunt.co/boost" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Get your startup in the news with PR Boost by Press Hunt 🚀" />
          <meta property="og:description" content="PR Boost matches you with thousands of journalists for your PR campaign to get your startup featured in the news" />
          <meta property="og:image" content="https://i.imgur.com/EIQ67IR.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Get your startup in the news with PR Boost by Press Hunt 🚀" />
          <meta name="twitter:description" content="PR Boost matches you with thousands of journalists for your PR campaign to get your startup featured in the news" />
          <meta name="twitter:image" content="https://i.imgur.com/EIQ67IR.png" />

        </Helmet>

        <Navigation />
        { this.state.width > 900 ? <img
          src={ Hero }
          className="is-overlay"
        /> : <img
          src={ MobileHero }
          className="is-overlay"
        />}

        <section className="section has-bg-image is-centered has-text-centered" style={{ height: "26em" }}>
        <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

          <div className="columns">
            <div className="column">
              <div className="container is-overlay has-padding-bottom">
                <div className="hero-div-width">
                  <h1 className="title has-text-white has-text-weight-bold is-hero-title-center is-size-3">
                    Get your startup in the news by running PR campaigns powered by AI
                  </h1>
                  <h2 className="subtitle has-text-white has-text-weight-normal is-hero-title-center is-size-4" style={{opacity:'.75'}}>
                    Boost matches you with thousands of journalists in your niche, then our team of PR experts writes and sends custom press pitches to them to help you get featured
                  </h2>
                   <br />
                </div>

                <a className="button is-stripe-hero-exclamation" href="#pricing">Start your campaign today</a>
                <AnchorLink href="#howitworks" className={ this.state.isActive ? 'button is-hiw-hero' : 'button is-hiw-hero-exclamation'}>How it works</AnchorLink>
                <p className="has-text-white is-size-6 is-hero-sub">

                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns has-text-centered is-centered">
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  💌
                </div>
                <div className="title is-size-4 para-title">
                  Discover journalists with our PR database of 1M+ reporters, podcasts, and influencers
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  🔮
                </div>
                <div className="title is-size-4 para-title">
                  Match with thousands of journalists and podcasts using our AI
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  🤝
                </div>
                <div className="title is-size-4 para-title">
                  Our team of PR experts writes pitches and runs PR campaigns for you
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section" style={{marginBottom: '30px'}}>
          <div className="has-text-centered is-centered">
            <p className="is-size-6 has-text-weight-normal used-by">Thousands of companies have run PR campaigns with Press Hunt:</p>
            <img
              src={ Users }
              style={{width:'100%',zIndex:'0'}}
              alt="User logos"
            />
            <a href="https://www.producthunt.com/posts/press-hunt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt" target="_blank"><img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=123406&theme=dark"} className="mx-auto mt-2" alt="Press Hunt - Find the best journalists to cover you with our DB of 500k 🎉 | Product Hunt Embed" style={{width: "250px", height: "54px"}} /></a>
            <a href="https://www.producthunt.com/posts/press-hunt-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt-2-0" target="_blank"><img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=141052&theme=dark"}  className="mx-auto my-2" style={{width: "250px", height: "54px"}} /></a>
            <a href="https://www.producthunt.com/posts/pr-requests?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pr-requests" target="_blank"><img src={"https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180815&theme=dark&period=daily"} className="mx-auto" style={{width: "250px", height: "54px"}} /></a>
          </div>
        </section>

        {/*<section>
          <div className="columns">
            <div className="column">
              <div className="card is-boost-pricing-card" id="pricing" style={{float: 'right'}}>
                <div className="card-header-title is-centered">
                  <span role="img" aria-label="UFO">🛸&nbsp;</span> PR Boost Basic
                </div>
                <header className="pricing-card-header-emphasis">

                  <div className="title has-text-centered">
                    { this.state.checked ? <div>$583.25<span style={{ fontSize:"14px" }}>/month</span></div>  : <div>$699<span style={{ fontSize:"14px" }}>/month</span></div>  }
                  </div>
                  <div className="subtitle has-text-centered is-size-6 is-transparent">
                    { this.state.checked ? "billed annually" : "billed monthly" }
                  </div>
                </header>
                <div className="card-content pricing-card-content">
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Everything in <a href="/pricing" target="_blank" className="is-link">Press Hunt Premium</a>
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;1 PR campaign ran by our team of PR experts every month
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;1 PR campaign to 1,000 journalists every month
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;1 custom-written press pitch every month with 3 follow ups per journalist
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Daily report of PR campaign outreach stats
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Dedicated account manager
                  </div>
                  <div className="buttons is-centered">
                    <FirebaseUser>
                      { ({ isLoading, error, auth, stripeCustomerId, email }) => {
                        if (isLoading)
                          return <button className="is-pricing-button">Buy now!</button>
                        if (error)
                          return <Error error={error} />
                        if (!auth)
                          return <Link to="/signup"><button className="is-pricing-button">Buy now!</button></Link>
                        if (auth)
                          return <StripeCheckout plan="boostBasic" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                      }}
                    </FirebaseUser>
                  </div>
                </div>
                </div>
                </div>
                <div className="column">
                <div className="card is-boost-pricing-card" id="pricing" style={{float: 'left'}}>
                  <div className="card-header-title is-centered">
                    <span role="img" aria-label="stars">✨&nbsp;</span> PR Boost Growth
                  </div>
                  <header className="pricing-card-header-emphasis">

                    <div className="title has-text-centered">
                      { this.state.checked ? <div>$833.25<span style={{ fontSize:"14px" }}>/month</span></div>  : <div>$999<span style={{ fontSize:"14px" }}>/month</span></div>  }
                    </div>
                    <div className="subtitle has-text-centered is-size-6 is-transparent">
                      { this.state.checked ? "billed annually" : "billed monthly" }
                    </div>
                  </header>
                  <div className="card-content pricing-card-content">
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Everything in PR Boost Basic
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;1 PR campaign to 2,000 journalists every month
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;1 PR campaign to 500 podcasts every month
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;1 custom-written press pitch every month with 3 follow ups per journalist
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Press Release distributed to CBS, FOX, ABC, & NBC syndicates
                    </div>
                    <div className="buttons is-centered">
                      <FirebaseUser>
                        { ({ isLoading, error, auth, stripeCustomerId, email }) => {
                          if (isLoading)
                            return <button className="is-pricing-button">Buy now!</button>
                          if (error)
                            return <Error error={error} />
                          if (!auth)
                            return <Link to="/signup"><button className="is-pricing-button">Buy now!</button></Link>
                          if (auth)
                            return <StripeCheckout plan="boostGrowth" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                        }}
                      </FirebaseUser>
                    </div>
                  </div>
                  </div>
                  </div>
            </div>
        </section> */}

        <section className="section is-paddingless">
          <img
            src={ Media }
            style={{width:'100%',zIndex:'0'}}
          />
        </section>

        <section className="section has-background-white">
          <div className="container has-text-centered is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered" id="pricing">Why use Boost to run your PR?</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">It normally takes hundreds of hours and thousands of dollars to run an effective PR campaign, but Boost campaigns start at just $699 and take only minutes</p>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    ⭐️
                  </div>
                  <p className="title has-text-weight-bold is-size-4">PR Boost is integrated with one of the most advanced PR tools available</p>
                  <p className="subtitle is-size-6 is-feature-para">Press Hunt is a contact database of 550k journalists complete with emails & phone numbers <a className="is-link" href="https://presshunt.co/?ref=boost">(learn more about the database)</a></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    🤖
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Cutting edge journalist matching algorithms powered by AI</p>
                  <p className="subtitle is-size-6 is-feature-para">Our technology generates targeted media lists for you in minutes</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    ✌️
                  </div>
                  <p className="title has-text-weight-bold is-size-4">No long term contracts unlike traditional PR agencies</p>
                  <p className="subtitle is-size-6 is-feature-para">PR Boost is a simple monthly subscription you can cancel anytime unlike traditional PR agency contracts</p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    🍻
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Skip the weeks of work researching reporters, finding emails, and writing pitches</p>
                  <p className="subtitle is-size-6 is-feature-para">Our team, PR tools, and technology save you hundreds of hours of work</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    📝
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Our PR experts write compelling press pitches for you</p>
                  <p className="subtitle is-size-6 is-feature-para">We’ll work on your PR pitch until you’re satisfied––once you confirm the pitch we’ll queue it up for your PR campaign</p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    🚀
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Our PR experts run your PR outreach campaign for you</p>
                  <p className="subtitle is-size-6 is-feature-para">When journalists are interested in your story we’ll introduce you so you can set up a time for an interview</p>
                </div>
              </div>
            </div>
            <a className="button is-stripe-hero" href="#pricing">Subscribe now</a>
          </div>
        </section>

        <section className="section" id="howitworks">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">How it works</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Getting your PR campaigns started with Boost is simple and only takes a few minutes.</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  💬
                </div>
                <div className="title is-size-4 para-title">
                  1. After you sign up, you’ll fill out a quick form to help us understand your project, campaign, and goals
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  📝
                </div>
                <div className="title is-size-4 para-title">
                  2. We’ll start writing your pitch with your feedback, and refine it until you’re happy
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  🔮
                </div>
                <div className="title is-size-4 para-title">
                  3. We’ll run you through our journalist matching tool and build a custom media list for your campaign
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  💌
                </div>
                <div className="title is-size-4 para-title">
                  4. Our PR experts will run an email outreach campaign complete with follow-ups over the course of 2-3 weeks
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  🤝
                </div>
                <div className="title is-size-4 para-title">
                  5. When journalists are interested we’ll introduce you directly so you can set up an interview
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="is-size-2">
                  📊
                </div>
                <div className="title is-size-4 para-title">
                  6. Once your PR campaign is complete, we’ll send you a report of email open & reply rates
                </div>
              </div>
            </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="#pricing">Subscribe now</a>
            </div>
          </div>
        </section>

        <section className="section has-background-white">
          <div className="has-text-centered is-centered">
            <p className="is-size-6 has-text-weight-semibold used-by">We've ran PR campaigns for companies like:</p>
            <img
              src={ Users }
              style={{width:'100%',zIndex:'0'}}
            />
          </div>
        </section>

        <section className="section is-paddingless">
          <img
            src={ Media }
            style={{width:'100%',zIndex:'0'}}
          />
        </section>

        <section className="section">
          <div className="container clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">We've helped companies like yours get hundreds of news features</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Our PR campaigns have garnered millions of impressions from news websites, radio shows, and television segments all over the world from media outlets like The Washington Post, BBC, and VICE</p>
            </div>
            <div className="columns">
              <div className="column">
                <img
                  src={ Rumblr }
                  className="case-study-image"
                />
              </div>
              <div className="column is-para-case-study">
                <div className="title is-size-4 para-title">
                  Rumblr was featured by hundreds of news outlets in just four days
                </div>
                <p className="is-size-5">We used Boost’s PR outreach strategy to get the attention of reporters, helping Rumblr get featured by hundreds of media outlets like The Independent, Fox & Friends, and Business Insider in only four days</p>
                <a href="https://medium.com/the-mission/how-we-hacked-the-media-and-landed-six-figure-contracts-in-four-days-96ea4aca4eef" target="_blank">
                  <div className="button is-stripe-middle">READ THE CASE STUDY</div>
                </a>
              </div>
            </div>
          </div>

          <div className="container clear-section-card">
            <div className="columns">
                <div className="column is-hiw-card is-sample-card">
                  <a className="no-styling" href="https://www.independent.co.uk/life-style/gadgets-and-tech/news/rumblr-tinder-for-fighting-app-website-launch-date-beta-9-november-a6727561.html" target="_blank">
                    <div className="is-size-2 has-padding-bottom">
                      🥊
                    </div>
                    <div className="subtitle is-size-5 has-text-weight-bold">
                      Rumblr: The 'Tinder for fighting' website that allows users to arrange real-life brawls is set for launch
                    </div>
                    <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by The Independent for Rumblr</p>
                  </a>
                </div>
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://www.cltampa.com/music/music-news/article/20999358/radiate-the-tinder-of-coachella-also-works-at-sunset-music-festival-and-wanee" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    💃
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    The "Tinder for meeting people at Coachella" also works at Sunset Music Festival and Wanee
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by Creative Loafing: Tampa Bay for Radiate</p>
                </a>
              </div>
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://mashable.com/2018/02/21/bitcoin-regret-club/#LRdfkjVTyOqN" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    💸
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    This might be the most depressing website ever made
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by Mashable for Bitcoin Regret Club</p>
                </a>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://www.gonzagabulletin.com/news/article_b2319aba-2d46-11e8-9677-8333f5900a42.html" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    📣
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    March for Our Lives takes to the streets of Spokane this Saturday
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by The Gonzaga Bulletin for Betterplace</p>
                </a>
              </div>
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://www.stuff.tv/hot-stuff/tablets-computers/pureshape-minimalist-mousepad-apples-magic-mouse-inspired-dieter-rams" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    💻
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    PureShape is a minimalist mousepad for Apple’s Magic Mouse, inspired by Dieter Rams
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by Stuff for PureShape</p>
                </a>
              </div>
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://gameranx.com/features/id/141839/article/bacon-man-an-adventure-impressions-sizzling-porky-goodness/" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    🐷
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    Bacon Man: An Adventure Impressions—Sizzling Porky Goodness
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by Gameranx for Bacon Man: An Adventure</p>
                </a>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://www.makeuseof.com/tag/gmail-message-ids-specific-emails/" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    💌
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    How to Bookmark Individual Emails Using Gmail Message IDs
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by MakeUseOf for Gmail Message ID Finder</p>
                </a>
              </div>
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="http://www.iheartradio.ca/cjay92/trending/this-pool-sharing-site-is-genius-and-we-need-it-in-calgary-1.7383857" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    🏊
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    This Pool Sharing Site Is Genius and We Need It in Calgary
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by iHeartRadio for Swimply</p>
                </a>
              </div>
              <div className="column is-hiw-card is-sample-card">
                <a className="no-styling" href="https://observer.com/2016/02/how-rumblrs-marketing-agency-gamed-the-media-for-100k-in-business/" target="_blank">
                  <div className="is-size-2 has-padding-bottom">
                    🏴
                  </div>
                  <div className="subtitle is-size-5 has-text-weight-bold">
                    How Rumblr’s Marketing Agency Gamed the Media for $100k in Business
                  </div>
                  <p className="is-size-6 has-text-weight-normal" style={{opacity:'.5'}}>Featured by Observer for von Hughes</p>
                </a>
              </div>
            </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="#pricing">Subscribe now</a>
            </div>

          </div>
        </section>

        <section className="section">
          <div className="container clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Our users love us</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Our database has been used by dozens of companies from all over the world</p>
            </div>
            <div className="columns">
            <div className="column is-hiw-card">
              <div className="subtitle is-size-5 has-text-weight-normal">
                "Thanks for your hard work. I did get this interview on the Mike Koolidge show, which I guess is going to be broadcast later today to thousands of people? This kind of persistence is NOT something I could do on my own. So thanks for your hard work on it. That's the first win! Yay! Also I have the BBC interview setup for Thursday. So we'll see what happens then!"
              </div>
              <div className="testimonial-profile-pic-wrapper">
                <img
                  src={ Jack }
                  className="is-testimonial-photo"
                  >
                </img>
                <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Jack Rhysider, Host of Darknet Diaries podcast</p>
              </div>
            </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "I've launched a number of projects that I look for press and often the most time consuming part is finding and researching relevant journalists. I was extremely please with how easy Press Hunt is to use. I had a whole list of journalists that I wanted to contact but was unable to find accurate emails and Press Hunt did the job. I would highly recommend it."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Lukas }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Lukas Bentel, Founder of Hello Velocity</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "Press Hunt has a seemingly endless database and tons of little details on each journalist - feels like clearbit for PR. Rounded off with a simple UI, this product is brilliant for anyone trying to get their name out there to relevant journalists."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Nile }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name"> Nile Frater, Founder of ConcreteCapital</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "I've seen Matt and his team get millions of impressions and coverage for clients. I highly recommend their services."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Chad }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Chad Grills, Founder & CEO of The Mission</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "They did an excellent job increasing traffic to our website. We saw a major lift in both SEO and ASO after working with them."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Will }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Will Jamieson, Founder of Yik Yak & The Q</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "We’ve been using this to reach out to journalists about our hackathons and startup competition at NYU. Much better than researching reporters on Google and finding their emails manually."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Tim }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Tim Nugmanov, President of NYU Innovention Society</p>
                </div>
              </div>
              </div>
            <div className="columns">
              <div className="column is-email-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                Matt/Frances,<br /><br />
                Thanks for your hard work. I did get this interview on the Mike Koolidge show, which I guess is going to be broadcast later today to thousands of people? It's hard to tell what's happening on live radio.<br /><br />
                But Mike had some feedback for you. He said something like:<br /><br />
                "You know I get tons of emails from people wanting to be a guest on the show and they talk about themselves and how many other shows have interviewed them. But this one stood out because you personalized the email and used Mike's first name, and you followed up 3 times on it." So it wasn't until that 3rd email that he clicked the link and it made him interested.<br /><br />
                This kind of persistence is NOT something I could do on my own. So thanks for your hard work on it.<br /><br />
                That's the first win! Yay! Also I have the BBC interview setup for Thursday. So we'll see what happens then!<br /><br />
                - Jack
                </div>
              </div>
            </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="#pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container has-text-centered is-centered">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Our team</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">We're building the PR tools of the future in New York City & Southern California</p>
            </div>
            <div className="columns">
              <div className="column">
                <img
                  src= {Matt}
                  className="h-20 w-20 m-auto"
                />
                <p className="has-text-weight-bold is-size-4 person-title">Matt Henderson</p>
                <p className="is-size-6">
                  <b>Co-founder</b> • Growth marketer & PR consultant whose work has been featured by over 200 global media outlets from from The Washington Post to Bleacher Report to Conan O'Brien<br /><br />
                </p>
              </div>
              <div className="column">
                <img
                  src={Aaron}
                  className="h-20 w-20 m-auto"
                />
                <p className="has-text-weight-bold is-size-4 person-title">Aaron Tsai</p>
                <p className="is-size-6">
                  <b>Co-founder</b> • Full-stack software engineer, ex-cancer researcher at UCSD published by Science, built & launched 15 products in 2018 with West Vesey<br /><br />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section" id="FAQ">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">FAQ</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">If our FAQ doesn't answer your questions, please reach out to us in our live chat in the bottom right.</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  How is Boost so affordable? PR campaigns like these normally cost thousands of dollars.
                </div>
                <p className="is-size-6">We’ve built out our own PR tools and automations to dramatically cut costs for our team. We save money by building PR automations and pass those savings onto you.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Is Boost a PR agency?
                </div>
                <p className="is-size-6">Boost is an alternative to sluggish, expensive PR agencies. Boost is a PR tool powered by software, data science, a team of PR experts, and one of the largest journalist databases available.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Do I get full access to the PR software product Press Hunt when I sign up for Boost?
                </div>
                <p className="is-size-6">Yes, all Boost subscribers receive Premium access to Press Hunt. You can use Press Hunt to search through our journalist database, build lists of your favorite journalists, and export their contact information to CSV.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Do you guarantee press?
                </div>
                <p className="is-size-6">While we’ll do our best to get you a news feature, we unfortunately can’t guarantee press. We don’t offer refunds if you don’t get featured. By working with Boost, you acknowledge you understand this.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  How many campaigns should I run with Boost? Why is PR Boost a subscription?
                </div>
                <p className="is-size-6">We recommend running 3-5 PR campaigns with Boost, iterating the pitch and target journalist segments every campaign to get the best idea of what works and what doesn’t for your business. While this is recommended, we’ve had success with companies that have only run 1 campaign. Whatever you’d like to do, you can cancel your subscription any time you’d like. Boost doesn’t require you to sign a contract.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What is your conversion rate?
                </div>
                <p className="is-size-6">We calculate our conversion rate based on the number of matches we’re able to make between our clients and interested journalists. When a journalist responds to one of our PR campaigns with interest, we count that as a conversion. Our conversion rate is 1%-2%, about 4x the average cold outreach conversion rate of ~0.5%.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What is your refund policy?
                </div>
                <p className="is-size-6">Refunds are not permitted after we’ve begun writing the pitch for your campaign. If you accrue multiple campaigns over the course of a few months that you haven’t used, you can get a full refund for the last campaign. You can cancel your subscription with us at any time by talking to your account manager, or through our live chat on the bottom right.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Can you help me get featured by a news outlet that isn’t in English?
                </div>
                <p className="is-size-6">We only send PR outreach campaigns in English. However, it’s not uncommon that if your story is picked up by a primetime media outlet like the New York Times that outlets in other languages would pick the story up and cover it in their language.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  How long do Boost PR campaigns last?
                </div>
                <p className="is-size-6">Boost PR campaigns last about a month. Your subscription to Boost renews monthly, and every time your subscription renews, you are allotted one PR campaign.</p>
              </div>
              <div className="column is-hiw-card" style={{opacity:'0'}}>
                <div className="title is-size-5">

                </div>
                <p className="is-size-6"></p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Boost;
