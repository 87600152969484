import React, { Component } from 'react';
import {
  Link,
} from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from '../../assets/press-hunt-logo-clear.svg';
import JournalistDatabaseIcon from '../../assets/journalist-database-icon.svg';
import PodcastDatabaseIcon from '../../assets/podcast-database-icon.svg';
import PrBoostIcon from '../../assets/pr-boost-icon.svg';
import FireflySubscription from '../misc/FireflySubscription';
import PrRequestIcon from '../../assets/pr-request-card-icon.svg';
import SequencesIcon from '../../assets/sequences-icon-head.svg';

class Navigation extends Component {

  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }))
  }

  render() {
    return (
      <FireflySubscription>
        { ({isLoading, error, subscription, auth}) => {
          if (!auth) {
            return (
              <nav className="navbar">
                <div className="navbar-brand">
                  <div className="navbar-item logo">
                    <Link to="/">
                      <img
                        src= { Logo }
                        className="is-logo"
                        alt="Press Hunt logo"
                      />
                      <h1 className="has-text-white title is-size-4 is-logo-word">
                        Press Hunt
                      </h1>
                    </Link>
                  </div>
                  <div className="navbar-burger burger" onClick={this.toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                  <div className="navbar-end">
                    <div className="navbar-item">
                      <div className={ this.state.isActive ? 'field' : 'field is-grouped'}>

                        <div className="dropdown is-hoverable">
                          <div className="dropdown-trigger">
                            <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                              <Link to="" className={ this.state.isActive ? 'button is-clear is-nav-dropdown' : 'button is-clear is-nav-dropdown'} aria-haspopup="true" aria-controls="dropdown-menu">Product&nbsp;&nbsp;<i className="fas fa-angle-down" aria-hidden="true" style={{opacity:'.5'}}></i></Link>
                            </p>
                          </div>
                          <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content1">

                              <a href="/journalist-database">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {JournalistDatabaseIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">Journalist Database</p>
                                  </div>
                                  <p className="is-product-nav-description">A database of over 500,000 journalists to feature your business</p>
                                </div>
                              </a>

                              <a href="/podcasts">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {PodcastDatabaseIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">Podcast Database</p>
                                  </div>
                                  <p className="is-product-nav-description">A database of over 200,000 podcasts to pitch your business to</p>
                                </div>
                              </a>

                              <a href="/requests">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {PrRequestIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">PR Requests</p>
                                  </div>
                                  <p className="is-product-nav-description">A realtime database of thousands of source requests from journalists</p>
                                </div>
                              </a>

                              <a href="/sequences">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {SequencesIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">PR Sequences</p>
                                  </div>
                                  <p className="is-product-nav-description">Automagically email journalists when they mention keywords in articles & tweets</p>
                                </div>
                              </a>

                              <a href="/boost">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {PrBoostIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">PR Boost</p>
                                  </div>
                                  <p className="is-product-nav-description">A micro PR agency powered by our PR team, media datasets, and AI</p>
                                </div>
                              </a>

                            </div>
                          </div>
                        </div>

                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <AnchorLink href="#FAQ" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>FAQ</AnchorLink>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>Pricing</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/signin"><button className={ this.state.isActive ? 'button is-white' : 'button is-white'}>Sign in</button></Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/signup"><button className={ this.state.isActive ? 'button is-stripe-nav' : 'button is-boost-blue'}>Get started</button></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            )
          }
          if (!subscription) {
            return (
              <nav className="navbar">
                <div className="navbar-brand">
                  <div className="navbar-item logo">
                    <Link to="/">
                      <img
                        src= { Logo }
                        className="is-logo"
                        alt="Press Hunt logo"
                      />
                      <h1 className="has-text-white title is-size-4 is-logo-word">
                        Press Hunt
                      </h1>
                    </Link>
                  </div>
                  <div className="navbar-burger burger" onClick={this.toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                  <div className="navbar-end">
                    <div className="navbar-item">
                      <div className={ this.state.isActive ? 'field' : 'field is-grouped'}>

                        <div className="dropdown is-hoverable">
                          <div className="dropdown-trigger">
                            <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                              <Link to="" className={ this.state.isActive ? 'button is-clear is-nav-dropdown' : 'button is-clear is-nav-dropdown'} aria-haspopup="true" aria-controls="dropdown-menu">Product&nbsp;&nbsp;<i className="fas fa-angle-down" aria-hidden="true" style={{opacity:'.5'}}></i></Link>
                            </p>
                          </div>
                          <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content1">

                              <a href="/journalist-database">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {JournalistDatabaseIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">Journalist Database</p>
                                  </div>
                                  <p className="is-product-nav-description">A database of over 500,000 journalists to feature your business</p>
                                </div>
                              </a>

                              <a href="/podcasts">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {PodcastDatabaseIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">Podcast Database</p>
                                  </div>
                                  <p className="is-product-nav-description">A database of over 200,000 podcasts to pitch your business to</p>
                                </div>
                              </a>

                              <a href="/requests">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {PrRequestIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">PR Requests</p>
                                  </div>
                                  <p className="is-product-nav-description">A realtime database of thousands of source requests from journalists</p>
                                </div>
                              </a>

                              <a href="/sequences">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {SequencesIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">PR Sequences</p>
                                  </div>
                                  <p className="is-product-nav-description">Automagically email journalists when they mention keywords in articles & tweets</p>
                                </div>
                              </a>

                              <a href="/boost">
                                <div className="dropdown-item dropdown-list-name">
                                  <div className="nav-dropdown-title-icon-group">
                                    <img src= {PrBoostIcon} className="nav-dropdown-icon"></img>
                                    <p className="is-product-nav-title">PR Boost</p>
                                  </div>
                                  <p className="is-product-nav-description">A micro PR agency powered by our PR team, media datasets, and AI</p>
                                </div>
                              </a>

                            </div>
                          </div>
                        </div>

                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <AnchorLink href="#FAQ" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>FAQ</AnchorLink>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>Pricing</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-boost-blue' : 'button is-boost-blue'}>Subscribe now</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            )
          } else {
            return (
              <nav className="navbar">
                <div className="navbar-brand">
                  <div className="navbar-item logo">
                    <Link to="/">
                      <img
                        src= { Logo }
                        className="is-logo"
                        alt="Press Hunt logo"
                      />
                      <h1 className="has-text-white title is-size-4 is-logo-word">
                        Press Hunt
                      </h1>
                    </Link>
                  </div>
                  <div className="navbar-burger burger" onClick={this.toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                  <div className="navbar-end">
                  <div className="navbar-item">
                    <div className={ this.state.isActive ? 'field' : 'field is-grouped'}>

                      <div className="dropdown is-hoverable">
                        <div className="dropdown-trigger">
                          <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                            <Link to="" className={ this.state.isActive ? 'button is-clear is-nav-dropdown' : 'button is-clear is-nav-dropdown'} aria-haspopup="true" aria-controls="dropdown-menu">Product&nbsp;&nbsp;<i className="fas fa-angle-down" aria-hidden="true" style={{opacity:'.5'}}></i></Link>
                          </p>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                          <div className="dropdown-content1">

                            <a href="/journalist-database">
                              <div className="dropdown-item dropdown-list-name">
                                <div className="nav-dropdown-title-icon-group">
                                  <img src= {JournalistDatabaseIcon} className="nav-dropdown-icon"></img>
                                  <p className="is-product-nav-title">Journalist Database</p>
                                </div>
                                <p className="is-product-nav-description">A database of over 500,000 journalists to feature your business</p>
                              </div>
                            </a>

                            <a href="/podcasts">
                              <div className="dropdown-item dropdown-list-name">
                                <div className="nav-dropdown-title-icon-group">
                                  <img src= {PodcastDatabaseIcon} className="nav-dropdown-icon"></img>
                                  <p className="is-product-nav-title">Podcast Database</p>
                                </div>
                                <p className="is-product-nav-description">A database of over 200,000 podcasts to pitch your business to</p>
                              </div>
                            </a>

                            <a href="/requests">
                              <div className="dropdown-item dropdown-list-name">
                                <div className="nav-dropdown-title-icon-group">
                                  <img src= {PrRequestIcon} className="nav-dropdown-icon"></img>
                                  <p className="is-product-nav-title">PR Requests</p>
                                </div>
                                <p className="is-product-nav-description">A realtime database of thousands of source requests from journalists</p>
                              </div>
                            </a>

                            <a href="/sequences">
                              <div className="dropdown-item dropdown-list-name">
                                <div className="nav-dropdown-title-icon-group">
                                  <img src= {SequencesIcon} className="nav-dropdown-icon"></img>
                                  <p className="is-product-nav-title">PR Sequences</p>
                                </div>
                                <p className="is-product-nav-description">Automagically email journalists when they mention keywords in articles & tweets</p>
                              </div>
                            </a>

                            <a href="/boost">
                              <div className="dropdown-item dropdown-list-name">
                                <div className="nav-dropdown-title-icon-group">
                                  <img src= {PrBoostIcon} className="nav-dropdown-icon"></img>
                                  <p className="is-product-nav-title">PR Boost</p>
                                </div>
                                <p className="is-product-nav-description">A micro PR agency powered by our PR team, media datasets, and AI</p>
                              </div>
                            </a>

                          </div>
                        </div>
                      </div>

                      <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                        <AnchorLink href="#FAQ" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>FAQ</AnchorLink>
                      </p>
                      <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                        <Link to="/pricing" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>Pricing</Link>
                      </p>
                      <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                        <Link to="/dashboard" className={ this.state.isActive ? 'button is-boost-blue' : 'button is-boost-blue'}>Go to dashboard&nbsp;&nbsp;&nbsp;<i className="fas fa-chevron-right"></i></Link>
                      </p>
                    </div>
                  </div>
                  </div>
                </div>
              </nav>
            )
          }
        }}
      </FireflySubscription>
    )
  }
}

export default Navigation;
