import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import Error from '../misc/Error';
import updateAlert from '../../actions/alert/updateAlert';
import AlertForm from './alertForm';
import Loading from '../misc/Loading';

const AlertEdit = ({ match, history, auth }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
    <FirestoreCollection
      path={'alerts'}
      filter={[['createdBy', '==', auth.uid], [ 'slug', '==', match.params.slug ]]}
    >
      { ({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />
        }
        if (isLoading) {
          return <Loading />
        }
        if (!isLoading && data.length === 0) {
          return <p>{match.params.slug}</p>
        }
        const alert = data[0];
        return (
          <AlertForm
            alert={alert}
            onSubmit={values => updateAlert(alert.id, values).then(() => history.push("/alert-dashboard"))}
          />
        )
      }}
    </FirestoreCollection>
  </div>
)

export default AlertEdit;
