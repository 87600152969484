import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { auth } from "../../api/firebase";
import Logo from "../../assets/press-hunt-full-logo-20-dark.svg";
import { migrateAccount, getMailAuthToken } from "../../api/mail";

import createUser from "../../actions/createUser";

export default function HookSignUp(props) {
  const { register, errors, handleSubmit } = useForm();
  const [error, setError] = useState("");

  const onSubmit = data => {
    if (data.passwordOne === data.passwordTwo) {
      const referral =
        window.Rewardful && window.Rewardful.referral
          ? window.Rewardful.referral
          : "";
      auth
        .createUserWithEmailAndPassword(data.email, data.passwordOne)
        .then(() => {
          const authUser = auth.currentUser;
          console.log(`signed up as ${authUser.email}`);
          console.log(authUser.uid);
          if (authUser) {
            return createUser(authUser, referral)
              .then(async () => {
                await migrateAccount(
                  authUser.uid,
                  data.email,
                  data.passwordOne,
                  referral
                );

                await getMailAuthToken({
                  account: data.email,
                  password: data.passwordOne
                })
                  .then(res => {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    localStorage.setItem("accessTokenExpiresAt", res.data.accessTokenExpiresAt);
                    localStorage.setItem("refreshToken", res.data.refreshToken);
                    localStorage.setItem("refreshTokenExpiresAt", res.data.refreshTokenExpiresAt);
                  })
                  .catch(() => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("accessTokenExpiresAt");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("refreshTokenExpiresAtrefreshTokenExpiresAt");
                  });
              })
              .then(() => {
                props.history.push("/dashboard");
              })
              .catch(error => {
                setError(error);
              });
          }
        })
        .catch(error => {
          setError(error);
        });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
        <img src={Logo} className="mb-8 w-40 text-center m-auto" />
        <h2 className="mt-4 text-xl sm:text-2xl leading-9 font-extrabold text-gray-900">
          Get started with Press Hunt today 🎊
        </h2>
        <h3 className="font-normal text-gray-600 mt-3 text-xs sm:text-sm px-4 sm:px-0">
          We're trusted by thousands of PR agencies & companies all over the
          world like Universal Music Group, Instacart, and Facebook.
        </h3>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white p-10 shadow-sm sm:rounded-lg sm:px-10 border border-gray-200">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="text-sm font-bold leading-5 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-1 rounded-lg shadow-sm">
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="elon@tesla.com"
                  ref={register({
                    required: "This input is required.",
                    minLength: {
                      value: 9,
                      message: "This input must exceed 8 characters"
                    },
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-200 rounded-lg placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.email && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div className="mt-5">
              <label
                htmlFor="password"
                className="text-sm font-bold leading-5 text-gray-900"
              >
                Password
              </label>
              <div className="mt-1 rounded-lg shadow-sm">
                <input
                  name="passwordOne"
                  id="passwordOne"
                  type="password"
                  placeholder="Password"
                  ref={register({
                    required: "This input is required.",
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-200 rounded-lg placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.passwordOne && (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  {errors.passwordOne.message}
                </p>
              )}
            </div>

            <div className="mt-4">
              <label
                htmlFor="password"
                className="text-sm font-bold leading-5 text-gray-900"
              >
                Confirm password
              </label>
              <div className="mt-1 rounded-lg shadow-sm">
                <input
                  name="passwordTwo"
                  id="passwordTwo"
                  type="password"
                  placeholder="Confirm password"
                  ref={register({
                    required: "This input is required.",
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-200 rounded-lg placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.passwordTwo && (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  {errors.passwordTwo.message}
                </p>
              )}
            </div>

            <div className="mt-8">
              <span className="block w-full rounded-lg shadow-sm">
                <button
                  type="submit"
                  className="shadow-sm w-full flex justify-center py-2 px-4 border border-transparent text-md font-bold rounded-lg text-white bg-blue-1000 hover:opacity-75 focus:shadow-outline-indigo active:bg-blue-1000 transition duration-150 ease-in-out"
                >
                  Sign up for free now
                </button>
              </span>
              {error && (
                <p className="mt-2 text-sm text-red-600" id="error">
                  {error.message}
                </p>
              )}
            </div>
          </form>
        </div>
        <p className="mt-5 text-sm leading-5 text-gray-600 max-w text-center">
          <span>Already have an account?</span>
          <NavLink
            to="/signin"
            className="ml-2 font-semibold text-blue-1000 hover:opacity-50 hover:text-blue-1000 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            Sign in now
            {/* <svg className="ml-2 text-gray-500 p-1 h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
            </svg> */}
          </NavLink>
        </p>
      </div>
    </div>
  );
}
