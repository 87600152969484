import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';
import FireflySubscription from './misc/FireflySubscription';
import Loading from './misc/Loading';

import Dashnav from './Dashboard/dashnav';
import Landing from './layout/landing';
import SignUp from './account/hookSignUp';
import SignIn from './account/hookSignIn';
import GoogleSignIn from './account/googleSignIn';
import PasswordForgetPage from './account/PasswordForget';
import StartupPricing from './Pricing/startupPricing';
import Lists from './Lists/HeavenListIndex';
import List from './Lists/infiniteList';
import ListNew from './Lists/listNew';
import ListContact from './Lists/listContact';
import JournoDashboard from './Dashboard/HeavenJournoDash';
import JournalistBulk from './Dashboard/HeavenJournoBulk';
import InfluencerDashboard from './Dashboard/HeavenInfluencerDash.js';
import HomeDashboard from './Dashboard/HeavenHomeDashboard.js';
import RequestsDashboard from './Dashboard/HeavenRequestDash.js';
import Requests from './layout/requests.js';
import ArticleDashboard from './Dashboard/HeavenArticleDash.js';
import TweetDashboard from './Dashboard/HeavenTweetDash.js';
import PodcastDashboard from './Dashboard/HeavenPodcastDash';
import BroadcastDashboard from './Dashboard/HeavenBroadcastDash';
import Account from './account/Account';
import Sequence from './Sequence/HeavenSequenceIndex';
import SequencesLanding from './layout/sequences-landing.js';
import Domain from './Domain/HeavenDomainIndex';
import DomainNew from './Domain/domainNew';
import DomainStats from './CampaignAnalytics/domainStats';
import SequenceNew from './Sequence/sequenceNew';
import SequenceEdit from './Sequence/sequenceEdit';
import Alert from './Alert/HeavenAlertIndex';
import AlertNew from './Alert/alertNew';
import AlertEdit from './Alert/alertEdit';
import MyTriggeredAlert from './Alert/myTriggeredAlert';
import AllTriggeredAlerts from './Alert/allTriggeredAlerts';
import Campaigns from './Campaign/HeavenCampaignIndex';
import CampaignIntro from './Campaign/campaignIntro';
import CampaignNew from './Campaign/campaignNew';
import CampaignEdit from './Campaign/campaignEdit';
import BoostHome from './layout/boost';
import PodcastsHome from './layout/podcasts';
import AdminPanel from './Admin';
import JournalistDatabaseHome from './layout/journalist-database';
import InfluencerDatabaseHome from './layout/influencer-database';
import ArticleLanding from './layout/article-database';
import AgencyPricing from './Pricing/agencyPricing';
import Journalists from './Journalists';
import JournalistForm from './Journalists/journalistForm';
import JournalistList from './Journalists/journalistList';
import Profile from './Journalists/profile';
import CampaignAnalytics from './CampaignAnalytics';
import Error from './misc/Error';
import Mail from './Mail/mail';

export const NavRoute = ({ exact, path, component: Component, authCheck = false }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => (
      <div>
        <Dashnav path={path} />
        <FireflySubscription>
          {({
            isLoading, error, subscription, auth, status, plan,
          }) => {
            if (isLoading) {
              return <Loading />;
            }

            if (error) {
              return <Error error={error} />;
            }

            if (!auth) {
              if (authCheck) {
                return <Redirect to={{ pathname: "/signin" }}/>
              }
              return <Component {...props} />;
            }

            if (auth) {
              return (
                <FirestoreCollection
                  path="lists"
                  sort="createdOn:desc"
                  filter={['createdBy', '==', auth.uid]}
                >
                  {({ error, isLoading, data }) => {
                    if (error) {
                      return <Error error={error} />;
                    }
                    if (isLoading) {
                      return <Loading />;
                    }
                    if (!isLoading) {
                      return (
                        <Component
                          {...props}
                          subscription={subscription}
                          status={status}
                          plan={plan}
                          lists={data}
                          auth={auth}
                        />
                      );
                    }
                  }}
                </FirestoreCollection>
              );
            }
          }}
        </FireflySubscription>
      </div>
    )}
  />
);
const Routes = () => (
  <Route
    render={({ location }) => (
      <Switch location={location}>
        {/* Routes are for landing page routes */}
        <Route exact path="/" component={Landing} />
        <Route path="/agency-pricing" component={AgencyPricing} />
        <Route exact path="/pricing" component={StartupPricing} />
        <Route path="/journalist-database" component={JournalistDatabaseHome} />
        <Route path="/influencers" component={InfluencerDatabaseHome} />
        <Route path="/boost" component={BoostHome} />
        <Route path="/podcasts" component={PodcastsHome} />
        <Route path="/news-monitoring" component={ArticleLanding} />
        <Route path="/requests" component={Requests} />
        <Route exact path="/sequences" component={SequencesLanding} />
        <Route path="/signup" component={SignUp} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signin/google" component={GoogleSignIn} />
        <Route path="/passwordforget" component={PasswordForgetPage} />
        {/* NavRoute provides topnav for in-app routes */}
        <NavRoute exact path="/account" component={Account} authCheck={true} />
        <NavRoute exact path="/admin" component={AdminPanel} authCheck={true} />
        <NavRoute exact path="/dashboard" component={HomeDashboard} authCheck={true} />
        <NavRoute
          exact
          path="/journalist-dashboard"
          component={JournoDashboard}
          authCheck={true}
        />
        <NavRoute
          exact
          path="/journalist-dashboard/bulk"
          component={JournalistBulk}
          authCheck={true}
        />
        <NavRoute
          exact
          path="/broadcast-dashboard"
          component={BroadcastDashboard}
          authCheck={true}
        />
        <NavRoute
          exact
          path="/podcast-dashboard"
          component={PodcastDashboard}
          authCheck={true}
        />
        <NavRoute
          exact
          path="/influencer-dashboard"
          component={InfluencerDashboard}
          authCheck={true}
        />
        <NavRoute
          exact
          path="/article-dashboard"
          component={ArticleDashboard}
          authCheck={true}
        />
        <NavRoute exact path="/tweet-dashboard" component={TweetDashboard} authCheck={true} />
        <NavRoute
          exact
          path="/request-dashboard"
          component={RequestsDashboard}
          authCheck={true}
        />
        <NavRoute exact path="/lists" component={Lists} authCheck={true} />
        <NavRoute exact path="/lists/new" component={ListNew} authCheck={true} />
        <NavRoute exact path="/list/:slug" component={List} authCheck={true} />
        <NavRoute path="/list/:slug/:objectID" component={ListContact} authCheck={true} />
        <NavRoute exact path="/alert-dashboard" component={Alert} authCheck={true} />
        <NavRoute
          exact
          path="/alert-dashboard/triggered"
          component={AllTriggeredAlerts}
          authCheck={true}
        />
        <NavRoute
          path="/alert-dashboard/triggered/:slug"
          component={MyTriggeredAlert}
          authCheck={true}
        />
        <NavRoute exact path="/alert/new" component={AlertNew} authCheck={true} />
        <NavRoute path="/alert/:slug/edit" component={AlertEdit} authCheck={true} />
        <NavRoute exact path="/sequence-dashboard" component={Sequence} authCheck={true} />
        <NavRoute exact path="/sequence/new" component={SequenceNew} authCheck={true} />
        <NavRoute path="/sequence/:slug/edit" component={SequenceEdit} authCheck={true} />
        <NavRoute exact path="/domain" component={Domain} authCheck={true} />
        <NavRoute exact path="/domain/new" component={DomainNew} authCheck={true} />
        <NavRoute path="/domain/stats/:slug" component={DomainStats} authCheck={true} />
        <NavRoute exact path="/analytics" component={CampaignAnalytics} authCheck={true} />
        <NavRoute exact path="/campaigns" component={CampaignIntro} authCheck={true} />
        <NavRoute exact path="/campaigns/pro" component={Campaigns} authCheck={true} />
        <NavRoute path="/campaign/new" component={CampaignNew} authCheck={true} />
        <NavRoute path="/campaign/:slug/edit" component={CampaignEdit} authCheck={true} />
        <NavRoute path="/mail" component={Mail} authCheck={true} />

        <Route exact path="/journalists" component={Journalists} />
        <Route path="/journalists/new" component={JournalistForm} />
        <Route path="/journalists/list" component={JournalistList} />
        <Route path="/journalists/:slug" component={Profile} />
        <Route component={Error} />
      </Switch>
    )}
  />
);

export default Routes;
