import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';
import FireflySubscription from '../misc/FireflySubscription';
import Firebase from 'firebase/app';
import Error from '../misc/Error';

class AddToListButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      profile: this.props.profile,
      dataType: this.props.dataType,
      journalistIndex: [],
      podcastIndex: [],
      broadcasterIndex: [],
      influencerIndex: [],
      emailIndex: [],
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
     this._isMounted = false;
  }

  async addJournalistToList(key, profile, plan) {
    console.log("adding journalist");

    let objectID = profile.objectID;
    var docRef = Firebase.firestore().collection("lists").doc(key);

    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        journalistIndex: doc.data().journalistIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      //if email already exists in the index, return alert
      if (objectID && this.state.journalistIndex.includes(objectID)) {
        return alert("duplicate record");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        profile["counter"] = snap.size + 1;
        profile.addedOn = Firebase.firestore.Timestamp.now();
        profile.dataType = "journalist";

        if (profile.secondary_email && profile.email === "") {
          profile.email = profile.secondary_email;
        }

        docRef.collection("entries")
          .add(profile)
          .then( () => {
            let journalistIndex= this.state.journalistIndex;
            journalistIndex.push(objectID);
            docRef.update({
              updatedOn: Firebase.firestore.Timestamp.now(),
              journalistIndex
            });
          })
          .catch( error => {
            alert(`Whoops, couldn't add the record: ${error.message}`)
          });
        this.setState({ saved: true });
        setTimeout(() => {
          this.setState({ saved: false });
        }, 1000);
      }
      else
        return alert("You reached your limit of 200 entries!");
    });
  };

  async addPodcastToList(key, profile, plan) {
    console.log("adding Podcast");

    let objectID = profile.objectID;
    var docRef = Firebase.firestore().collection("lists").doc(key);

    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        podcastIndex: doc.data().podcastIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      //if email already exists in the index, return alert
      if (objectID && this.state.podcastIndex.includes(objectID)) {
        return alert("duplicate record");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        profile["counter"] = snap.size + 1;
        profile.addedOn = Firebase.firestore.Timestamp.now();
        profile.dataType = "podcast";
        profile.name = profile.title;

        docRef.collection("entries")
          .add(profile)
          .then( () => {
            let podcastIndex= this.state.podcastIndex;
            podcastIndex.push(objectID);
            docRef.update({
              updatedOn: Firebase.firestore.Timestamp.now(),
              podcastIndex
            });
          })
          .catch( error => {
            alert(`Whoops, couldn't add the record: ${error.message}`)
          });
        this.setState({ saved: true });
        setTimeout(() => {
          this.setState({ saved: false });
        }, 1000);
      }
      else
        return alert("You reached your limit of 200 entries!");
    });
  };

  async addBroadcastToList(key, profile, plan) {
    console.log("adding Broadcaster");

    let objectID = profile.objectID;
    var docRef = Firebase.firestore().collection("lists").doc(key);

    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        broadcasterIndex: doc.data().broadcasterIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      //if email already exists in the index, return alert
      if (objectID && this.state.broadcasterIndex.includes(objectID)) {
        return alert("duplicate record");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        profile["counter"] = snap.size + 1;
        profile.addedOn = Firebase.firestore.Timestamp.now();
        profile.dataType = "broadcaster";
        if (profile.name !== undefined) {
          profile.name = profile.outlet;
        };

        docRef.collection("entries")
          .add(profile)
          .then( () => {
            let broadcasterIndex= this.state.broadcasterIndex;
            broadcasterIndex.push(objectID);
            docRef.update({
              updatedOn: Firebase.firestore.Timestamp.now(),
              broadcasterIndex
            });
          })
          .catch( error => {
            alert(`Whoops, couldn't add the record: ${error.message}`)
          });
        this.setState({ saved: true });
        setTimeout(() => {
          this.setState({ saved: false });
        }, 1000);
      }
      else
        return alert("You reached your limit of 200 entries!");
    });
  };

  async addInfluencerToList(key, profile, plan) {
    console.log("adding Influencer");

    let objectID = profile.objectID;
    var docRef = Firebase.firestore().collection("lists").doc(key);

    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        influencerIndex: doc.data().influencerIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      //if email already exists in the index, return alert
      if (objectID && this.state.influencerIndex.includes(objectID)) {
        return alert("duplicate record");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        profile["counter"] = snap.size + 1;
        profile.addedOn = Firebase.firestore.Timestamp.now();
        profile.dataType = "influencer";

        docRef.collection("entries")
          .add(profile)
          .then( () => {
            let influencerIndex= this.state.influencerIndex;
            influencerIndex.push(objectID);
            docRef.update({
              updatedOn: Firebase.firestore.Timestamp.now(),
              influencerIndex
            });
          })
          .catch( error => {
            alert(`Whoops, couldn't add the record: ${error.message}`)
          });
        this.setState({ saved: true });
        setTimeout(() => {
          this.setState({ saved: false });
        }, 1000);
      }
      else
        return alert("You reached your limit of 200 entries!");
    });
  };

  async addToList(key, profile, plan) {
    console.log("legacy function");
  };

  render() {
    const { profile, dataType } = this.state;

    return (
      <div className="mt-2 float-right">
        <FireflySubscription>
          { ({isLoading, error, auth, subscription}) => {
            if (isLoading) {
              return <span />
            }
            if (error) {
              return <Error error={error} />
            }
            if (!auth) {
              return <div>
                <p>Log in to see your account</p>
              </div>
            }
            return  <FirestoreCollection
              path={`lists`}
              sort="createdOn:desc"
              filter={['createdBy', '==', auth.uid]}
            >
              { ({error, isLoading, data}) => {
                if (error) {
                  return <Error error={error} />
                }
                if (isLoading) {
                  return <span />
                }
                if (!isLoading && data.length === 0) {
                  return (
                    <div className="dropdown is-right is-hoverable">
                      <div className="dropdown-trigger">
                        <a className="heaven-card-cta-button">
                          <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save<i className="fas fa-chevron-down heaven-nav-button-icon-right"></i>
                        </a>
                      </div>
                      <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                        <div className="dropdown-content heaven-dropdown-content">
                          <div className="heaven-dropdown-content-second-half" style={{ borderTop:'none',marginTop:'0px',paddingBottom:'15px' }}>
                            {(subscription.plan === 1 || subscription.plan === 10) ? (
                              <NavLink className="heaven-page-dropdown-button" to="/pricing" style={{ fontSize:'14px' }}><i className="fas fa-plus heaven-nav-button-icon" />New list<i className="fas fa-lock heaven-nav-link-icon-lock" /></NavLink>
                            ) : (
                              <NavLink className="heaven-page-dropdown-button" to="/lists/new" style={{ fontSize:'14px' }}><i className="fas fa-plus heaven-nav-button-icon" />New list</NavLink>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                return (
                  <div>
                    {this.state.saved ? (
                    <a className="heaven-card-success-button bg-green-600 border border-green-600">
                      <i className="fas fa-check-circle heaven-nav-button-icon"></i>Saved!
                    </a>
                    ) : (
                    <div className="dropdown is-right is-hoverable">
                      <div className="dropdown-trigger">
                        <a className="heaven-card-cta-button">
                          <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save<i className="fas fa-chevron-down heaven-nav-button-icon-right"></i>
                        </a>
                      </div>
                      <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                        <div className="dropdown-content heaven-dropdown-content">
                          {data.map((list, index) => (
                            ((subscription.plan === 2 || subscription === 20) && list.csvUpload) ? (
                              <div key={index}>
                                <NavLink
                                  to="/pricing"
                                  className="dropdown-item heaven-page-dropdown-item"
                                  style={{ cursor:"pointer" }}
                                >
                                  <i className="fas fa-list heaven-page-dropdown-item-icon" />
                                  {list.name}<i className="fas fa-lock heaven-nav-link-icon-lock" />
                                </NavLink>
                              </div>
                            ) : (
                              <div
                                key={index}
                                onClick={() => {
                                  dataType === "journalist" ?
                                  this.addJournalistToList(list.id, profile, subscription.plan) : dataType === "podcast" ?
                                  this.addPodcastToList(list.id, profile, subscription.plan) : dataType === "broadcaster" ?
                                  this.addBroadcastToList(list.id, profile, subscription.plan) : dataType === "influencer" ?
                                  this.addInfluencerToList(list.id, profile, subscription.plan) : this.addToList(list.id, profile, subscription.plan)
                                }}
                                className="dropdown-item heaven-page-dropdown-item"
                                style={{ cursor:"pointer" }}
                              >
                                <i className="fas fa-list heaven-page-dropdown-item-icon" />
                                {list.name}
                              </div>
                            )
                          ))}
                          <div className="heaven-dropdown-content-second-half">
                            {(subscription.plan === 1 || subscription.plan === 10) ? (
                              <NavLink className="heaven-page-dropdown-button" to="/pricing"><i className="fas fa-plus heaven-nav-button-icon" />New list<i className="fas fa-lock heaven-nav-link-icon-lock" /></NavLink>
                            ) : (
                              <NavLink className="heaven-page-dropdown-button" to="/lists/new" style={{fontSize:'14px'}}><i className="fas fa-plus heaven-nav-button-icon" />New list</NavLink>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                )
              }}
            </FirestoreCollection>
          }}
        </FireflySubscription>
      </div>
    )
  }
}
export default AddToListButton;
