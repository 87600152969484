import React from 'react';
import Select from 'react-select';
import RichTextEditor from 'react-rte';
import { TargetTags, Areas, Countries, Beats } from '../../data/selectOptions';

class SequenceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      archived: false,
      EmailBody: this.props.sequence ? RichTextEditor.createValueFromString(this.props.sequence.EmailBody, 'html') : RichTextEditor.createEmptyValue(),
      TargetTags: this.props.sequence ? this.props.sequence.TargetTags : [],
      Areas: this.props.sequence ? this.props.sequence.Areas : [],
      Countries: this.props.sequence ? this.props.sequence.Countries : [],
      Beats: this.props.sequence ? this.props.sequence.Beats : [],
    };
  }
  formChangeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleReactSelectChange = name => value => {
    this.setState({
      [name]: value
    })
  }
  onRichEditorChange = (EmailBody) => {
    this.setState({EmailBody});
  };


  onSubmit = event => {
    event.preventDefault()
    const {
      SequenceName,
      TargetKeywords,
      Sentiment,
      TargetChannel,
      TargetOutletTier,
      SendAs,
      SubjectLine,
    } = event.target.elements

    const {
      TargetTags,
      Areas,
      Countries,
      Beats,
      active,
      archived,
    } = this.state

    const EmailBody = this.state.EmailBody.toString('html');
    console.log(EmailBody)
    const values = {
      SequenceName: SequenceName.value,
      EmailBody,
      TargetKeywords: TargetKeywords.value,
      TargetTags,
      Areas,
      Countries,
      Beats,
      Sentiment: Sentiment.value,
      TargetChannel: TargetChannel.value,
      TargetOutletTier: TargetOutletTier.value,
      SendAs: SendAs.value,
      SubjectLine: SubjectLine.value,
      Status: active,
      archived,
    }
    this.props.onSubmit(values)
  }
  render() {
    return (
      <div className="is-sequence-form">
        <form onSubmit={this.onSubmit}>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <label className="sequence-form-label">What do you want to name your sequence?</label>
              <input
                className="sequence-form-input"
                required
                type="text"
                name="SequenceName"
                defaultValue={this.props.sequence ? this.props.sequence.SequenceName : ''}
                placeholder="Sequence Name"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Please list the keywords you'd like to target for this sequence, separated by commas.
              </p>
              <p className="sequence-form-sublabel">
                If you list more than 1 keyword, only hits that match <span className="is-strong">ALL</span> of the listed keywords will be selected.
              </p>
              <input
                className="sequence-form-input"
                required
                type="text"
                name="TargetKeywords"
                defaultValue={this.props.sequence ? this.props.sequence.TargetKeywords : ''}
                placeholder="Bitcoin, Ethereum (3 max)"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the topics they cover, list up to 3 topics here<br />
              </p>
              <p className="sequence-form-sublabel">
                This will filter each hit by the journalist's covered topics. For example, if you list "technology" and "San Francisco", it will filter for journalists that cover either technology <span className="is-strong">OR</span> San Francisco.
              </p>
              <Select
                isMulti
                required
                name="TargetTags"
                options={TargetTags}
                defaultValue={this.props.sequence ? this.props.sequence.TargetTags : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('TargetTags')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the beats they cover, list up to 3 topics here<br />
              </p>
              <p className="sequence-form-sublabel">
                This will filter each hit by the journalist's covered beats. For example, if you list "technology" and "San Francisco", it will filter for journalists that cover either technology <span className="is-strong">OR</span> San Francisco.
              </p>
              <Select
                isMulti
                name="Beats"
                options={Beats}
                defaultValue={this.props.sequence ? this.props.sequence.Beats : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('Beats')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the area they live/work in<br />
              </p>
              <p className="sequence-form-sublabel">
                An area is either a city, state, or metropolitan area––but not a country. If you list "California" and "Hong Kong", it will filter for journalists that are based in either California <span className="is-strong">OR</span> Hong Kong.
              </p>
              <Select
                isMulti
                name="Areas"
                options={Areas}
                defaultValue={this.props.sequence ? this.props.sequence.Areas : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('Areas')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="sequence-form-label">
                If you want to target journalists based on the country they live/work in<br />
              </p>
              <p className="sequence-form-sublabel">
                If you list "United Kingdom" and "Morocco", this will filter for journalists that are based in either United Kingdom <span className="is-strong">OR</span> Morocco.
              </p>
              <Select
                isMulti
                name="Countries"
                options={Countries}
                defaultValue={this.props.sequence ? this.props.sequence.Countries : ''}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleReactSelectChange('Countries')}
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Please select the desired sentiment you want your sequence to trigger on.
              </p>
              <p className="sequence-form-sublabel">
                Sentiment is essentially a measurement of the emotional positive/negative feeling of the text (using Google's Machine Learning API). Tesla, for example, can use a sentiment trigger here to track when journalists write something negative about them, and automatically email them a response accordingly.<br /><br /><br />
              </p>
                <input
                  type="radio"
                  name="Sentiment"
                  value="positive"
                  className="is-checkradio"
                  defaultChecked={ this.props.sequence && this.props.sequence.Sentiment === "positive" ? true : false}
                  id="sentimentRadioInput1"
                  onChange={(event, name, value) =>
                    this.formChangeHandler(event, name, value)
                  }
                />
              <label htmlFor="sentimentRadioInput1">
                Positive
              </label>
              <input
                type="radio"
                name="Sentiment"
                value="negative"
                className="is-checkradio"
                defaultChecked={ this.props.sequence && this.props.sequence.Sentiment === "negative" ? true : false}
                id="sentimentRadioInput2"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="sentimentRadioInput2">
                Negative
              </label>
              <input
                type="radio"
                name="Sentiment"
                value="either"
                className="is-checkradio"
                defaultChecked={ this.props.sequence && this.props.sequence.Sentiment === "either" ? true : false}
                id="sentimentRadioInput3"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="sentimentRadioInput3">
                Either
              </label>
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Which channels would you like to monitor for keywords?
              </p>
              <p className="sequence-form-sublabel">
                Only those on the Agency & Enterprise plans can monitor articles.<br /><br /><br />
              </p>
              <input
                type="radio"
                name="TargetChannel"
                value="tweets"
                className="is-checkradio"
                defaultChecked={ this.props.sequence && this.props.sequence.TargetChannel === "tweets" ? true : false}
                id="targetChannelRadioInput1"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="targetChannelRadioInput1">
                Tweets
              </label>
              <input
                type="radio"
                name="TargetChannel"
                value="articles"
                className="is-checkradio"
                defaultChecked={ this.props.sequence && this.props.sequence.TargetChannel === "articles" ? true : false}
                id="targetChannelRadioInput2"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="targetChannelRadioInput2">
                Articles
              </label>
              <input
                type="radio"
                name="TargetChannel"
                value="tweets, articles"
                className="is-checkradio"
                id="targetChannelRadioInput3"
                defaultChecked={ !this.props.sequence || (this.props.sequence && this.props.sequence.TargetChannel === "both") ? true : false}
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="targetChannelRadioInput3">
                Both
              </label>
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">
                Please select the tier of media outlet you'd like your sequence to trigger on.
              </p>
              <p className="sequence-form-sublabel">
                First-tier news outlets like The New York Times and TechCrunch are global outlets, for example, while smaller blogs and TV and radio stations are local outlets.<br /><br /><br />
              </p>
              <input
                type="radio"
                name="TargetOutletTier"
                value="global"
                className="is-checkradio"
                defaultChecked={ this.props.sequence && this.props.sequence.TargetOutletTier === "global" ? true : false}
                id="TargetOutletTierRadioInput1"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="TargetOutletTierRadioInput1">
                Global
              </label>
              <input
                type="radio"
                name="TargetOutletTier"
                value="local"
                className="is-checkradio"
                defaultChecked={ this.props.sequence && this.props.sequence.TargetOutletTier === "local" ? true : false}
                id="TargetOutletTierRadioInput2"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="TargetOutletTierRadioInput2">
                Local
              </label>
              <input
                type="radio"
                name="TargetOutletTier"
                value="both"
                className="is-checkradio"
                id="TargetOutletTierRadioInput3"
                defaultChecked={ !this.props.sequence || (this.props.sequence && this.props.sequence.TargetOutletTier === "both") ? true : false}
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
              <label htmlFor="TargetOutletTierRadioInput3">
                Both
              </label>
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <label className="sequence-form-label">Subject line for the email you want to send when your sequence gets triggered</label>

              <input
                className="sequence-form-input"
                required
                type="text"
                name="SubjectLine"
                defaultValue={this.props.sequence ? this.props.sequence.SubjectLine : ''}
                placeholder="We just launched a new crypto startup to change the world"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">The email address you want to send the email from</p>
              <p className="sequence-form-sublabel">Make sure you've authenticated the domain of your email address <a href="" target="_blank">here</a> so your emails get delivered properly. If you've already authenticated a domain like tesla.com, you can send from any email address like elon@tesla.com or sam@tesla.com without a problem.</p>
              <input
                className="sequence-form-input"
                required
                type="email"
                placeholder="e.g. info@yourcompany.com"
                name="SendAs"
                defaultValue={this.props.sequence ? this.props.sequence.SendAs : ''}
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
              />
            </div>
          </div>
          <div className="sequence-form-last-q">
            <div className="control">
              <p className="seq-form-required">REQUIRED</p>
              <p className="sequence-form-label">Write the body of your email here</p>
              <p className="sequence-form-sublabel">Emails are sent in plain text, not in HTML. If you'd like to personalize the email using the triggered journalist's name, use the tag [[journalist_name]]</p>
              <RichTextEditor
                className="rich-text-editor"
                required
                name="EmailBody"
                value={this.state.EmailBody}
                placeholder="Hi [[journalist_name]]!"
                onChange={this.onRichEditorChange}
              />
            </div>
          </div>
          <div className="sequence-form-button-wrapper">
            <button
              className="heaven-card-cta-button"
              type="submit"
              style={{fontWeight:'bold'}}
            >
              <i className="fas fa-plus heaven-nav-button-icon" />Save sequence
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default SequenceForm;
