import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';
import updateAlert from '../../actions/alert/updateAlert';
import Error from '../misc/Error';
import TriggeredAlertItems from './triggeredAlertItems';
import Alerts from './alerts';

import Loading from '../misc/Loading';

class MyTriggeredAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Status: false,
    }
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }
    
  handleToggleChange = (id, Status) => {
    const values = { Status: !Status }
    updateAlert(id, values)
    .then(() => {
      this.setState(prevState => ({
        check: !prevState.check,
        Status: !prevState.Status,
      }))
    })
    .catch(error => console.log(error))
  }
  render() {
    return (
      <FirestoreCollection
        path={`alerts`}
        filter={ [['createdBy', '==', this.props.auth.uid], ['slug', '==', this.props.match.params.slug]] }
      >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (isLoading) {
            return <Loading />
          }
          if (!isLoading && data.length === 0) {
            return (
              <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
                <div className="heaven-empty-card" style={{marginTop:'22px'}}>
                  <i className="fas fa-exclamation-circle heaven-card-title-icon" />You have not set up this alert yet!
                </div>
              </div>
            )
          }
          if (!isLoading && data.length >= 1) {
            return (
              <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
                <div className="columns">
                  <div className="column is-one-thirds">
                    <div className="column is-half" style={{ display:'inline' }}>
                      <div className="heaven-content-card">
                        <div className="heaven-content-card-header">
                          <div className="columns">
                            <div className="column is-three-quarters">
                              <p className="heaven-card-header-title"><i className="fas fa-bell heaven-card-title-icon" />{data[0].alertName}</p>
                            </div>
                            <div className="column is-one-quarters is-pulled-right">
                              <input
                                id={data[0].id}
                                type="checkbox"
                                name="Status"
                                className="switch is-rounded is-info"
                                checked={data[0].Status}
                                onChange={() =>
                                  this.handleToggleChange(data[0].id, data[0].Status)
                                }
                              />
                              <label
                                htmlFor={data[0].id}
                                className="heaven-card-activate-toggle"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="heaven-content-card-content">
                          {data[0].TargetKeywords ? <p className="heaven-alert-content-line" style={{ marginBottom:'12px' }}><span className="equal-icon-wrapper"><i className="fas fa-arrow-alt-circle-right heaven-nav-link-icon" /></span><b>{data[0].TargetKeywords}</b></p> : <span />}
                          {(data[0].Areas && data[0].Areas.length > 0) ? <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-city heaven-nav-link-icon" /></span>{data[0].Areas ? data[0].Areas.map(obj => obj.value).join(', ') : "" }</p> : <span />}
                          {(data[0].Countries && data[0].Countries.length > 0) ? <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-globe-americas heaven-nav-link-icon" /></span>{ data[0].Countries ? data[0].Countries.map(obj => obj.value).join(', ') : "" }</p> : <span />}
                          {(data[0].TargetTags && data[0].TargetTags.length > 0) ? <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-tags heaven-nav-link-icon" /></span>{ data[0].TargetTags ? data[0].TargetTags.map(obj => obj.value).join(', ') : "" }</p> : <span />}
                          {(data[0].Beats && data[0].Beats.length > 0) ? <p className="heaven-alert-content-line" style={{ marginBottom:'0px' }}><span className="equal-icon-wrapper"><i className="fas fa-clipboard-list heaven-nav-link-icon" /></span>{ data[0].Beats ? data[0].Beats.map(obj => obj.value).join(', ') : "" }</p> : <span />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column is-two-thirds">
                    <TriggeredAlertItems email={this.props.auth.email} slug={this.props.match.params.slug}/>
                  </div>
                </div>
              </div>
            )
          }
        }}
      </FirestoreCollection>
    )
  }
}

export default MyTriggeredAlert;
