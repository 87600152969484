import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import { Link } from 'react-router-dom';
import Error from '../misc/Error';
import updateSequence from '../../actions/sequence/updateSequence';

import Loading from '../misc/Loading';

import MailIcon from '../../assets/mail.svg';
import TrashIcon from '../../assets/trash-2.svg';
import EditIcon from '../../assets/edit (1).svg';
import MonitorIcon from '../../assets/sequence-monitoring.svg';
import KeywordIcon from '../../assets/sequence-keyword.svg';
import TagsIcon from '../../assets/sequence-tags.svg';
import SentimentIcon from '../../assets/sequence-sentiment.svg';

class Sequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Status: false,
    }
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  handleToggleChange = (id, Status) => {
    const values = { Status: !Status }
    updateSequence(id, values)
    .then(() => {
      this.setState(prevState => ({
        check: !prevState.check,
        Status: !prevState.Status,
      }))
    })
    .catch(error => console.log(error))
  }

  handleArchive = id => {
    const values = { archived: true }
    updateSequence(id, values)
  }

  render() {
    return (
      <FirestoreCollection
        path={`sequences`}
        sort="createdOn:desc"
        filter={['createdBy', '==', this.props.uid]}
      >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (isLoading) {
            return <Loading />
          }
          if (!isLoading && data.length === 0) {
            return (
              <div className="is-sequence-card-empty">
                <div className="sequence-card-header">
                  <div className="sequence-card-header-icon-and-title">
                    <img src={MailIcon} className="sequence-card-icon-header" style={{opacity:'.5'}}></img>
                    <p className="sequence-card-header-title" style={{opacity:'.5'}}>Example sequence for Tesla</p>
                  </div>
                </div>
                <div className="sequence-card-content" style={{opacity:'.5'}}>
                  <p className="sequence-card-line"><img src={MonitorIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Monitoring <span className="is-strong">tweets, articles</span></span></p>
                  <p className="sequence-card-line"><img src={KeywordIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Triggers on keywords: <span className="is-strong">Tesla</span></span></p>
                  <p className="sequence-card-line"><img src={TagsIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Only journalists who cover <span className="is-strong">technology, business</span> at <span className="is-strong">first</span> tier outlets</span></p>
                  <p className="sequence-card-line"><img src={SentimentIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Only in response to <span className="is-strong">positive</span> sentiment</span></p>
                </div>
                <div className="sequence-card-email">
                  <div className="sequence-card-email-subject">
                    <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>SUBJECT</p>
                    <p className="sequence-card-email-subject-text" style={{opacity:'.5'}}>Thanks for mentioning us! Here's our press kit…</p>
                  </div>
                  <div className="sequence-card-email-body">
                    <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>BODY</p>
                    <p className="sequence-card-email-text" style={{opacity:'.5'}}>Hi **journalist_name**! You can find our press kit here…</p>
                  </div>
                </div>
                <div className="sequence-card-footer">
                    <div className="sequence-card-footer-button">
                      <input
                        type="checkbox"
                        name="active"
                        className="switch is-rounded is-info"
                      />
                      <label className="sequence-card-footer-button-activate" style={{opacity:'.5', cursor:'default'}}><span className="sequence-card-footer-button-activate-text">Activate</span></label>
                    </div>
                    <div className="sequence-card-footer-button">
                      <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img src={EditIcon} className="sequence-card-icon-standard"></img><span className="sequence-card-footer-button-standard-text">Edit</span></div>
                    </div>
                    <div className="sequence-card-footer-button">
                      <div className="sequence-card-footer-button-archive" style={{opacity:'.5', cursor:'default'}}><img src={TrashIcon} className="sequence-card-icon-archive"></img><span className="sequence-card-footer-button-archive-text">Archive</span></div>
                    </div>
                </div>
              </div>
            )
          }
          return (
            <div>
              {data.map(sequence => (
                <div key={sequence.id}>
                { sequence.archived ? <span /> : <div className="is-sequence-card">
                    <div className="sequence-card-header">
                      <div className="sequence-card-header-icon-and-title">
                        <img src={MailIcon} className="sequence-card-icon-header"></img>
                        <p className="sequence-card-header-title">{sequence.SequenceName}</p>
                      </div>
                    </div>
                    <div className="sequence-card-content">
                      <p className="sequence-card-line">
                        <img src={MonitorIcon} className="sequence-card-content-icon" />
                        <span className="sequence-card-content-text">Monitoring <span className="is-strong">{sequence.TargetChannel}</span> from journalists in: <span className="is-strong">{ sequence.Areas ? sequence.Areas.map(obj => obj.value).join(', ') : "" } { sequence.Countries ? sequence.Countries.map(obj => obj.value).join(', ') : "" }</span></span>
                      </p>
                      <p className="sequence-card-line">
                        <img src={KeywordIcon} className="sequence-card-content-icon"/>
                        <span className="sequence-card-content-text">Triggers on keywords: <span className="is-strong">{sequence.TargetKeywords}</span></span>
                      </p>
                      <p className="sequence-card-line">
                        <img src={TagsIcon} className="sequence-card-content-icon" />
                        <span className="sequence-card-content-text">Only journalists who cover <span className="is-strong">{sequence.TargetTags ? sequence.TargetTags.map(obj => obj.value).join(', ') : "" }</span> topics and <span className="is-strong">{ sequence.Beats ? sequence.Beats.map(obj => obj.value).join(', ') : "" }</span> beats at outlets with
                          <span className="is-strong"> {sequence.TargetOutletTier}</span> reach
                        </span>
                      </p>
                      <p className="sequence-card-line">
                        <img src={SentimentIcon} className="sequence-card-content-icon" />
                        <span className="sequence-card-content-text">Triggers on <span className="is-strong">{sequence.Sentiment}</span> sentiment</span>
                      </p>
                    </div>
                    <div className="sequence-card-email">
                      <div className="sequence-card-email-subject">
                        <p className="sequence-card-email-label-text">SUBJECT</p>
                        <p className="sequence-card-email-subject-text">{sequence.SubjectLine}…</p>
                      </div>
                      <div className="sequence-card-email-body">
                        <p className="sequence-card-email-label-text">BODY</p>
                        <p className="sequence-card-email-text">{sequence.EmailBody}…</p>
                      </div>
                    </div>
                    <div className="sequence-card-footer">
                        <div className="sequence-card-footer-button">
                          <input
                            id={sequence.id}
                            type="checkbox"
                            name="Status"
                            className="switch is-rounded is-info"
                            checked={sequence.Status}
                            onChange={() =>
                              this.handleToggleChange(sequence.id, sequence.Status)
                            }
                          />
                          <label
                            htmlFor={sequence.id}
                            className="sequence-card-footer-button-activate"
                          >
                            <span className="sequence-card-footer-button-activate-text">Activate</span>
                          </label>
                        </div>
                        <div className="sequence-card-footer-button">
                          <Link
                            to={`sequence/${sequence.slug}/edit`}
                            className="sequence-card-footer-button-standard">
                            <img src={EditIcon} className="sequence-card-icon-standard" />
                            <span className="sequence-card-footer-button-standard-text">Edit</span>
                          </Link>
                        </div>
                        <div className="sequence-card-footer-button">
                          <div
                            className="sequence-card-footer-button-archive"
                            onClick={ () => this.handleArchive(sequence.id)}
                          >
                            <img src={TrashIcon} className="sequence-card-icon-archive" />
                            <span className="sequence-card-footer-button-archive-text">Archive</span>
                          </div>
                        </div>
                    </div>
                  </div>
                }
                </div>
              ))}
            </div>
          )
        }}
      </FirestoreCollection>
    )
  }
}

export default Sequences;
