import React from 'react';
// import { Link } from 'react-router-dom';
import WV from '../../assets/west-vesey.svg';

const now = new Date();
const nowYear = now.getFullYear();

const Footer = () =>
  <footer className="footer">
    <div className="container">
      <div className="content has-text-centered">
        <p className="has-text-weight-400 has-text-white is-size-5">
          Questions? <a href="mailto:matt@presshunt.co" className="is-dark-link">Send us an email</a> or chat with us over here <span role="img" aria-label="pointing">👉</span>
        </p>
        <p className="has-text-weight-medium has-text-white">
          <span style={{opacity:.7}}><a href="/" className="has-text-white">Home</a> &nbsp;•&nbsp; <a href="/pricing" className="has-text-white">Pricing</a>&nbsp;•&nbsp; <a href="/news-monitoring" className="has-text-white">News Monitoring</a> &nbsp;•&nbsp; <a href="/journalist-database" className="has-text-white">Journalists</a> &nbsp;•&nbsp; <a href="/podcasts" className="has-text-white">Podcasts</a> &nbsp;•&nbsp; <a href="/requests" className="has-text-white">PR Requests</a> &nbsp;•&nbsp; <a href="/influencers" className="has-text-white">Influencers</a> &nbsp;•&nbsp; <a href="/journalists" className="has-text-white">Directory</a></span>
        </p>
        <p className="has-text-weight-medium has-text-white">
          <span style={{opacity:.4}}>© {nowYear} Press Hunt &nbsp;•&nbsp; Building the PR tools of the future in NYC & LA</span>
        </p>
        <p className="has-text-weight-medium has-text-white">
          <span style={{opacity:.3}}>
            <a
              href="https://www.iubenda.com/terms-and-conditions/12880537"
              target="_blank"
              rel="noopener noreferrer"
              className="has-text-white"
            >
              Terms & Conditions
            </a>&nbsp;&nbsp;•&nbsp;&nbsp;
            <a
              href="https://www.iubenda.com/privacy-policy/12880537"
              target="_blank"
              rel="noopener noreferrer"
              className="has-text-white"
            >
              Privacy Policy
            </a>&nbsp;&nbsp;•&nbsp;&nbsp;
            <a
              href="https://www.iubenda.com/privacy-policy/12880537/cookie-policy"
              className="has-text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>
          </span>
        </p>
        <a href="https://www.westvesey.com/?ref=presshunt-2" target="_blank" rel="noopener noreferrer">
          <img
            src= { WV }
            style= {{ height:"40px", margin:"auto" }}
            alt="West Vesey"
          />
        </a>
      </div>
    </div>
  </footer>

export default Footer;
