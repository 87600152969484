import React, { Component } from 'react';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';
import FireflySubscription from '../misc/FireflySubscription';
import Error from '../misc/Error';

class AddAllToListButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      isLoading: false,
      emailIndex: [],
      journalistIndex: [],
      podcastIndex: [],
      broadcasterIndex: [],
      influencerIndex: [],
      dataType: this.props.dataType,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async addJournalistsToList(key, profiles, plan) {
    console.log("Adding journalists");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        journalistIndex: doc.data().journalistIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        let journalistIndex = this.state.journalistIndex;

        Promise.all(profiles.map((profile, index) => {

          let objectID = profile.objectID;
          this.setState({ isLoading: true });

          if (objectID && journalistIndex.includes(objectID)) {
            return;
          } else {
            profile.addedOn = Firebase.firestore.Timestamp.now();
            profile.dataType = "journalist";
            profile["counter"] = snap.size + index + 1;
            if (profile.secondary_email && profile.email === "") {
              profile.email = profile.secondary_email;
            };
            journalistIndex.push(objectID);
            return docRef.collection("entries")
              .add(profile)
              .catch( error => {
                alert(`Whoops, couldn't add to list: ${error.message}`)
              })
          };
        }))
        .then(() => {
          docRef.update({
            updatedOn: Firebase.firestore.Timestamp.now(),
            journalistIndex
          });
          this.setState({ isLoading: false, saved: true });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 2000);
        });
      }
      else
        return alert("This list has reached your limit of 200 entries!");
    });
  }

  async addPodcastsToList(key, profiles, plan) {
    console.log("Adding podcasts");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        podcastIndex: doc.data().podcastIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        let podcastIndex = this.state.podcastIndex;

        Promise.all(profiles.map((profile, index) => {

          let objectID = profile.objectID;
          this.setState({ isLoading: true });

          if (objectID && podcastIndex.includes(objectID)) {
            return;
          } else {
            profile.addedOn = Firebase.firestore.Timestamp.now();
            profile.dataType = "podcast";
            profile.name = profile.title;
            profile["counter"] = snap.size + index + 1;

            podcastIndex.push(objectID);
            return docRef.collection("entries")
              .add(profile)
              .catch( error => {
                alert(`Whoops, couldn't add to list: ${error.message}`)
              })
          };
        }))
        .then(() => {
          docRef.update({
            updatedOn: Firebase.firestore.Timestamp.now(),
            podcastIndex
          });
          this.setState({ isLoading: false, saved: true });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 2000);
        });
      }
      else
        return alert("This list has reached your limit of 200 entries!");
    });
  }

  async addBroadcastsToList(key, profiles, plan) {
    console.log("Adding broadcasters");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        broadcasterIndex: doc.data().broadcasterIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        let broadcasterIndex = this.state.broadcasterIndex;

        Promise.all(profiles.map((profile, index) => {

          let objectID = profile.objectID;
          this.setState({ isLoading: true });

          if (objectID && broadcasterIndex.includes(objectID)) {
            return;
          } else {
            profile.addedOn = Firebase.firestore.Timestamp.now();
            profile.dataType = "broadcaster";
            profile["counter"] = snap.size + index + 1;
            if (profile.name !== undefined) {
              profile.name = profile.outlet;
            };

            broadcasterIndex.push(objectID);
            return docRef.collection("entries")
              .add(profile)
              .catch( error => {
                alert(`Whoops, couldn't add to list: ${error.message}`)
              })
          };
        }))
        .then(() => {
          docRef.update({
            updatedOn: Firebase.firestore.Timestamp.now(),
            broadcasterIndex
          });
          this.setState({ isLoading: false, saved: true });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 2000);
        });
      }
      else
        return alert("This list has reached your limit of 200 entries!");
    });
  }
  async addInfluencersToList(key, profiles, plan) {
    console.log("Adding influencers");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      //fetch email Index and set state as such
      this.setState({
        influencerIndex: doc.data().influencerIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 12000) {
        return alert("You've reached limit. Please contact support!");
      }
      if (snap.size < 201 || plan  === 3 || plan === 30 || plan  === 4 || plan === 40 || plan  === 5 || plan === 50 || plan  === 6 || plan === 60 || plan  === 7 || plan === 70) {
        let influencerIndex = this.state.influencerIndex;

        Promise.all(profiles.map((profile, index) => {

          let objectID = profile.objectID;
          this.setState({ isLoading: true });

          if (objectID && influencerIndex.includes(objectID)) {
            return;
          } else {
            profile.addedOn = Firebase.firestore.Timestamp.now();
            profile.dataType = "influencer";
            profile["counter"] = snap.size + index + 1;

            influencerIndex.push(objectID);
            return docRef.collection("entries")
              .add(profile)
              .catch( error => {
                alert(`Whoops, couldn't add to list: ${error.message}`)
              })
          };
        }))
        .then(() => {
          docRef.update({
            updatedOn: Firebase.firestore.Timestamp.now(),
            influencerIndex
          });
          this.setState({ isLoading: false, saved: true });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 2000);
        });
      }
      else
        return alert("This list has reached your limit of 200 entries!");
    });
  }

  async addToList(key, profiles, plan) {
    console.log("Legacy function");
  }

  render() {
    const { dataType } = this.state;
    return (
      <FireflySubscription>
        { ({ isLoading, error, auth, subscription }) => {
          if (isLoading) {
            return (
              <a className="heaven-page-cta-button" aria-haspopup="true" aria-controls="dropdown-menu3">
                <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-chevron-down heaven-nav-button-icon-right" aria-hidden="true"></i>
              </a>
            )
          }
          if (error) {
            return <Error error={error} />
          }
          if (!auth) {
            return <span />
          }
          return (
            <FirestoreCollection
              path={`lists`}
              sort="createdOn:desc"
              filter={['createdBy', '==', auth.uid]}
            >
              { ({error, isLoading, data}) => {
                if (error) {
                  return <Error error={error} />
                }
                if (isLoading) {
                  return  (
                    <a className="heaven-page-cta-button">
                      <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-chevron-down heaven-nav-button-icon-right" aria-hidden="true"></i>
                    </a>
                  )
                }
                if (!isLoading && data.length === 0) {
                  return <span />
                }
                return (
                  <div>
                    {this.state.isLoading ? (
                      <a className="heaven-page-success-button bg-yellow-600 border border-yellow-600">
                        <i className="fas fa-spinner heaven-nav-button-icon"></i>Processing…
                      </a>
                    ) : this.state.saved ? (
                      <a className="heaven-page-success-button bg-green-600 border border-green-600">
                        <i className="fas fa-check-circle heaven-nav-button-icon"></i>Saved!
                      </a>
                    ) : (
                    <div className="dropdown is-right is-hoverable">
                      <div className="dropdown-trigger">
                        <a className="heaven-page-cta-button" aria-haspopup="true" aria-controls="dropdown-menu3">
                          <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-chevron-down heaven-nav-button-icon-right" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                        <div className="dropdown-content heaven-dropdown-content">
                          {data.map((list, index) => (
                            ((subscription.plan === 2 || subscription === 20) && list.csvUpload) ? (
                              <div key={list.id}>
                                <NavLink
                                  to="/pricing"
                                  className="dropdown-item heaven-page-dropdown-item"
                                  style={{ cursor:"pointer" }}
                                >
                                  <i className="fas fa-list heaven-page-dropdown-item-icon" />
                                  {list.name}<i className="fas fa-lock heaven-nav-link-icon-lock" />
                                </NavLink>
                              </div>
                            ) : (
                              <div
                                key={index}
                                onClick={() => {
                                  dataType === "journalist" ?
                                  this.addJournalistsToList(list.id, this.props.profiles, subscription.plan) : dataType === "podcast" ?
                                  this.addPodcastsToList(list.id, this.props.profiles, subscription.plan) : dataType === "broadcaster" ?
                                  this.addBroadcastsToList(list.id, this.props.profiles, subscription.plan) : dataType === "influencer" ?
                                  this.addInfluencersToList(list.id, this.props.profiles, subscription.plan) : this.addToList(list.id, this.props.profiles, subscription.plan)
                                }}
                                className="dropdown-item heaven-page-dropdown-item"
                                style={{ cursor:"pointer" }}
                              >
                                <i className="fas fa-list heaven-page-dropdown-item-icon" />
                                <span>{list.name}</span>
                              </div>
                            )
                          ))}
                          <div className="heaven-dropdown-content-second-half">
                            <NavLink className="heaven-page-dropdown-button" to="/lists/new" style={{ fontSize:'14px' }}><i className="fas fa-plus heaven-nav-button-icon" />New list</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                )
              }}
            </FirestoreCollection>
          )
        }}
      </FireflySubscription>
    )
  }
}
export default AddAllToListButton;
