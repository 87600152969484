import React, { PureComponent } from 'react';
import submitJournalist from '../../actions/submitJournalist';
import {
  FormRow,
  FormLabel,
  TextInput,
  TextArea,
} from '../../styles/forms';

class JournalistForm extends PureComponent {

  onSubmit = event => {
    event.preventDefault()
    const {name, email, phone, avatar, outlet, outlet_url, twitter, website, linkedin, tags, industries, articles} = event.target.elements
    const values = {
      name: name.value,
      email: email.value,
      phone: phone.value || "",
      avatar: avatar.value || "",
      outlet: outlet.value || "",
      outlet_url: outlet_url.value || "",
      twitter: twitter.value || "",
      website: website.value || "",
      linkedin: linkedin.value || "",
      tags: tags.value || "",
      industries: industries.value || "",
      articles: articles.value || "",
    }
    submitJournalist(values).then(() => this.props.history.push("/"));
  }
  render() {
    return (
      <section className="section has-dashboard-padding">
        <div className="container is-paddingless">
          <div className="is-sign-in-up">
            <form onSubmit={this.onSubmit}>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="name" className="form-title">Name*</FormLabel>
                  <TextInput type="text" name="name" className="form-input" required />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="email" className="form-title">E-mail*</FormLabel>
                  <TextInput type="email" name="email" className="form-input" required />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="phone" className="form-title">Phone Number</FormLabel>
                  <TextInput type="tel" name="phone" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="avatar" className="form-title">Link to Profile Photo</FormLabel>
                  <TextInput type="url" name="avatar" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="outlet" className="form-title">Affliated Media Outlet</FormLabel>
                  <TextInput type="text" name="outlet" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="outlet_url" className="form-title">Outlet Website</FormLabel>
                  <TextInput type="url" name="outlet_url" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="twitter" className="form-title">Twitter Username</FormLabel>
                  <TextInput type="text" name="twitter" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="website" className="form-title">Personal Website/Blog</FormLabel>
                  <TextInput type="url" name="website" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="linkedin" className="form-title">LinkedIn</FormLabel>
                  <TextInput type="url" name="linkedin" className="form-input" />
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="tags" className="form-title">Tags (separated by comma)</FormLabel>
                  <TextArea type="text" name="tags" className="form-input"/>
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="industries" className="form-title">Industries (separated by comma)</FormLabel>
                  <TextArea type="text" name="industries" className="form-input"/>
                </div>
              </FormRow>
              <FormRow>
                <div className="form-container">
                  <FormLabel for="articles" className="form-title">Links to Articles (separated by comma)</FormLabel>
                  <TextArea type="text" name="articles" className="form-input"/>
                </div>
              </FormRow>

              <button className="is-action-button">Submit Journalist</button>
            </form>

          </div>
        </div>
      </section>
    )
  }
}

export default JournalistForm;
