import React from 'react';

const JournalistBulkResult = ({ hit }) => (
  <div className="container search-list is-centered">
    <div className="bulk-journalist-card journalist-card-dash">
      <div className="journalist-card-content">
        {(hit.country && hit.Area) ? (
            <p><span className="bulk-journo-name">{hit.name}</span> in {hit.Area}, {hit.country}</p>
          ) : (hit.country && !hit.Area) ? (
            <p><span className="bulk-journo-name">{hit.name}</span> in {hit.country}</p>
          ) : <p><span className="bulk-journo-name">{hit.name}</span></p>
        }

        {(hit.outlet_text && Array.isArray(hit.outlet_text) && hit.outlet_text.length > 0 && hit.kind_of_journalist) ? (
        <p>{hit.kind_of_journalist} at { hit.outlet_text.map((outlet, index) => <span key={index}>{index > 0 && ", "}{outlet}</span>)}</p>
          ) : (!hit.mediatype && hit.kind_of_journalist !== "False") ? <p>{hit.kind_of_journalist}</p> : <p>{hit.mediatype}</p>
        }
      </div>
    </div>
  </div>
)

export default JournalistBulkResult;
