import React, { Component } from 'react';
import { FirestoreCollection } from 'react-firestore';
import { NavLink } from 'react-router-dom';
import Firebase from 'firebase/app';
import { CSVReader } from 'react-papaparse';
import createList from '../../actions/createList';

import NoAuth from '../misc/noAuth';

class Lists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalActive: false,
    }
  }
  toCamel = o => {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = this.toCamel(value)
          }
          return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).replace(/\s/g, '').toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = this.toCamel(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }

  toggleModal = () => {
    this.setState(state => ({
      isModalActive: !state.isModalActive
    }));
  }

  handleOnDrop = (data, meta) => {
    if (meta.type === "text/csv") {
      var entries = [];
      const values = {
        name: meta.name.substr(0, meta.name.indexOf('.')),
        csvUpload: true,
      }
      createList(values).then( listId => {
        var docRef = Firebase.firestore().collection("lists").doc(listId);
        docRef.collection("entries").get().then(snap => {
          var count = snap.size;
          data.map((entry, index) => {
            let camelData = this.toCamel(entry.data);
            camelData.csvUpload = true;
            camelData.counter = count + index + 1;
            camelData.addedOn = Firebase.firestore.Timestamp.now();
            camelData.name = camelData.firstName + " " + camelData.lastName;
            docRef.collection("entries").add(camelData);
          })
        });
      })
    } else {
      alert("We currently support CSV files only!");
    }
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }

  noActiveSub = () => {
    return (
      <section className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto px-4 max-w-3xl">
        <div className="float-right mb-10">
          <NavLink className="heaven-card-page-white-button" to="/mail/new">
            <button><i className="fas fa-envelope heaven-nav-button-icon"></i>Email contacts</button>
          </NavLink>
          <NavLink className="heaven-page-cta-button" to="/pricing">
            <button><i className="fas fa-plus heaven-nav-button-icon"></i>New list<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ color:'white',opacity:'.5' }} /></button>
          </NavLink>
        </div>
        <div className="grid-list" style={{ clear:'both' }}>
          <div className="grid-list-top">
            <i className="fas fa-list heaven-nav-link-icon" />Media lists
          </div>
          <NavLink to="/pricing" className="grid-list-row">
            <i className="fas fa-list grid-list-row-icon" />
            <span className="grid-list-row-text">Technology</span>
            <i className="fas fa-arrow-right grid-list-row-icon-right" />
          </NavLink>
          <NavLink to="/pricing" className="grid-list-row">
            <i className="fas fa-list grid-list-row-icon" />
            <span className="grid-list-row-text">Fashion</span>
            <i className="fas fa-arrow-right grid-list-row-icon-right" />
          </NavLink>
          <NavLink to="/pricing" className="grid-list-row">
            <i className="fas fa-list grid-list-row-icon" />
            <span className="grid-list-row-text">Podcasts</span>
            <i className="fas fa-arrow-right grid-list-row-icon-right" />
          </NavLink>
          <NavLink to="/pricing" className="grid-list-row">
            <i className="fas fa-list grid-list-row-icon" />
            <span className="grid-list-row-text">New York Times</span>
            <i className="fas fa-arrow-right grid-list-row-icon-right" />
          </NavLink>
          <NavLink to="/pricing" className="grid-list-row">
            <i className="fas fa-list grid-list-row-icon" />
            <span className="grid-list-row-text">Influencers</span>
            <i className="fas fa-arrow-right grid-list-row-icon-right" />
          </NavLink>
          <div className="grid-list-bottom" />
        </div>
      </section>
    )
  }

  render() {
    const { auth, subscription, plan, status, lists } = this.props;
    
    return (
      <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto px-4 max-w-3xl">
        {!auth ? (
          <NoAuth />
        ) : (!subscription || subscription.stripeSubscriptionStatus !== "active" || plan === 1 || plan === 10) ? (
          this.noActiveSub()
        ) : (
          lists.length === 0 ? (
            <section>
              <div className={this.state.isModalActive ? "modal is-active" : "modal"}>
                <div className="modal-background" onClick={this.toggleModal}></div>
                <div className="modal-card">
                  <header className="heaven-modal-header">
                    <div className="columns">
                      <div className="column is-three-quarters">
                        <p className="heaven-modal-title">Import list from <span style={{color:'#1d2531'}}>Cision</span></p>
                      </div>
                      <div className="column is-one-quarters">
                        <button className="delete heaven-close-top-right" aria-label="close" onClick={this.toggleModal}></button>
                      </div>
                    </div>
                  </header>
                  <section className="modal-card-body" style={{ padding:'40px' }}>
                    <CSVReader
                      onDrop={this.handleOnDrop}
                      onError={this.handleOnError}
                      style={{color:'#1d2531',fontWeight:'bold',fontStyle:'italic',borderColor:'#5e6e77'}}
                      config={{header: true}}
                      addRemoveButton
                      onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span><i className="fas fa-file-upload heaven-nav-button-icon" />Drop CSV file here or click to import</span>
                    </CSVReader>
                  </section>
                  <div className="heaven-card-footer">
                    <div className="heaven-card-footer-buttons">
                      <div className="is-pulled-left" style={{ color: "#ef2942", padding: 8 }}><i className="fas fa-exclamation-circle" /> CSV file only</div>
                      <a className="heaven-card-cta-button is-pulled-right heaven-margin-right" style={{padding:'8px 12px'}} onClick={this.toggleModal}><i className="fas fa-check heaven-nav-button-icon" />Save</a>
                      <a className="heaven-card-white-button is-pulled-right" style={{padding:'8px 12px'}} onClick={this.toggleModal}><i className="fas fa-times-circle heaven-nav-button-icon" />Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="float-right mb-10">
                <NavLink className="heaven-card-page-white-button heaven-margin-right" to="/mail/new">
                  <button><i className="fas fa-envelope heaven-nav-button-icon" />Email contacts</button>
                </NavLink>
                { (subscription.plan !== 1 && subscription.plan !== 10) ? (
                  <button className="heaven-card-page-white-button hidden lg:inline" onClick={this.toggleModal}>
                    <i className="fas fa-file-upload heaven-nav-button-icon" />Import list
                  </button>
                ) : (
                  <NavLink className="heaven-card-page-white-button hidden lg:inline" to="/pricing">
                    <button><i className="fas fa-file-upload heaven-nav-button-icon" />Import list<i className="fas fa-lock heaven-nav-link-icon-lock"/></button>
                  </NavLink>
                ) }
                { (subscription.plan !== 2 && subscription.plan !== 20)? (
                  <NavLink className="heaven-page-cta-button" to="/lists/new">
                    <button><i className="fas fa-plus heaven-nav-button-icon" />New list</button>
                  </NavLink>
                ) : <span /> }
              </div>
              <div className="grid-list" style={{ clear:'both' }}>
                <div className="grid-list-top">
                  <i className="fas fa-list heaven-nav-link-icon" />Media lists
                </div>
                <div className="grid-list-row">
                  <i className="fas fa-list grid-list-row-icon" />
                  <span className="grid-list-row-text">No list yet</span>
                </div>
                <div className="grid-list-bottom" />
              </div>
            </section>
          ) : (
            <section>
              <div className={this.state.isModalActive ? "modal is-active" : "modal"}>
                <div className="modal-background" onClick={this.toggleModal}></div>
                <div className="modal-card">
                  <header className="heaven-modal-header">
                    <div className="columns">
                      <div className="column is-three-quarters">
                        <p className="heaven-modal-title">Import list from <span style={{color:'#1d2531'}}>Cision</span></p>
                      </div>
                      <div className="column is-one-quarters">
                        <button className="delete heaven-close-top-right" aria-label="close" onClick={this.toggleModal}></button>
                      </div>
                    </div>
                  </header>
                  <section className="modal-card-body" style={{ padding:'40px' }}>
                    <CSVReader
                      onDrop={this.handleOnDrop}
                      onError={this.handleOnError}
                      style={{color:'#1d2531',fontWeight:'bold',fontStyle:'italic',borderColor:'#5e6e77'}}
                      config={{header: true}}
                      addRemoveButton
                      onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span><i className="fas fa-file-upload heaven-nav-button-icon" />Drop CSV file here or click to import</span>
                    </CSVReader>
                  </section>
                  <div className="heaven-card-footer">
                    <div className="heaven-card-footer-buttons">
                      <div className="is-pulled-left" style={{ color: "#ef2942", padding: 8 }}><i className="fas fa-exclamation-circle" /> CSV file only</div>
                      <a className="heaven-card-cta-button is-pulled-right heaven-margin-right" style={{padding:'8px 12px'}} onClick={this.toggleModal}><i className="fas fa-check heaven-nav-button-icon" />Save</a>
                      <a className="heaven-card-white-button is-pulled-right" style={{padding:'8px 12px'}} onClick={this.toggleModal}><i className="fas fa-times-circle heaven-nav-button-icon" />Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="float-right mb-10">
                <NavLink className="heaven-margin-right" to="/mail/new">
                  <button className="heaven-card-page-white-button"><i className="fas fa-envelope heaven-nav-button-icon" />Email contacts</button>
                </NavLink>
                { (subscription.plan !== 1 && subscription.plan !== 10)? (
                  <button className="heaven-card-page-white-button hidden lg:inline" onClick={this.toggleModal}>
                    <i className="fas fa-file-upload heaven-nav-button-icon" />Import list
                  </button>
                ) : (
                  <NavLink className="hidden lg:inline" to="/pricing">
                    <button className="heaven-card-page-white-button"><i className="fas fa-file-upload heaven-nav-button-icon" />Import list<i className="fas fa-lock heaven-nav-link-icon-lock"/></button>
                  </NavLink>
                ) }
                { (subscription.plan !== 2 && subscription.plan !== 20)? (
                  <NavLink to="/lists/new">
                    <button className="heaven-page-cta-button"><i className="fas fa-plus heaven-nav-button-icon" />New list</button>
                  </NavLink>
                ) : <span /> }
              </div>
              <div className="grid-list" style={{ clear:'both' }}>
                <div className="grid-list-top">
                  <i className="fas fa-list heaven-nav-link-icon" />Media lists
                </div>
                {lists.map(list => (
                <NavLink key={list.id} to={`list/${list.slug}`} className="grid-list-row">
                  <i className="fas fa-list grid-list-row-icon" />
                  <span className="grid-list-row-text">{list.name}</span>
                  <i className="fas fa-arrow-right grid-list-row-icon-right" />
                </NavLink>
                ))}
                <div className="grid-list-bottom" />
              </div>
            </section>
          )
        )}
      </div>
    )
  }
}

export default Lists;
