import React from 'react';
import FireflySubscription from '../misc/FireflySubscription';
import AddToListButton from './addToListButton';
import Placeholder from '../../assets/placeholder.png';

const SearchResult = ({ hit }) => (
  <div className="container search-list is-centered">
    <FireflySubscription>
      {({ isLoading, subscription, plan }) => {
        if (isLoading) {
          return <span />
        } else {
          return (
            <div className="heaven-journalist-card journalist-card-dash">
              <div className="journalist-card-content">
                <div className="journalist-card-content-header">
                  {hit.avatar ? (
                    <div>
                      <img src={hit.avatar.replace("_normal","")} alt="" className="rounded-full w-16 h-16" />
                    </div>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" style={{ color: "#eff3f7" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  )}
                  <div className="py-2 px-4">
                    <div className="journalist-card-name-and-link">
                      <div className="dropdown is-hoverable is-left">
                        <div className="dropdown-trigger">
                          <p className="journalist-req-name">{hit.name}<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></p>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                          <ul className="dropdown-content heaven-req-card-dropdown-content">
                            {(hit.outlet_text && Array.isArray(hit.outlet_text) && hit.outlet_text.length > 0 && hit.kind_of_journalist) ?
                              <p className="journalist-card-type-outlet" style={{paddingBottom:'0px'}}>
                                <span className="journalist-card-kind"><i className="fas fa-id-badge heaven-nav-link-icon"/>{hit.kind_of_journalist}</span> at { hit.outlet_text.map((outlet, index) => <span key={index} className="journalist-card-outlet">{index > 0 && ", "}{outlet}</span>)}
                              </p> : (hit.mediatype === "" && hit.kind_of_journalist !== "False" && hit.kind_of_journalist) ? <p><i className="fas fa-id-badge heaven-nav-link-icon"/>{hit.kind_of_journalist}</p>
                                  : hit.mediatype ? (<p>{hit.mediatype}</p>) : <span /> }
                            {hit.country && hit.area ? (
                              <p className="heaven-margin-bottom"><b><i className="fas fa-globe-americas heaven-nav-link-icon"/>{hit.area}, {hit.country}</b></p>
                            ) : hit.country ? (
                              <p className="heaven-margin-bottom"><b><i className="fas fa-globe-americas heaven-nav-link-icon"/>{hit.country}</b></p>
                            ) : hit.twitter_location ? (
                              <p className="heaven-margin-bottom"><b><i className="fas fa-globe-americas heaven-nav-link-icon"/>{hit.twitter_location}</b></p>
                            ) : <span />
                            }
                            {hit.twitter_bio ? <p className="heaven-req-bio">{hit.twitter_bio}</p> : <span /> }
                            {(hit.industry && Array.isArray(hit.industry)) ? (
                              hit.industry.map((industry, index) =>
                                <div key={index} className="journalist-card-tag-wrapper">
                                  <div className="heaven-contact-card-tag">
                                    <i className="fas fa-clipboard-list heaven-nav-link-icon"/>
                                    <p className="journalist-card-tag-text">{industry}</p>
                                  </div>
                                </div>
                              )
                            ) : <span /> }
                            {/*tags conditional*/}
                            {(hit.tags && Array.isArray(hit.tags)) ? (
                              hit.tags.map((tag, index) =>
                                <div key={index} className="journalist-card-tag-wrapper">
                                  <div className="heaven-contact-card-tag">
                                    <i className="fas fa-tags heaven-nav-link-icon"/>
                                    <p className="journalist-card-tag-text">{tag}</p>
                                  </div>
                                </div>
                              )
                            ) : <span /> }
                          </ul>
                        </div>
                      </div>
                      {/*checks to see if there's a journalist url*/}
                      {(hit.journalist_url && hit.email_domain_corrected !== "gmail.com" && hit.email_domain_corrected !== "yahoo.com" && hit.email_domain_corrected !== "yahoo.co.uk") ? (
                        <a href={hit.journalist_url} target="_blank" rel="noopener noreferrer">
                          <div className="heaven-contact-card-url-tag">
                            <i className="fas fa-external-link-alt journalist-card-url-link"/>
                            <p className="heaven-content-card-url-text">{hit.email_domain_corrected}</p>
                          </div>
                        </a>
                      ) : <span /> }
                    </div>
                    {/*checks to see if there is outlet_text conditional*/}
                    <div className="journalist-card-type-outlet" style={{ marginBottom:'0px' }} />
                  </div>
                </div>
              </div>

              {(hit.query) ? (
              <div className="heaven-req-card-content">
                {(hit.title && hit.category) ? (
                  <p className="req-card-title">{hit.title} • {hit.category}</p>
                ) : (hit.title) ? (
                  <p className="req-card-title">{hit.title}</p>
                ) : <span /> }
                <p className="req-card-query">
                  {hit.query.replace(RegExp("@@@",'g'),"   ")}
                </p>
                {hit.requirements ? (
                  <p className="req-card-require"><i className="fas fa-clipboard-check heaven-nav-button-icon" />Requirements: <b>{hit.requirements}</b></p>
                ) : <span /> }
                {hit.deadline ? (
                  <p className="req-card-deadline"><i className="fas fa-calendar-alt heaven-nav-button-icon" />Deadline: <b>{hit.deadline}</b></p>
                ) : <span /> }
                <p className="req-card-date">{hit.location_of_tweet ? (hit.date in hit.location_of_tweet) : hit.date}</p>
              </div>
              ) : <span /> }

              <div className="heaven-card-footer">
                <div className="columns heaven-card-footer-columns">
                  <div className="column is-one-quarters">
                    <div className="heaven-card-footer-buttons">
                      <div className="dropdown is-hoverable is-left">
                        <div className="dropdown-trigger">
                          <a className="heaven-card-white-button is-pulled-left"><i className="fas fa-address-card heaven-nav-button-icon"/>Contact<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></a>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                          <ul className="dropdown-content heaven-card-dropdown-content">
                            {(hit.email) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"mailto:" + hit.email} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope heaven-nav-link-icon"/>{hit.email}
                              </a>
                            ) : <span /> }
                            {(hit.secondary_email) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"mailto:" + hit.secondary_email} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope heaven-nav-link-icon"/>{hit.secondary_email}
                              </a>
                            ) : <span /> }
                            {(hit.phone) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" style={{cursor: 'auto'}}>
                                <i className="fas fa-phone heaven-nav-link-icon"/>{hit.phone.replace("@","")}
                              </a>
                            ) : <span /> }
                            {(hit.twitter_name && hit.twitter_name.includes("://")) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"https://www.twitter.com/" + hit.twitter_name.substr(hit.twitter_name.lastIndexOf('/') + 1)} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter heaven-nav-link-icon"/>Twitter
                              </a>
                            ) : (hit.twitter_name) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"https://twitter.com/" + hit.twitter_name} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter heaven-nav-link-icon"/>Twitter
                              </a>
                            ) : <span /> }
                            {(hit.linkedin_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.linkedin_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin heaven-nav-link-icon"/>LinkedIn
                              </a>
                            ) : <span /> }
                            {(hit.facebook_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.facebook_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook heaven-nav-link-icon"/>Facebook
                              </a>
                            ) : <span /> }
                            {(hit.instagram_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.instagram_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram heaven-nav-link-icon"/>Instagram
                              </a>
                            ) : <span /> }
                            {(hit.youtube_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.youtube_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-youtube heaven-nav-link-icon"/>YouTube
                              </a>
                            ) : <span /> }
                            {(hit.website) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.website} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-link heaven-nav-link-icon"/>Website
                              </a>
                            ) : <span /> }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="heaven-card-footer-buttons-right">
                      <AddToListButton profile={hit} dataType="journalist" />
                      {(hit.secondary_email) ? (
                        <a className="heaven-card-white-button is-pulled-right" href={"mailto:" + hit.secondary_email}><i className="fas fa-envelope heaven-nav-button-icon"/>Reply</a>
                      ) : (hit.email) ? (
                        <a className="heaven-card-white-button is-pulled-right" href={"mailto:" + hit.email}><i className="fas fa-envelope heaven-nav-button-icon"/>Reply</a>
                      ) : (!hit.email && !hit.secondary_email && hit.twitter_name) ? (
                        <a className="heaven-card-white-button is-pulled-right" href={"https://twitter.com/" + hit.twitter_name} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter heaven-nav-button-icon"/>Reply</a>
                      ) : <span /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }}
    </FireflySubscription>
  </div>
)

export default SearchResult;
