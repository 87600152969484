import React, { Component } from 'react';
import {
  Link,
} from 'react-router-dom';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import Logo from '../../assets/press-hunt-logo-clear.svg';
import FireflySubscription from '../misc/FireflySubscription';

class Navigation extends Component {

  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }))
  }

  render() {
    return (
      <FireflySubscription>
        { ({isLoading, error, subscription, auth}) => {
          if (!auth) {
            return (
              <nav className="navbar-directory">
                <div className="navbar-brand">
                  <div className="navbar-item logo">
                    <Link to="/">
                      <img
                        src= { Logo }
                        className="is-logo"
                        alt="Press Hunt logo"
                      />
                      <h1 className="has-text-white title is-size-4 is-logo-word">
                        Press Hunt
                      </h1>
                    </Link>
                    <span className="two-white">
                      2.0
                    </span>
                  </div>
                  <div className="navbar-burger burger" onClick={this.toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                  <div className="navbar-end">
                    <div className="navbar-item">
                      <div className={ this.state.isActive ? 'field' : 'field is-grouped'}>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/#FAQ" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>FAQ</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>Pricing</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/signin"><button className={ this.state.isActive ? 'button is-white' : 'button is-white'}>Sign in</button></Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/signup"><button className={ this.state.isActive ? 'button is-stripe-nav' : 'button is-black'}>Get started</button></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            )
          }
          if (!subscription) {
            return (
              <nav className="navbar-directory">
                <div className="navbar-brand">
                  <div className="navbar-item logo">
                    <Link to="/">
                      <img
                        src= { Logo }
                        className="is-logo"
                        alt="Press Hunt logo"
                      />
                      <h1 className="has-text-white title is-size-4 is-logo-word">
                        Press Hunt
                      </h1>
                    </Link>
                    <span className="two-white">
                      2.0
                    </span>
                  </div>
                  <div className="navbar-burger burger" onClick={this.toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                  <div className="navbar-end">
                    <div className="navbar-item">
                      <div className={ this.state.isActive ? 'field' : 'field is-grouped'}>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/#FAQ" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>FAQ</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>Pricing</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-black' : 'button is-black'}>Subscribe now</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            )
          } else {
            return (
              <nav className="navbar-directory">
                <div className="navbar-brand">
                  <div className="navbar-item logo">
                    <Link to="/">
                      <img
                        src= { Logo }
                        className="is-logo"
                        alt="Press Hunt logo"
                      />
                      <h1 className="has-text-white title is-size-4 is-logo-word">
                        Press Hunt
                      </h1>
                    </Link>
                    <span className="two-white">
                      2.0
                    </span>
                  </div>
                  <div className="navbar-burger burger" onClick={this.toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
                  <div className="navbar-end">
                    <div className="navbar-item">
                      <div className={ this.state.isActive ? 'field' : 'field is-grouped'}>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/#FAQ" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>FAQ</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/pricing" className={ this.state.isActive ? 'button is-clear' : 'button is-clear'}>Pricing</Link>
                        </p>
                        <p className={ this.state.isActive ? 'control-mobile' : 'control'}>
                          <Link to="/dashboard" className={ this.state.isActive ? 'button is-black' : 'button is-black'}>Go to dashboard&nbsp;&nbsp;&nbsp;<i className="fas fa-chevron-right"></i></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            )
          }
        }}
      </FireflySubscription>
    )
  }
}

export default Navigation;
