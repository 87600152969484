import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import Error from '../misc/Error';
import { Page } from '../../styles/layout';
import FireflySubscription from '../misc/FireflySubscription';

const AdminPanel = () => (
    <Page>
      <FireflySubscription>
        { ({isLoading, error, subscription, auth}) => {
          if (isLoading) {
            return <p className="loading has-text-centered is-centered"><span role="img" aria-label="construction">🚧</span> Loading… <span role="img" aria-label="construction">🚧</span></p>
          }
          if (error) {
            return <Error error={error} />
          }
          if (subscription.isAdmin) {
            return (
              <div>
                <FirestoreCollection
                  path="subscriptions"
                  sort="createdOn:desc"
                >
                  { ({error, isLoading, data}) => {
                    if (error) {
                      return <Error error={error} />
                    }

                    if (isLoading) {
                      return <p className="loading has-text-centered is-centered"><span role="img" aria-label="construction">🚧</span> Loading… <span role="img" aria-label="construction">🚧</span></p>
                    }

                    if (!isLoading && data.length === 0) {
                      return (
                        <section className="has-margin-left-for-dashnav-v2 has-dashboard-padding">
                          <div className="container is-marginless">
                            <h1 className="title">Your Subscriber</h1>
                            <p className="tip">You don't have any subscriber yet. Good luck.</p>
                          </div>
                        </section>
                      )
                    }

                    return (
                      <section className="section has-margin-left-for-dashnav-v2 has-dashboard-padding">
                        <div className="container is-marginless">
                          <h1 className="title">Your subscribers</h1>
                          <br /><br />
                        </div>
                        <div>
                          <table className="table is-striped">
                            <thead>
                              <tr>
                                <th><abbr title="Position">Pos</abbr></th>
                                <th><abbr title="uid">UID</abbr></th>
                                <th><abbr title="email">E-mail</abbr></th>
                                <th><abbr title="plan">Plan</abbr></th>
                                <th><abbr title="sub">Subscription Status</abbr></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((subscription, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td><p>{subscription.user}</p></td>
                                  <td>{subscription.isAdmin ? <p style={{color: "#f4425f", fontWeight: 900}}>{subscription.email}</p> : <p>{subscription.email}</p>}</td>
                                  <td><p>{subscription.plan}</p></td>
                                  <td><p>{subscription.stripeSubscriptionStatus}</p></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </section>
                    )
                  }}
                </FirestoreCollection>

                <FirestoreCollection
                  path="users"
                  sort="signUpAt:desc"
                >
                  { ({error, isLoading, data}) => {
                    if (error) {
                      return <Error error={error} />
                    }

                    if (isLoading) {
                      return <p className="loading has-text-centered is-centered"><span role="img" aria-label="construction">🚧</span> Loading… <span role="img" aria-label="construction">🚧</span></p>
                    }

                    if (!isLoading && data.length === 0) {
                      return (
                        <section className="has-margin-left-for-dashnav-v2 has-dashboard-padding">
                          <div className="container is-marginless">
                            <h1 className="title">Your Signups</h1>
                            <p className="tip">You don't have any signups yet. Good luck.</p>
                          </div>
                        </section>
                      )
                    }

                    return (
                      <section className="section has-margin-left-for-dashnav-v2 has-dashboard-padding">
                        <div className="container is-marginless">
                          <h1 className="title">Your Signups</h1>
                          <br /><br />
                        </div>
                        <div>
                          <table className="table is-striped">
                            <thead>
                              <tr>
                                <th><abbr title="Position">Pos</abbr></th>
                                <th><abbr title="customerid">Customer ID</abbr></th>
                                <th><abbr title="email">E-mail</abbr></th>
                                <th><abbr title="sub">Subscribed?</abbr></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((user, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td><p>{user.stripeCustomerId}</p></td>
                                  <td><p>{user.email}</p></td>
                                  <td>{user.subscribed ? <p><span role="img" aria-label="checked">✅</span></p> : <p></p>}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </section>
                    )
                  }}
                </FirestoreCollection>
              </div>
            )
          }
          else {
            return <p className="loading has-text-centered is-centered"><span role="img" aria-label="stop">⛔️</span> You Have No Access Right <span role="img" aria-label="stop">⛔️</span></p>
          }
        }}
      </FireflySubscription>
    </Page>
)

export default AdminPanel;
