import Firebase from 'firebase/app';
import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const CheckStats = (domainId, values) => {
  return Firebase.firestore()
    .collection('domainStats')
    .doc(domainId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Sorry, update domain stats: ${error.message}`)
    })
}
const checkDomainStats = (data) => {
	console.log("Reached action");
	var checkingStats = Firebase.functions().httpsCallable('updateDomainStats');
	return checkingStats(data)
    .then(() => {
      console.log("Return from Firestore!");
      return "Firestore updated successfully";
    })
    .catch( error => {
      alert(`Sorry, update domain stats: ${error.message}`)
    })
}

export {
  CheckStats,
  checkDomainStats,
}

