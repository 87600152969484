import React from 'react';
import { Link } from 'react-router-dom';

import Error from '../misc/Error';
import createCampaign from '../../actions/createCampaign';
import CampaignForm from './campaignForm';

const CampaignNew = ({ history, subscription, auth, plan }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
  {(!subscription || plan === 1 || plan === 10) ? (
    <div className="loading has-text-centered is-centered">
      <p>You must upgrade to start a campaign</p>
      <Link to="/pricing">Get Started</Link>
    </div>
  ) : (
    <section>
      <CampaignForm
        auth={auth}
        subscription={subscription}
        onSubmit={values => createCampaign(values).then(campaign => history.push("/campaigns"))}
      />
    </section>
  )}
  </div>
)

export default CampaignNew;
