import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Helmet} from "react-helmet";
import Footer from './footer';
import Hero from '../../assets/requests-hero.svg';
import MobileHero from '../../assets/hero-mobile.svg';
import Nav from '../layout/navigation';
import Users from '../../assets/howler-users.svg';
import Media from '../../assets/media-outlets-prod.svg';
import Chad from '../../assets/chad.jpeg';
import Nile from '../../assets/nile.jpeg';
import Tim from '../../assets/optemization.jpg';
import Lukas from '../../assets/lukas.jpg';
import Will from '../../assets/will.jpeg';
import Jack from '../../assets/jack-darknet.jpg';
import RequestSVG from '../../assets/pr-requests-for-landing.svg';

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      searchTerm: "",
      isRequestActive: true,
      isJournalistActive: false,
      isPodcastActive: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleJournalistNav = () => {
    this.setState(prevState => ({
      isJournalistActive: !prevState.isJournalistActive,
      isPodcastActive: !prevState.isPodcastActive,
      isRequestActive: !prevState.isRequestActive
    }))
  }
  togglePodcastNav = () => {
    this.setState(prevState => ({
      isPodcastActive: !prevState.isPodcastActive,
      isJournalistActive: !prevState.isJournalistActive,
      isRequestActive: !prevState.isRequestActive
    }))
  }
  toggleRequestNav = () => {
    this.setState(prevState => ({
      isRequestActive: !prevState.isRequestActive,
      isPodcastActive: !prevState.isPodcastActive,
      isJournalistActive: !prevState.isJournalistActive
    }))
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>PR Requests: Get press by helping a reporter out 🌟</title>
          <meta name="description" content="Help a reporter out with journo requests –– PR Requests by Press Hunt" />

          <meta itemprop="name" content="PR Requests: Get press by helping a reporter out 🌟" />
          <meta itemprop="description" content="Help a reporter out with journo requests –– PR Requests by Press Hunt" />
          <meta itemprop="image" content="https://i.imgur.com/XJSs0LN.png" />

          <meta property="og:url" content="https://presshunt.co/requests" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="PR Requests: Get press by helping a reporter out 🌟" />
          <meta property="og:description" content="Help a reporter out with journo requests –– PR Requests by Press Hunt" />
          <meta property="og:image" content="https://i.imgur.com/XJSs0LN.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="PR Requests: Get press by helping a reporter out 🌟" />
          <meta name="twitter:description" content="Help a reporter out with journo requests –– PR Requests by Press Hunt" />
          <meta name="twitter:image" content="https://i.imgur.com/XJSs0LN.png" />

          <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

        </Helmet>

        <Nav />

        { this.state.width > 900 ? <img
          src={ Hero }
          className="is-overlay"
          alt="desktop hero"
        /> : <img
          src={ MobileHero }
          className="is-overlay"
          alt="mobile hero"
        />}

        <section className="section has-bg-image is-centered has-text-centered" style={{ height: "28em" }}>
        <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

          <div className="columns">
            <div className="column" style={{paddingTop:'0 !important'}}>
              <div className="container is-overlay has-padding-bottom">
                <div className="hero-div-width has-text-centered">
                  <div><p className="new-tag-landing has-text-centered"><span className="new-tag-landing-span">NEW</span> PR Requests</p></div>
                  <h1 className="title has-text-white has-text-weight-bold is-hero-title-center is-size-3">
                    Get quoted by top-tier journalists using our realtime database of PR requests
                  </h1>
                  <h2 className="subtitle has-text-white has-text-weight-normal is-hero-title-center is-size-4" style={{opacity:'.75'}}>
                    PR Requests is a database of thousands of source requests from journalists all over the world, updated with dozens of new requests every hour
                  </h2>
                   <br />
                </div>

                <a className="button is-stripe-hero-exclamation" href="/signup">Get started for free&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
                <AnchorLink href="#search" className={ this.state.isActive ? 'button is-hiw-hero' : 'button is-hiw-hero-exclamation'}>Learn more</AnchorLink>
                <p className="has-text-white is-size-6 is-hero-sub"></p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns has-text-centered is-centered">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2 has-padding-bottom">
                  <span role="img" aria-label="stars">🤩</span>
                </div>
                <div className="title is-size-4 para-title">
                  Hundreds of chances everyday to get featured in news seen by millions
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="magnifying glass">🔎</span>
                </div>
                <div className="title is-size-4 para-title">
                  Requests aggregated with direct contact info to maximize your coverage
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="newspaper">📰</span>
                </div>
                <div className="title is-size-4 para-title">
                  Search for the best PR requests in seconds and respond in minutes
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section is-paddingless">
          <img
            src={ Media }
            style={{width:'100%',zIndex:'0'}}
            alt="media logos"
          />
        </section>

        <section className="section">
          <div className="container clear-section-card has-text-centered">
            <h2 className="title has-text-centered is-centered">What is a PR request?</h2>
            <p className="subtitle has-text-centered is-centered pricing-sub" style={{marginBottom:'0',paddingBottom:'40px'}}>When journalists write new articles, they like to quote experts on the subject to back up their case and provide more information to their readers. Journalists submit PR requests (also known as “source requests”) like “Looking for an expert in cybersecurity for an article about privacy issues on Facebook.”</p>
            <img
              src={ RequestSVG }
              style={{width:'750px !important', maxWidth:'750px', maxHeight: '500px'}}

            />
            <div>
              <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </section>

        <section className="section" id="howitworks">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">How it works</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Getting started with Press Hunt only takes a few seconds!</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="crystal ball">🔮</span>
                </div>
                <div className="title is-size-4 para-title">
                  1. We aggregate thousands of requests every month
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  Every hour we aggregate dozens of PR requests, complete with the journalist’s contact info like their email, phone number, or their social media
                </p>

              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="magnifying glass">🔎</span>
                </div>
                <div className="title is-size-4 para-title">
                  2. Search through our entire database of PR requests, then directly respond to relevant pitches
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  Finding relevant requests takes only a few seconds!
                </p>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="newspaper">📰</span>
                </div>
                <div className="title is-size-4 para-title">
                  3. If the journalist likes your response, they interview you and quote you in their article
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  A quote in a big article can be seen by millions!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="has-text-centered is-centered">
            <p className="is-size-6 has-text-weight-normal used-by">Our database has been used to run PR campaigns for companies like:</p>
            <img
              src={ Users }
              style={{width:'100%',zIndex:'0'}}
              alt="User logos"
            />
            <a href="https://www.producthunt.com/posts/press-hunt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=123406&theme=dark"} alt="Press Hunt - Find the best journalists to cover you with our DB of 500k 🎉 | Product Hunt Embed"  className="mx-auto" style={{width: "250px", height: "54px", marginTop:'2em'}} />
            </a>
            <a href="https://www.producthunt.com/posts/press-hunt-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt-2-0" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=141052&theme=dark"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
            </a>
            <a href="https://www.producthunt.com/posts/pr-requests?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pr-requests" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180815&theme=dark&period=daily"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
            </a>
          </div>
        </section>

        <section className="section has-background-white">
          <div className="container has-text-centered is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered" id="pricing">What do you get with Press Hunt's PR request database?</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub"></p>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="file cabinet">🗃</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Hundreds of new PR requests added every day, dozens every hour, thousands every month</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="magnifying glass">🔎</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">A dashboard to search through all our active PR requests to find the most relevant for you in seconds</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="magnifying glass">🎊</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">PR requests covering every topic from business and technology to fashion and culture</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="earth">🌍</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Journalists from all over the world––from the USA and Europe to MENA and Asia––submit PR requests every day</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="newspaper">📰</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">A simple, easy way to get more media coverage for your business, startup, product, or even yourself</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="celebrate">🤝</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Get media coverage & build relationships with journalists with just a few minutes of work every day</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="television">📺</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">PR requests for quotes in articles, podcasts, radio, TV, blogs, books, and more!</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="letter">💌</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">An easy to read daily digest newsletter of the newest PR requests</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
          </div>
        </section>

        <section className="section">
          <div className="container clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Our users love us</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Our database has been used by dozens of companies from all over the world</p>
            </div>
            <div className="columns">
            <div className="column is-hiw-card">
              <div className="subtitle is-size-5 has-text-weight-normal">
                "Thanks for your hard work. I did get this interview on the Mike Koolidge show, which I guess is going to be broadcast later today to thousands of people? This kind of persistence is NOT something I could do on my own. So thanks for your hard work on it. That's the first win! Yay! Also I have the BBC interview setup for Thursday. So we'll see what happens then!"
              </div>
              <div className="testimonial-profile-pic-wrapper">
                <img
                  src={ Jack }
                  className="is-testimonial-photo"
                  >
                </img>
                <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Jack Rhysider, Host of Darknet Diaries podcast</p>
              </div>
            </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "I've launched a number of projects that I look for press and often the most time consuming part is finding and researching relevant journalists. I was extremely please with how easy Press Hunt is to use. I had a whole list of journalists that I wanted to contact but was unable to find accurate emails and Press Hunt did the job. I would highly recommend it."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Lukas }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Lukas Bentel, Founder of Hello Velocity</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "Press Hunt has a seemingly endless database and tons of little details on each journalist - feels like clearbit for PR. Rounded off with a simple UI, this product is brilliant for anyone trying to get their name out there to relevant journalists."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Nile }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name"> Nile Frater, Founder of ConcreteCapital</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "I've seen Matt and his team get millions of impressions and coverage for clients. I highly recommend their services."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Chad }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Chad Grills, Founder & CEO of The Mission</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "They did an excellent job increasing traffic to our website. We saw a major lift in both SEO and ASO after working with them."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Will }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Will Jamieson, Founder of Yik Yak & The Q</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "We’ve been using this to reach out to journalists about our hackathons and startup competition at NYU. Much better than researching reporters on Google and finding their emails manually."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Tim }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Tim Nugmanov, President of NYU Innovention Society</p>
                </div>
              </div>
              </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
            </div>

          </div>
        </section>


        <section className="section" id="FAQ">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">FAQ</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">If our FAQ doesn't answer your questions, please reach out to us in our live chat in the bottom right.</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Where do the requests come from?
                </div>
                <p className="is-size-6">We aggregate requests we get directly from journalists & reporters themselves, and from other sources like niche industry newsletters, blogs, Facebook groups, Twitter, Reddit, etc.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What is a PR request?
                </div>
                <p className="is-size-6">When journalists write new articles, they like to quote experts on the subject to back up their case and provide more information to their readers. Journalists submit PR requests (also known as “source requests”) like “Looking for an expert in cybersecurity for an article about privacy issues on Facebook.”</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Can I respond to every request?
                </div>
                <p className="is-size-6">Please only respond to requests that are relevant to you. If you don’t fit what they’re looking for, they’re not going to cover you––so it’s best to not reach out unless you really feel like you’re what they’re looking for.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  How are Press Hunt's PR requests different than HARO?
                </div>
                <p className="is-size-6">HARO’s requests are anonymized––they don’t give you the journalist’s direct contact info. They also don’t aggregate requests, so they have much less with less breadth. HARO charges you to search through requests, while we offer it for free (we only charge for access to contact info). HARO is also traditionally for coverage in only articles & blog posts, while we’ve optimized for every medium from articles to TV to podcasts.</p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Requests;
