// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const EditorComponent = ({ onChange }) => (
  <Editor
    onEditorStateChange={(e) => {
      const editorSourceHTML = draftToHtml(convertToRaw(e.getCurrentContent()));

      return onChange(editorSourceHTML);
    }}
    wrapperClassName="leading-none"
    editorClassName="bg-white shadow leading-none py-3 px-4 h-64 max-h-64"
    placeholder="Hi {first_name}"
  />
);

export default EditorComponent;
