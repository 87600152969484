import React from 'react';
import { NavLink } from 'react-router-dom';

import Placeholder from '../../assets/placeholder.png';
import TwitterBadge from '../../assets/Twitter_Verified_Badge.svg';

export default function InfluencerAllData({ hit, plan }) {

  return (
	    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
	      <div className="flex-1 flex flex-col p-8">
	        <img className="w-24 h-24 flex-shrink-0 mx-auto bg-black rounded-full" src={hit.avatar ? hit.avatar.replace("_normal","") : Placeholder } alt="" />
	        <div className="flex mx-auto">
		        <h3 className="mt-3 text-gray-900 text-lg leading-5 font-large text-center">{hit.name}</h3>
		        <img src={TwitterBadge} alt="verified" className="image twitter-verified-badge" />
		      </div>
	        <div className="mt-1 flex-grow flex flex-col justify-between text-center">
	          <div className="text-gray-500 text-md leading-5 text-center">
              <p>
                {hit.outlet}
              </p>
              {(hit.journalist_url) ? (
                <a href={hit.journalist_url} target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">
                  <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
										<svg className="h-4 w-4 text-gray-800" viewBox="0 0 20 20" fill="currentColor">
										  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
										  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
										</svg>                    
                    <p>{hit.email_domain}</p>
                  </div>
                </a>
              ) : <span /> }
				      {(hit.twitter_location) ? (
				      	<div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
					      	<svg className="mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
									  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
									</svg>
					      	<span className="text-md leading-5 font-medium text-gray-900">{hit.twitter_location}</span> 
					      </div>
				      ) : <span /> }
	          </div>
	        </div>

	      </div>

	      {hit.twitter_bio ? (
	      	<div className="py-5 lg:px-40 sm:px-6">
		      	<p className="text-md leading-5 font-medium text-gray-900">{hit.twitter_bio}</p> 
		      </div>
	      ) : <span /> }

	      {hit.linkedin_url || hit.twitter_name || hit.facebook_url ? (
	      <div className="px-4 py-5 lg:px-40 sm:px-6">
	        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 text-center">
	        {hit.linkedin_url ? (
	          <div className="sm:col-span-1">
              <a href={hit.linkedin_url} target="_blank" rel="noopener noreferrer" className="inline-flex text-md leading-5 font-medium text-indigo-600">
                LinkedIn
                <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
	          </div>
	        ) : <span /> }
	        {hit.twitter_name ? (
	          <div className="sm:col-span-1">
              <a href={"https://twitter.com/" + hit.twitter_name}  className="inline-flex text-md leading-5 font-medium text-indigo-600" target="_blank" rel="noopener noreferrer">
                Twitter
                <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
	          </div>
	        ) : <span /> }
	        {hit.facebook_url ? (
	          <div className="sm:col-span-1">
              <a href={hit.facebook_url} className="inline-flex text-md leading-5 font-medium text-indigo-600" target="_blank" rel="noopener noreferrer">
                Facebook
                <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
	          </div>
	        ) : <span /> }
	        </div>
	      </div>
	      ) : <span /> }

  			{hit.email || hit.secondary_email || hit.phone ? (
	      <div className="py-5 lg:px-20 sm:px-6">
	        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 text-left">
	        	{hit.email ? (
	          <div className="sm:col-span-1">
	            <dt className="text-md leading-5 font-medium text-gray-600">
	              Email address
	            </dt>
	              <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
	                {hit.email}
	              </div>                          
	          </div>
	          ) : <span /> }
	          {hit.secondary_email ? (
	          	<div className="sm:col-span-1">
		            <dt className="text-md leading-5 font-medium text-gray-600">
		              Secondary Email
		            </dt>

		              <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
		               	{hit.secondary_email}
		              </div>

		          </div>
		        ) : <span /> }
		        {hit.phone ? (
	          <div className="sm:col-span-1">
	            <dt className="text-md leading-5 font-medium text-gray-600">
	              Phone number
	            </dt>
	            	{ (plan === 2 || plan === 20) ? (
		            	<NavLink className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800" to="/pricing">Upgrade to access</NavLink>
		            ) : (
		              <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
		                {hit.phone}
		              </div>
		            ) }
	          </div>
	          ) : <span /> }
	        </dl>
	      </div>
	      ) : <span /> }

        <div className="py-5 lg:px-20 sm:px-6 flex">
            <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
              <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
							  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
							</svg>
              <span>{hit.press_hunt_score}</span>
            </div>
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
            <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
						  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
						</svg>
            <span>{hit.followers_count} followers</span>
          </div>
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
            <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
						  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
						</svg>
            <span>{hit.followers_percentile_text ? hit.followers_percentile_text.replace("lower","less followers").replace("higher","more followers") : ""}</span>
          </div>
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
	          <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
						  <path fillRule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clipRule="evenodd" />
						</svg>
            <span>{ hit.statuses_count ? hit.statuses_count.replace("lower","less active").replace("higher","more active") : ""}</span>
          </div>
        </div>

	    </div>
	);
}
