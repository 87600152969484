import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Doughnut } from 'react-chartjs-2';
import Firebase from 'firebase/app';
import { subMenu } from "../path";
import SubMenuSideBar from "../subMenuSideBar";
import MailBreadcrumb from "../breadcrumb";
import MailPagination from '../pagination';
import { checkConnectMail, getCampaigns, getReAuthMessage } from "../../../api/mail";
import UnConnectContainer from "./unConnectContainer";
import Loading from '../../misc/Loading';
import NoAuth from '../../misc/noAuth';
import { auth } from '../../../api/firebase';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import LoadingCircle from '../../misc/LoadingCircle';
import ConnectMailFailedModal from '../connectMailFailedModal';

const MailAnalytics = ({ history, subscription, plan }) => {
  const doughnutDefaultData = {
    backgroundColor: [
      'rgba(225, 85, 84, 0.8)',
      'rgba(77, 157, 224, 0.8)',
    ],
    borderWidth: 0
  }

  const options = {
      legend: {
          display: false,
      },
      plugins: {
        legend: {
            display: false,
        },
      }
  };

  const { pathname } = useLocation();
  const [isSending, setIsSending] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCircle, setIsLoadingCircle] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsCount, setCampaignsCount] = useState(0);
  const [pagination, setPagination] = useState({
    count: 0, limit: 0, offset: 0,
  });
  const [connectMailFailed, setConnectMailFailed] = useState(false);
  const [connectMailFailedLabel, setConnectMailFailedLabel] = useState(false);
  const [connectMailFailedButtonLink, setConnectMailFailedButtonLink] = useState('/dashboard');
  const [connectFailedMessage, setConnectMailFailedMessage] = useState('');

  const onGetCampaigns = ({ offset = 0, limit = 10 }) => {
    setIsLoadingCircle(true);
    getCampaigns({ offset, limit }).then((res) => {
      const {
        campaigns, count,
      } = res.data;
      prepareChartData(campaigns);
      setCampaignsCount(count);
      setPagination({
        count, limit, offset,
      });
      setIsLoadingCircle(false);
    });
  };

  useEffect(() => {
    getSubscribeStatus();
    checkConnectMail()
      .then((res) => {
        const emails = res.data.emails;
        for (const email of emails) {
          if (email.status && email.info.state === 3) {
              setConnectMailFailedMessage(getReAuthMessage());
          } else if (email.status) {
            setIsConnected(email.status);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const offset = urlParams.get('offset') ?? pagination.offset;
            onGetCampaigns({ offset: parseInt(offset) });
          }
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (err.response.status == 401) {
          setConnectMailFailed(true);
          setConnectMailFailedButtonLink('/account')
          setConnectMailFailedLabel("Oops! Access token expired. Please try to log out and log back in again.");
        } else {
          setConnectMailFailed(true);
          setConnectMailFailedLabel("Oops! " + err.message + " Please contact support.");
        }
      });
  }, []);

  const getSubscribeStatus = async() => {
    const db = Firebase.firestore();
    db.collection('subscriptions').where('user', '==', auth.currentUser.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const subscriptions = [];
          snapshot.forEach((doc) => subscriptions.push({ ...doc.data(), id: doc.id }));
          setSubscriptions(subscriptions[0]);
        }
    })
  }

  const prepareChartData = (campaigns) => {
    let tempCampaigns = campaigns;

    tempCampaigns = tempCampaigns.map((tempCampaign) => {
      tempCampaign.deliveredRate = parseInt(tempCampaign.delivered) / parseInt(tempCampaign.total);
      tempCampaign.openedRate = parseInt(tempCampaign.opened) / parseInt(tempCampaign.delivered);
      tempCampaign.clickedRate = parseInt(tempCampaign.link_clicked) / parseInt(tempCampaign.delivered);
      tempCampaign.deliveredRate = isNaN(tempCampaign.deliveredRate) ? 0 : tempCampaign.deliveredRate;
      tempCampaign.openedRate = isNaN(tempCampaign.openedRate) ? 0 : tempCampaign.openedRate;
      tempCampaign.clickedRate = isNaN(tempCampaign.clickedRate) ? 0 : tempCampaign.clickedRate;
      tempCampaign.deliveredChartData = {
        labels: ['not delivered', 'delivered'],
        datasets: [
          {
            ...doughnutDefaultData,
            data: [(parseInt(tempCampaign.total) - parseInt(tempCampaign.delivered)), tempCampaign.delivered],
          },
        ],
      };
      tempCampaign.openedChartData = {
        labels: ['not opened', 'opened'],
        datasets: [
          {
            ...doughnutDefaultData,
            data: [(parseInt(tempCampaign.delivered) - parseInt(tempCampaign.opened)), tempCampaign.opened],
          },
        ],
      };
      tempCampaign.clickedChartData = {
        labels: ['not clicked', 'clicked'],
        datasets: [
          {
            ...doughnutDefaultData,
            data: [(parseInt(tempCampaign.delivered) - parseInt(tempCampaign.link_clicked)), tempCampaign.link_clicked],
          },
        ],
      };
      return {
        ...tempCampaign
      }
    });

    setCampaigns(tempCampaigns);
  }

  if (subscriptions.length == 0) {
    return (
      <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
        <div className="min-h-0 flex-1 flex overflow-hidden">
          <SubMenuSideBar pathname={pathname} path={subMenu.mail} isConnected={isConnected} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <NoAuth />
          </main>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loading />
        <ConnectMailFailedModal
          modalOpen={connectMailFailed}
          setModalOpen={setConnectMailFailed}
          buttonLink={connectMailFailedButtonLink}
          label={connectMailFailedLabel}
        />
      </div>
    )
  } else if (isLoadingCircle) {
    return <LoadingCircle isLoading={isLoadingCircle} />;
  }

  if (!isConnected) {
    return (
      <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
        <div className="min-h-0 flex-1 flex overflow-hidden">
          <SubMenuSideBar pathname={pathname} path={subMenu.mail} isConnected={isConnected} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <MailBreadcrumb>
              <MailBreadcrumb.Item>
                <>
                  <Link to="/mail/inbox" className="text-gray-400 hover:text-gray-500">
                    <svg
                      className="flex-shrink-0 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                  </Link>
                </>
              </MailBreadcrumb.Item>
              <MailBreadcrumb.Item>
                <>
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span
                    to="/mail/new"
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current="page"
                  >
                    Inbox
                  </span>
                </>
              </MailBreadcrumb.Item>
            </MailBreadcrumb>
            <UnConnectContainer flashAlert={connectFailedMessage} />
          </main>
        </div>
      </div>
    )
  }
  return (
    <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
      <div className="min-h-0 flex-1 flex overflow-hidden">
        <LoadingCircle
          isSending={isSending}
          setIsSending={setIsSending}
        />

        <SubMenuSideBar pathname={pathname} path={subMenu.mail} />

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none pb-10">
          <MailBreadcrumb>
            <MailBreadcrumb.Item>
              <>
                <Link to="/mail/inbox" className="text-gray-400 hover:text-gray-500">
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </Link>
              </>
            </MailBreadcrumb.Item>
            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  to="/mail/analytics"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Analytics
                </span>
              </>
            </MailBreadcrumb.Item>
          </MailBreadcrumb>

          <div className="flex-1 flex sm:justify-end">
            <MailPagination
              offset={pagination.offset}
              limit={pagination.limit}
              count={pagination.count}
              navClass="py-3 flex items-center justify-between border-gray-200 sm:px-5"
              onPrevious={() => {
                if (pagination.offset <= 0) {
                  pagination.offset = 0;
                  return null;
                }

                return onGetCampaigns({ limit: pagination.limit, offset: pagination.offset - pagination.limit });
              }}
              onNext={() => {
                if (pagination.offset + pagination.limit >= pagination.count) return null;

                return onGetCampaigns({ limit: pagination.limit, offset: pagination.offset + pagination.limit });
              }}
              style={{ display: (isConnected && campaignsCount > 0) ? '' : 'none' }}
            />
          </div>

          <div className="max-w-100 mx-auto grid grid-cols-1 sm:px-5 lg:grid-flow-col-dense">
            <div>
              {campaigns.map((campaign, index) => (
                <div key={index} className="grid bg-white shadow-sm rounded-sm mb-10">
                  <div className="flex justify-between px-4 py-4 sm:px-6">
                    <h3 className="px-4 text-lg leading-6 font-bold text-gray-900">
                      {campaign.subject}
                    </h3>
                    <p className="px-4 text-sm text-gray-600">
                      <Moment
                        date={campaign.sent_at}
                        format="MMMM Do YYYY, h:mma"
                      />
                    </p>
                  </div>
                  <div className="grid grid-cols-3 px-4 row-span-2">
                    <div className="px-4 py-5 sm:p-6">
                      <div className="grid md:grid-cols-2 md:divide-y-0">
                        <div className="grid grid-rows-2 px-0 py-0 sm:p-6 flex flex-col justify-between">
                          <div>
                            <dt className="text-sm font-normal text-gray-900">Total Processed</dt>
                            <dd className="flex justify-between items-baseline md:block lg:flex">
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={campaign.total}
                                />
                              </div>
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-normal text-gray-900">Delivered</dt>
                            <dd className="flex justify-between items-baseline md:block lg:flex">
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={campaign.delivered}
                                />
                              </div>
                            </dd>
                          </div>
                          <div>
                            <div className="text-base">
                              <dt className="text-sm font-normal text-gray-900">Delivered Rate: </dt>
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  suffix={'%'}
                                  decimalScale={2}
                                  value={campaign.deliveredRate * 100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-0 py-0 sm:p-6 w-4/5">
                          <Doughnut options={options} data={campaign.deliveredChartData} />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <div className="grid md:grid-cols-2 md:divide-y-0">
                        <div className="grid grid-rows-2 px-0 py-0 sm:p-6 flex flex-col justify-between">
                          <div>
                            <dt className="text-sm font-normal text-gray-900">Opened</dt>
                            <dd className="flex justify-between items-baseline md:block lg:flex">
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={campaign.opened}
                                />
                              </div>
                            </dd>
                          </div>
                          <div>
                            <div className="text-base">
                              <dt className="text-sm font-normal text-gray-900">Opened Rate: </dt>
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  suffix={'%'}
                                  decimalScale={2}
                                  value={campaign.openedRate * 100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-0 py-0 sm:p-6 w-4/5">
                          <Doughnut options={options} data={campaign.openedChartData} />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <div className="grid md:grid-cols-2 md:divide-y-0">
                        <div className="grid grid-rows-2 px-0 py-0 sm:p-6 flex flex-col justify-between">
                          <div>
                            <dt className="text-sm font-normal text-gray-900">Clicked</dt>
                            <dd className="flex justify-between items-baseline md:block lg:flex">
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  value={campaign.link_clicked}
                                />
                              </div>
                            </dd>
                          </div>
                          <div>
                            <div className="text-base">
                              <dt className="text-sm font-normal text-gray-900">Clicked Rate: </dt>
                              <div className="text-xl flex items-baseline text-base font-semibold text-blue-1000">
                                <NumberFormat
                                  displayType="text"
                                  suffix={'%'}
                                  decimalScale={2}
                                  value={campaign.clickedRate * 100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-0 py-0 sm:p-6 w-4/5">
                          <Doughnut options={options} data={campaign.clickedChartData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MailAnalytics;
