import React from 'react';
import { FirestoreDocument } from 'react-firestore';
import Moment from 'react-moment';

import { checkDomainStats } from '../../actions/sequence/checkStats';

import Error from '../misc/Error';
import Loading from '../misc/Loading';
import EmailDeliveredIcon from '../../assets/email-delivered.svg';
import EmailOpenedIcon from '../../assets/email-opened.svg';
import MailIcon from '../../assets/mail.svg';

class domainStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }
  handleClick = (event, data) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    checkDomainStats(data)
    .then( () => {
      this.setState({ isLoading:false });
    })
  }

  render() {
    return (
      <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
        <FirestoreDocument
          path={`domainStats/${this.props.match.params.slug}`}
        >
          {({ error, isLoading, data }) => {
            if (error) {
              return <Error error={error} />
            }
            if (isLoading) {
              return <Loading />
            }
            if (!isLoading && data.length === 0) {
              return <Error />
            }
            return (
              <section>
                <div className="heaven-top-buttons-div">
                  <div className="heaven-refresh-button-w-text">
                    {this.state.isLoading ? <p className="refresh-stats-text">Loading...</p> : (
                      <div>
                        {data.updatedOn ? <p className="refresh-stats-text">Last updated <Moment fromNow ago>{data.updatedOn.toDate()}</Moment> ago</p> : <span /> }
                      </div>
                    )}
                    <button className="heaven-page-cta-button" onClick={ event => this.handleClick(event, data) } style={{ cursor:'pointer' }}>
                      <i className="fas fa-sync-alt heaven-nav-button-icon"></i>
                      Refresh stats
                    </button>
                  </div>
                </div>
                {data.stats ? (
                data.stats.map((entry, index) =>
                <div key={index} className="is-sequence-card" style={{clear:'both'}}>
                  <div className="sequence-card-header">
                    <div className="sequence-card-header-icon-and-title">
                      <img alt="" src={MailIcon} className="sequence-card-icon-header"></img>
                      <p className="sequence-card-header-title">{entry.tag}</p>
                    </div>
                  </div>
                  {entry.stats.map(( month, index ) =>
                  <div key={index} className="stats-card-content">
                    <div className="sequence-card-line">
                      <p className="sequence-card-content-text"><span className="is-strong"><Moment add={{ day: 1 }} format="MMMM">{month.time}</Moment></span></p>
                    </div>
                    <div className="sequence-card-line">
                      <img alt="" src={EmailDeliveredIcon} className="sequence-card-content-icon" />
                      <p className="sequence-card-content-text">Delivered: <span className="is-strong">{month.delivered.total}</span></p>
                    </div>
                    {month.delivered.total > 0 ? (
                      <div className="sequence-card-line">
                        <img alt="" src={EmailOpenedIcon} className="sequence-card-content-icon" />
                        <p className="sequence-card-content-text">Opened: <span className="is-strong">{month.opened.unique}</span> {(month.opened.unique / month.delivered.total * 100).toFixed(2)}%</p>
                      </div>
                    ) : <span /> }
                  </div>
                  )}
                </div>
                )) : <span /> }
              </section>
            )
          }}
        </FirestoreDocument>
      </div>
    )
  }
}

export default domainStats;
