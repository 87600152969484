import React, { useState, useEffect } from 'react';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';
import { auth } from '../../api/firebase';

const activeClass = 'text-gray-900 hover:bg-gray-100 bg-blue-100 bg-opacity-75 hover:bg-blue-100 hover:text-blue-1000 flex-shrink-0 inline-flex items-center justify-center rounded-lg text-blue-1000 transition duration-150 ease-in-out text-opacity-100';
const originClass = 'text-gray-900 hover:bg-gray-100 flex-shrink-0 inline-flex items-center justify-center rounded-lg transition duration-150 ease-in-out text-opacity-100';

const SubMenuSideBar = ({ pathname, path = [], isConnected = true }) => {
  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscribeStatus = async() => {
    const db = Firebase.firestore();
    db.collection('subscriptions').where('user', '==', auth.currentUser.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const subscriptions = [];
          snapshot.forEach((doc) => subscriptions.push({ ...doc.data(), id: doc.id }));
          setSubscriptions(subscriptions[0]);
        }
    })
  }

  useEffect(() => {
    getSubscribeStatus();
  }, []);

  return (
    <nav
      aria-label="Sidebar"
      className="hidden md:block md:flex-shrink-0 md:bg-white md:overflow-y-auto border-r "
    >
      <div className="relative w-20 flex flex-col p-3 space-y-3 {{subscriptions.length}}">
        {path.map((item) => {
          const linkClassName = String(pathname).match(item.name)
            ? activeClass
            : originClass;

          const itemShow = !(item.connectRequired && !isConnected);

          return (
            <NavLink
              key={item.name}
              to={ subscriptions.length == 0 && item.path == '/mail/new' ? "/pricing" : item.path }
              style={{ width: '3.5rem', height: '3.5rem', display: itemShow ? "" : "none" }}
              className={linkClassName}
            >
              <span className="sr-only">{item.name}</span>

              { subscriptions.length == 0 && item.path == '/mail/new' ? item.unSubscriptionIcon : item.icon }
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
};

export default SubMenuSideBar;
