import React from 'react';
import { NavLink } from 'react-router-dom';
import { mailPath } from './path';

const activeClass = 'bg-gray-900 text-white flex-shrink-0 inline-flex items-center justify-center rounded-lg';
const originClass = 'text-gray-400 hover:bg-gray-700 flex-shrink-0 inline-flex items-center justify-center rounded-lg';

const MenuSideBar = ({ pathname }) => (
  <nav
    aria-label="Sidebar"
    className="hidden md:block md:flex-shrink-0 md:bg-gray-800 md:overflow-y-auto"
  >
    <div className="relative w-20 flex flex-col p-3 space-y-3">
      {mailPath.map((item) => {
        const splitPath = String(pathname).split('/');
        const isActive = splitPath[2] === item.name;

        const linkClassName = isActive ? activeClass : originClass;

        return (
          <NavLink
            key={item.name}
            to={item.path}
            style={{ width: '3.5rem', height: '3.5rem' }}
            className={linkClassName}
          >
            <span className="sr-only">Open</span>

            {item.icon}
          </NavLink>
        );
      })}
    </div>
  </nav>
);

export default MenuSideBar;
