import React from 'react';
import createAlert from '../../actions/alert/createAlert';
import AlertForm from './alertForm';
import { Link } from 'react-router-dom';

import Loading from '../misc/Loading';

const AlertNew = ({ history, subscription, status, plan }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
  {(!subscription || status !== "active" || plan === 1 || plan === 10) ? (
    <div className="loading has-text-centered is-centered">
      <p>You must be upgrade to create alerts</p>
      <Link to="/pricing">Get Started</Link>
    </div> ) : ( 
    <AlertForm
      onSubmit={values => createAlert(values).then(alert => history.push("/alert-dashboard"))}
    /> ) }
  </div>
)

export default AlertNew;
