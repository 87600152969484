import React from 'react';
import {
  FormRow,
  FormLabel,
  TextInput,
  TextArea,
} from '../../styles/forms';

class ListForm extends React.Component {

  onSubmit = event => {
    event.preventDefault()
    const {name, description} = event.target.elements
    const values = {
      name: name.value,
      description: description.value,
    }
    this.props.onSubmit(values)
  }
  render() {
    return (
      <div className="is-account">
        <form onSubmit={this.onSubmit}>
          <FormRow>
            <div className="form-container">
              <FormLabel htmlFor="name" className="form-title">Title</FormLabel>
              <TextInput type="text" name="name" className="form-input" defaultValue={this.props.list ? this.props.list.name : ''} required />
            </div>
          </FormRow>
          <FormRow>
            <div className="form-container">
              <FormLabel htmlFor="description" className="form-title">Description</FormLabel>
              <TextArea type="text" name="description" className="form-input" defaultValue={this.props.list ? this.props.list.description : ''}  />
            </div>
          </FormRow>
          <button className="heaven-card-cta-button"><i className="fas fa-plus heaven-nav-button-icon" />Save list</button>
        </form>
      </div>
    )
  }
}

export default ListForm;
