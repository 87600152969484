import React, { useState, createContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import MailInbox from './Mail/Inbox';
import MailOutbox from './Mail/Outbox';
import MailScheduled from './Mail/Scheduled';
import MailScheduledInfo from './Mail/scheduledInfo';
import MailInfo from './Mail/info';
import MailNew from './Mail/new';
import MailAnalytics from './Mail/analytics';
import MailCallBack from './callback';
import ContactsPersons from './Contacts/persons';
import SettingsSyncGmail from './Settings/sync/gmail';
import { NavRoute } from '../Routes';

export const MailContext = createContext([]);

const Mail = ({ ...rest }) => {
  const [mailDatas, setMailDatas] = useState([]);

  return (
    <MailContext.Provider value={[mailDatas, setMailDatas]}>
      <Route exact path="/mail/inbox" component={MailInbox} />
      <Route exact path="/mail/inbox/:id" component={MailInfo} />
      <Route exact path="/mail/new">
        <MailNew {...rest} />
      </Route>
      <Route exact path="/mail/callback" component={MailCallBack} />

      <Route exact path="/mail/outbox" component={MailOutbox} />
      <Route exact path="/mail/outbox/:id" component={MailInfo} />

      <Route exact path="/mail/scheduled" component={MailScheduled} />
      <Route exact path="/mail/scheduled/:id" component={MailScheduledInfo} />

      <Route exact path="/mail/analytics" component={MailAnalytics} />

      <Route exact path="/mail/contacts/persons" component={ContactsPersons} />
      <Route exact path="/mail/settings/sync/gmail" component={SettingsSyncGmail} />
      <Route exact path="/mail/settings/sync/outlook" component={SettingsSyncGmail} />
    </MailContext.Provider>
  );
};

export default Mail;
