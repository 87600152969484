import React, { Component } from 'react';
import { Route, Link, NavLink } from 'react-router-dom';
import StripeBillingPortal from '../subscriptions/StripeBillingPortal';
import logOut from '../../actions/logOut';
import FirebaseUser from '../misc/FirebaseUser';
import SubscriptionPlan from '../misc/SubscriptionPlan';
import deleteSubscription from '../../actions/deleteSubscription';
import PasswordChangeForm from './PasswordChange';

import NoAuth from '../misc/noAuth';
import Error from '../misc/Error';

const Account = ({
  history, auth, subscription, status,
}) => (
  <div className="border-b border-gray-200 py-5 mt-10 px-4 mb-20 mx-auto max-w-5xl">
    {!auth ? (
      <NoAuth />
    ) : !subscription ? (
      <div className="is-account">
        {(auth.displayName) ? (
          <div>
            <h1 className="title">{auth.displayName}</h1>
            <h2 className="subtitle">{auth.email}</h2>
          </div>
        ) : <h2 className="subtitle">{auth.email}</h2> }
        <NavLink
          to="/pricing"
          className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75"
        >
          Subscribe now
        </NavLink>
        <button
          className="mt-2 block w-full px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => logOut().then(() => history.push('/'))}
        >
          Log out
        </button>
      </div>
    ) : (subscription && status === 'active') ? (
      <div>
        <div className="is-account">
          {/* <img src={auth.photoURL} alt={auth.displayName} width="100" height="100" style={{borderRadius:'.4em',marginBottom:'1em'}} /> */}
          {(auth.displayName) ? (
            <div>
              <h1 className="title">{auth.displayName}</h1>
              <h2 className="subtitle">{auth.email}</h2>
            </div>
          ) : <h2 className="subtitle">{auth.email}</h2> }
          <div>
            <SubscriptionPlan />
            <p>Upgrade or downgrade your plan, view your invoices, and update your payment method below.</p>
            <FirebaseUser>
              { ({
                isLoading, error, auth, stripeCustomerId, email,
              }) => {
                if (isLoading) return <button className="is-pricing-button">Get press today!</button>;
                if (error) return <Error error={error} />;
                if (stripeCustomerId) return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />;
              }}
            </FirebaseUser>
          </div>
          <button
            className="mt-2 block w-full px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => logOut().then(() => history.push('/'))}
          >
            Log out
          </button>
        </div>
        <div className="my-10">
          <PasswordChangeForm />
        </div>
      </div>
    ) : (
      <div className="is-account">
        {(auth.displayName) ? (
          <div>
            <h1 className="title">{auth.displayName}</h1>
            <h2 className="subtitle">{auth.email}</h2>
          </div>
        ) : <h2 className="subtitle">{auth.email}</h2> }
        <NavLink
          to="/pricing"
          className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75"
        >
          Subscribe now
        </NavLink>
        <button
          className="mt-2 block w-full px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => logOut().then(() => history.push('/'))}
        >
          Log out
        </button>
      </div>
    )}
  </div>
);

export default Account;
