import React from 'react'
import { NavLink } from 'react-router-dom';

import '../../styles/tailwind.output.css';

const noAuth = () => (
  <section>
    <div className="container mx-auto mb-20">
      <div className="rounded-md bg-orange-100 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </div>
          <div>
            <h3 className="text-md leading-5 font-medium text-yellow-800 text-left ml-2">
              Subscribe now to access this feature
            </h3>
            <div className="mt-2 text-sm leading-5 text-yellow-700 ml-2">
              Access the most comprehensive journalist database available today.
              <NavLink to="/pricing" className="ml-1 font-medium underline text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150">
                See Pricing.
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default noAuth;
