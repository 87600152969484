import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForCreate } from '../helpers/firestoreHelpers';

const createAlert = values => {
	values.slug = slugify(values.alertName, {lower: true})
  return Firebase.firestore()
    .collection(`alerts`)
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch( error => {
      alert(`Sorry, couldn't create the alert: ${error.message}`)
    })
}

export default createAlert;
