import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';
import Error from '../misc/Error';
import Sequences from './sequences';

import NoAuth from '../misc/noAuth';
import Loading from '../misc/Loading';

import MailIcon from '../../assets/mail.svg';
import TrashIcon from '../../assets/trash-2.svg';
import EditIcon from '../../assets/edit (1).svg';
import MonitorIcon from '../../assets/sequence-monitoring.svg';
import KeywordIcon from '../../assets/sequence-keyword.svg';
import TagsIcon from '../../assets/sequence-tags.svg';
import SentimentIcon from '../../assets/sequence-sentiment.svg';

const Sequence = ({ auth, subscription, status, plan }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
  {!auth ? (
    <NoAuth />
  ) : (!subscription || status !== "active") ? (
    <section>
      <div className="columns">
        <div className="column is-one-thirds">
          <div className="is-dash-left-sequence-card-top">
            <p className="is-dash-left-sequence-card-title">Automatically email journalists when they mention keywords in their tweets & articles</p>
            <p className="is-8 is-action-card-para">With sequences you can send personalized emails to journalists when they write or tweet about keywords. Emails send from your email addresses (that you’ve authenticated here), and can be personalized with the journalist’s name.</p>
            <p className="is-8 is-action-card-para">You can filter your sequences to trigger based on a handful of factors like the sentiment of the content, the location of the journalist, and what topics & beats the journalist usually covers.</p>
            <p className="is-8 is-action-card-para">For example––if you work at Tesla––you can automatically send emails to first tier journalists based in Hong Kong who write about “technology” when they mention “Tesla Model 3” with negative sentiment.</p>
            <p className="is-action-card-para">Sequences can be used for everything from building relationships with journalists to crisis management.</p>
          </div>
          <div className="is-dash-left-sequence-card-bottom">
            <p className="is-action-card-para"><NavLink to="/pricing" className="is-link">Upgrade today</NavLink> to create your own sequences</p>
          </div>
        </div>
        <div className="column is-two-thirds">
          <div className="heaven-top-buttons-div">
            <NavLink className="heaven-card-page-white-button heaven-margin-right" to="/domain">
              <i className="fas fa-link heaven-nav-button-icon"></i>Domains
            </NavLink>
            <NavLink className="heaven-card-page-white-button" to="/pricing">
              <i className="fas fa-chart-line heaven-nav-button-icon" />Analytics
            </NavLink>
            <NavLink className="heaven-page-cta-button" to="/sequence/new">
              <i className="fas fa-plus heaven-nav-button-icon"></i>New sequence<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ color:'white',opacity:'.5' }} />
            </NavLink>
          </div>
          <div className="sequence-card-list">
            <div className="is-sequence-card-empty">
              <div className="sequence-card-header">
                <div className="sequence-card-header-icon-and-title">
                  <img src={MailIcon} className="sequence-card-icon-header" style={{opacity:'.5'}}></img>
                  <p className="sequence-card-header-title" style={{opacity:'.5'}}>Example sequence for Tesla</p>
                </div>
              </div>
              <div className="sequence-card-content" style={{opacity:'.5'}}>
                <p className="sequence-card-line"><img src={MonitorIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Monitoring <span className="is-strong">tweets, articles</span></span></p>
                <p className="sequence-card-line"><img src={KeywordIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Triggers on keywords: <span className="is-strong">Tesla</span></span></p>
                <p className="sequence-card-line"><img src={TagsIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Only journalists who cover <span className="is-strong">technology, business</span> at <span className="is-strong">first</span> tier outlets</span></p>
                <p className="sequence-card-line"><img src={SentimentIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Only in response to <span className="is-strong">positive</span> sentiment</span></p>
              </div>
              <div className="sequence-card-email">
                <div className="sequence-card-email-subject">
                  <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>SUBJECT</p>
                  <p className="sequence-card-email-subject-text" style={{opacity:'.5'}}>Thanks for mentioning us! Here's our press kit…</p>
                </div>
                <div className="sequence-card-email-body">
                  <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>BODY</p>
                  <p className="sequence-card-email-text" style={{opacity:'.5'}}>Hi **journalist_name**! You can find our press kit here…</p>
                </div>
              </div>
              <div className="sequence-card-footer">
                <div className="sequence-card-footer-button">
                  <input
                    type="checkbox"
                    name="active"
                    className="switch is-rounded is-info"
                  />
                  <label className="sequence-card-footer-button-activate" style={{opacity:'.5', cursor:'default'}}><span className="sequence-card-footer-button-activate-text">Activate</span></label>
                </div>
                <div className="sequence-card-footer-button">
                  <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img src={EditIcon} className="sequence-card-icon-standard"></img><span className="sequence-card-footer-button-standard-text">Edit</span></div>
                </div>
                <div className="sequence-card-footer-button">
                  <div className="sequence-card-footer-button-archive" style={{opacity:'.5', cursor:'default'}}><img src={TrashIcon} className="sequence-card-icon-archive"></img><span className="sequence-card-footer-button-archive-text">Archive</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </section>
  ) : (
    <section>
      <div className="columns">
        <div className="column is-one-thirds">
          <div className="is-dash-left-sequence-card-top">
            <p className="is-dash-left-sequence-card-title">Automatically email journalists when they mention keywords in their tweets & articles</p>
            <p className="is-8 is-action-card-para">With sequences you can send personalized emails to journalists when they write or tweet about keywords. Emails send from your email addresses (that you’ve authenticated here), and can be personalized with the journalist’s name.</p>
            <p className="is-8 is-action-card-para">You can filter your sequences to trigger based on a handful of factors like the sentiment of the content, the location of the journalist, and what topics & beats the journalist usually covers.</p>
            <p className="is-8 is-action-card-para">For example––if you work at Tesla––you can automatically send emails to first tier journalists based in Hong Kong who write about “technology” when they mention “Tesla Model 3” with negative sentiment.</p>
            <p className="is-action-card-para">Sequences can be used for everything from building relationships with journalists to crisis management.</p>
          </div>
          <div className="is-dash-left-sequence-card-bottom">
            {(plan === 5 || plan === 50) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Sequences</span> plan with a limit of 10 sequences and a sending limit of 50 emails daily and 500 monthly.<br /><br /><a href="/account" className="is-link">Upgrade here for higher limits</a></p>
            ) : (plan === 6 || plan === 60) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Agency</span> plan with a limit of 100 sequences and a sending limit of 150 emails daily and 1,500 monthly.<br /><br /><a href="/account" className="is-link">Upgrade here for higher limits</a></p>
            ) : (plan === 7 || plan === 70) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Enterprise</span> plan with a limit of 500 sequences and a sending limit of 300 emails daily and 3,000 monthly.</p>
            ) : (plan < 5 || plan === 10 || plan === 20 || plan === 30 || plan === 40) ? (
              <p className="is-action-card-para"><a href="/account" className="is-link">Upgrade today</a> to create your own sequences</p>
              ) : <p className="is-action-card-para"><a href="/pricing" className="is-link">Subscribe today</a> to create your own sequences</p>
            }
          </div>
        </div>
        <div className="column is-two-thirds">
          <div className="heaven-top-buttons-div">
            <NavLink className="heaven-card-page-white-button heaven-margin-right" to="/domain">
              <i className="fas fa-link heaven-nav-button-icon"></i>Domains
            </NavLink>
            <NavLink className="heaven-card-page-white-button" to="/analytics">
              <i className="fas fa-chart-line heaven-nav-button-icon" />Analytics
            </NavLink>
            <NavLink className="heaven-page-cta-button" to={(plan === 5 || plan === 6 || plan === 7 || plan === 50 || plan === 60 || plan === 70) ? "/sequence/new" : "/pricing"}>
              <i className="fas fa-plus heaven-nav-button-icon"></i>New sequence
            </NavLink>
          </div>
          <div style={{ clear:'both' }}>
            <Sequences uid={auth.uid} />
          </div>
        </div>
      </div>
    </section>
  ) }
  </div>
)

export default Sequence;
