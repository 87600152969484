import React from 'react';
import { NavLink } from 'react-router-dom';

import Campaigns from './campaigns';

import NoAuth from '../misc/noAuth';
import Loading from '../misc/Loading';
import Error from '../misc/Error';

import MailIcon from '../../assets/mail.svg';
import TrashIcon from '../../assets/trash-2.svg';
import EditIcon from '../../assets/edit (1).svg';

const Campaign = ({ auth, subscription, status }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
  {!auth ? (
    <NoAuth />
  ) : (!subscription || status!== "active") ? (
    <section>
      <div className="heaven-top-buttons-div">
        <NavLink className="heaven-card-page-white-button heaven-margin-right" to="/domain">
          <i className="fas fa-link heaven-nav-button-icon" />Domains
        </NavLink>
        <NavLink className="heaven-card-page-white-button" to="/pricing">
          <i className="fas fa-chart-line heaven-nav-button-icon" />Analytics
        </NavLink>
        <NavLink className="heaven-page-cta-button" to="/pricing">
          <i className="fas fa-plus heaven-nav-button-icon" />New email<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ opacity:'.5',color:'white' }} />
        </NavLink>
      </div>
      <div className="is-sequence-card-empty" style={{ clear:'both' }}>
        <div className="sequence-card-header">
          <div className="sequence-card-header-icon-and-title">
            <img alt="" src={MailIcon} className="sequence-card-icon-header" style={{opacity:'.5'}}></img>
            <p className="sequence-card-header-title" style={{opacity:'.5'}}>Example campaign for Tesla</p>
          </div>
        </div>
        <div className="sequence-card-email">
          <div className="sequence-card-email-subject">
            <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>SUBJECT</p>
            <p className="sequence-card-email-subject-text" style={{opacity:'.5'}}>Thanks for mentioning us! Here's our press kit…</p>
          </div>
          <div className="sequence-card-email-body">
            <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>BODY</p>
            <p className="sequence-card-email-text" style={{opacity:'.5'}}>Hi **journalist_name**! You can find our press kit here…</p>
          </div>
        </div>
        <div className="sequence-card-footer">
          <div className="sequence-card-footer-button">
            <input
              type="checkbox"
              name="active"
              className="switch is-rounded is-info"
            />
            <label className="sequence-card-footer-button-activate" style={{opacity:'.5', cursor:'default'}}><span className="sequence-card-footer-button-activate-text">Activate</span></label>
          </div>
          <div className="sequence-card-footer-button">
            <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img alt="" src={EditIcon} className="sequence-card-icon-standard"></img><span className="sequence-card-footer-button-standard-text">Edit</span></div>
          </div>
          <div className="sequence-card-footer-button">
            <div className="sequence-card-footer-button-archive" style={{opacity:'.5', cursor:'default'}}><img alt="" src={TrashIcon} className="sequence-card-icon-archive"></img><span className="sequence-card-footer-button-archive-text">Archive</span></div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section>
      <div className="text-right mb-10">
        <NavLink className="heaven-card-page-white-button heaven-margin-right" to="/domain">
          <i className="fas fa-link heaven-nav-button-icon" />Domains
        </NavLink>
        <NavLink className="heaven-card-page-white-button" to="/analytics">
          <i className="fas fa-chart-line heaven-nav-button-icon" />Analytics
        </NavLink>
        <NavLink className="heaven-page-cta-button" to="/campaign/new">
          <i className="fas fa-plus heaven-nav-button-icon" />New email
        </NavLink>
      </div>
      <div>
        <Campaigns uid={auth.uid} />
      </div>
    </section>
  )}
  </div>
)

export default Campaign;
