import styled from 'styled-components'
import { Link } from 'react-router-dom'

import colors from './colors'

const InternalLink = styled(Link)`

`
const HeaderLink = styled(Link)`
`

export {
  InternalLink,
  HeaderLink,
}
