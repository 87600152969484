import React, { Component } from 'react';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Transition from '../../styles/Transition.js';

import Logo from '../../assets/press-hunt-full-logo-20-dark.svg';

class Dashnav extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      helpDeskOpen: false,
      error: null,
      auth: null,
      subscription: null,
      isLoading: true,
      plan: null,
      status: null,
      listCount: null,
      isOn: false,
      accountDropdownOn: false,
      searchDropdownOn: false,
      createDropdownOn: false,
      path: this.props.path,
      mobileInactiveClass: 'flex pl-2 pr-2 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-100 focus:border-gray-300 transition duration-150 ease-in-out',
      mobileActiveClass: 'flex pl-2 pr-2 py-2 border-l-4 border-blue-500 text-base font-medium text-blue-1000 bg-blue-50 focus:outline-none focus:text-blue-800 focus:bg-blue-100 focus:border-blue-700 transition duration-150 ease-in-out',
      activeClass: 'ml-1 mr-1 bg-blue-100 bg-opacity-75 hover:bg-blue-100 hover:text-blue-1000 px-2 py-2 rounded-lg inline-flex items-center text-base font-bold leading-5 text-blue-1000 transition duration-150 ease-in-out text-opacity-100',
      inactiveClass: 'ml-1 mr-1 inline-flex items-center border-transparent text-base font-bold leading-5 text-gray-900 hover:bg-gray-100 rounded-lg px-2 py-2 transition duration-150 ease-in-out',
    };
    this.setAccountDropdown = this.setAccountDropdown.bind(this);
    this.setCreateDropdown = this.setCreateDropdown.bind(this);
    this.setSearchDropdown = this.setSearchDropdown.bind(this);
    this.setMobile = this.setMobile.bind(this);
    this.getSubscription = this.getSubscription.bind(this);
    this.getListCount = this.getListCount.bind(this);
  }

  setAccountDropdown() {
    this.setState((prevState) => ({
      accountDropdownOn: !prevState.accountDropdownOn,
    }));
  }

  setCreateDropdown() {
    this.setState((prevState) => ({
      createDropdownOn: !prevState.createDropdownOn,
    }));
  }

  setSearchDropdown() {
    this.setState((prevState) => ({
      searchDropdownOn: !prevState.searchDropdownOn,
    }));
  }

  setMobile() {
    this.setState((prevState) => ({
      isOn: !prevState.isOn,
    }));
  }

  componentDidMount() {
    this._isMounted = true;
    this.unsubscribe = Firebase.auth()
      .onAuthStateChanged(this.handleAuth, this.handleError);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  handleAuth = (auth) => {
    this.setState({
      isLoading: false,
      auth,
      error: null,
    });
    if (auth) {
      this.getSubscription();
      this.getListCount();
    }
  }

  handleError = (error) => {
    this.setState({
      isLoading: false,
      auth: null,
      error,
    });
  }

  getSubscription = () => {
    const db = Firebase.firestore();
    db.collection('subscriptions').where('user', '==', this.state.auth.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const subscriptions = [];
          snapshot.forEach((doc) => subscriptions.push({ ...doc.data(), id: doc.id }));
          if (this._isMounted) {
            this.setState({
              subscription: subscriptions[0],
              status: subscriptions[0].stripeSubscriptionStatus,
              plan: subscriptions[0].plan,
              isLoading: false,
            });
          }
        } else {
          console.log('No document found!');
        }
      });
  }

  getListCount = () => {
    const db = Firebase.firestore();
    db.collection('lists').where('createdBy', '==', this.state.auth.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          this.setState({
            listCount: snapshot.size,
          });
        } else {
          this.setState({
            listCount: 0,
          });
        }
      });
  }

  handleCrispHelpdeskClick() {
    this.setState({
      helpDeskOpen: true,
    });
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '99788d98-6b5f-41ad-9705-497d0dbff310';
    window.$crisp.push(['do', 'helpdesk:search']);

    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    }());
  }

  render() {
    const {
      auth, subscription, status, plan, isLoading,
    } = this.state;
    const {
      isOn, accountDropdownOn, searchDropdownOn, createDropdownOn, path, activeClass, inactiveClass, mobileActiveClass, mobileInactiveClass,
    } = this.state;

    return (
      <div>
        <nav className="bg-white shadow-sm border-b border-gray-300">
          <div className="max-w-full mx-auto px-4">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <NavLink to="/dashboard">
                    <img className="lg:block h-8 w-auto" src={Logo} alt="Investor Scout logo" />
                  </NavLink>
                </div>
                <div className="hidden lg:ml-3 items-center lg:flex">
                  <NavLink to="/mail/inbox" path="/mail" activeClassName={activeClass} className={inactiveClass}>
                    <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span>Inbox</span>
                  </NavLink>
                  <OutsideClickHandler
                    onOutsideClick={searchDropdownOn ? () => { this.setSearchDropdown(); } : () => {}}
                  >
                    <div className="relative">
                      <button onClick={() => this.setSearchDropdown()} type="button" className="hover:bg-gray-100 px-2 py-2 rounded-lg flex border-transparent font-bold text-base mr-1 ml-1 leading-5 text-gray-900 hover:text-black focus:outline-none active:text-blue-1000 transition duration-150 ease-in-out">
                        <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
                        </svg>
                        <span>Database</span>
                        <svg className="outline-current w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <Transition
                        show={searchDropdownOn}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-lg shadow-md bg-white ring-1 ring-gray-200 ring-opacity-5 divide-y divide-gray-100 z-50" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <div className="py-1">
                            <NavLink onClick={() => this.setSearchDropdown()} to="/journalist-dashboard" className="group flex items-center px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 hover:text-gray-900" role="menuitem">
                              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clipRule="evenodd" />
                                <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                              </svg>
                              Journalists
                            </NavLink>
                            <NavLink onClick={() => this.setSearchDropdown()} to="/broadcast-dashboard" className="group flex items-center px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 hover:text-gray-900" role="menuitem">
                              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clipRule="evenodd" />
                              </svg>
                              Broadcasters
                            </NavLink>
                            <NavLink onClick={() => this.setSearchDropdown()} to="/podcast-dashboard" className="group flex items-center px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 hover:text-gray-900" role="menuitem">
                              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clipRule="evenodd" />
                              </svg>
                              Podcasts
                            </NavLink>
                            <NavLink onClick={() => this.setSearchDropdown()} to="/influencer-dashboard" className="group flex items-center px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 hover:text-gray-900" role="menuitem">
                              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                              </svg>
                              Influencers
                            </NavLink>
                          </div>
                          {/*<div className="py-1">
                            <NavLink onClick={() => this.setSearchDropdown()} to="/article-dashboard" className="group flex items-center px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 hover:text-gray-900" role="menuitem">
                              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clipRule="evenodd" />
                                <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                              </svg>
                              News articles
                            </NavLink>
                            <NavLink onClick={() => this.setSearchDropdown()} to="/tweet-dashboard" className="group flex items-center px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100 hover:bg-opacity-75 hover:text-gray-900" role="menuitem">
                              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                              </svg>
                              Tweets
                            </NavLink>
                          </div>*/}
                        </div>
                      </Transition>
                    </div>
                  </OutsideClickHandler>
                  <NavLink to="/lists" activeClassName={activeClass} className={inactiveClass}>
                    <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                    </svg>
                    <span>Lists</span>
                    <span className="border border-gray-200 bg-white px-1 rounded-full text-xs ml-1">{this.state.listCount}</span>
                  </NavLink>
                  <NavLink to="/mail/new" path="/mail" activeClassName={inactiveClass} className={inactiveClass}>
                    <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span>Campaigns</span>
                  </NavLink>
                  {/*<NavLink to="/alert-dashboard" activeClassName={activeClass} className={inactiveClass}>
                    <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                    </svg>
                    <span>Alerts</span>
                  </NavLink>*/}
                  <NavLink to="/request-dashboard" activeClassName={activeClass} className={inactiveClass}>
                    <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                      <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                    </svg>
                    <span>Requests</span>
                  </NavLink>
                  <a href="https://airtable.com/shraXULrHOUJjc1bK" target="_blank" rel="noopener noreferrer" activeClassName={activeClass} className={inactiveClass}>
                    <svg className="outline-current w-5 h-5 mr-1 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                    </svg>
                    <span>Releases</span>
                  </a>
                </div>
              </div>
              <div className="hidden lg:ml-6 lg:flex lg:items-center">
                <OutsideClickHandler
                  onOutsideClick={createDropdownOn ? () => { this.setCreateDropdown(); } : () => {}}
                >
                  <div className="relative">
                    <div className="flex-shrink-0">
                      <button onClick={() => this.setCreateDropdown()} type="button" className="relative inline-flex items-center px-2 py-2 border border-transparent text-base leading-5 font-bold rounded-lg text-white bg-blue-1000 shadow-sm hover:opacity-75 focus:outline-none transition ease-in-out duration-150">
                        <svg className="mr-2 ml-1 h-4 w-4 opacity-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                        <span>Create</span>
                        <svg className="ml-2 mr-1 h-5 w-5 opacity-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                    <Transition
                      show={createDropdownOn}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-lg shadow-md bg-white border border-gray-200">
                        <div className="py-1">
                          <NavLink onClick={() => this.setCreateDropdown()} to="/lists/new" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-blue-100 hover:bg-opacity-50 hover:text-blue-1000 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="opacity-50 outline-current mr-2 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                            </svg>
                            <span>New list</span>
                          </NavLink>
                          <NavLink onClick={() => this.setCreateDropdown()} to="/mail/new" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-blue-100 hover:bg-opacity-50 hover:text-blue-1000 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="opacity-50 outline-current mr-2 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                            <span>New campaign</span>
                          </NavLink>
                          {/*<NavLink onClick={() => this.setCreateDropdown()} to="/alert/new" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-blue-100 hover:bg-opacity-50 hover:text-blue-1000 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="opacity-50 outline-current mr-2 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                            </svg>
                            <span>New alert</span>
                          </NavLink>*/}
                          <a onClick={() => this.setCreateDropdown()} href="https://airtable.com/shraXULrHOUJjc1bK" target="_blank" rel="noopener noreferrer" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-blue-100 hover:text-blue-1000 hover:bg-opacity-50 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="opacity-50 outline-current mr-2 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                            </svg>
                            <span>New release</span>
                          </a>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </OutsideClickHandler>
                <button onClick={() => this.handleCrispHelpdeskClick()} className="ml-3 p-1 border-2 border-transparent text-pink-600 rounded-full hover:text-pink-600 focus:outline-none focus:text-pink-600 hover:bg-pink-100 transition duration-150 ease-in-out" aria-label="helpdesk">
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                  </svg>
                </button>
                <OutsideClickHandler
                  onOutsideClick={accountDropdownOn ? () => { this.setAccountDropdown(); } : () => {}}
                >
                  <div className="ml-3 relative">
                    <div>
                      <button onClick={() => this.setAccountDropdown()} className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out" id="user-menu" aria-label="User menu" aria-haspopup="true">
                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                          <svg className="h-full w-full text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <Transition
                      show={accountDropdownOn}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-lg shadow-md border border-gray-200 bg-white">
                        <div className="py-1">
                          <NavLink onClick={() => this.setAccountDropdown()} to="/account" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 fill-current outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                            </svg>
                            Account
                          </NavLink>
                          <NavLink onClick={() => this.setAccountDropdown()} to="/pricing" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 fill-current outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                            </svg>
                            Upgrade
                          </NavLink>
                          {/*<NavLink onClick={() => this.setAccountDropdown()} to="/domain" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
                            </svg>
                            Domains
                          </NavLink>*/}
                          <a onClick={() => this.setAccountDropdown()} href="https://new.presshunt.co/" target="_blank" rel="noopener noreferrer" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clipRule="evenodd" />
                              <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
                            </svg>
                            What's new
                          </a>
                          {/* <a onClick={() => this.setAccountDropdown()} href="https://new.presshunt.co/" target="_blank" rel="noopener noreferrer" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clipRule="evenodd" />
                            </svg>
                            Support
                          </a>
                          <a onClick={() => this.setAccountDropdown()} href="https://new.presshunt.co/" target="_blank" rel="noopener noreferrer" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                            Tutorial
                          </a> */}
                          <a onClick={() => this.setAccountDropdown()} href="https://press-hunt.getrewardful.com/signup" target="_blank" rel="noopener noreferrer" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
                            </svg>
                            Affiliates
                          </a>
                          <NavLink onClick={() => this.setAccountDropdown()} to="/dashboard" className="flex px-4 py-2 text-base font-bold mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                            <svg className="text-gray-400 fill-current outline-current mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                            </svg>
                            Demo
                          </NavLink>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </OutsideClickHandler>
              </div>
              <div className="flex items-center lg:hidden">
                <button onClick={() => this.setMobile()} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                  <svg className={`${isOn ? 'hidden' : 'inline'} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                  <svg className={`${isOn ? 'inline' : 'hidden'} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className={`${isOn ? 'inline' : 'hidden'} lg:hidden`}>
            <div className="pt-2 pb-3 space-y-1">
              <NavLink onClick={() => this.setMobile()} to="/journalist-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>
                <svg className="flex-shrink-0 h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                </svg>
                Journalists
              </NavLink>
              <NavLink onClick={() => this.setMobile()} to="/podcast-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>
                <svg className="flex-shrink-0 h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                </svg>
                Podcasts
              </NavLink>
              <NavLink onClick={() => this.setMobile()} to="/broadcast-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>
                <svg className="flex-shrink-0 h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Broadcasters
              </NavLink>
              <NavLink onClick={() => this.setMobile()} to="/influencer-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>
                <svg className="flex-shrink-0 h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Influencers
              </NavLink>
              {/*<NavLink onClick={() => this.setMobile()} to="/article-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>
                <svg className="flex-shrink-0 h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                </svg>
                News articles
              </NavLink>
              <NavLink onClick={() => this.setMobile()} to="/tweet-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>
                <svg className="flex-shrink-0 h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                </svg>
                Tweets
              </NavLink>*/}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="mt-3 space-y-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <NavLink onClick={() => this.setMobile()} to="/lists" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Lists</NavLink>
                <NavLink onClick={() => this.setMobile()} to="/account" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Account</NavLink>
              </div>
            </div>
          </div>
        </nav>

      </div>
    );
  }
}

export default Dashnav;
