import React from 'react';
import Error from './Error';
import FireflySubscription from './FireflySubscription';

const SubscriptionPlan = () => (
  <FireflySubscription>
    { ({isLoading, error, subscription}) => {

      if (error) {
        return <Error error={error} />
      }
      if (isLoading) {
        return <p>loading...</p>
      }
      if (!subscription) {
        return <p>No active subscription</p>
      }
      if (subscription.upgrade) {
        return <p>Upgrading...</p>
      }
      if (subscription.plan === 7 || subscription.plan === 70) {
        return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-yellow-200 text-yellow-800">Enterprise</span>
      }
      if (subscription.plan === 6 || subscription.plan === 60) {
        return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-yellow-200 text-yellow-800">Agency</span>
      }
      if (subscription.plan === 5 || subscription.plan === 50) {
        return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-yellow-200 text-yellow-800">Premium</span>
      }
      if (subscription.plan === 4 || subscription.plan === 40) {
        return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-pink-200 text-pink-800">Boost</span>
      }
      if (subscription.plan === 3 || subscription.plan === 30) {
      	return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-green-200 text-green-800">Startup</span>
      }
      if (subscription.plan === 2 || subscription.plan === 20) {
      	return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-green-200 text-green-800">Startup</span>
      }
      if (subscription.plan === 1 || subscription.plan === 10) {
      	return <span className="inline-flex items-center mt-2 mb-6 px-5 py-1 rounded-full text-sm font-medium bg-green-200 text-green-800">Basic</span>
      }
      else {
      	return <div />
      }
   }}
  </FireflySubscription>
)

export default SubscriptionPlan
