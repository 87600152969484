import React from 'react';

import TriggeredAlertItems from './triggeredAlertItems';

const AllTriggeredAlerts = ({ auth }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
    <TriggeredAlertItems email={auth ? auth.email : ""} />
  </div>
)

export default AllTriggeredAlerts;
