import React, { Component } from 'react';
import { auth } from '../../api/firebase';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;

    auth.currentUser.updatePassword(passwordOne)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '';

    return (
      <section className="is-account">
        <form onSubmit={this.onSubmit}>
          <h2 className="form-title" style={{ paddingBottom:'1em' }}>Change my password</h2>
          <input
            value={passwordOne}
            onChange={event => this.setState(updateByPropertyName('passwordOne', event.target.value))}
            type="password"
            placeholder="New Password"
            className="form-input is-sign-up-form-input"
          />
          <input
            value={passwordTwo}
            onChange={event => this.setState(updateByPropertyName('passwordTwo', event.target.value))}
            type="password"
            placeholder="Confirm New Password"
            className="form-input is-sign-up-form-input"
          />
          <button
            disabled={isInvalid}
            type="submit"
            className="mt-2 block w-full px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset My Password
          </button>
          <a
            href="https://airtable.com/shrC8jK16KXXwmSlp"
            className="mt-6 block w-full px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
          >
            Unsubscribe from Press Hunt
          </a>
          { error && <p>{error.message}</p> }
        </form>
      </section>
    );
  }
}

export default PasswordChangeForm;
