import React from 'react';
import Placeholder from '../../assets/placeholder.png';

const SearchResult = ({ hit }) => (
  <div className="container search-list is-centered">
    <div className="heaven-journalist-card journalist-card-dash">
      <div className="journalist-card-content">
        <div className="journalist-card-content-header">
          {hit.avatar ? (
            <div>
              <img src={hit.avatar.replace("_normal","")} alt="" className="rounded-full w-16 h-16" />
            </div>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" style={{ color: "#eff3f7" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )}
          <div className="py-2 px-4">
            <div className="journalist-card-name-and-link">
              <p className="journalist-req-name">Journalist<i className="fas fa-chevron-down heaven-nav-button-icon-right"/><i className="fas fa-lock heaven-nav-link-icon-lock"/></p>
            </div>
            {/*checks to see if there is outlet_text conditional*/}
            <div className="journalist-card-type-outlet" style={{ marginBottom:'0px' }} />
          </div>
        </div>
      </div>

      {(hit.query) ? (
      <div className="heaven-req-card-content">
        {(hit.title && hit.category) ? (
          <p className="req-card-title">{hit.title} • {hit.category}</p>
        ) : (hit.title) ? (
          <p className="req-card-title">{hit.title}</p>
        ) : <span />
        }
        <p className="req-card-query">
          {hit.query.replace(RegExp("@@@",'g'),"   ")}
        </p>
        {hit.requirements ? (
          <p className="req-card-require"><i className="fas fa-clipboard-check heaven-nav-button-icon" />Requirements: <b>{hit.requirements}</b></p>
        ) : <span />}
        {hit.deadline ? (
          <p className="req-card-deadline"><i className="fas fa-calendar-alt heaven-nav-button-icon" />Deadline: <b>{hit.deadline}</b></p>
        ) : <span />}
        <p className="req-card-date">{hit.location_of_tweet ? (hit.date in hit.location_of_tweet) : hit.date}</p>
      </div>
      ) : <span />}

      <div className="heaven-card-footer">
        <div className="columns heaven-card-footer-columns">
          <div className="column is-one-quarters">
            <div className="heaven-card-footer-buttons">
              <div className="dropdown is-hoverable is-left">
                <div className="dropdown-trigger">
                  <a className="heaven-card-white-button is-pulled-left"><i className="fas fa-address-card heaven-nav-button-icon"/>Contact<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></a>
                </div>
                <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                  <ul className="dropdown-content heaven-card-dropdown-content">
                    {(hit.email) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fas fa-envelope heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.secondary_email) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fas fa-envelope heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.phone) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fas fa-phone heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.twitter_name && hit.twitter_name.includes("://")) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fab fa-twitter heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : (hit.twitter_name) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fab fa-twitter heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.linkedin_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fab fa-linkedin heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.facebook_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fab fa-facebook heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span />
                    }
                    {(hit.instagram_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fab fa-instagram heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.youtube_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fab fa-youtube heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                    {(hit.website) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                        <i className="fas fa-link heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock"/>
                      </a>
                    ) : <span /> }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-three-quarters">
            <div className="heaven-card-footer-buttons-right">
              <a className="heaven-card-cta-button is-pulled-right" href="/pricing" style={{padding:'8px 12px'}}>
                <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save<i className="fas fa-lock heaven-nav-link-icon-lock" style={{color:'white',opacity:'.5'}} />
              </a>
              {(hit.secondary_email) ? (
                <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fas fa-envelope heaven-nav-button-icon"/>Reply<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
              ) : (hit.email) ? (
                <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fas fa-envelope heaven-nav-button-icon"/>Reply<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
              ) : (!hit.email && !hit.secondary_email && hit.twitter_name) ? (
                <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fab fa-twitter heaven-nav-button-icon"/>Reply<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
              ) : <span /> }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SearchResult;
