import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

import NoAuth from '../misc/noAuth';

class HomeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatBoxOpen: false,
    };
  }

  handleCrispClick() {
    this.setState({
      chatBoxOpen: true,
    });
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '99788d98-6b5f-41ad-9705-497d0dbff310';
    window.$crisp.push(['do', 'chat:open']);

    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    }());
  }

  render() {
    const { auth, subscription, status } = this.props;

    return (
      <section className="border-b border-gray-200 py-5 px-4 mt-10 mb-20 mx-auto max-w-3xl">
        {!auth ? (
          <NoAuth />
        ) : (
          <section>
            <div className="float-right mb-10 flex">
              <button className="heaven-card-page-white-button mx-2" onClick={() => this.handleCrispClick()}>
                <i className="fas fa-life-ring heaven-nav-button-icon" />
                Support
              </button>
              <a href="https://calendly.com/robert-1116/press-hunt-demo" target="_blank" rel="noopener noreferrer">
                <button className="heaven-card-page-white-button">
                  <i className="fas fa-calendar-alt heaven-nav-button-icon" />
                  Book demo
                </button>
              </a>
              <NavLink to="/pricing">
                <button className="heaven-page-cta-button">
                  <i className="fas fa-star heaven-nav-button-icon" />
                  Upgrade
                </button>
              </NavLink>
            </div>
            {(subscription && status === 'active') ? (
              <div className="heaven-dash-body-intro-grey" style={{ clear: 'both', color: 'white' }}>
                <p className="heaven-dash-body-intro-title-white">
                  <span role="img" aria-label="heart">❤️</span>
                  {' '}
                  Thanks for subscribing to Press Hunt!
                </p>
                <p className="heaven-dash-body-intro-body-white">We're excited to help you get started, and we're grateful you're giving our platform a shot. Below you can find a full demo of our platform and learn more about what you can do with Press Hunt, and how we're building the future of PR powered by the most robust realtime PR database in the world.</p>
                <p className="heaven-dash-body-intro-body-white">
                  We're always available in the big pink live chat on the bottom right of your screen, and would love for you to say hi and let us know how we can help! If you run into any trouble, you can also check out our helpdesk by clicking on the pink question mark in the top right of your dashboard.
                  <span role="img" aria-label="thumbs up">👍</span>
                </p>
                <iframe className="rounded-lg mt-8 mb-3 shadow-lg" width="100%" height="391" src="https://www.youtube.com/embed/KsyayfIqEOE" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            ) : (
              <div className="heaven-dash-body-intro-grey" style={{ clear: 'both', color: 'white' }}>
                <p className="heaven-dash-body-intro-title-white">
                  <span role="img" aria-label="heart">❤️</span>
                  {' '}
                  Thanks for signing up for Press Hunt!
                </p>
                <p className="heaven-dash-body-intro-body-white">We're excited to help you get started. Below you can find a full demo of our platform. On your free plan, you can start searching through our media database for free by clicking on Database above and selecting the database you'd like to search through. You can learn more about what you can do with Press Hunt, and how we're building the future of PR powered by the most robust realtime PR database in the world below.</p>
                <p className="heaven-dash-body-intro-body-white">
                  We're always available in the big pink live chat on the bottom right of your screen, and would love for you to say hi and let us know how we can help!
                  <span role="img" aria-label="hearts">💕</span>
                </p>
                <iframe className="rounded-lg mt-8 mb-3 shadow-lg" width="100%" height="391" src="https://www.youtube.com/embed/KsyayfIqEOE" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            )}
            <div>
              <div className="heaven-dash-body-intro" style={{ display: 'inline-block' }}>
                <p className="heaven-dash-body-intro-title">
                  <i className="fas fa-search heaven-nav-link-icon" />
                  Search
                </p>
                <p className="heaven-dash-body-intro-body">Our media database has 1.2M+ contacts, including journalists, broadcasters, podcasts, and influencers. We also monitor hundreds of thousands of reporters every day, and highlight trending tweets and news articles. You can start searching through our media & media monitoring databases above by hovering over the Search button.</p>
                <Link to="/journalist-dashboard">
                  <div className="heaven-card-white-button dash-body-intro-button">
                    <i className="fas fa-search heaven-nav-link-icon" />
                    Search journalists
                  </div>
                </Link>
              </div>

              <div className="heaven-dash-body-intro" style={{ display: 'inline-block' }}>
                <p className="heaven-dash-body-intro-title">
                  <i className="fas fa-comment heaven-nav-link-icon" />
                  Requests
                </p>
                <p className="heaven-dash-body-intro-body">We source thousands of PR Requests every week, from outlets like Forbes, Associated Press, FOX Business, and thousands more! We add new PR Requests every 30 minutes, 7 days a week, complete with journalist's direct contact info (not a generic encrypted email address).</p>
                <Link to="/request-dashboard">
                  <div className="heaven-card-white-button dash-body-intro-button">
                    <i className="fas fa-search heaven-nav-link-icon" />
                    Search requests
                  </div>
                </Link>
              </div>

              {/*<div className="heaven-dash-body-intro" style={{ display: 'inline-block' }}>
                <p className="heaven-dash-body-intro-title">
                  <i className="fas fa-bell heaven-nav-link-icon" />
                  Alerts
                </p>
                <p className="heaven-dash-body-intro-body">You can create alerts in seconds to monitor news coverage from around the world. When a journalist mentions a keyword in either an article or a tweet, we'll send you an email letting you know. We'll also include their best contact info to get in touch with them.</p>
                <p className="heaven-dash-body-intro-body">You can also created automated emails to send to journalists when your alert triggers, so you can put your outreach on autopilot.</p>
                <Link to="/alert-dashboard">
                  <div className="heaven-card-white-button dash-body-intro-button heaven-margin-right">
                    <i className="fas fa-plus heaven-nav-link-icon" />
                    New alert
                  </div>
                </Link>
                <Link to="/sequence-dashboard">
                  <div className="heaven-card-white-button dash-body-intro-button">
                    <i className="fas fa-plus heaven-nav-link-icon" />
                    New sequence
                  </div>
                </Link>
              </div>*/}

              <div className="heaven-dash-body-intro" style={{ display: 'inline-block' }}>
                <p className="heaven-dash-body-intro-title">
                  <i className="fas fa-paper-plane heaven-nav-link-icon" />
                  Releases
                </p>
                <p className="heaven-dash-body-intro-body">Distribute traditional press releases to our network of 250+ media outlets including CBS, FOX, ABC, and NBC syndicates.</p>
                <a className="heaven-card-white-button dash-body-intro-button" href="https://airtable.com/shraXULrHOUJjc1bK" target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-plus heaven-nav-link-icon" />
                  New release
                </a>
              </div>

              <div className="heaven-dash-body-intro" style={{ display: 'inline-block' }}>
                <p className="heaven-dash-body-intro-title">
                  <i className="fas fa-envelope heaven-nav-link-icon" />
                  Emails
                </p>
                <p className="heaven-dash-body-intro-body">
                  Build lists of your favorite journalists, organize your lists around your different PR campaigns and clients, and send all pitches from your own email address. We've built advanced deliverability optimizations to ensure your emails actually
                  <i>land</i>
                  {' '}
                  in the journalist's inbox.
                </p>
                <p className="heaven-dash-body-intro-body">We've also built out advanced safety measures to ensure that sending emails doesn't risk either you or your client's domain reputation when you send emails from your own email address.</p>
                <Link to="/mail/new">
                  <div className="heaven-card-white-button dash-body-intro-button">
                    <i className="fas fa-plus heaven-nav-link-icon" />
                    New email
                  </div>
                </Link>
              </div>

              <div className="heaven-dash-body-intro" style={{ display: 'inline-block' }}>
                <p className="heaven-dash-body-intro-title">
                  <i className="fas fa-list heaven-nav-link-icon" />
                  Lists
                </p>
                <p className="heaven-dash-body-intro-body">In less than a minute, you can build media lists of thousands of journalists, podcasts, broadcasters, and influencers. You can either export your lists to CSV, or email contacts using Press Hunt's advanced PR deliverability system.</p>
                <Link to="/lists">
                  <div className="heaven-card-white-button dash-body-intro-button">
                    <i className="fas fa-plus heaven-nav-link-icon" />
                    New list
                  </div>
                </Link>
              </div>
            </div>
          </section>
        )}
      </section>
    );
  }
}

export default HomeDashboard;
