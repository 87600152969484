import React from 'react';
import { RefinementList } from 'react-instantsearch-dom';

const JournoSearchFilter = ({ locked, area, mediaOutlet }) => (
  <div className="heaven-search-filters">
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-envelope heaven-nav-link-icon" />Has email address<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="has_email"
        className="journalist-filter-refinement-list"
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-phone heaven-nav-link-icon" />Has phone number<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="has_phone_number"
        className="journalist-filter-refinement-list"
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-globe-americas heaven-nav-link-icon" />Countries <span className="border border-gray-200 text-xs font-bold py-1 px-2 ml-1 -mt-3 rounded-full text-gray-700 font-normal">168 total</span><i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="country"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-city heaven-nav-link-icon" />Areas <span className="border border-gray-200 text-xs font-bold py-1 px-2 ml-1 -mt-3 rounded-full text-gray-700 font-normal">978 total</span><i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute={area}
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-clipboard-list heaven-nav-link-icon" />Beats <span className="border border-gray-200 text-xs font-bold py-1 px-2 ml-1 -mt-3 rounded-full text-gray-700 font-normal">73 total</span><i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="industry"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-tags heaven-nav-link-icon" />Topics <span className="border border-gray-200 text-xs font-bold py-1 px-2 ml-1 -mt-3 rounded-full text-gray-700 font-normal">7,517 total</span><i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="tags"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-broadcast-tower heaven-nav-link-icon" />Media Outlets<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute={mediaOutlet}
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section" style={{ borderBottom:'none' }}>
      <p className="heaven-search-filter-subtitle"><i className="fas fa-id-badge heaven-nav-link-icon" />Job Titles<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="kind_of_journalist"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
  </div>
);

export default JournoSearchFilter;
