import React, { useState, useEffect } from 'react';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';
import { auth } from '../../api/firebase';

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-green-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);

export default function Example() {
  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscribeStatus = async() => {
    const db = Firebase.firestore();
    db.collection('subscriptions').where('user', '==', auth.currentUser.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const subscriptions = [];
          snapshot.forEach((doc) => subscriptions.push({ ...doc.data(), id: doc.id }));
          setSubscriptions(subscriptions[0]);
        }
    })
  }

  useEffect(() => {
    getSubscribeStatus();
  }, []);
  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-6xl mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Campaigns</h2>
          <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
            <p className="text-xl text-gray-500">Send bulk emails to contacts in your media lists</p>
          </div>
        </div>
        <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Campaign <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">Basic</span></h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <NavLink
                  to={ subscriptions.length == 0 ? "/pricing" : "/mail/new"}
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-blue-1000 hover:text-white hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                >
                  Get started
                </NavLink>
              </div>
            </div>
            <section className="my-8 py-4">
              <div className="flex">
                <CheckIcon />
                <span className="ml-2">
                  Get up and running in 30 seconds
                </span>
              </div>
              <div className="flex">
                <CheckIcon />
                <span className="ml-2">
                  Get started quickly in just 2 clicks
                </span>
              </div>
              <div className="flex">
                <CheckIcon />
                <span className="ml-2">
                  Requires Google or Microsoft email account
                </span>
              </div>
              <div className="flex">
                <CheckIcon />
                <span className="ml-2 font-bold">
                  Limit: 1,000 emails per day
                </span>
              </div>
            </section>
          </div>

          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Campaign <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Pro</span></h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <NavLink
                  to={ subscriptions.length == 0 ? "/pricing" : "/campaigns/pro"}
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white hover:text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                >
                  Get started
                </NavLink>
              </div>
            </div>
            <section className="my-8 py-4">
              <div className="flex">
                <CheckIcon />
                <span className="ml-2">
                  Requires access to your DNS panel
                </span>
              </div>
              <div className="flex">
                <CheckIcon />
                <span className="ml-2">
                  Better for larger email campaigns
                </span>
              </div>
              <div className="flex">
                <CheckIcon />
                <span className="ml-2">
                  Requires 2-3 weeks of smaller campaigns before your can send larger blasts
                </span>
              </div>
              <div className="flex">
                <CheckIcon />
                <span className="ml-2 font-bold">
                  Limit: 25,000 emails per day after warmup
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}