import React, { PureComponent } from 'react';
import Firebase from 'firebase/app';
import Pagination from 'react-js-pagination';
import Slugify from 'slugify';
import Nav from '../layout/directory-nav';
import Footer from '../layout/footer';

class JournalistList extends PureComponent {
	constructor(props) {
    super(props);
    this.state = {
      dbItems: [],
      currentPage: this.props.location.state ? this.props.location.state.page : 1,
      itemsPerPage: 1000,
      totalItemCount: 1000,
      //subject to change depending on # of data in dB
      activePage: this.props.location.state ? this.props.location.state.page : 1,
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getItems = this.getItems.bind(this);
	}

	handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({
    	activePage: pageNumber,
    	currentPage: pageNumber,
    });
	}

	getItems = () => {
		console.log("first invoked")
	  const {currentPage, itemsPerPage} = this.state
	  const startAt = (currentPage * itemsPerPage - itemsPerPage)
    var db = Firebase.firestore();
    var first = db.collection('journalists')
      .orderBy("index")
      .startAt(startAt)
	  	.limit(itemsPerPage)

    first.get().then( documentSnapshots => {
      // var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
      // console.log("last", lastVisible);
  	  var items = documentSnapshots.docs.map(doc => doc.data())
      console.log(items)
  	  return this.setState({
        dbItems: items,
      })
    })
	}

	componentDidMount() {
    this.getItems()
	}

	componentDidUpdate(prevProps, prevState) {
    const isDifferentPage = this.state.currentPage !== prevState.currentPage
    if (isDifferentPage) this.getItems()
	}

  render() {
    return (
    	<div>
    		<Nav />
        <section className="section">
					<div className="container">
						<div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
							<div className="column is-4">
								<div className="is-profile-left-action-card">
									<p className="is-action-card-title has-text-white">Sign up today to search through our database of 400,000 journalists</p>
									<p className="is-8 is-action-card-para">Sign up today to search through our entire database of almost 400,000 journalists for free!</p>
									<p className="is-action-card-para">Emails, phone numbers, and search filters are redacted until you subscribe to a plan. After subscribing to a paid plan, you can also create media lists of your favorite journalists and export their contact information to CSV! Press Hunt plans start at just $69 and you can upgrade or cancel at any time.</p>
									<a href="/signup">
										<button className="button is-blue is-profile-cta-button">Get started for free</button>
									</a>
								</div>
							</div>
							<div className="column">
								<div className="is-profile-right-buttons">
									<div className="columns is-profile-right-buttons-columns">
										<div className="column is-half is-profile-right-buttons-column">
											<a className="button is-profile-back-button" href="/?ref=directory">Learn more about Press Hunt</a>
										</div>
										<div className="column is-half is-pulled-right is-profile-right-buttons-column">
											<a className="button is-new-journalist-button-dir" href="/journalists/new/?ref=directory">Submit new journalist</a>
										</div>
									</div>
								</div>
								<a href="/signup">
									<p className="ais-SearchBox-input is-fake-search-bar">Search our journalist directory for free today…</p>
								</a>
		            {this.state.dbItems.map((journalist, index) => {
		            	var link = Slugify(journalist.name, {lower: true});
		              return (
		              	<p>https://presshunt.co/journalists/{link}</p>
          				)
								})}
									<div className="pagination is-centered is-directory-pagination-div" role="navigation" aria-label="pagination">
										<Pagination
											activePage={this.state.activePage}
											itemsCountPerPage={this.state.itemsPerPage}
											totalItemsCount={this.state.totalItemCount}
											pageRangeDisplayed={this.state.itemsPerPage}
											onChange={this.handlePageChange}
											firstPageText="First"
											lastPageText="Last"
											innerClass="pagination-list"
											linkClass="pagination-link"
											activeLinkClass="pagination-link is-current"
											className="is-directory-pagination"
										/>
									</div>
							</div>
						</div>
					</div>
        </section>
        <Footer />
    	</div>
		)
  }
}

export default JournalistList;
