import React from 'react';

import createDomain from '../../actions/sequence/createDomain';
import DomainForm from './domainForm';

const DomainNew = ({ history }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
    <div className="columns">
      <div className="column is-one-thirds">
        <div className="is-dash-left-sequence-card">
          <p className="is-dash-left-sequence-card-title">Authenticate domains to send emails from your own email address</p>
          <p className="is-8 is-action-card-para">To send emails from your own email address, authenticate your domain here.</p>
          <p className="is-8 is-action-card-para"> Your domain needs to match the domain of your FROM address on the emails you are sending out. For example, if I am sending an email from example@presshunt.co, I would set my domain authentication domain to be presshunt.co.</p>
        </div>
      </div>
      <div className="column is-two-thirds">
        <DomainForm
          onSubmit={values => createDomain(values).then(domain => history.push("/domain"))}
        />
      </div>
    </div>
  </div>
)

export default DomainNew;
