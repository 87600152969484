import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import FirebaseUser from '../misc/FirebaseUser';
import StripeCheckout from '../subscriptions/StripeCheckout';
import StripeBillingPortal from '../subscriptions/StripeBillingPortal';
import Error from '../misc/Error';
import HeroPricing from '../../assets/agency-pricing-hero.svg';
import MobileHeroPricing from '../../assets/hero-pricing-mobile.svg';
import Nav from '../layout/agency-nav.js';
import FixedComponents from './fixedComponents';

class AgencyPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      checked: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleCheckboxChange = event =>
    this.setState({ checked: event.target.checked })


  render() {
    return (
      <div>
        <Helmet>
          <title>Press Hunt Pricing | An affordable journalist database 🚀</title>
          <meta name="description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />

          <meta itemprop="name" content="Press Hunt is a database of 580,000 journalists 🚀" />
          <meta itemprop="description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta itemprop="image" content="https://i.imgur.com/uOpUy46.png" />

          <meta property="og:url" content="https://presshunt.co" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Press Hunt is a database of 580,000 journalists 🚀" />
          <meta property="og:description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta property="og:image" content="https://i.imgur.com/uOpUy46.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Press Hunt is a database of 580,000 journalists 🚀" />
          <meta name="twitter:description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta name="twitter:image" content="https://i.imgur.com/uOpUy46.png" />

          <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

        </Helmet>

        <Nav />

        { this.state.width > 900 ? <img
          src={ HeroPricing }
          className="is-overlay"
          alt="hero"
        /> : <img
          src={ MobileHeroPricing }
          className="is-overlay"
          alt="mobile hero"
        />}

        <section className="section is-centered has-text-centered" style={{ minHeight: "35em", paddingTop: "0em", paddingLeft: "0em", paddingRight: "0em" }}>
          <div className="clear-pricing-section-card" style={{margin: "5%"}}>
            <div className="container pricing-head has-text-centered is-centered">
              <h1 className="title has-text-centered is-centered has-text-white" id="pricing">An affordable way to get your business in the news <span role="img" aria-label="dart">🎯</span></h1>
              <p className="subtitle has-text-centered is-centered has-text-white pricing-sub">Press Hunt plans let businesses of all sizes access the media industry. Upgrade or cancel anytime.</p>
              {/*annual plan switch*/}
              <div className="field is-pricing-switch">
                <input
                  id="switchExample"
                  type="checkbox"
                  name="switchExample"
                  className="switch is-medium"
                  defaultChecked={this.state.checked}
                  onChange={this.handleCheckboxChange}
                />
                <label
                  style={{ color: "white" }}
                  htmlFor="switchExample"
                >
                  { this.state.checked ? "Billed annually at a 15% discount!" : "Billed monthly" }
                </label>
              </div>
            </div>

            <div className="columns is-centered is-agency-pricing-div">
              <div className="column">
                <div className="card pricing-card">
                <div className="card-header-title is-centered">
                  <span role="img" aria-label="horse">🐴</span> Agency
                </div>
                  <header className="pricing-card-header-emphasis">
                    <div className="title has-text-centered">
                      { this.state.checked ? <div>$833.25<span style={{ fontSize:"14px" }}>/month</span></div> : <div>$999<span style={{ fontSize:"14px" }}>/month</span></div> }
                    </div>
                    <div className="subtitle has-text-centered is-size-6 is-transparent">
                      { this.state.checked ? "billed annually " : "billed monthly" }
                    </div>
                  </header>

                  <div className="card-content pricing-card-content">
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Everything in Sequences
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Send up to 25,000 emails from your own email address every month
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Sequences triggered by both articles & tweets
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Create up to 100 PR Sequences at a time
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Authenticate unlimited domains to send emails from
                    </div>
                    <div className="content title is-pricing-line">
                      <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Send up to 1,500 sequences per month, 150 per day
                    </div>
                    <div className="buttons is-centered">
                      <FirebaseUser>
                        { ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {
                          if (isLoading)
                            return <button className="is-pricing-button">Buy now!</button>
                          if (error)
                            return <Error error={error} />
                          if (!auth)
                            return <Link to="/signup"><button className="is-pricing-button">Buy now!</button></Link>
                          if (subscribed)
                            return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                          if (this.state.checked)
                            return <StripeCheckout plan="agencyAnnual" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                          return <StripeCheckout plan="agencyMonthly" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                        }}
                      </FirebaseUser>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="card pricing-card-emphasis is-middle-pricing">
                <div className="card-header-title is-centered">
                  <span role="img" aria-label="unicorn">🦄</span> Enterprise

                </div>
                  <header className="pricing-card-header-emphasis">
                    <div className="title has-text-centered">
                      { this.state.checked ? <div>$4,166.58<span style={{ fontSize:"14px" }}>/month</span></div>  : <div>$4,999<span style={{ fontSize:"14px" }}>/month</span></div>  }
                    </div>
                    <div className="subtitle has-text-centered is-size-6 is-transparent">
                      { this.state.checked ? "billed annually" : "billed monthly" }
                    </div>
                  </header>
                  <div className="card-content pricing-card-content">
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Everything in Agency
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Send up to 60,000 emails from your own email address every month
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Create up to 500 PR Sequences at a time
                  </div>
                  <div className="content title is-pricing-line">
                    <i className="far fa-check-circle pricing-icon-yes"></i>&nbsp;&nbsp;Send up to 3,000 sequences per month, 300 per day
                  </div>
                    <div className="buttons is-centered">
                      <FirebaseUser>
                        { ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {
                          if (isLoading)
                            return <button className="is-pricing-button">Buy now!</button>
                          if (error)
                            return <Error error={error} />
                          if (!auth)
                            return <Link to="/signup"><button className="is-pricing-button">Buy now!</button></Link>
                          if (subscribed)
                            return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                          if (this.state.checked)
                            return <StripeCheckout plan="enterpriseAnnual" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                          return <StripeCheckout plan="enterpriseMonthly" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                        }}
                      </FirebaseUser>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pricing-head has-text-centered is-centered">
              <p className="subtitle has-text-centered is-centered pricing-below-text is-size-6">All payments are processed securely through Stripe.</p>
            </div>
          </div>
        </section>
        <FixedComponents />
      </div>
    );
  }
}

export default AgencyPricing;
