import React, { PureComponent } from 'react';
import Firebase from 'firebase/app';
import Pagination from 'react-js-pagination';
import Slugify from 'slugify';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Nav from '../layout/directory-nav';
import Placeholder from '../../assets/placeholder.png';
import Footer from '../layout/footer';

class Journalists extends PureComponent {
	constructor(props) {
    super(props);
    this.state = {
      dbItems: [],
      currentPage: this.props.location.state ? this.props.location.state.page : 1,
      itemsPerPage: 10,
      totalItemCount: 1000,
      //subject to change depending on # of data in dB
      activePage: this.props.location.state ? this.props.location.state.page : 1,
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getItems = this.getItems.bind(this);
	}

	handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({
    	activePage: pageNumber,
    	currentPage: pageNumber,
    });
	}

	getItems = () => {
		console.log("first invoked")
	  const {currentPage, itemsPerPage} = this.state
	  const startAt = (currentPage * itemsPerPage - itemsPerPage)
    var db = Firebase.firestore();
    var first = db.collection('journalists')
      .orderBy("index")
      .startAt(startAt)
	  	.limit(itemsPerPage)

    first.get().then( documentSnapshots => {
      // var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
      // console.log("last", lastVisible);
  	  var items = documentSnapshots.docs.map(doc => doc.data())
      console.log(items)
  	  return this.setState({
        dbItems: items,
      })
    })
	}

	componentDidMount() {
    this.getItems()
	}

	componentDidUpdate(prevProps, prevState) {
    const isDifferentPage = this.state.currentPage !== prevState.currentPage
    if (isDifferentPage) this.getItems()
	}

  render() {
    return (
    	<div>
				<Helmet>
					<title>Find journalists with Press Hunt's database of 580,000 journalists</title>
					<meta name="description" content="Press Hunt is a database of almost 580,000 journalist emails and phone numbers to help you find journalists to feature your business" />

					<meta itemprop="name" content="Find journalists with Press Hunt's database of 580,000 journalists" />
					<meta itemprop="description" content="Press Hunt is a database of almost 580,000 journalist emails and phone numbers to help you find journalists to feature your business" />
					<meta itemprop="image" content="https://i.imgur.com/uOpUy46.png" />

					<meta property="og:url" content={"https://presshunt.co/journalists/"+ this.props.match.params.slug} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Find journalists with Press Hunt's database of 580,000 journalists" />
					<meta property="og:description" content="Press Hunt is a database of almost 580,000 journalist emails and phone numbers to help you find journalists to feature your business" />
					<meta property="og:image" content="https://i.imgur.com/uOpUy46.png" />

					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content="Find journalists with Press Hunt's database of 580,000 journalists" />
					<meta name="twitter:description" content="Press Hunt is a database of almost 580,000 journalist emails and phone numbers to help you find journalists to feature your business" />
					<meta name="twitter:image" content="https://i.imgur.com/uOpUy46.png" />

					<script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>
				</Helmet>
    		<Nav />
        <section className="section">
					<div className="container">
						<div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
							<div className="column is-4">
								<div className="is-profile-left-action-card">
									<p className="is-action-card-title has-text-white">Sign up today to search through our database of 580,000 journalists</p>
									<p className="is-8 is-action-card-para">Sign up today to search through our entire database of almost 580,000 journalists for free!</p>
									<p className="is-action-card-para">Emails, phone numbers, and search filters are redacted until you subscribe to a plan. After subscribing to a paid plan, you can also create media lists of your favorite journalists and export their contact information to CSV! Press Hunt plans start at just $69 and you can upgrade or cancel at any time.</p>
									<a href="/signup">
										<button className="button is-blue is-profile-cta-button">Get started for free</button>
									</a>
								</div>
							</div>
							<div className="column">
								<div className="is-profile-right-buttons">
									<div className="columns is-profile-right-buttons-columns">
										<div className="column is-half is-profile-right-buttons-column">
											<a className="button is-profile-back-button" href="/?ref=directory">Learn more about Press Hunt</a>
										</div>
										<div className="column is-half is-pulled-right is-profile-right-buttons-column">
											<a className="button is-new-journalist-button-dir" href="/journalists/new/?ref=directory">Submit new journalist</a>
										</div>
									</div>
								</div>
								<a href="/signup">
									<p className="ais-SearchBox-input is-fake-search-bar">Search our journalist directory for free today…</p>
								</a>
		            {this.state.dbItems.map((journalist, index) => {
		            	var link = Slugify(journalist.name, {lower: true});
		              return (
		              	<div key={index}>
	                    <div className="index-profile-card">
	                    	<Link to={{
	                    		pathname: `/journalists/${link}`,
	                    		state: { page: this.state.currentPage }
	                    	}}>
		                      <div className="search-card-header">
		                        {/*avatar image conditional*/}
		                        {(journalist.avatar) ? (<img src={journalist.avatar} alt="avatar" className="image is-64x64 search-card-picture" />)
		                          : <img src={Placeholder} alt="Placeholder" className="image is-64x64 search-card-picture" />
		                        }
		                        <p className="title is-4 search-card-name">{journalist.name}</p>
		                      </div>
	                      </Link>
                      <div className="search-card-contact">
												{/*twitter bio conditional */}
												{(journalist.twitter_bio) ? (
													<p className="twitter-bio">{journalist.twitter_bio}</p>) : <span />
												}

												{/*outlet conditional*/}
                        {(journalist.outlet_text[0] && journalist.outlet_text !== "[]") ?  (
                        <p className="is-5 search-card-contact-tag">
                          <span className="icon is-small is-industry-icon">
                            <i className="fas fa-briefcase" aria-hidden="true"></i>
                          </span>
                          {journalist.outlet_text}
                        </p>) : <span />}
													{(journalist.email) ? (
													<a href="/pricing" className="is-social-button-link">
														<p className="is-5 search-card-contact-tag">
															<span className="icon is-small is-industry-icon">
																<i className="fas fa-envelope" aria-hidden="true"></i>
															</span>
															{'premium' + journalist.email.substring(journalist.email.lastIndexOf("@"))}
														</p>
													</a>) : <span />}

													{(journalist.phone) ? (
													<p className="is-5 search-card-contact-tag is-premium-hidden">
														<span className="icon is-small is-industry-icon">
															<i className="fas fa-phone" aria-hidden="true"></i>
														</span>
														{'* premium data *'}
													</p>) : <span />}
	                        {/*twitter conditional*/}

									        { journalist.twitter_name ? 
									        <Link to="/pricing" className="is-social-button-link">
									          <p className="is-5 search-card-contact-tag">
									            <span className="icon is-small is-industry-icon">
									              <i className="fab fa-twitter" aria-hidden="true"></i>
									            </span>
									            {journalist.twitter_name}
									          </p>
									        </Link> : <span />}
												</div>
												
												<div className="search-card-tags">
													{/*website conditional*/}
													{(journalist.website && (journalist.website[0].length!==0)) ?  (
													<p className="is-5 search-card-contact-tag">
														<span className="icon is-small is-industry-icon">
															<i className="fas fa-link" aria-hidden="true"></i>
														</span>
														<a href={journalist.website} target="_blank" className="is-social-button-link">Website</a>
													</p>) : <span />}

													{/*type conditional*/}
													{(journalist.type && (journalist.type[0].length!==0)) ?  (
													<p className="is-5 search-card-contact-tag">
														<span className="icon is-small is-industry-icon">
															<i className="far fa-address-book" aria-hidden="true"></i>
														</span>
														{journalist.type}
													</p>) : <span />}

													{/*industry conditional*/}
													{(journalist.industry && (journalist.industry[0].length!==0) && Array.isArray(journalist.industry) ) ? (
														journalist.industry.map((industry) =>
															<div key={industry} className="is-5 search-card-industry-tag">
																<span className="icon is-small is-industry-icon">
																	<i className="fas fa-industry" aria-hidden="true"></i>
																</span>
																{industry}
															</div>
														)
													) : (
														<span />)}
													{/*tags conditional*/}
													{(journalist.tags && (journalist.tags[0].length!==0)) ? (
														journalist.tags.map((tag) =>
															<div key={tag} className="is-search-card-tag">
																<span className="icon is-small is-tag-icon">
																	<i className="fas fa-tag" aria-hidden="true"></i>
																</span>
																{tag}
															</div>
														)
													) : (
														<span />)}
		                    </div>
											</div>
										</div>
            				)
									})}
									<div className="pagination is-centered is-directory-pagination-div" role="navigation" aria-label="pagination">
										<Pagination
											activePage={this.state.activePage}
											itemsCountPerPage={this.state.itemsPerPage}
											totalItemsCount={this.state.totalItemCount}
											pageRangeDisplayed={this.state.itemsPerPage}
											onChange={this.handlePageChange}
											firstPageText="First"
											lastPageText="Last"
											innerClass="pagination-list"
											linkClass="pagination-link"
											activeLinkClass="pagination-link is-current"
											className="is-directory-pagination"
										/>
									</div>
							</div>
						</div>
					</div>
        </section>
        <Footer />
    	</div>
		)
  }
}

export default Journalists;
