import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';
import Error from '../misc/Error';
import Alerts from './alerts';

import NoAuth from '../misc/noAuth';
import Loading from '../misc/Loading';

const Alert = ({ auth, subscription, status, plan }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
  {(!subscription || status !== "active") ? (
    <section>
      <div className="float-right mb-10">
        <NavLink className="heaven-page-cta-button" to="/pricing">
          <i className="fas fa-plus heaven-nav-button-icon"></i>New alert<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ color:'white',opacity:'.5' }} />
        </NavLink>
      </div>
      <div className="columns" style={{ clear:'both' }}>
        <div className="column is-half">
          <div className="heaven-content-card">
            <div className="heaven-content-card-header">
              <div className="columns">
                <div className="column is-three-quarters">
                  <div className="dropdown is-hoverable is-left">
                    <div className="dropdown-trigger">
                      <p className="heaven-card-header-title"><i className="fas fa-bell heaven-card-title-icon" />Tesla Mentions<i className="fas fa-chevron-down heaven-nav-button-icon-right" /></p>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                      <div className="dropdown-content heaven-nav-link-dropdown-content" style={{ marginTop: "5px" }}>
                        <NavLink className="dropdown-item heaven-nav-link-dropdown-item" to={'/pricing'}>
                          <i className="fas fa-edit heaven-page-dropdown-item-icon"/>Edit
                        </NavLink>
                        <div className="dropdown-item heaven-nav-link-dropdown-item-red">
                          <i className="fas fa-trash-alt heaven-page-dropdown-item-icon-red"/>Archive
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-one-quarters is-pulled-right">
                  <NavLink to="/pricing">
                    <input
                      type="checkbox"
                      name="Status"
                      className="switch is-rounded is-info"
                      checked="true"
                    />
                    <label className="heaven-card-activate-toggle" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="heaven-content-card-content">
              <p className="heaven-alert-content-line" style={{marginBottom:'12px'}}><span className="equal-icon-wrapper"><i className="fas fa-arrow-alt-circle-right heaven-nav-link-icon" /></span><b>Tesla</b></p>
              <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-city heaven-nav-link-icon" /></span>Los Angeles, San Francisco</p>
              <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-globe-americas heaven-nav-link-icon" /></span>United States</p>
              <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-tags heaven-nav-link-icon" /></span>Technology, Business</p>
              <p className="heaven-alert-content-line" style={{marginBottom:'0px'}}><span className="equal-icon-wrapper"><i className="fas fa-clipboard-list heaven-nav-link-icon" /></span>Business, Finance, Technology</p>
            </div>
            <div className="heaven-card-footer">
              <div className="columns heaven-card-footer-columns">
                <div className="column is-one-quarters" />
                <div className="column is-three-quarters">
                  <div className="heaven-card-footer-buttons-right">
                    <NavLink to={'/pricing'} className="heaven-card-cta-button is-pulled-right" style={{ padding:'6px 12px' }}>
                      See alerts<i className="fas fa-arrow-right heaven-nav-button-icon-right"/>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="column is-half">
          <div className="heaven-content-card">
            <div className="heaven-content-card-header">
              <div className="columns">
                <div className="column is-three-quarters">
                  <div className="dropdown is-hoverable is-left">
                    <div className="dropdown-trigger">
                      <p className="heaven-card-header-title"><i className="fas fa-bell heaven-card-title-icon" />Electric Cars<i className="fas fa-chevron-down heaven-nav-button-icon-right" /></p>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                      <div className="dropdown-content heaven-nav-link-dropdown-content" style={{ marginTop: "5px" }}>
                        <NavLink className="dropdown-item heaven-nav-link-dropdown-item" to={'/pricing'}>
                          <i className="fas fa-edit heaven-page-dropdown-item-icon"/>Edit
                        </NavLink>
                        <div className="dropdown-item heaven-nav-link-dropdown-item-red">
                          <i className="fas fa-trash-alt heaven-page-dropdown-item-icon-red"/>Archive
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-one-quarters is-pulled-right">
                  <NavLink to="/pricing">
                    <input
                      type="checkbox"
                      name="Status"
                      className="switch is-rounded is-info"
                      checked="false"
                    />
                    <label className="heaven-card-activate-toggle" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="heaven-content-card-content">
              <p className="heaven-alert-content-line" style={{marginBottom:'12px'}}><span className="equal-icon-wrapper"><i className="fas fa-arrow-alt-circle-right heaven-nav-link-icon" /></span><b>electric cars, tesla, volt</b></p>
              <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-city heaven-nav-link-icon" /></span>Anywhere</p>
              <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-globe-americas heaven-nav-link-icon" /></span>United States</p>
              <p className="heaven-alert-content-line"><span className="equal-icon-wrapper"><i className="fas fa-tags heaven-nav-link-icon" /></span>Technology, Cars</p>
              <p className="heaven-alert-content-line" style={{marginBottom:'0px'}}><span className="equal-icon-wrapper"><i className="fas fa-clipboard-list heaven-nav-link-icon" /></span>Any beat</p>
            </div>
            <div className="heaven-card-footer">
              <div className="columns heaven-card-footer-columns">
                <div className="column is-one-quarters" />
                <div className="column is-three-quarters">
                  <div className="heaven-card-footer-buttons-right">
                    <NavLink to={'/pricing'} className="heaven-card-cta-button is-pulled-right" style={{ padding:'6px 12px' }}>
                      See alerts<i className="fas fa-arrow-right heaven-nav-button-icon-right"/>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (subscription && status === "active") ? (
    <FirestoreCollection
      path={`alerts`}
      filter={['createdBy', '==', auth.uid]}
    >
    { ({ error, isLoading, data }) => {
      if (error) {
        return <Error error={error} />
      }
      else {
        return (
          <div>
            <div className="float-right mb-10">
              <NavLink className="heaven-card-page-white-button" to="/alert-dashboard/triggered/" style={{padding:'8px 12px'}}>
                See all alerts<i className="fas fa-arrow-right heaven-nav-button-icon-right"/>
              </NavLink>
              { ((subscription.plan > 3 && subscription.plan !== 10 && subscription.plan !== 20 && subscription.plan !== 30 && data.length < 100) || ((subscription.plan === 3 || subscription === 30) && data.length < 20) || ((subscription.plan === 2 || subscription.plan === 20) &&  data.length < 10)) ? (
                <NavLink className="heaven-page-cta-button" to="/alert/new" style={{padding:'8px 12px'}}>
                  <i className="fas fa-plus heaven-nav-button-icon"></i>New alert
                </NavLink>
              ) : (
                <NavLink className="heaven-page-cta-button" to="/pricing" style={{padding:'8px 12px'}}>
                  <i className="fas fa-plus heaven-nav-button-icon"></i>New alert<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ color:'white',opacity:'.5' }} />
                </NavLink>
              )}
            </div>
            <div style={{ clear:'both' }}>
              <Alerts uid={auth.uid} />
            </div>
          </div>
        )}
      }}
    </FirestoreCollection>
  ) : <span /> }
  </div>
)

export default Alert;
