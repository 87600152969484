import React, { Component } from 'react';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  RefinementList,
} from 'react-instantsearch-dom';
import searchClient from './algoliaCred';
import CustomHits from './customHits';
import CustomHitsPerPage from './customHitsPerPage';
import SearchResult from '../search/JournalistBulkResult';

import Loading from '../misc/Loading';
import NoAuth from '../misc/noAuth';
import HeavenSearchFilters from './journoSearchFilter';

class JournalistBulk extends Component {
  render() {
    const { auth, subscription, plan, status, lists } = this.props;
    return (
      <div className="max-w-6xl mx-auto py-10 px-4 sm:px-0 lg:px-0">
        {(!auth || status !== "active") ? (
          <NoAuth />
        ) : (subscription && subscription.tempStripePaymentTokenId) ? (
          <Loading />
        ) : (status === "active" && (plan === 3 || plan === 30 || plan === 4 || plan === 40 || plan === 5 || plan === 50 || plan === 6 || plan === 60 || plan === 7 || plan === 70)) ? (
          <section>
            <InstantSearch
              searchClient={searchClient}
              indexName="journalists-test"
            >
              <div className="columns">
                <div className="column is-one-thirds">
                  <HeavenSearchFilters area="Area" mediaOutlet="outlet_text"/>
                </div>
                <div className="column is-two-thirds">
                  <div className="mb-5 float-right hidden md:flex">
                    <CustomHitsPerPage
                      defaultRefinement={500}
                      items={[
                        { value: 500, label: '500 contacts' },
                        { value: 1000, label: '1,000 contacts' },
                      ]}
                    />
                    <Pagination
                      className="heaven-top-pagination"
                      translations={{
                        previous: 'Back',
                        next: 'Next',
                      }}
                      padding={0}
                      showLast={false}
                      showFirst={false}
                    />
                    <CustomHits dataType="journalist" />
                  </div>
                  <SearchBox
                    translations={{ placeholder: "Search through journalists…" }}
                    className="heaven-search-box"
                    submit={<img src="/submit.png" alt=""/>}
                    focusShortcuts={['s']}
                  />
                  <Hits hitComponent={SearchResult} />
                  <div className="heaven-bottom-pag-div">
                    <Pagination
                      className="heaven-bottom-pagination"
                      translations={{
                        previous: 'Back',
                        next: 'Next',
                      }}
                      padding={2}
                      showLast={false}
                      showFirst={false}
                    />
                  </div>
                </div>
              </div>
            </InstantSearch>
          </section>
        ) : (
          <NoAuth />
        )}
      </div>
    );
  }
}

export default JournalistBulk;
