import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirestoreCollection } from 'react-firestore';

import Domains from './domains';
import NoAuth from '../misc/noAuth';
import Loading from '../misc/Loading';
import Error from '../misc/Error';

const Domain = ({ auth, subscription, status, plan }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
  {!auth ? (
    <NoAuth />
  ) : (!subscription || status !== "active") ? (
    <section>
      <div className="columns">
        <div className="column is-one-thirds">
          <div className="is-dash-left-sequence-card-top">
            <p className="is-dash-left-sequence-card-title">Authenticate domains to send emails from your own email address</p>
            <p className="is-8 is-action-card-para">To send emails from your own email address when your sequences trigger, authenticate your domain here.</p>
            <p className="is-8 is-action-card-para">If you authenticate your domain––for example, “tesla.com”––you’ll be able to send emails from any tesla.com email address like elon@tesla.com or matt@tesla.com.</p>
          </div>
          <div className="is-dash-left-sequence-card-bottom">
            <p className="is-action-card-para"><NavLink to="/pricing" className="is-link">Upgrade today</NavLink> to send emails from your own email address</p>
          </div>
        </div>
        <div className="column is-two-thirds">
          <div className="heaven-top-buttons-div">
            <NavLink className="heaven-card-page-white-button" to="/pricing">
              <i className="fas fa-plus heaven-nav-button-icon"></i>New domain<i className="fas fa-lock heaven-nav-link-icon-lock" />
            </NavLink>
          </div>
          <div className="sequence-card-list">
            <Domains uid={auth.uid} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section>
      <div className="columns">
        <div className="column is-one-thirds">
          <div className="is-dash-left-sequence-card-top">
            <p className="is-dash-left-sequence-card-title">Authenticate domains to send emails from your own email address</p>
            <p className="is-8 is-action-card-para">To send emails from your own email address when your sequences trigger, authenticate your domain here.</p>
            <p className="is-8 is-action-card-para">If you authenticate your domain––for example, “tesla.com”––you’ll be able to send emails from any tesla.com email address like elon@tesla.com or matt@tesla.com.</p>
          </div>
          <div className="is-dash-left-sequence-card-bottom">
            {(plan === 5 || plan === 50) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Sequences</span> plan with a limit of 1 domain.<br /><br /><a href="/account" className="is-link">Upgrade here for higher limits</a></p>
            ) : (plan === 6 || plan === 60 || plan === 7 || plan === 70) ? (
              <p className="is-action-card-para">With your plan you can authenticate an unlimited number of domains.</p>
            ) : (plan < 5 || plan === 10 || plan === 20 || plan === 30 || plan === 40) ? (
              <p className="is-action-card-para"><a href="/account" className="is-link">Upgrade today</a> to send emails from your own email address</p>
            ) : <p className="is-action-card-para"><a href="/pricing" className="is-link">Subscribe today</a> to send emails from your own email address</p> }
          </div>
        </div>
        <FirestoreCollection
          path={`domains`}
          filter={['createdBy', '==', auth.uid]}
        >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (isLoading) {
            return <Loading />
          }
          else {
            return (
              <div className="column is-two-thirds">
                <div className="text-right">
                {(((plan === 2 || plan === 20 || plan === 3 || plan === 30 ||plan === 4 || plan === 40 || plan === 5 || plan === 50) && data.length < 1) || plan === 6 || plan === 60 || plan === 7 || plan === 70) ? (
                  <NavLink className="heaven-card-page-white-button" to="/domain/new">
                    <i className="fas fa-plus heaven-nav-button-icon"></i>New domain
                  </NavLink> ) : (
                  <NavLink className="heaven-card-page-white-button" to="/pricing">
                    <i className="fas fa-plus heaven-nav-button-icon"></i>New domain<i className="fas fa-lock heaven-nav-link-icon-lock" />
                  </NavLink>
                )}
                </div>
                <div className="sequence-card-list">
                  <Domains uid={auth.uid} />
                </div>
              </div>
            )
          }
        }}
        </FirestoreCollection>
      </div>
    </section>
  )}
  </div>
)

export default Domain;
