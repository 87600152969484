import Firebase from 'firebase/app';

const deleteEntry = async (listId, entryId, dataType, objectID) => {
	var docRef = Firebase.firestore().collection("lists").doc(listId);

	await removeEntry(listId, entryId);
	await updateIndex(docRef, dataType, objectID);
};

const removeEntry = (listId, entryId) => {
	console.log("Entering removeEntry function")

  return Firebase.firestore()
    .collection(`lists/${listId}/entries`)
    .doc(entryId)
    .delete()
    .catch( error => {
      alert(`Whoops, couldn't delete the list: ${error.message}`)
    })
};

const updateIndex = (docRef, dataType, objectID) => {

	return docRef.get().then(function(doc) {
    if (doc.exists) {
    	if (dataType === "journalist") {
    		var journalistIndex = doc.data().journalistIndex;
    		const index = journalistIndex.indexOf(objectID);
    		journalistIndex.splice(index, 1);
    		return docRef.update({
			    journalistIndex,
			    updatedOn: Firebase.firestore.Timestamp.now(),
				})
				.then(function() {
			    console.log("Document successfully updated!");
				})
				.catch(function(error) {
			    console.error("Error updating document: ", error);
				});
    	} else if (dataType === "podcast") {
    		var podcastIndex = doc.data().podcastIndex;
    		const index = podcastIndex.indexOf(objectID);
    		podcastIndex.splice(index, 1);
    		return docRef.update({
    			podcastIndex,
    			updatedOn: Firebase.firestore.Timestamp.now(),
    		})
				.then(function() {
			    console.log("Document successfully updated!");
				})
				.catch(function(error) {
			    console.error("Error updating document: ", error);
				});
    	} else if (dataType === "broadcaster") {
    		var broadcasterIndex = doc.data().broadcasterIndex;
    		const index = broadcasterIndex.indexOf(objectID);
    		broadcasterIndex.splice(index, 1);
    		return docRef.update({
    			broadcasterIndex,
    			updatedOn: Firebase.firestore.Timestamp.now(),
    		})
				.then(function() {
			    console.log("Document successfully updated!");
				})
				.catch(function(error) {
			    console.error("Error updating document: ", error);
				});
    	} else if (dataType === "influencer") {
    		var influencerIndex = doc.data().influencerIndex;
    		const index = influencerIndex.indexOf(objectID);
    		influencerIndex.splice(index, 1);
    		return docRef.update({
    			influencerIndex,
    			updatedOn: Firebase.firestore.Timestamp.now(),
    		})
				.then(function() {
			    console.log("Document successfully updated!");
				})
				.catch(function(error) {
			    console.error("Error updating document: ", error);
				});
    	}
    } else {
      console.log("No such document!");
    }
	}).catch(function(error) {
    console.log("Error getting document:", error);
	});
};

export default deleteEntry;
