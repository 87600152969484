import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForCreate } from './helpers/firestoreHelpers';

const submitJournalist = values => {
  values.slug = slugify(values.name, {lower: true})

  return Firebase.firestore()
    .collection(`submissions`)
    .add(prepareDocForCreate(values))
    .then( () => values)
    .catch( error => {
      alert(`Whoops, couldn't create the list: ${error.message}`)
    })
}

export default submitJournalist;
