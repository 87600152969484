import React from 'react';
import { NavLink } from 'react-router-dom';

import Placeholder from '../../assets/placeholder.png';

export default function PodcastAllData({ hit, plan }) {

  return (
	    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
	      <div className="flex-1 flex flex-col p-8">
	        <img className="w-24 h-24 flex-shrink-0 mx-auto bg-black rounded-full" src={hit.img_url ? hit.img_url : Placeholder} alt="" />
	        <h3 className="mt-3 text-gray-900 text-lg leading-5 font-large text-center">{hit.title}</h3>
	        <div className="mt-1 flex-grow flex flex-col justify-between text-center">
	          <div className="text-gray-500 text-md leading-5 text-center">
            	<p>{hit.title}</p>
              {(hit.itunes_url) ? (
                <a href={hit.itunes_url} target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">
                  <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
										<svg className="h-4 w-4 text-gray-800 mr-2" viewBox="0 0 20 20" fill="currentColor">
										  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
										  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
										</svg>                    
                    <span>iTune Link</span>
                  </div>
                </a>
              ) : <span /> }
	          </div>
	        </div>
	      </div>

	      {hit.twitterBio ? (
	      	<div className="py-5 lg:px-40 sm:px-6">
		      	<p className="text-md leading-5 font-medium text-gray-900">{hit.twitterBio}</p> 
		      </div>
	      ) : <span /> }

	      {hit.linkedin_url || hit.twitter_name || hit.facebook_url ? (
	      <div className="px-4 py-5 lg:px-40 sm:px-6">
	        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 text-center">
	        {hit.linkedin_url ? (
	          <div className="sm:col-span-1">
              <a href={hit.linkedin_url} target="_blank" rel="noopener noreferrer" className="inline-flex text-md leading-5 font-medium text-indigo-600">
                LinkedIn
                <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
	          </div>
	        ) : <span /> }
	        {hit.twitter_name ? (
	          <div className="sm:col-span-1">
              <a href={"https://twitter.com/" + hit.twitter_name}  className="inline-flex text-md leading-5 font-medium text-indigo-600" target="_blank" rel="noopener noreferrer">
                Twitter
                <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
	          </div>
	        ) : <span /> }
	        {hit.facebook_url ? (
	          <div className="sm:col-span-1">
              <a href={hit.facebook_url} className="inline-flex text-md leading-5 font-medium text-indigo-600" target="_blank" rel="noopener noreferrer">
                Facebook
                <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
	          </div>
	        ) : <span /> }
	        </div>
	      </div>
	      ) : <span /> }

  			{hit.email || hit.secondary_email || hit.phone ? (
	      <div className="py-5 lg:px-20 sm:px-6">
	        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 text-left">
	        	{hit.email ? (
	          <div className="sm:col-span-1">
	            <dt className="text-md leading-5 font-medium text-gray-600">
	              Email address
	            </dt>
	              <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
	                {hit.email}
	              </div>                          
	          </div>
	          ) : <span /> }
	          {hit.secondary_email ? (
	          	<div className="sm:col-span-1">
		            <dt className="text-md leading-5 font-medium text-gray-600">
		              Secondary Email
		            </dt>

		              <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
		               	{hit.secondary_email}
		              </div>

		          </div>
		        ) : <span /> }
		        {hit.phone ? (
	          <div className="sm:col-span-1">
	            <dt className="text-md leading-5 font-medium text-gray-600">
	              Phone number
	            </dt>
	            	{ (plan === 2 || plan === 20) ? (
		            	<NavLink className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800" to="/pricing">Upgrade to access</NavLink>
		            ) : (
		              <div className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
		                {hit.phone}
		              </div>
		            ) }
	          </div>
	          ) : <span /> }
	        </dl>
	      </div>
	      ) : <span /> }

        <div className="py-5 lg:px-20 sm:px-6 flex">
          {hit.rating && hit.numberOfRatings ? (
            <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
              <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
							  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
							</svg>
              <span>{hit.rating}/5 from {hit.numberOfRatings} ratings</span>
            </div>
          ) : <span /> }
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
            <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
						  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
						</svg>
            <span>First episode: {hit.earliest_pub_date}</span>
          </div>
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
	          <svg className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
						  <path fillRule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clipRule="evenodd" />
						</svg>
            <span>{hit.episode_count} episodes</span>
          </div>
        </div>
        <div className="journalist-card-tags-wrapper" style={{display:'flex'}}>
        {hit.twitterFollowersCount ? (
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
            <i className="fab fa-twitter heaven-nav-link-icon"/>
            <span>{hit.twitterFollowersCount} Twitter followers</span>
          </div>
        ) : <span /> }
        {hit.twitterListedCount ? (
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
            <i className="fab fa-twitter heaven-nav-link-icon"/>
            <span>Included in {hit.twitterListedCount} Twitter lists</span>
          </div>
        ) : <span /> }
        </div>
        <div className="journalist-card-tags-wrapper" style={{display:'flex'}}>
        {hit.twitterFollowingCount ? (
          <div className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
            <i className="fab fa-twitter heaven-nav-link-icon"/>
            <span>Following {hit.twitterFollowingCount} Twitter users</span>
          </div>
        ) : <span /> }
        </div>

  			{(hit.tags && hit.tags.length > 0) ? (
	      <div className="bg-white py-5 border-b border-gray-200 lg:px-20 sm:px-6">
				  <h3 className="text-md leading-5 font-medium text-gray-600">
				    Topics
				  </h3>
          <dd className="mt-1 text-sm leading-5 text-gray-900">
	          {(hit.tags && Array.isArray(hit.tags)) ? (
	            hit.tags.map((tag, index) =>
                <div key={index} className="inline-flex mx-1 items-left mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800">
									<svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
									</svg>            
									<span>{tag}</span>
                </div>
	            )
	          ) : <span /> }
          </dd>
	      </div>
	      ) : <span /> }

	      {(hit.industry && hit.industry.length > 0) ? (
	      <div className="bg-white py-5 border-b border-gray-200 lg:px-20 sm:px-6">
				  <h3 className="text-md leading-5 font-medium text-gray-600">
				    Beats
				  </h3>
          <dd className="mt-1 text-sm leading-5 text-gray-900">
	          {(hit.industry && Array.isArray(hit.industry)) ? (
	            hit.industry.map((industry, index) =>
                <div key={index} className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-orange-100 text-orange-800">
									<svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
									</svg>
                  <span>{industry}</span>
                </div>
	            )
	          ) : <span /> }
          </dd>                       
	      </div>
	      ) : <span /> }
	    </div>
	);
}
