import axios from 'axios';
import { getAccessToken } from './auth.js';

const BASE_URL = process.env.REACT_APP_PRESSHUNT_API_DOMAIN;

export const migratePassword = (firebaseId, email, password) => axios.post(`${BASE_URL}/api/v1/users/resetPasswordByMigrate`, {
  refFirebaseId: firebaseId,
  email,
  password,
});

export const migrateAccount = (firebaseId, email, password, referral) => axios.post(`${BASE_URL}/api/v1/users`, {
  refFirebaseId: firebaseId,
  email,
  password,
  referral,
});

export const getMailAuthToken = async ({ account, password }) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'password');
  params.append('username', account);
  params.append('password', password);
  params.append('client_id', 'presshunt');
  params.append('client_secret', 'fskefgtarwdbawydrawpdpaiuiawdtg');

  return axios.post(`${BASE_URL}/api/v1/users/token`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const getConnectMailUri = async (provider) => {
  const token = await getAccessToken();

  return axios.post(
    `${BASE_URL}/api/v1/users/getConnectMailUri`,
    {
      callbackUrl: `${window.location.origin}/mail/callback`,
      provider,
    },
    // { callbackUrl: 'https://www.presshunt.dev/mail/callback' },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const connectMail = async (code) => {
  const token = await getAccessToken();

  return axios.post(
    `${BASE_URL}/api/v1/users/connectMail`,
    {
      code,
      syncAtTimestamp: 1609459200,
    },
    // { callbackUrl: 'https://www.presshunt.dev/mail/callback' },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const checkConnectMail = async () => {
  const token = await getAccessToken();

  return axios.get(`${BASE_URL}/api/v1/users/connectMail`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getMailsInbox = async ({ code, offset, limit }) => {
  const token = await getAccessToken();

  return axios.get(`${BASE_URL}/api/v1/mails/inbox`, {
    params: { code, offset, limit },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getMailsOutbox = async ({ code, offset, limit }) => {
  const token = await getAccessToken();

  return axios.get(`${BASE_URL}/api/v1/mails/sent`, {
    params: { code, offset, limit },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const postNewMail = async ({
  subject, to, body, sent_at,
}) => {
  const token = await getAccessToken();

  return axios.post(
    `${BASE_URL}/api/v1/mails/sentMany`,
    // { ...data },
    {
      subject,
      to,
      body,
      sent_at,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const emailLogOut = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('accessTokenExpiresAt');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenExpiresAtrefreshTokenExpiresAt');
};

export const setMailReadByThreadId = async (thread_id) => {
  const token = await getAccessToken();

  return axios.post(
    `${BASE_URL}/api/v1/mails/${thread_id}/read`,
    {
      //
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const setMailsArchive = async (thread_ids) => {
  const token = await getAccessToken();

  return axios.delete(`${BASE_URL}/api/v1/mails`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      thread_ids,
    },
  });
};

export const postReplyMail = async ({
  to, thread_id, subject, body,
}) => {
  const token = await getAccessToken();

  return axios.post(
    `${BASE_URL}/api/v1/mails/sent`,
    {
      to,
      reply_to: to,
      reply_to_message_id: thread_id,
      subject,
      body,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const getMailsScheduled = async ({ offset, limit }) => {
  const token = await getAccessToken();

  return axios.get(`${BASE_URL}/api/v1/campaigns`, {
    params: {
      offset,
      limit,
      status: 'waiting',
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getCampaigns = async ({ offset, limit }) => {
  const token = await getAccessToken();

  return axios.get(`${BASE_URL}/api/v1/campaigns`, {
    params: {
      offset,
      limit,
      status: 'finish',
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteScheduledMails = async (mail_ids) => {
  const token = await getAccessToken();

  for (const mail_id of mail_ids) {
    await axios.delete(`${BASE_URL}/api/v1/campaigns/${mail_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return true;
};

export const getReAuthMessage = () => {
  return 'You have been signed out. Please reauthenticate below with your email provider to continue sending campaigns.';
}

