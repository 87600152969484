import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForCreate } from './helpers/firestoreHelpers';

const createCampaign = values => {
	values.slug = slugify(values.CampaignName, { lower: true })
	values.domain = "pr." + values.SendAs.split("@").pop();
  values.RecipientVariables = JSON.stringify(values.RecipientVariables);
  return Firebase.firestore()
    .collection(`campaigns`)
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch( error => {
      alert(`Sorry, couldn't create the campaign: ${error.message}`)
    })
}

export default createCampaign;
