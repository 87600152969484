import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForUpdate } from './helpers/firestoreHelpers';

const updateCampaign = (campaignId, values) => {
	values.slug = slugify(values.CampaignName, { lower: true })
	values.domain = "pr." + values.SendAs.split("@").pop();
  values.RecipientVariables = JSON.stringify(values.RecipientVariables);
  return Firebase.firestore()
    .collection('campaigns')
    .doc(campaignId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Sorry, couldn't edit this campaign: ${error.message}`)
    })
}

export default updateCampaign;
