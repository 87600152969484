import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForCreate } from './helpers/firestoreHelpers';

const createList = values => {
  values.slug = slugify(values.name, { lower: true })
  values.includeCounter = true
  values.infiniteList = true
  values.emailIndex = [];
  return Firebase.firestore()
    .collection(`lists`)
    .add(prepareDocForCreate(values))
    .then(docRef => {
      return docRef.id;
    })
    .catch( error => {
      alert(`Whoops, couldn't create the list: ${error.message}`)
    })
}

export default createList;
