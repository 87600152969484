import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateAlert = (alertId, values) => {
	if (values.alertName) {
		values.slug = slugify(values.alertName, {lower: true})
	}
  return Firebase.firestore()
    .collection('alerts')
    .doc(alertId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Sorry, couldn't edit this alert: ${error.message}`)
    })
}

export default updateAlert;
