import Firebase from 'firebase/app';
import { emailLogOut } from '../api/mail';

const BASE_URL = process.env.REACT_APP_PRESSHUNT_AUTH_SERVICES_BASE_DOMAIN;

const logOut = () => {
  emailLogOut();
  Firebase.auth().signOut();
  window.location.href = (`${BASE_URL}/api/logout?redirectUrl=${window.location.href}`);
};

export default logOut;
