// a generic error page to show whenever something goes wrong in other views

import React from 'react'

import {
  InternalLink,
} from '../../styles/links'

const Error = ({error}) => (
  <section className="section">
    <div className="container">
      <div className="is-sign-in-up has-text-centered">
        <h2 className="title">Whoops</h2>
        <h2 className="subtitle">{`Sorry, something went wrong. We're looking into it.`}</h2>
        <div style={{fontFamily: 'monospace'}}>{error ? error.message : null}</div>
        <InternalLink to="/" className="is-pricing-button is-centered">Go home</InternalLink>
      </div>
    </div>
  </section>
)

export default Error
