import Firebase from 'firebase/app';
import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const activateDomain = (domainId, values) => {
  return Firebase.firestore()
    .collection('domains')
    .doc(domainId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Sorry, couldn't edit the list: ${error.message}`)
    })
}

export default activateDomain;
