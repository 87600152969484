import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { auth } from '../../api/firebase';
import { getMailAuthToken, migratePassword } from '../../api/mail';
import { postIdTokenToSessionLogin } from '../../api/firebaseAuthServices';
import Logo from '../../assets/press-hunt-full-logo-20-dark.svg';

export default function HookSignIn(props) {
  const { register, errors, handleSubmit } = useForm();
  const [error, setError] = useState('');

  const getCookie = (name) => {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  };

  const onSubmit = (data) => {
    auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then(async () => {
        await migratePassword(auth.currentUser.uid, data.email, data.password);
        await getMailAuthToken({
          account: data.email,
          password: data.password,
        })
          .then((res) => {
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('accessTokenExpiresAt', res.data.accessTokenExpiresAt);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('refreshTokenExpiresAt', res.data.refreshTokenExpiresAt);
          })
          .catch(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('accessTokenExpiresAt');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshTokenExpiresAtrefreshTokenExpiresAt');
          });
      })
      .then(() => {
        // Set session cookie
        auth.currentUser.getIdToken()
          .then((idToken) => {
            const csrfToken = getCookie('csrfToken');
            return postIdTokenToSessionLogin(idToken, csrfToken)
              .then(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectUrl = urlParams.get('redirectUrl');
                if (redirectUrl) {
                  window.location.assign(`/api/exchangeAuthToken?redirectUrl=${redirectUrl}`);
                  return;
                }

                // Redirect to profile on success.
                props.history.push('/dashboard');
              }, (error) => {
                // Refresh page on error.
                // In all cases, client side state should be lost due to in-memory
                // persistence.
                setError(error);
              });
          });
      }, (error) => {
        // Refresh page on error.
        // In all cases, client side state should be lost due to in-memory
        // persistence.
        setError(error);
      });
    // .then(() => {
    //   props.history.push('/dashboard');
    // })
    // .catch((error) => {
    //   setError(error);
    // });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
        <img src={Logo} className="mb-8 w-40 text-center m-auto" />
        <h2 className="mt-4 text-xl sm:text-2xl leading-9 font-extrabold text-gray-900">
          Sign into your Press Hunt account
        </h2>
        <h3 className="font-normal text-gray-600 mt-3 text-xs sm:text-sm px-4 sm:px-0">
          <span>If you don't have an account already, </span>
          <NavLink
            to="/signup"
            className="font-semibold text-blue-1000 hover:opacity-50 hover:text-blue-1000 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            sign up here
          </NavLink>
        </h3>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white p-10 shadow-sm sm:rounded-lg sm:px-10 border border-gray-200">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="text-sm font-bold leading-5 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-1 rounded-lg shadow-sm">
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="elon@tesla.com"
                  ref={register({
                    required: 'This input is required.',
                    minLength: {
                      value: 9,
                      message: 'This input must exceed 8 characters',
                    },
                    maxLength: {
                      value: 129,
                      message: 'This input exceeds 128 characters',
                    },
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-200 rounded-lg placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.email && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div className="mt-4">
              <label
                htmlFor="password"
                className="text-sm font-bold leading-5 text-gray-900"
              >
                Password
              </label>
              <div className="mt-1 rounded-lg shadow-sm">
                <input
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Password"
                  ref={register({
                    required: 'This input is required.',
                    maxLength: {
                      value: 129,
                      message: 'This input exceeds 128 characters',
                    },
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-200 rounded-lg placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-gray-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.password && (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  {errors.password.message}
                </p>
              )}
            </div>

            <div className="mt-8">
              <span className="block w-full rounded-lg shadow-sm">
                <button
                  type="submit"
                  className="shadow-sm w-full flex justify-center py-2 px-4 border border-transparent text-md font-bold rounded-lg text-white bg-blue-1000 hover:opacity-75 focus:shadow-outline-indigo active:bg-blue-1000 transition duration-150 ease-in-out"
                >
                  Sign in
                </button>
              </span>
              {console.log(error)}
              {error && (
                <p className="mt-2 text-sm text-red-600" id="error">
                  {error.message}
                </p>
              )}
            </div>
            <div className="mt-5 flex items-center justify-between mx-auto text-center">
              <div className="text-sm leading-5 mx-auto text-center">
                <NavLink
                  to="passwordforget"
                  className="font-bold text-gray-600 hover:opacity-50 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
