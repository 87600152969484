import React from 'react';
import Placeholder from '../../assets/placeholder.png';

const TrialSearchResult = ({ hit }) => (
  <div className="container search-list is-centered">
    <div className="heaven-journalist-card journalist-card-dash">
      <div className="journalist-card-content">
        <div className="journalist-card-content-header">
          <div className="heaven-contact-card-location">
            <img alt="" src={hit.country_flag_url} className="heaven-contact-card-flag" />
            {hit.avatar ? (
              <div>
                <img src={hit.avatar.replace("_normal","")} alt="" className="rounded-full w-16 h-16" />
              </div>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" style={{ color: "#eff3f7" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            )}
          </div>
          <div className="py-2 px-4">
            <div className="journalist-card-name-and-link">
              <p className="journalist-card-name">{hit.name}</p>
              {/*checks to see if there's a journalist url*/}
              {(hit.journalist_url) ? (
                <a href={hit.journalist_url} target="_blank" rel="noopener noreferrer">
                  <div className="heaven-contact-card-url-tag">
                    <i className="fas fa-external-link-alt journalist-card-url-link" />
                    <p className="heaven-content-card-url-text">{hit.email_domain}</p>
                  </div>
                </a>
              ) : <span /> }
            </div>
            {/*checks to see if there is outlet_text conditional*/}
            <div className="journalist-card-type-outlet">
            {(hit.outlet_text && Array.isArray(hit.outlet_text) && hit.outlet_text.length > 0 && hit.kind_of_journalist) ?
              <p className="journalist-card-type-outlet">
                <span className="journalist-card-kind">{hit.kind_of_journalist}</span> at { hit.outlet_text.map((outlet, index) => <span key={index} className="journalist-card-outlet">{index > 0 && ", "}{outlet}</span>)}
              </p> : (hit.mediatype === "" && hit.kind_of_journalist !== "False") ? <p>{hit.kind_of_journalist}</p>
                  : <p>{hit.mediatype}</p> }
            </div>
          </div>
        </div>
        {/*twitter bio*/}
        <div className="journalist-card-bio">
          {hit.twitter_bio ? <p style={{marginBottom:'8px'}}>{hit.twitter_bio}</p> : <span /> }
        </div>
      </div>
      {(hit.industry=="" && hit.tags=="") ? (<span />
      ) : <div className="heaven-contact-card-tags-full">
        <div className="journalist-card-tags-wrapper">
          <div className="heaven-contact-card-tags">
          {(hit.name.split(" ")[0]!=="") ?(
            <p className="heaven-contact-card-subheader">{hit.name.split(" ")[0]}'s <span className="heaven-beats-span"><i className="fas fa-clipboard-list heaven-beats-tag"/>Beats</span> and <span className="heaven-beats-span"><i className="fas fa-tags heaven-beats-tag"/>Topics</span></p>
          ) : <p className="heaven-contact-card-subheader">{hit.name}'s <span className="heaven-beats-span"><i className="fas fa-clipboard-list heaven-beats-tag"/>Beats</span> and <span className="heaven-beats-span"><i className="fas fa-tags heaven-beats-tag"/>Topics</span></p> }
          {/*industry conditional*/}
          {(hit.industry && Array.isArray(hit.industry)) ? (
            hit.industry.map((industry, index) =>
              <div key={index} className="journalist-card-tag-wrapper">
                <div className="heaven-contact-card-tag">
                  <i className="fas fa-clipboard-list heaven-nav-link-icon"/>
                  <p className="journalist-card-tag-text">{industry}</p>
                </div>
              </div>
            )
          ) : <span /> }
          {/*tags conditional*/}
          {(hit.tags && Array.isArray(hit.tags)) ? (
            hit.tags.map((tag, index) =>
              <div key={index} className="journalist-card-tag-wrapper">
                <div className="heaven-contact-card-tag">
                  <i className="fas fa-tags heaven-nav-link-icon"/>
                  <p className="journalist-card-tag-text">{tag}</p>
                </div>
              </div>
            )
          ) : <span /> }
          </div>
        </div>
      </div> }

      <div className="heaven-card-footer">
        <div className="columns heaven-card-footer-columns">
          <div className="column is-one-quarters">
            <div className="heaven-card-footer-buttons">
              <div className="dropdown is-hoverable is-left">
                <div className="dropdown-trigger">
                  <a className="heaven-card-white-button is-pulled-left"><i className="fas fa-address-card heaven-nav-button-icon"/>Contact<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></a>
                </div>
                <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                  <ul className="dropdown-content heaven-card-dropdown-content">
                    {(hit.email) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-envelope heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.secondary_email) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-envelope heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.phone) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-phone heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.twitter_name) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.linkedin_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.facebook_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.instagram_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.youtube_url) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                    {(hit.website) ? (
                      <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-link heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                      </a>
                    ) : <span /> }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-three-quarters">
            <div className="heaven-card-footer-buttons-right">
              <a className="heaven-card-cta-button is-pulled-right" href="/pricing" target="_blank" rel="noopener noreferrer" style={{ padding:'8px 12px' }}>
                <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ color:'white',opacity:'.5' }} />
              </a>
              {(hit.secondary_email) ? (
                <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fas fa-envelope heaven-nav-button-icon"/>Email<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
              ) : (hit.email) ? (
                <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fas fa-envelope heaven-nav-button-icon"/>Email<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
              ) : (!hit.email && !hit.secondary_email && hit.twitter_name) ? (
                <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fab fa-twitter heaven-nav-button-icon"/>Tweet<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
              ) : <span /> }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TrialSearchResult;
