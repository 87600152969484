import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForCreate } from '../helpers/firestoreHelpers';

const createSequence = values => {
	values.slug = slugify(values.SequenceName, {lower: true})
  return Firebase.firestore()
    .collection(`sequences`)
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch( error => {
      alert(`Sorry, couldn't create the sequence: ${error.message}`)
    })
}

export default createSequence;
