import React from 'react';
import { NavLink } from 'react-router-dom';
import { connectHitsPerPage } from 'react-instantsearch-dom';

const HitsPerPage = ({ items, currentRefinement, refine, createURL, journalist, subscription }) => (
  <div className="dropdown is-hoverable is-right">
    <div className="dropdown-trigger">
      <a className="heaven-card-page-white-button" aria-haspopup="true" aria-controls="dropdown-menu4">
        <i className="fas fa-user-friends heaven-nav-button-icon" aria-hidden="true"></i>
        {currentRefinement} contacts
        <i className="fas fa-chevron-down heaven-nav-button-icon-right" aria-hidden="true"></i>
      </a>
    </div>
    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
      <ul className="dropdown-content heaven-dropdown-content">
        {items.map(item => (
          <div key={item.value} className="dropdown-item heaven-page-dropdown-item-no-icon">
            <li>
              <a
                href={createURL(item.value)}
                style={{ color: item.isRefined ? '#003dff' : '' }}
                onClick={event => {
                  event.preventDefault();
                  refine(item.value);
                }}
              >
              <i className="fas fa-user-friends heaven-page-dropdown-item-icon"></i>
              {item.label}
              </a>
            </li>
          </div>
        ))}
        {/* @aaron when a user is on anything below premium, put a red lock icon on "show 1000 contacts" and link to /pricing instead of /bulk. also, when user is on /journalist-dashboard/bulk, change this button to display "show less contacts" and link back to vanilla /journalist-dashboard */}
        {(journalist && (!subscription || subscription.plan === 1 || subscription.plan === 2 || subscription.plan === 10 || subscription.plan === 20)) ? (
          <div className="heaven-dropdown-content-second-half">
            <NavLink className="heaven-page-dropdown-button" to="/pricing"><i className="fas fa-users heaven-nav-button-icon" />Show 1,000 contacts<i className="fas fa-lock heaven-nav-link-icon-lock" /></NavLink>
          </div>
        ) : (journalist && subscription && (subscription.plan === 3 || subscription.plan === 30 || subscription.plan === 4 || subscription.plan === 40 || subscription.plan === 5 || subscription.plan === 50 || subscription.plan === 6 || subscription.plan === 60 || subscription.plan === 7 || subscription.plan === 70)) ? (
          <div className="heaven-dropdown-content-second-half">
            <NavLink className="heaven-page-dropdown-button" to="/journalist-dashboard/bulk"><i className="fas fa-users heaven-nav-button-icon" />Show 1,000 contacts</NavLink>
          </div>
        ) : <span /> }
      </ul>
    </div>
  </div>
);

const CustomHitsPerPage = connectHitsPerPage(HitsPerPage);
export default CustomHitsPerPage;
