import React from 'react';
import Firebase from 'firebase/app';

class CampaignAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      domains: [],
    };
    this.getDocument = this.getDocument.bind(this);
  }

  componentDidMount() {
    this.getDocument();
  }

  getDocument() {
    this.setState({ loading: true });
    var db = Firebase.firestore();
    db.collection('domainStats')
      .where('createdBy', '==', this.props.auth.uid)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let domains = [];
          snapshot.forEach(doc =>
            domains.push({ ...doc.data(), id: doc.id }),
          );
          this.setState({
            domains,
            loading: false,
          });
        } else {
          this.setState({ domains: null, loading: false });
        }
      });
  }

  render() {
    return (
      <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
        <div className="grid-list">
          <div className="grid-list-top">
            Which domain would you like to see sending stats for?
          </div>
          {this.state.domains ? this.state.domains.map((entry, index) => {
            return (
              <div key={index} className="grid-list-row" onClick={ () => this.props.history.push("/domain/stats/" + entry.id)}>
                <i className="fas fa-link grid-list-row-icon" />
                <span className="grid-list-row-text">{entry.userDomain.replace("pr.","")}</span>
                <i className="fas fa-arrow-right grid-list-row-icon-right" />
              </div>
          )}) : <span /> }
          <div className="grid-list-bottom" />
        </div>
      </div>
    )
  }
}

export default CampaignAnalytics;
