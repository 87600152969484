import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import { NavLink } from 'react-router-dom';
import Error from '../misc/Error';
import updateAlert from '../../actions/alert/updateAlert';
import MailIcon from '../../assets/mail.svg';
import TrashIcon from '../../assets/trash-2.svg';
import EditIcon from '../../assets/edit (1).svg';
import MonitorIcon from '../../assets/sequence-monitoring.svg';
import KeywordIcon from '../../assets/sequence-keyword.svg';
import TagsIcon from '../../assets/sequence-tags.svg';
import SentimentIcon from '../../assets/sequence-sentiment.svg';

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Status: false,
    }
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  handleToggleChange = (id, Status) => {
    const values = { Status: !Status }
    updateAlert(id, values)
    .then(() => {
      this.setState(prevState => ({
        check: !prevState.check,
        Status: !prevState.Status,
      }))
    })
    .catch(error => console.log(error))
  }

  handleArchive = id => {
    const values = { archived: true }
    updateAlert(id, values)
  }

  render() {
    return (
      <FirestoreCollection
        path={`alerts`}
        sort="createdOn:desc"
        filter={['createdBy', '==', this.props.uid]}
      >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (!isLoading && data.length === 0) {
            return (
              <div className="is-sequence-card-empty">
                <div className="sequence-card-header">
                  <div className="sequence-card-header-icon-and-title">
                    <p className="sequence-card-header-title" style={{opacity:'.5'}}><i className="fas fa-bell heaven-card-title-icon" />Example alert for Tesla</p>
                  </div>
                </div>
                <div className="sequence-card-content" style={{opacity:'.5'}}>
                  <p className="sequence-card-line"><img src={MonitorIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Monitoring <span className="is-strong">tweets, articles</span></span></p>
                  <p className="sequence-card-line"><img src={KeywordIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Triggers on keywords: <span className="is-strong">Tesla</span></span></p>
                  <p className="sequence-card-line"><img src={TagsIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Only journalists who cover <span className="is-strong">technology, business</span> at <span className="is-strong">first</span> tier outlets</span></p>
                  <p className="sequence-card-line"><img src={SentimentIcon} className="sequence-card-content-icon"></img><span className="sequence-card-content-text">Only in response to <span className="is-strong">positive</span> sentiment</span></p>
                </div>
                <div className="sequence-card-footer">
                  <div className="sequence-card-footer-button">
                    <input
                      type="checkbox"
                      name="active"
                      className="switch is-rounded is-info"
                    />
                    <label className="sequence-card-footer-button-activate" style={{opacity:'.5', cursor:'default'}}><span className="sequence-card-footer-button-activate-text">Activate</span></label>
                  </div>
                  <div className="sequence-card-footer-button">
                    <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img src={EditIcon} className="sequence-card-icon-standard"></img><span className="sequence-card-footer-button-standard-text">Edit</span></div>
                  </div>
                  <div className="sequence-card-footer-button">
                    <div className="sequence-card-footer-button-archive" style={{opacity:'.5', cursor:'default'}}><img src={TrashIcon} className="sequence-card-icon-archive"></img><span className="sequence-card-footer-button-archive-text">Archive</span></div>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div>
              {data.map(alert => (
                <div key={alert.id}>
                { alert.archived ? <span /> : <div className="is-sequence-card">
                    <div className="sequence-card-header">
                      <div className="sequence-card-header-icon-and-title">
                        <p className="sequence-card-header-title"><i className="fas fa-bell heaven-card-title-icon" />{alert.alertName}</p>
                      </div>
                    </div>
                    <div className="sequence-card-content">
                      {/*<p className="sequence-card-line">
                        <img src={MonitorIcon} className="sequence-card-content-icon" />
                        <span className="sequence-card-content-text">Monitoring <span className="is-strong">{alert.TargetChannel}</span> from journalists in: <span className="is-strong">{alert.Areas ? alert.Areas.map(obj => obj.value).join(', ') : "" } { alert.Countries ? alert.Countries.map(obj => obj.value).join(', ') : "" }</span></span>
                      </p>*/}
                      <p className="sequence-card-line mb-4">
                        <img src={KeywordIcon} className="sequence-card-content-icon"/>
                        <span className="sequence-card-content-text">Triggers on keywords: <span className="is-strong">{alert.TargetKeywords}</span></span>
                      </p>
                      {/*<p className="sequence-card-line">
                        <img src={TagsIcon} className="sequence-card-content-icon" />
                        <span className="sequence-card-content-text">Only journalists who cover <span className="is-strong">{ alert.TargetTags ? alert.TargetTags.map(obj => obj.value).join(', ') : "" }</span> topics and <span className="is-strong">{ alert.Beats ? alert.Beats.map(obj => obj.value).join(', ') : "" }</span> beats at outlets with
                          <span className="is-strong"> {alert.TargetOutletTier}</span> reach
                        </span>
                      </p>*/}
                      {/*<p className="sequence-card-line">
                        <img src={SentimentIcon} className="sequence-card-content-icon" />
                        <span className="sequence-card-content-text">Triggers on <span className="is-strong">{alert.Sentiment}</span> sentiment</span>
                      </p>*/}
                    </div>
                    <div className="sequence-card-footer">
                      <div className="sequence-card-footer-button">
                        <input
                          id={alert.id}
                          type="checkbox"
                          name="Status"
                          className="switch is-rounded is-info"
                          checked={alert.Status}
                          onChange={() =>
                            this.handleToggleChange(alert.id, alert.Status)
                          }
                        />
                        <label
                          htmlFor={alert.id}
                          className="sequence-card-footer-button-activate"
                        >
                          <span className="sequence-card-footer-button-activate-text">Activate</span>
                        </label>
                      </div>
                      <div className="sequence-card-footer-button">
                        <NavLink
                          to={`alert/${alert.slug}/edit`}
                          className="sequence-card-footer-button-standard">
                          <img src={EditIcon} className="sequence-card-icon-standard" />
                          <span className="sequence-card-footer-button-standard-text">Edit</span>
                        </NavLink>
                      </div>
                      <div className="sequence-card-footer-button">
                        <div
                          className="sequence-card-footer-button-archive"
                          onClick={ () => this.handleArchive(alert.id)}
                        >
                          <img src={TrashIcon} className="sequence-card-icon-archive" />
                          <span className="sequence-card-footer-button-archive-text">Archive</span>
                        </div>
                      </div>
                      <div className="sequence-card-footer-button">
                        <NavLink to={`alert-dashboard/triggered/${alert.slug}`} >
                          <span style={{ color:"#003dff" }} className="sequence-card-footer-button-standard-text">See alerts</span><i className="fas fa-arrow-right heaven-nav-button-icon-right"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                }
                </div>
              ))}
            </div>
          )
        }}
      </FirestoreCollection>
    )
  }
}

export default Alert;
