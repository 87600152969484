import React from 'react';
import FirebaseAuth from './FirebaseAuth';
import { FirestoreCollection } from 'react-firestore';

const FireflySubscription = ({children}) => (
  <FirebaseAuth>
    { ({isLoading, error, auth}) => {

      if (error || isLoading || !auth) {
        return children({
          error,
          isLoading,
          subscription: null,
        })
      }

      return <FirestoreCollection
        path="subscriptions"
        filter={['user', '==', auth.uid]}
      >
        { ({error, isLoading, data}) => {
          return children({
            error,
            isLoading,
            auth: auth,
            subscription: data.length > 0 ? data[0] : null,
            status: data.length > 0 ? data[0].stripeSubscriptionStatus : null,
            plan: data.length > 0 ? data[0].plan : null,

          })
        }}
      </FirestoreCollection>

    }}
  </FirebaseAuth>
)

export default FireflySubscription
