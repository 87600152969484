import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import { Link } from 'react-router-dom';
import Error from '../misc/Error';
import updateCampaign from '../../actions/updateCampaign';
import deleteCampaign from '../../actions/deleteCampaign';

import Loading from '../misc/Loading';
import MailIcon from '../../assets/mail.svg';
import TrashIcon from '../../assets/trash-2.svg';
import EditIcon from '../../assets/edit (1).svg';
import SendIcon from '../../assets/grey-send.svg';

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Status: false,
    }
    this.handleToggleChange = this.handleToggleChange.bind(this);
  }

  handleToggleChange = (id, Status) => {
    const values = { Status: !Status }
    updateCampaign(id, values)
    .then(() => {
      this.setState(prevState => ({
        check: !prevState.check,
        Status: !prevState.Status,
      }))
    })
    .catch(error => console.log(error))
  }

  handleDelete = id => {
    deleteCampaign(id)
  }

  render() {
    return (
      <FirestoreCollection
        path={`campaigns`}
        sort="createdOn:desc"
        filter={['createdBy', '==', this.props.uid]}
      >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (isLoading) {
            return <Loading />
          }
          if (!isLoading && data.length === 0) {
            return (
              <div className="is-sequence-card-empty">
                <div className="sequence-card-header">
                  <div className="sequence-card-header-icon-and-title">
                    <img alt="" src={MailIcon} className="sequence-card-icon-header" style={{opacity:'.5'}}></img>
                    <p className="sequence-card-header-title" style={{opacity:'.5'}}>Example campaign for Tesla</p>
                  </div>
                </div>
                <div className="sequence-card-email">
                  <div className="sequence-card-email-subject">
                    <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>SUBJECT</p>
                    <p className="sequence-card-email-subject-text" style={{opacity:'.5'}}>Thanks for mentioning us! Here's our press kit…</p>
                  </div>
                  <div className="sequence-card-email-body">
                    <p className="sequence-card-email-label-text" style={{opacity:'.5'}}>BODY</p>
                    <p className="sequence-card-email-text" style={{opacity:'.5'}}>Hi %recipient.first%! You can find our press kit here…</p>
                  </div>
                </div>
                <div className="sequence-card-footer">
                    <div className="sequence-card-footer-button">
                      <input
                        type="checkbox"
                        name="active"
                        className="switch is-rounded is-info"
                      />
                      <label className="sequence-card-footer-button-activate" style={{opacity:'.5', cursor:'default'}}><span className="sequence-card-footer-button-activate-text">Activate</span></label>
                    </div>
                    <div className="sequence-card-footer-button">
                      <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img alt="" src={EditIcon} className="sequence-card-icon-standard"></img><span className="sequence-card-footer-button-standard-text">Edit</span></div>
                    </div>
                    <div className="sequence-card-footer-button">
                      <div className="sequence-card-footer-button-archive" style={{opacity:'.5', cursor:'default'}}><img alt="" src={TrashIcon} className="sequence-card-icon-archive"></img><span className="sequence-card-footer-button-archive-text">Archive</span></div>
                    </div>
                </div>
              </div>
            )
          }
          return (
            <div>
              {data.map(campaign => (
                <div key={campaign.id}>
                { campaign.archived ? <span /> : <div className="is-sequence-card">
                    <div className="sequence-card-header">
                      <div className="sequence-card-header-icon-and-title">
                        <img alt="" src={MailIcon} className="sequence-card-icon-header"></img>
                        <p className="sequence-card-header-title">{campaign.CampaignName}</p>
                      </div>
                    </div>
                    <div className="sequence-card-email">
                      <div className="sequence-card-email-subject">
                        <p className="sequence-card-email-label-text">SUBJECT</p>
                        <p className="sequence-card-email-subject-text">{campaign.SubjectLine.substring(0,70)}</p>
                      </div>
                      <div className="sequence-card-email-body">
                        <p className="sequence-card-email-label-text">BODY</p>
                        <p className="sequence-card-email-text">{campaign.EmailBody.substring(0,70)}…</p>
                      </div>
                    </div>
                    <div className="sequence-card-footer">
                        <div className="sequence-card-footer-button">
                          <Link
                            to={`/campaign/${campaign.slug}/edit`}
                            className="sequence-card-footer-button-standard">
                            <img alt="" src={SendIcon} className="sequence-card-icon-standard" />
                            <span className="sequence-card-footer-button-standard-text">Send to another list</span>
                          </Link>
                        </div>
                        <div className="sequence-card-footer-button">
                          <div
                            className="sequence-card-footer-button-archive"
                            onClick={ () => this.handleDelete(campaign.id)}
                          >
                            <img alt="" src={TrashIcon} className="sequence-card-icon-archive" />
                            <span className="sequence-card-footer-button-archive-text">Delete</span>
                          </div>
                        </div>
                    </div>
                  </div>
                }
                </div>
              ))}
            </div>
          )
        }}
      </FirestoreCollection>
    )
  }
}

export default Campaigns;
