import React, { Component } from 'react';
import { auth, firebaseApp } from '../api/firebase';
import { FirestoreProvider } from 'react-firestore';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { BrowserRouter, Route } from 'react-router-dom';
import 'bulma/css/bulma.css';
import '../styles/bulma-tooltip.css';
import '../styles/bulma-switch.css';
import '../styles/bulma-checkradio.css';
import '../styles/bulma-divider.css';
import '../styles/global';
import '../styles/animation';
import ErrorBoundary from './misc/ErrorBoundary';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Routes from './Routes';
import Layout from './layout/Layout';

class App extends Component {

  render() {
    return (
      <FirestoreProvider firebase={firebaseApp}>
        <BrowserRouter>
          <ErrorBoundary>
            <Helmet>
              <script async src='https://r.wdfl.co/rw.js' data-rewardful='ed8d2c'></script>
            </Helmet>
            <Layout>
              <Route path="/" component={Analytics} />
              <Routes />
            </Layout>
          </ErrorBoundary>
        </BrowserRouter>
      </FirestoreProvider>
    )
  }
}
  
 // Track Google Analytics page view for every route
 // https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
 const Analytics = ({location}) => {
   const page = location.pathname + location.search
   ReactGA.set({ page })
   ReactGA.pageview(page)
   return null
 }

export default App;
