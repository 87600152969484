import React from 'react';

class DomainForm extends React.Component {
  formChangeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
    console.log(event.target.value)
  }

  onSubmit = event => {
    event.preventDefault()
    const values = { userDomain: `pr.${this.state.domain}` }
    this.props.onSubmit(values)
  }
  render() {
    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">What domain do you want to authenticate?</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-600">
            <p>Make sure that you only enter the name of your <span className="font-bold">root domain.</span> <span className="text-red-600">Do NOT include www or http://www in this field.</span></p>
          </div>
          <form className="mt-5 sm:flex sm:items-center" onSubmit={this.onSubmit}>
            <div className="w-full sm:max-w-sm">
              <label htmlFor="domain" className="sr-only">
                Domain
              </label>
              <input
                required
                type="text"
                name="domain"
                placeholder="For example: 'google.com' or 'presshunt.co'"
                onChange={(event, name, value) =>
                  this.formChangeHandler(event, name, value)
                }
                className="shadow-sm border focus:ring-indigo-500 py-3 px-4 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <button
              type="submit"
              className=" w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default DomainForm;
