import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Firebase from 'firebase/app';
import moment from "moment";
import 'moment-timezone';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerPopper } from '../../../styles/datepicker';
import TimezoneSelect from 'react-timezone-select'
import { subMenu } from "../path";
import MenuSideBar from "../menuSideBar";
import SubMenuSideBar from "../subMenuSideBar";
import MailBreadcrumb from "../breadcrumb";
import EditorComponent from "../Editor";
import Transition from "../../../styles/Transition";
import { FirestoreCollection } from "react-firestore";
import { postNewMail, checkConnectMail, getReAuthMessage } from "../../../api/mail";
import UnConnectContainer from "./unConnectContainer";
import Loading from '../../misc/Loading';
import NoAuth from '../../misc/noAuth';
import { auth } from '../../../api/firebase';
import ConnectMailFailedModal from '../connectMailFailedModal';

const MailNew = ({ history, subscription, plan }) => {
  const { pathname } = useLocation();
  const [subject, setSubject] = useState("");
  const [to, setTo] = useState("");
  const [toList, setToList] = useState([]);
  const [body, setBody] = useState("");
  const [toError, setToError] = useState(null);
  const [mailSendSuccessModalOn, setMailSendSuccessModalOn] = useState("");
  const [selectedListName, setSelectedListName] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [connectMailFailed, setConnectMailFailed] = useState(false);
  const [connectMailFailedLabel, setConnectMailFailedLabel] = useState(false);
  const [connectMailFailedButtonLink, setConnectMailFailedButtonLink] = useState('/dashboard');
  const [connectFailedMessage, setConnectMailFailedMessage] = useState('');

  const [sendNow, setSendNow] = useState(true);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const getSubscribeStatus = async() => {
    const db = Firebase.firestore();
    console.log(auth.currentUser.uid)
    db.collection('subscriptions').where('user', '==', auth.currentUser.uid)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          const subscriptions = [];
          snapshot.forEach((doc) => subscriptions.push({ ...doc.data(), id: doc.id }));
          setSubscriptions(subscriptions[0]);
        }
    })
  }

  useEffect(() => {
    getSubscribeStatus();
    checkConnectMail()
      .then((res) => {
        const emails = res.data.emails;
        for (const email of emails) {
          if (email.status && email.info.state === 3) {
              setConnectMailFailedMessage(getReAuthMessage());
          } else if (email.status) {
            setIsConnected(email.status);
          }
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (err.response.status == 401) {
          setConnectMailFailed(true);
          setConnectMailFailedButtonLink('/account')
          setConnectMailFailedLabel("Oops! Access token expired. Please try to log out and log back in again.");
        } else {
          setConnectMailFailed(true);
          setConnectMailFailedLabel("Oops! " + err.message + " Please contact support.");
        }
      });
  }, []);

  const isInList = email => toList.some(item => item.email === email);

  const isEmail = email => /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);

  const isValid = email => {
    // eslint-disable-next-line no-underscore-dangle
    let _error = null;

    if (isInList(email)) {
      _error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      _error = `${email} is not a valid email address.`;
    }

    if (_error) {
      setToError(_error);

      return false;
    }

    return true;
  };

  const handleAdded = email => {
    const rex = /([\w\d\.-]+)@[\w\d\.-]+\.[\w\d\.-]+/;
    const name = String(email).replace(rex, "$1");

    setToList(prev => [...prev, { name, email }]);
  };

  const handleKeyDown = e => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      const email = e.target.value.trim();

      if (email && isValid(email)) {
        handleAdded(email);

        setTo("");
        setToError(null);
        // this.setState({
        //   items: [...this.state.items, this.state.value],
        //   value: '',
        // });
      }
    }
  };

  const handleChange = e => {
    setTo(e.target.value);
    setToError(null);
  };

  const handleDelete = item => {
    const newToList = toList.filter(i => i !== item);
    setToList(newToList);
    setToError("");
  };

  const handlePaste = evt => {
    evt.preventDefault();

    const email = evt.clipboardData.getData("text");

    if (email && isValid(email)) {
      handleAdded(email);
    }
  };

  const importList = list => {
    const db = Firebase.firestore();
    const listEntries = db
      .collection("lists")
      .doc(list.id)
      .collection("entries")
      .get()
      .then(documentSnapshots => {
        const items = documentSnapshots.docs.map(doc => doc.data());

        const _toList = items
          .filter(item => item.email && item.email !== "")
          .map((item, index) => {
            return {
              email: item.email,
              name: (item.dataType === "journalist" && item.firstName) ? item.firstName : (item.dataType === "journalist" && item.name) ? item.name.split(" ")[0] : "there"
            }
          });

        setSelectedListName(list.name);
        setToList(_toList);
      });
  };

  const onPostNewMail = () => {
    let utcOffset = 0;
    const data = { to: toList, subject, body };

    if (!sendNow) {
      data.sent_at = moment(scheduledDate).unix();
    } else {
      data.sent_at = moment().unix() + 60;
    }

    if (selectedTimezone.offset != undefined) {
      utcOffset = selectedTimezone.offset - (moment().utcOffset() / 60);
    }

    data.sent_at = parseInt(data.sent_at) - (utcOffset * 60 * 60);

    setIsSending(true)
    postNewMail(data)
      .then(() => {
        setIsSending(false)
        setMailSendSuccessModalOn("Success");

        setSubject("");
        setToList([]);
        setTo("");
        setBody("");
      })
      .catch(e => {
        setIsSending(false);
        setMailSendSuccessModalOn("something went wrong");
      });
  };

  const sendTypeOnChange = () => {
    setSendNow(!sendNow);
  }

  if (subscriptions.length == 0) {
    return (
      <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
        <div className="min-h-0 flex-1 flex overflow-hidden">
          <SubMenuSideBar pathname={pathname} path={subMenu.mail} isConnected={isConnected} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <NoAuth />
          </main>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loading />
        <ConnectMailFailedModal
          modalOpen={connectMailFailed}
          setModalOpen={setConnectMailFailed}
          buttonLink={connectMailFailedButtonLink}
          label={connectMailFailedLabel}
        />
      </div>
    )
  }

  if (!isConnected) {
    return (
      <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
        <div className="min-h-0 flex-1 flex overflow-hidden">
          <SubMenuSideBar pathname={pathname} path={subMenu.mail} isConnected={isConnected} />
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <MailBreadcrumb>
              <MailBreadcrumb.Item>
                <>
                  <Link to="/mail/inbox" className="text-gray-400 hover:text-gray-500">
                    <svg
                      className="flex-shrink-0 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                  </Link>
                </>
              </MailBreadcrumb.Item>
              <MailBreadcrumb.Item>
                <>
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span
                    to="/mail/new"
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current="page"
                  >
                    Inbox
                  </span>
                </>
              </MailBreadcrumb.Item>
            </MailBreadcrumb>
            <UnConnectContainer flashAlert={connectFailedMessage} />
          </main>
        </div>
      </div>
    )
  }
  return (
    <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
      <div className="min-h-0 flex-1 flex overflow-hidden">
        <SuccessModal
          modalOpen={mailSendSuccessModalOn}
          setModalOpen={setMailSendSuccessModalOn}
          backLink={ sendNow ? "/mail/Inbox" : "/mail/scheduled" }
        />

        <Sending
          isSending={isSending}
          setIsSending={setIsSending}
        />

        {/*<MenuSideBar pathname={pathname} />*/}

        <SubMenuSideBar pathname={pathname} path={subMenu.mail} />

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none pb-10">
          <MailBreadcrumb>
            <MailBreadcrumb.Item>
              <>
                <Link to="/mail/inbox" className="text-gray-400 hover:text-gray-500">
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </Link>
              </>
            </MailBreadcrumb.Item>
            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  to="/mail/new"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  New
                </span>
              </>
            </MailBreadcrumb.Item>
          </MailBreadcrumb>

          <div className="mt-8 max-w-100 mx-auto grid grid-cols-1 sm:px-6 lg:grid-flow-col-dense">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-4">
                    <div className="flex flex-wrap -mx-3 mb-3">
                      <FirestoreCollection
                        path={`lists`}
                        filter={["createdBy", "==", auth.currentUser.uid]}
                        sort="createdOn:desc"
                      >
                        {({ error, data }) => {
                          return (
                            <div className="w-full md:w-1/2 px-3">
                              <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-last-name"
                              >
                                Email
                              </label>
                              <div>
                                <input
                                  className={`${
                                    error ? "border-red-500" : ""
                                  }  appearance-none block w-full shadow-sm focus:ring-indigo-500 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight`}
                                  type="text"
                                  name="To"
                                  id="To"
                                  // value={to}
                                  readOnly
                                  value={toList
                                    .map(list =>
                                      list?.email ? `<${list.email}>` : ""
                                    )
                                    .join("")}
                                  // onKeyDown={handleKeyDown}
                                  // onPaste={evt => handlePaste(evt)}
                                  // onChange={e => handleChange(e)}
                                />
                                <div className="grid grid-rows-3 py-3">
                                  <div className="float-left row-span-2">
                                    <div className="dropdown is-hoverable float-left">
                                      <div className="dropdown-trigger">
                                        <a className="heaven-card-white-button is-pulled-left">
                                          <i className="fas fa-list heaven-nav-button-icon" />
                                          Lists
                                          <i className="fas fa-chevron-down heaven-nav-button-icon-right" />
                                        </a>
                                      </div>
                                      <div
                                        className="dropdown-menu"
                                        id="dropdown-menu3"
                                        role="menu"
                                      >
                                        <div className="dropdown-content heaven-card-dropdown-content">
                                          {data.map(list => (
                                            <div key={list.id}>
                                              <a
                                                onClick={() => importList(list)}
                                                className="dropdown-item heaven-page-dropdown-item"
                                              >
                                                <i className="fas fa-list heaven-page-dropdown-item-icon" />
                                                {list.name}
                                              </a>
                                            </div>
                                          ))}
                                          <div></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="float-left"
                                      style={{
                                        "fontWeight": "700",
                                        "marginLeft": "20px",
                                        "padding": "6px 12px",
                                        "backgroundColor": "white",
                                        "border": "solid 1px #dfe8ef",
                                        "borderRadius": "7px",
                                        "boxShadow": "rgb(191 191 191 / 10%) 0px 2px 2px 1px",
                                        "color": "#003dff",
                                        "display": !selectedListName && "none"
                                      }}
                                    >
                                      {selectedListName}
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    <p className="block text-sm font-medium text-gray-600">
                                      To personalize the email using journalist's first name, use the tag <span className="font-bold">&#123;first_name&#125;</span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {error && (
                                <p className="text-red-500 text-xs italic">
                                  {error}
                                </p>
                              )}
                              {/* <p className="mt-2 text-sm text-gray-500">
                            {toList.map((item) => (
                              <button
                                key={item.email}
                                onClick={() => handleDelete(item)}
                                style={{ cursor: 'pointer' }}
                              >
                                {`<${item.email}>`}
                              </button>
                            ))}
                          </p> */}
                            </div>
                          );
                        }}
                      </FirestoreCollection>
                      <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Subject
                        </label>
                        <div>
                          <input
                            className="appearance-none block w-full shadow-sm focus:ring-indigo-500 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight"
                            type="text"
                            name="Subject"
                            id="Subject"
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* <Link
                        to="/settings/emailsync"
                        type="button"
                        className="m-1 inline-flex justify-center px-4 pt-3 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-600 bg-white hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                      >
                        <SettingIcon />
                      </Link> */}
                    </div>

                    <EditorComponent onChange={html => setBody(html)} />
                    <div className="shadow sm:rounded-md  mt-3">
                      <div className="bg-white py-4 px-4 space-y-6 sm:p-6">
                        <p className="text-sm text-gray-600">You may choose to deliver your email now or at a later time.</p>
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              id="sendTypeNow"
                              name="SendType"
                              type="radio"
                              value="send_now"
                              defaultChecked={true}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              onChange={() =>
                                sendTypeOnChange()
                              }
                            />
                            <label htmlFor="sendTypeNow" className="ml-3">
                              <span className="block text-sm font-medium text-gray-700">Send now</span>
                            </label>
                          </div>
                          <div className="grid grid-cols-6 gap-6">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="SendType"
                                value="send_scheduled"
                                defaultChecked={false}
                                id="sendTypeScheduled"
                                onChange={() =>
                                  sendTypeOnChange()
                                }
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label htmlFor="sendTypeScheduled" className="ml-3">
                                <span className="block text-sm font-medium text-gray-700">Schedule send</span>
                              </label>
                            </div>
                            <div className="flex items-center">
                              <DatePickerPopper>
                                <DatePicker
                                  disabled={sendNow}
                                  className="appearance-none block w-full h-9 w-full shadow-sm focus:ring-indigo-500 text-gray-700 border rounded py-3 px-4 leading-tight"
                                  selected={scheduledDate}
                                  minDate={new Date()}
                                  showTimeSelect
                                  dateFormat="Pp"
                                  onChange={(date) => setScheduledDate(date)}
                                />
                              </DatePickerPopper>
                            </div>
                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                              <TimezoneSelect
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: provided => ({ ...provided, zIndex: 999 }),
                                  control: provided => ({ ...provided, height: "100%" })
                                }}
                                className="h-full"
                                isDisabled={sendNow}
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          disabled={!subject || !body || !toList?.length}
                          onClick={() => {
                            onPostNewMail();
                          }}
                          type="button"
                          className="inline-flex justify-center px-5 py-2 border border-gray-400 shadow-sm text-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                        >
                        <span>{sendNow ? "Send" : "Schedule"}</span>
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MailNew;

// const DsaIcon = () =>
const SettingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
      clipRule="evenodd"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const SuccessModal = ({ modalOpen = "", setModalOpen, backLink = "/mail/Inbox" }) => {
  if (!modalOpen) return null;

  return (
    <div
      className={`${
        modalOpen ? "block z-10" : "hidden"
      } fixed inset-0 overflow-y-auto`}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={modalOpen}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Successful
                    </h3>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <Link to={backLink} className="text-gray-400 hover:text-gray-500">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

const Sending = ({ isSending = "", setIsSending }) => {
  if (!isSending) return null;

  return (
    <div>
      <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" style={{ "display": "flex", "align-items": "center", "justify-content": "center" }}>
        <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ "top": "50%;"}}>
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
    </div>
  );
};
