import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import { getConnectMailUri } from '../../../api/mail';
import Transition from "../../../styles/Transition";

const UnConnectContainer = ({ flashAlert = '' }) => {
  const [openConnectGmailAlertModal, setOpenConnectGmailAlertModal] = useState("");

  const handleGmailConnectButton = () => {
    setOpenConnectGmailAlertModal(true);
  }
  return (
    <>
      <ConnectGmailAlertModal
        modalOpen={openConnectGmailAlertModal}
        setModalOpen={setOpenConnectGmailAlertModal}
      />
      {flashAlert !== '' &&
        <div className="mt-8 max-w-100 mx-auto grid grid-cols-1 sm:px-6 lg:grid-flow-col-dense">
          <div className="bg-yellow-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="font-bold text-red-500">{flashAlert}</span>
          </div>
        </div>
      }
      <div className="mt-8 max-w-100 mx-auto grid grid-cols-1 sm:px-6 lg:grid-flow-col-dense">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          {/* Description list */}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-10 py-5">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    <dt className="text-lg text-sm font-bold text-gray-800">
                      Sync your email inbox with Press Hunt
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      It takes 2 clicks to connect your email account to see replies to your PR campaigns and start sending emails through Press Hunt today.
                    </dd>

                    <div className="px-12 pt-10 flex justify-end">
                      <div style={{ 'padding-left': '12px' }}>
                        <button
                          onClick={
                            () => getConnectMailUri('outlook').then((res) => {
                              window.location.href = res.data.connectUrl;
                            })
                            // setMailAccountModalOn(!mailAccountModalOn)
                          }
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                        >
                          <span>Connect your Microsoft Outlook account</span>
                        </button>
                      </div>
                      <div style={{ 'padding-left': '12px' }}>
                        <button
                          onClick={() => handleGmailConnectButton()}
                          type="button"
                          className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                        >
                          <span>Connect your Google Mail account</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

const ConnectGmailAlertModal = ({ modalOpen = "", setModalOpen }) => {
  if (!modalOpen) return null;

  return (
    <div
      className={`${
        modalOpen ? "block z-10" : "hidden"
      } fixed inset-0 overflow-y-auto`}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={modalOpen}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <svg class="h-6 w-6 text-yellow-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Connect your Google Mail account
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-600">
                        After selecting your Email account on the next page, Google will present you a message saying “Google hans’t verified this app.”
                        In order to sync your Google account with Press Hunt, <span className="font-bold">you must click on “advanced” on the lower left side of the message.</span>
                        Then proceed by clicking “Go to Nylas.com”.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button
                    onClick={
                      () => getConnectMailUri('gmail').then((res) => {
                        window.location.href = res.data.connectUrl;
                      })
                    }
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-blue-1000 hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default UnConnectContainer;