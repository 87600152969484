import React from 'react';
import { FirestoreCollection } from 'react-firestore';

import Error from '../misc/Error';
import updateCampaign from '../../actions/updateCampaign';
import CampaignForm from './campaignForm';

import Loading from '../misc/Loading';

const CampaignEdit = ({ history, match, subscription, auth }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
    <FirestoreCollection
      path={'campaigns'}
      filter={[['createdBy', '==', auth.uid], [ 'slug', '==', match.params.slug ]]}
    >
      { ({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />
        }

        if (isLoading) {
          return <Loading />
        }

        if (!isLoading && data.length === 0) {
          return <p>{match.params.slug}</p>
        }

        const campaign = data[0];
        return (
          <section>
            <CampaignForm
              campaign={campaign}
              auth={auth}
              subscription={subscription}
              onSubmit={values => updateCampaign(campaign.id, values).then(() => history.push("/campaigns"))}
            />
          </section>
        )
      }}
    </FirestoreCollection>
  </div>
)

export default CampaignEdit;
