import React from 'react';
import Select from 'react-select';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { FirestoreCollection } from 'react-firestore';
import RichTextEditor from 'react-rte';
import Error from '../misc/Error';
import DomainIcon from '../../assets/blue-globe.svg';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      archived: false,
      showDomainPopup: false,
      entries: [],
      SendTo: "",
      sendStatus: "processing",
      EmailBody: this.props.campaign ? RichTextEditor.createValueFromString(this.props.campaign.EmailBody, 'html') : RichTextEditor.createEmptyValue(),
      tags: this.props.campaign ? this.props.campaign.tags : [],
      suggestions: [
          { id: 'Technology', text: 'Technology' },
          { id: 'Germany', text: 'Germany' },
          { id: 'Austria', text: 'Austria' },
          { id: 'Startup', text: 'Startup' },
       ],
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.importList = this.importList.bind(this);
    this.getDomainCount = this.getDomainCount.bind(this);
  }

  componentDidMount() {
    this.getDomainCount();
  }

  importList = (list) => {
    var db = Firebase.firestore();
    var listEntries = db.collection('lists').doc(list.id).collection('entries')
      .get()
      .then( documentSnapshots => {
        var recipientVariables = {};
        var items = documentSnapshots.docs.map(doc => doc.data())
        items.filter(item => item.email && item.email !== "" ).map(item => this.state.entries.push(item.email))
        items.filter(item => item.email && item.email !== "" ).map( (item, index) => recipientVariables[item.email] = { id: index, first: (item.dataType === "journalist" && item.firstName) ? item.firstName : (item.dataType === "journalist" && item.name) ? item.name.split(" ")[0] : "there" })
        this.setState({
          SendTo: this.state.entries.join(", "),
          RecipientVariables: recipientVariables,
        })
        console.log(this.state.RecipientVariables);
      })
  }

  formChangeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleReactSelectChange = name => value => {
    this.setState({
      [name]: value,
    })
  }

  onRichEditorChange = (EmailBody) => {
    this.setState({EmailBody});
  };

  onSubmit = event => {
    event.preventDefault()
    const {
      CampaignName,
      SendTo,
      SendAs,
      SubjectLine,
    } = event.target.elements

    const {
      active,
      archived,
      RecipientVariables,
      sendStatus,
      tags,
    } = this.state

    const EmailBody = this.state.EmailBody.toString('html');
    console.log(EmailBody)
    let labels = tags.map(e => e.text);
    labels.unshift(CampaignName.value);

    const values = {
      CampaignName: CampaignName.value,
      SendTo: SendTo.value,
      EmailBody,
      SendAs: SendAs.value,
      SubjectLine: SubjectLine.value,
      Status: active,
      plan: this.props.subscription.plan,
      tags,
      labels,
      archived,
      sendStatus,
      RecipientVariables,
    }
    console.log(labels);
    console.log(values)
    this.props.onSubmit(values)
  }
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
     tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  getDomainCount = () => {
    const db = Firebase.firestore();
    db.collection('domains').where('createdBy', '==', this.props.auth.uid)
      .get()
      .then(documentSnapshots => {
        if (documentSnapshots.size < 1) {
          this.setState({
            showDomainPopup: true,
          });         
        } else if (documentSnapshots.size === 1) {
          documentSnapshots.forEach( doc => {
            if (doc.data().state !== "active") {
              this.setState({
                showDomainPopup: true,
              });
            };
          })
        };
      });
  }

  render() {
    const { tags, suggestions, showDomainPopup } = this.state;
    return (
      <div className="columns">
        <div className="column is-one-thirds">
          <div className="is-dash-left-campaign-card">
            <p className="is-dash-left-sequence-card-title">Select a media list for your outreach campaign</p>
            <p>To optimize email deliverability, for the first few weeks of sending emails, try to limit each campaign to 100 contacts. After a few weeks you can gradually increase the amount of contacts per campaign.</p>
            <FirestoreCollection
              path={`lists`}
              filter={['createdBy', '==', this.props.auth.uid]}
              sort="createdOn:desc"
            >
            { ({ error, isLoading, data }) => {
              if (error) {
                return <Error error={error} />
              }
              else {
                return (
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <a className="heaven-card-white-button is-pulled-left"><i className="fas fa-list heaven-nav-button-icon"/>Lists<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></a>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                      <div className="dropdown-content heaven-card-dropdown-content">
                      {data.map( list => (
                        <div key={list.id}>
                          <a
                            onClick={() => {this.importList(list, list.entries)}}
                            className="dropdown-item heaven-page-dropdown-item"
                          >
                            <i className="fas fa-list heaven-page-dropdown-item-icon" />
                            {list.name}
                          </a>
                        </div>
                      ))}
                      </div>
                    </div>
                  </div>
                )
              }
            }}
            </FirestoreCollection>
          </div>
        </div>
        <div className="column is-two-thirds">     
          { showDomainPopup ? (
            <div className="overflow-y-hidden fixed z-10 inset-0 overflow-y-auto mt-48">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                </div>
                <div className="border border-gray-200 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                  <div className="bg-white p-4 sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-centerrounded-full bg-white sm:mx-0">
                        <svg className="h-6 w-6 text-yellow-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-bold text-gray-900 -pt-4" id="modal-headline">
                          Authenticate your domain to send emails through Press Hunt
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm leading-5 text-gray-600">
                            Before you send emails, you'll need to authenticate your email sending domain with us. It's easy to set up, and only takes a few minutes.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                    <span className="flex w-full rounded-lg shadow-sm sm:ml-3 sm:w-auto">
                      <NavLink to="/domain" className="flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-blue-1000 text-base leading-6 font-bold text-white shadow-sm hover:opacity-75 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        <svg className="opacity-50 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2 font-bold text-white">Add domain</span>
                      </NavLink>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : <span /> }
          <div className="is-sequence-form">
            <form onSubmit={this.onSubmit}>
              <div className="sequence-form-q">
                <div className="control">
                  <p className="seq-form-required">REQUIRED</p>
                  <label className="sequence-form-label">What do you want to name your campaign?</label>
                  <input
                    className="sequence-form-input"
                    required
                    type="text"
                    name="CampaignName"
                    defaultValue={this.props.campaign ? this.props.campaign.CampaignName : '' }
                    placeholder="Campaign Name"
                    onChange={(event, name, value) =>
                      this.formChangeHandler(event, name, value)
                    }
                  />
                </div>
              </div>
              {/* styling guide: https://github.com/prakhar1989/react-tags#styling*/}
              {/*<div className="sequence-form-q">
                <div className="control">
                  <label className="sequence-form-label">Add labels to your campaign</label>
                  <ReactTags
                    tags={tags}
                    suggestions={suggestions}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleDrag={this.handleDrag}
                    delimiters={delimiters}
                    classNames={{
                      tagInputField: 'sequence-form-input',
                    }}
                  />
                </div>
              </div> */}
              <div className="sequence-form-q">
                <div className="control">
                  <p className="sequence-form-label">Here are the emails you're sending to</p>
                  <p className="sequence-form-sublabel">These emails come from the media list you select on the left.</p>
                  <input
                    className="sequence-form-input"
                    required
                    disabled
                    type="text"
                    name="SendTo"
                    // defaultValue={this.props.campaign ? this.props.campaign.SendTo : ""}
                    value={this.state.SendTo}
                    placeholder="No media list selected"
                    onChange={(event, name, value) =>
                      this.formChangeHandler(event, name, value)
                    }
                    style={{opacity: '.6'}}
                  />
                </div>
              </div>
              <div className="sequence-form-q">
                <div className="control">
                  <p className="seq-form-required">REQUIRED</p>
                  <label className="sequence-form-label">Subject line for the email you want to send</label>
                  <input
                    className="sequence-form-input"
                    required
                    type="text"
                    name="SubjectLine"
                    defaultValue={this.props.campaign ? this.props.campaign.SubjectLine : ''}
                    placeholder="We just launched a new blockchain startup to change the world"
                    onChange={(event, name, value) =>
                      this.formChangeHandler(event, name, value)
                    }
                  />
                </div>
              </div>
              <div className="sequence-form-q">
                <div className="control">
                  <p className="seq-form-required">REQUIRED</p>
                  <p className="sequence-form-label">What email address do you want to send these emails from?</p>
                  <p className="sequence-form-sublabel">Make sure you've authenticated the domain of your email address <a href="" target="_blank">here</a> so your emails get delivered properly. If you've already authenticated a domain like tesla.com, you can send from any email address like elon@tesla.com or sam@tesla.com without a problem.</p>
                  <FirestoreCollection
                    path={`domains`}
                    filter={['createdBy', '==', this.props.auth.uid]}
                  >
                  { ({ error, isLoading, data }) => {
                    if (error) {
                      return <Error error={error} />
                    }
                    else {
                      return (
                        <div className="campaign-form-domain-input-div">
                          <img src={DomainIcon} className="form-para-icon" />
                          <p className="active-domains-text">Active Domains:</p>
                          {data.map( domain => (
                            <div key={domain.id}>
                              {domain.state === "active" ? <p className="campaign-form-domain-item">{domain.userDomain.replace("pr.","")}</p> : <span />}
                            </div>
                          ))}
                        </div>
                      )
                    }
                  }}
                  </FirestoreCollection>
                  <input
                    className="sequence-form-input"
                    required
                    type="email"
                    placeholder="e.g. info@yourcompany.com"
                    name="SendAs"
                    defaultValue={this.props.campaign ? this.props.campaign.SendAs : ''}
                    onChange={(event, name, value) =>
                      this.formChangeHandler(event, name, value)
                    }
                  />
                </div>
              </div>
              <div className="sequence-form-last-q">
                <div className="control">
                  <p className="seq-form-required">REQUIRED</p>
                  <p className="sequence-form-label">Write the body of your email here</p>
                  <p className="sequence-form-sublabel">Emails are sent in plain text, not in HTML. If you'd like to personalize the email using the triggered journalist's name, use the tag %recipient.first%</p>
                  <RichTextEditor
                    className="rich-text-editor"
                    required
                    name="EmailBody"
                    value={this.state.EmailBody}
                    placeholder="Hi %recipient.first%!"
                    onChange={this.onRichEditorChange}
                  />
                </div>
              </div>
              <div className="sequence-form-button-wrapper" style={{display:'block'}}>
                <button
                  className="heaven-card-cta-button"
                  type="submit"
                  style={{marginLeft:'0px',fontWeight:'bold'}}
                >
                  <i className="fas fa-envelope heaven-nav-button-icon" />Send email now
                </button>
                <p className="form-button-below">Scheduling features coming soon.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignForm;
