import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Moment from 'react-moment';
import OutsideClickHandler from 'react-outside-click-handler';
import { subMenu } from '../path';
import MenuSideBar from '../menuSideBar';
import SubMenuSideBar from '../subMenuSideBar';
import MailBreadcrumb from '../breadcrumb';
import MailPagination from '../pagination';
import {
  getMailsInbox, checkConnectMail, setMailsArchive, getReAuthMessage,
} from '../../../api/mail';
import { MailContext } from '../mail';
import Loading from '../../misc/Loading';
import LoadingCircle from '../../misc/LoadingCircle';
import UnConnectContainer from './unConnectContainer';
import ConnectMailFailedModal from '../connectMailFailedModal';

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-green-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);

const MailInbox = () => {
  const [optionOpen, setOptionOpen] = useState(false);
  const [markDropdownOn, setMarkDropdownOn] = useState(false);
  const [quickPickDropdownOn, setQuickPickDropdownOn] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCircle, setIsLoadingCircle] = useState(false);
  const [emailToken, setEmailToken] = useState('');
  const [connectMailFailed, setConnectMailFailed] = useState(false);
  const [connectMailFailedLabel, setConnectMailFailedLabel] = useState(false);
  const [connectMailFailedButtonLink, setConnectMailFailedButtonLink] = useState('/dashboard');
  const [pagination, setPagination] = useState({
    count: 0, limit: 0, offset: 0,
  });
  const [mailDatasCount, setMailDatasCount] = useState('');
  const [connectFailedMessage, setConnectMailFailedMessage] = useState('');

  const [mailDatas, setMailDatas] = useContext(MailContext);

  const { pathname } = useLocation();

  const onGetMailInbox = ({ code, offset = 0, limit = 100 }) => {
    setIsLoadingCircle(true);
    getMailsInbox({ code, offset, limit }).then((res) => {
      const {
        threads, count,
      } = res.data;
      setMailDatas(threads);
      setMailDatasCount(count);
      setPagination({
        count, limit, offset,
      });
      setIsLoadingCircle(false);
    });
  };

  useEffect(() => {
    checkConnectMail()
      .then((res) => {
        const { emails } = res.data;
        for (const email of emails) {
          // if (true) {
          if (email.status && email.info.state === 3) {
            setConnectMailFailedMessage(getReAuthMessage());
          } else if (email.status) {
            setEmailToken(email.token);
            setIsConnected(email.status);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const offset = urlParams.get('offset') ?? pagination.offset;
            onGetMailInbox({ code: email.token, offset: parseInt(offset) });
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          setConnectMailFailed(true);
          setConnectMailFailedButtonLink('/account');
          setConnectMailFailedLabel('Oops! Access token expired. Please try to log out and log back in again.');
        } else {
          setConnectMailFailed(true);
          setConnectMailFailedLabel(`Oops! ${err.message} Please contact support.`);
        }
      });
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loading />
        <ConnectMailFailedModal
          modalOpen={connectMailFailed}
          setModalOpen={setConnectMailFailed}
          buttonLink={connectMailFailedButtonLink}
          label={connectMailFailedLabel}
        />
      </div>
    );
  } if (isLoadingCircle) {
    return <LoadingCircle isLoading={isLoadingCircle} />;
  }
  return (
    <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
      <div className="min-h-0 flex-1 flex overflow-hidden">

        {/* <MenuSideBar pathname={pathname} /> */}

        <SubMenuSideBar pathname={pathname} path={subMenu.mail} isConnected={isConnected} />

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <MailBreadcrumb>
            <MailBreadcrumb.Item>
              <>
                <Link to="/mail/inbox" className="text-gray-400 hover:text-gray-500">
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </Link>
              </>
            </MailBreadcrumb.Item>
            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  to="/mail/new"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Inbox
                </span>
              </>
            </MailBreadcrumb.Item>
          </MailBreadcrumb>

          {/* Main Content */}
          <div className="grid grid-cols-3 gap-4" style={{ display: quickPickDropdownOn ? '' : 'none' }}>
            <div className="px-4 mt-4 flex">
              <div className="relative z-0 inline-flex shadow-sm rounded-md m-1">
                <span className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white">
                  <label htmlFor="select-all" className="sr-only">
                    Select all
                  </label>
                  <input
                    id="select-all"
                    type="checkbox"
                    name="select-all"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                </span>

                <OutsideClickHandler
                  onOutsideClick={() => (quickPickDropdownOn
                    ? setQuickPickDropdownOn(!quickPickDropdownOn)
                    : () => {})}
                >
                  <span className="-ml-px relative block">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      id="option-menu"
                      aria-expanded="true"
                      aria-haspopup="true"
                      onClick={() => setQuickPickDropdownOn(!quickPickDropdownOn)}
                    >
                      <span className="sr-only">Open options</span>

                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    <div
                      className="origin-top-right absolute right-0 mt-2 -mr-1 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="option-menu"
                      style={{ display: quickPickDropdownOn ? '' : 'none' }}
                    >
                      <div className="py-1" role="none">
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          Save and schedule
                        </a>

                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          Save and publish
                        </a>

                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          Export PDF
                        </a>
                      </div>
                    </div>
                  </span>
                </OutsideClickHandler>
              </div>

              <div className="relative z-0 inline-flex shadow-sm rounded-md m-1">
                <OutsideClickHandler
                  onOutsideClick={() => (markDropdownOn
                    ? setMarkDropdownOn(!markDropdownOn)
                    : () => {})}
                >
                  <div>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                      id="options-menu"
                      aria-expanded={markDropdownOn}
                      aria-haspopup="true"
                      onClick={() => setMarkDropdownOn(!markDropdownOn)}
                    >
                      Mark
                      <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  <div
                    className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                    style={{ display: markDropdownOn ? '' : 'none' }}
                  >
                    <div className="py-1" role="none">
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >
                        Account settings
                      </a>
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>

              <span className="relative z-0 inline-flex shadow-sm rounded-md m-1">
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  Archive
                </button>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  Delete
                </button>
              </span>
            </div>

            <div className="px-4 mt-4" />
            <div className="px-4 mt-4">
              <OutsideClickHandler
                onOutsideClick={() => (optionOpen ? setOptionOpen(!optionOpen) : () => {})}
              >
                <div className="relative flex justify-end items-center m-1">
                  <button
                    onClick={() => setOptionOpen(!optionOpen)}
                    type="button"
                    className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    id="project-options-menu-0"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open options</span>

                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                  </button>

                  <div
                    className="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="project-options-menu-0"
                    style={{ display: optionOpen ? '' : 'none' }}
                  >
                    <div className="py-1" role="none">
                      <a
                        href="#"
                        className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >

                        <svg
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                        </svg>
                        Share
                      </a>
                    </div>
                    <div className="py-1" role="none">
                      <a
                        href="#"
                        className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >
                        <svg
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          </div>

          {isConnected ? (
            (mailDatas.length == 0 && pagination.offset == 0)
              ? <EmptyListContainer />
              : (
                <MailList
                  datas={mailDatas}
                  pagination={pagination}
                  emailToken={emailToken}
                  onGetMailInbox={onGetMailInbox}
                  isConnected={isConnected}
                  mailDatasCount={mailDatasCount}
                />
              )
          ) : (
            <UnConnectContainer flashAlert={connectFailedMessage} />
          )}
        </main>
      </div>
    </div>
  );
};

export default MailInbox;

/*
snippet from to id body

*/

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate()
    && someDate.getMonth() == today.getMonth()
    && someDate.getFullYear() == today.getFullYear()
  );
};
// eslint-disable-next-line no-unused-vars
const MailList = ({
  datas, pagination, emailToken, onGetMailInbox, isConnected, mailDatasCount,
}) => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [selectedMails, setSelectedMails] = useState([]);
  const [mailDatas, setMailDatas] = useState(datas.map((data) => ({
    ...data,
    selected: false,
  })));

  const selectAll = () => {
    if (selectedMails.length != mailDatas.length) {
      setSelectAllCheckbox(true);
      return selectAllMails();
    }

    setSelectAllCheckbox(false);
    return unSelectAllMails();
  };

  const selectAllMails = () => {
    const tempSelectedMails = selectedMails;
    let tempMailDatas = mailDatas;

    tempMailDatas = tempMailDatas.map((mailData) => {
      mailData.selected = true;
      if (tempSelectedMails.indexOf(mailData.id) == -1) {
        tempSelectedMails.push(mailData.id);
      }
      return mailData;
    });

    setMailDatas(tempMailDatas);
    setSelectedMails(tempSelectedMails);
  };

  const unSelectAllMails = () => {
    let tempMailDatas = mailDatas;
    const tempSelectedMails = selectedMails;

    tempMailDatas = tempMailDatas.map((mailData) => {
      mailData.selected = false;
      return mailData;
    });

    setMailDatas(tempMailDatas);
    setSelectedMails([]);
  };

  const selectSingleMail = (id) => {
    const tempSelectedMails = selectedMails;
    const index = tempSelectedMails.indexOf(id);
    if (index !== -1) {
      tempSelectedMails.splice(index, 1);
    } else {
      tempSelectedMails.push(id);
    }

    let tempMailDatas = mailDatas;
    tempMailDatas = tempMailDatas.map((mailData) => {
      if (mailData.id == id) {
        mailData.selected = !mailData.selected;
      }
      return mailData;
    });

    setSelectedMails(tempSelectedMails);
    setMailDatas(tempMailDatas);
  };

  const handleClickArchive = async () => {
    if (selectedMails.length == 0) {
      return;
    }

    await setMailsArchive(selectedMails);
    window.location.href = `/mail/inbox?offset=${pagination.offset}`;
  };

  return (
    <div className="hidden sm:block">
      <div className="align-middle inline-block min-w-full border-b border-gray-200 pb-10">
        {/* function check box */}
        <div className="grid grid-cols-3 gap-4 pb-1 bg-white">
          <div className="flex">
            <div className="relative z-0 inline-flex rounded-md m-1">
              <span className="relative inline-flex items-center px-2 py-2 rounded-l-md bg-white">
                <input
                  id="select-all"
                  type="checkbox"
                  name="select-all"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  defaultChecked={mailDatas.length == selectedMails.length}
                  checked={selectAllCheckbox}
                  onChange={() => selectAll()}
                />

                <div className="pl-10">
                  <button
                    type="button"
                    className={`
                      ${selectedMails.length == 0 ? 'opacity-50' : ''}
                      bg-white relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 text-sm font-medium
                    `}
                    disabled={selectedMails.length == 0}
                    onClick={() => handleClickArchive()}
                  >
                    Archive
                  </button>
                </div>
              </span>
            </div>
          </div>
          <div />
          <div className="flex-1 flex sm:justify-end">
            <MailPagination
              offset={pagination.offset}
              limit={pagination.limit}
              count={pagination.count}
              onPrevious={() => {
                if (pagination.offset <= 0) {
                  pagination.offset = 0;
                  return null;
                }

                return onGetMailInbox({ code: emailToken, limit: pagination.limit, offset: pagination.offset - pagination.limit });
              }}
              onNext={() => {
                if (pagination.offset + pagination.limit >= pagination.count) return null;

                return onGetMailInbox({ code: emailToken, limit: pagination.limit, offset: pagination.offset + pagination.limit });
              }}
              style={{ display: (isConnected && mailDatasCount > 0) ? '' : 'none' }}
            />
          </div>
        </div>

        <table className="min-w-full">
          <tbody className="bg-white divide-y divide-gray-100">
            {mailDatas.map((item) => {
              if (
                item.messages.length == 0
                || item.messages[0].from.length == 0
                || item.messages[0].body == ''
              ) {
                return false;
              }

              const timestamp = item.last_message_timestamp;
              const time = new Date();
              time.setTime(timestamp * 1000);
              item.from = item.messages[0].from;
              item.body = item.messages[0].body;

              return (
                <tr
                  key={item.id}
                  className={item.unread ? 'font-bold' : 'font-light'}
                >
                  <td className="px-1 hidden md:table-cell px-3 py-3 whitespace-nowrap text-sm text-gray-500 text-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      defaultChecked={item.selected}
                      onChange={() => selectSingleMail(item.id)}
                      checked={item.selected}
                    />
                  </td>

                  <td className="cursor-pointer px-4 hidden md:table-cell px-3 py-3 whitespace-nowrap text-sm text-gray-500 text-left">
                    <Link
                      to={{
                        pathname: `/mail/inbox/${item.account_id}?id=${item.id}`,
                        state: {
                          item,
                          target: '/mail/inbox',
                          offset: pagination.offset,
                        },
                      }}
                    >
                      <div
                        className="flex items-center space-x-3 lg:pl-2"
                        style={{ width: '9.5rem' }}
                      >
                        <div
                          className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-pink-600"
                          aria-hidden="true"
                        />
                        <a href="#" className="truncate hover:text-gray-600">
                          <span>{(item.messages[0] && item.messages[0].from[0]) ? item.messages[0].from[0].name : ''}</span>
                        </a>
                      </div>
                    </Link>
                  </td>
                  <div className="grid grid-cols-1 gap-4">
                    <td className="cursor-pointer px-4 px-5 py-3 max-w-0 w-full whitespace-nowrap text-sm text-gray-900">
                      <Link
                        to={{
                          pathname: `/mail/inbox/${item.account_id}?id=${item.id}`,
                          state: {
                            item,
                            target: '/mail/inbox',
                            offset: pagination.offset,
                          },
                        }}
                      >
                        <div className="flex items-center space-x-3 lg:pl-2">
                          <div
                            className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-pink-600"
                            aria-hidden="true"
                          />
                          <a href="#" className="truncate hover:text-gray-600">
                            <span>
                              {item.subject}
                              <span className="pl-1 text-gray-500 font-normal">
                                {item.snippet}
                              </span>
                            </span>
                          </a>
                        </div>
                      </Link>
                    </td>
                  </div>

                  <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                    <div className="flex items-center space-x-2">
                      <div className="flex flex-shrink-0 -space-x-1" />
                    </div>
                  </td>

                  <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                    {isToday(time) ? (
                      <Moment date={time} format="hh:mm" />
                    ) : (
                      <Moment date={time} format="MM/DD" />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const EmptyListContainer = () => (
  <div className="flex flex-col items-center justify-center" style={{ height: '80vh' }}>
    <div className="rounded-md bg-green-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">Sync completed</h3>
          <div className="mt-2 text-sm text-green-700">
            <p>Perfect! your inbox is being synced. Go refill your coffee and you’ll be able to see your emails within 5 minutes.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                type="button"
                className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
