import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js';
import Firebase from 'firebase/app';
const stripeAPIKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripeAPIKey);

class StripeBillingPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: false,
      url: "",
    };
    this.launchPortal = this.launchPortal.bind(this);
  }

  launchPortal = async (uid, stripeCustomerId, email) => {
    console.log(uid)
    console.log(stripeCustomerId)
    console.log(email)
    this.setState({
      isLoading: true,
    })
    var fetchBillingPortalSession = Firebase.functions().httpsCallable('fetchBillingPortalSession');
    var data = { uid, stripeCustomerId, email };

    const res = await fetchBillingPortalSession(data);
    console.log(res)
    console.log(res.data.session.url)
    if (res.data.session.url) {
      this.setState({
        url: res.data.session.url,
        redirect: true,
      });
    };
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      window.location.assign(this.state.url);
    }
  }

  render() {
    const { stripeCustomerId, email, uid } = this.props;
    return (
      <div>
        {this.renderRedirect()}
        {this.state.isLoading ? <button className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75">Loading…</button>
          : <button className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75" onClick={ () => this.launchPortal(uid, stripeCustomerId, email) }>Manage billing</button>}
      </div>
    );
  }
}

export default StripeBillingPortal;
