import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connectMail } from "../../api/mail";

const MailCallback = () => {
  const history = useHistory();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");

    if (code) {
      connectMail(code).then(() => {
        history.push("/mail/inbox");
      });
    }
  }, []);

  return <div />;
};

export default MailCallback;
