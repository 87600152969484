import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Firebase from 'firebase/app';
const stripeAPIKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripeAPIKey);

const stripePlanIds = {
  "basicMonthly": "plan_E5uir3ikDaY0zW",
  "basicAnnual": "plan_F7ZHI69Fw794MG",
  "startupMonthly": "price_1KvIS1AUDsfdEBFyU9OjK5X9",
  "startupAnnual": "price_1KvIStAUDsfdEBFygQPM3nPE",
  "premiumMonthly": "price_1KvIXDAUDsfdEBFylMz7OotK",
  "premiumAnnual": "price_1KvIXdAUDsfdEBFyNIkN7ok8",
  "boostBasic": "plan_HGf0L1ywZ3pBWv",
  "boostGrowth": "plan_HGwBPguEQIyDO9",
  "sequencesMonthly": "plan_GTZSi56sGUz4ST",
  "sequencesAnnual": "plan_GczyPyXzYacNnq",
  "agencyMonthly": "plan_GTZViK6nGeSdNT",
  "agencyAnnual": "plan_Gczy4OJR8qmsNz",
  "enterpriseMonthly": "plan_GTZWvCdwhDVaTW",
  "enterpriseAnnual": "plan_Gczxla3ukg8Wba",
  "guarantee": "price_1KI2z0AUDsfdEBFyOlCxydWU"
}

//on 5/3/2022 startup plan is now 3 and premium plan is now 5. sequence plan is cancelled

const firestorePlanIds = {
  "basicMonthly": 1,
  "basicAnnual": 10,
  "startupMonthly": 3,
  "startupAnnual": 30,
  "premiumMonthly": 5,
  "premiumAnnual": 50,
  "boostBasic" : 4,
  "boostGrowth": 4,
  "sequencesMonthly": 5,
  "sequencesAnnual": 50,
  "agencyMonthly": 6,
  "agencyAnnual": 60,
  "enterpriseMonthly": 7,
  "enterpriseAnnual": 70,
  "guarantee": 4,
}

class StripeCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  handleCheckout = async (uid, planId, stripeCustomerId, email, plan) => {
    console.log(plan)
    console.log(uid)
    console.log(planId)
    console.log(email)
    this.setState({
      isLoading: true,
    })
    var fetchCheckoutSession = Firebase.functions().httpsCallable('fetchCheckoutSession');
    var data = { uid, planId, stripeCustomerId, email, plan };

    const res = await fetchCheckoutSession(data);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: res.data.sessionId,
    });
    if (error) {
      alert(error.message);
    }
  }

  render() {
    const { stripeCustomerId, email, uid } = this.props;
    const planId = stripePlanIds[this.props.plan];
    const plan = firestorePlanIds[this.props.plan]
    return (
      !this.state.isLoading ? (
        <a
          className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75"
          onClick={ () => this.handleCheckout(uid, planId, stripeCustomerId, email, plan) }
        >
          <span>Buy now!</span>
        </a>
      ) : ( <button className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75">Loading…</button> )
    );
  }
}

export default StripeCheckout;
