import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Helmet} from "react-helmet";
import Footer from './footer';
import Hero from '../../assets/requests-hero.svg';
import MobileHero from '../../assets/hero-mobile.svg';
import Nav from '../layout/navigation';
import Users from '../../assets/howler-users.svg';
import Media from '../../assets/media-outlets-prod.svg';
import Chad from '../../assets/chad.jpeg';
import Nile from '../../assets/nile.jpeg';
import Tim from '../../assets/optemization.jpg';
import Lukas from '../../assets/lukas.jpg';
import Will from '../../assets/will.jpeg';
import Jack from '../../assets/jack-darknet.jpg';
import SequenceSVG from '../../assets/pr-sequences-for-landing.svg';

class SequencesLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      searchTerm: "",
      isRequestActive: true,
      isJournalistActive: false,
      isPodcastActive: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleJournalistNav = () => {
    this.setState(prevState => ({
      isJournalistActive: !prevState.isJournalistActive,
      isPodcastActive: !prevState.isPodcastActive,
      isRequestActive: !prevState.isRequestActive
    }))
  }
  togglePodcastNav = () => {
    this.setState(prevState => ({
      isPodcastActive: !prevState.isPodcastActive,
      isJournalistActive: !prevState.isJournalistActive,
      isRequestActive: !prevState.isRequestActive
    }))
  }
  toggleRequestNav = () => {
    this.setState(prevState => ({
      isRequestActive: !prevState.isRequestActive,
      isPodcastActive: !prevState.isPodcastActive,
      isJournalistActive: !prevState.isJournalistActive
    }))
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>PR Sequences: Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets</title>
          <meta name="description" content="Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />

          <meta itemprop="name" content="PR Sequences: Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />
          <meta itemprop="description" content="Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />
          <meta itemprop="image" content="https://i.imgur.com/O0g9T0P.png" />

          <meta property="og:url" content="https://presshunt.co/requests" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="PR Sequences: Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />
          <meta property="og:description" content="Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />
          <meta property="og:image" content="https://i.imgur.com/O0g9T0P.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="PR Sequences: Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />
          <meta name="twitter:description" content="Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets" />
          <meta name="twitter:image" content="https://i.imgur.com/O0g9T0P.png" />

          <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

        </Helmet>

        <Nav />

        { this.state.width > 900 ? <img
          src={ Hero }
          className="is-overlay"
          alt="desktop hero"
        /> : <img
          src={ MobileHero }
          className="is-overlay"
          alt="mobile hero"
        />}

        <section className="section has-bg-image is-centered has-text-centered" style={{ height: "28em" }}>
        <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

          <div className="columns">
            <div className="column" style={{paddingTop:'0 !important'}}>
              <div className="container is-overlay has-padding-bottom">
                <div className="hero-div-width has-text-centered">
                  <div><p className="new-tag-landing-seq has-text-centered"><span className="new-tag-landing-span">NEW</span> PR Sequences</p></div>
                  <h1 className="title has-text-white has-text-weight-bold is-hero-title-center is-size-3">
                    Get press coverage by automatically emailing journalists when they mention keywords in articles & tweets
                  </h1>
                  <h2 className="subtitle has-text-white has-text-weight-normal is-hero-title-center is-size-4" style={{opacity:'.75'}}>
                    With PR Sequences you can build trigger-based email campaigns to pitch & build relationships with journalists while you sleep!
                  </h2>
                   <br />
                </div>

                <a className="button is-stripe-hero-exclamation" href="/signup">Get started for free&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
                <AnchorLink href="#sequence" className={ this.state.isActive ? 'button is-hiw-hero' : 'button is-hiw-hero-exclamation'}>Learn more</AnchorLink>
                <p className="has-text-white is-size-6 is-hero-sub"></p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns has-text-centered is-centered">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2 has-padding-bottom">
                  <span role="img" aria-label="darts">🎯</span>
                </div>
                <div className="title is-size-4 para-title">
                  Target journalists by the beats they cover, location, and by the sentiment of their writing
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="love letter">💌</span>
                </div>
                <div className="title is-size-4 para-title">
                  Automatically send personalized, targeted emails to journalists anytime they cover your industry
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="newspaper">🚀</span>
                </div>
                <div className="title is-size-4 para-title">
                  Perfect for pitching stories to journalists, building relationships, and managing crisis management
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="has-text-centered is-centered">
            <p className="is-size-6 has-text-weight-normal used-by">Our PR database has been used to run PR campaigns for companies like:</p>
            <img
              src={ Users }
              style={{width:'100%',zIndex:'0'}}
              alt="User logos"
            />
            <a href="https://www.producthunt.com/posts/press-hunt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt" target="_blank"><img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=123406&theme=dark"} alt="Press Hunt - Find the best journalists to cover you with our DB of 500k 🎉 | Product Hunt Embed" style={{width: "250px", height: "54px", marginTop:'2em'}} /></a>
            <a href="https://www.producthunt.com/posts/press-hunt-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt-2-0" target="_blank"><img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=141052&theme=dark"} style={{width: "250px", height: "54px", marginLeft:'1.25em'}} /></a>
            <a href="https://www.producthunt.com/posts/pr-requests?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pr-requests" target="_blank"><img src={"https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180815&theme=dark&period=daily"} style={{width: "250px", height: "54px", marginLeft:'1.25em'}} /></a>
          </div>
        </section>

        <section className="section is-paddingless">
          <img
            src={ Media }
            style={{width:'100%',zIndex:'0'}}
            alt="media logos"
          />
        </section>

        <section className="section" id="sequence">
          <div className="container clear-section-card has-text-centered">
            <h2 className="title has-text-centered is-centered">What is a PR sequence?</h2>
            <p className="subtitle has-text-centered is-centered pricing-sub" style={{marginBottom:'0',paddingBottom:'40px'}}>PR Sequences are trigger-based email campaigns you create to email journalists automatically when they mention specific keywords in their tweets or articles. You can set sequences to trigger only in specific cases, like when a journalist in <u>California</u> who covers <u>technology</u> at a <u>global</u> media outlet mentions <u>“Tesla Model 3”</u> with <u>positive</u> sentiment.</p>
            <img
              src={ SequenceSVG }
              style={{width:'750px !important', maxWidth:'750px', maxHeight: '500px'}}

            />
            <div>
              <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </section>

        <section className="section" id="howitworks">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">How it works</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Getting started with Press Hunt only takes a few seconds!</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="zap">⚡️</span>
                </div>
                <div className="title is-size-4 para-title">
                  1. Every day Press Hunt monitors tweets & articles from hundreds of thousands of journalists
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  We monitor journalists from all over the world covering every beat
                </p>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="love letter">💌</span>
                </div>
                <div className="title is-size-4 para-title">
                  2. You create sequences that automatically send personalized emails to journalists when they write or tweet specific keywords
                </div>
              </div>
              </div>
              <div className="columns">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="crystal ball">🔮</span>
                </div>
                <div className="title is-size-4 para-title">
                  3. You can target journalists by beats, locations, sentiment, and outlet reach (global vs. local)
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  Only journalists that match your targeting filters will trigger your sequences
                </p>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="email outbox">📨</span>
                </div>
                <div className="title is-size-4 para-title">
                  4. When target journalists mention your keywords, we automatically send them a personalized email from your email address
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="section has-background-white">
          <div className="container has-text-centered is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Why PR Sequences?</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">PR Sequences is the most effective way to get media coverage for your business</p>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="handshake">🤝</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Maximize press coverage by never missing an opportunity to connect with a relevant journalist</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="zap">⚡️</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Pitch journalists, build relationships, and manage PR crises automatically</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
            <div className="column is-feature-col">
              <div className="has-text-centered">
                <div className="is-size-2">
                  <span role="img" aria-label="email outbox">📨</span>
                </div>
                <p className="title has-text-weight-bold is-size-4">Automatically send emails to journalists from your own email address, and receive replies in your own inbox</p>
                <p className="subtitle is-size-6 is-feature-para"></p>
              </div>
            </div>

              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="crystal ball">🔮</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Target journalists based on the beats & topics they cover, their location (city or country), the sentiment of their tweet/article, and the reach of the outlet they write for (global or local)</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="charts">📊</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Track sending stats of your sequences in our dashboard to see how many emails you’ve sent and your open & click rates</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>

              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="love letter">💌</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Send personalized emails to journalists using their first names</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
          </div>
        </section>

        <section className="section">
          <div className="container has-text-centered is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Use-cases & examples of PR Sequences in action</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub"></p>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="loud speaker">📣</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">When a politician has a rally in <u>Alabama</u> in a few months, they can send emails letting journalists who cover <u>local politics</u> in the area know when they <u>tweet</u> the <u>politician’s name</u> with <u>positive</u> sentiment</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="money bag">💰</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">When a startup raises $2M, they can send their fundraising announcements to <u>technology</u> journalists in <u>their area</u> when they write <u>articles</u> about other fundraising announcements</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
            <div className="column is-feature-col">
              <div className="has-text-centered">
                <div className="is-size-2">
                  <span role="img" aria-label="emergency siren">🚨</span>
                </div>
                <p className="title has-text-weight-bold is-size-4">When a company has a PR crisis, they can send emails to journalists when they mention the crisis (or specific details about it) with <u>negative</u> sentiment to try to resolve their concerns</p>
                <p className="subtitle is-size-6 is-feature-para"></p>
              </div>
            </div>

              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="cannabis">🍃</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">When a company launches a new cannabis product, they can email journalists in <u>Colorado</u> and <u>Oregon</u> with requests to share product samples with them whenever they <u>tweet</u> about <u>“weed”</u> or <u>“cannabis”</u> or <u>“smoking weed”</u></p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="books">📚</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">When an expert publishes a new book on cancer biology, they can share their book with journalists who cover <u>“biology”</u> and <u>“medicine”</u> whenever they mention <u>“cancer cure”</u> in an <u>article</u> or <u>tweet</u></p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>

              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="love letter">💌</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">When a journalist mentions you with <u>positive</u> sentiment, send them a thank you email to start building a relationship with them!</p>
                  <p className="subtitle is-size-6 is-feature-para"></p>
                </div>
              </div>
            </div>
            <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
          </div>
        </section>

        <section className="section">
          <div className="container clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Our users love us</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Our database has been used by dozens of companies from all over the world</p>
            </div>
            <div className="columns">
            <div className="column is-hiw-card">
              <div className="subtitle is-size-5 has-text-weight-normal">
                "Thanks for your hard work. I did get this interview on the Mike Koolidge show, which I guess is going to be broadcast later today to thousands of people? This kind of persistence is NOT something I could do on my own. So thanks for your hard work on it. That's the first win! Yay! Also I have the BBC interview setup for Thursday. So we'll see what happens then!"
              </div>
              <div className="testimonial-profile-pic-wrapper">
                <img
                  src={ Jack }
                  className="is-testimonial-photo"
                  >
                </img>
                <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Jack Rhysider, Host of Darknet Diaries podcast</p>
              </div>
            </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "I've launched a number of projects that I look for press and often the most time consuming part is finding and researching relevant journalists. I was extremely please with how easy Press Hunt is to use. I had a whole list of journalists that I wanted to contact but was unable to find accurate emails and Press Hunt did the job. I would highly recommend it."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Lukas }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Lukas Bentel, Founder of Hello Velocity</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "Press Hunt has a seemingly endless database and tons of little details on each journalist - feels like clearbit for PR. Rounded off with a simple UI, this product is brilliant for anyone trying to get their name out there to relevant journalists."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Nile }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name"> Nile Frater, Founder of ConcreteCapital</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "I've seen Matt and his team get millions of impressions and coverage for clients. I highly recommend their services."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Chad }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Chad Grills, Founder & CEO of The Mission</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "They did an excellent job increasing traffic to our website. We saw a major lift in both SEO and ASO after working with them."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Will }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Will Jamieson, Founder of Yik Yak & The Q</p>
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "We’ve been using this to reach out to journalists about our hackathons and startup competition at NYU. Much better than researching reporters on Google and finding their emails manually."
                </div>
                <div className="testimonial-profile-pic-wrapper">
                  <img
                    src={ Tim }
                    className="is-testimonial-photo"
                    >
                  </img>
                  <p className="is-size-6 has-text-weight-semibold is-marginless is-testimonial-name">Tim Nugmanov, President of NYU Innovention Society</p>
                </div>
              </div>
              </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
            </div>

          </div>
        </section>


        <section className="section" id="FAQ">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">FAQ</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">If our FAQ doesn't answer your questions, please reach out to us in our live chat in the bottom right.</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What is a PR Sequence?
                </div>
                <p className="is-size-6">PR Sequences are trigger-based email campaigns you create to email journalists automatically when they mention specific keywords in their tweets or articles. You can set sequences to trigger only in specific cases, like when a journalist in <u>California</u> who covers <u>"technology"</u> at a <u>global</u> media outlet mentions <u>“Tesla Model 3”</u> with positive sentiment.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Do PR Sequences work in my industry?
                </div>
                <p className="is-size-6">PR Sequences work for every industry. We monitor hundreds of thousands of journalists covering every beat & topic you can imagine all over the world.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                How do you target journalists based on the sentiment of their articles & tweets?
                </div>
                <p className="is-size-6">We analyze every tweet & article for its sentiment. Our analysis is powered by Google’s Machine Learning API.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  When sequences send to journalists, do they send from my email address?
                </div>
                <p className="is-size-6">Yes, you can authenticate your domain with us, then we’ll use it to send emails on your behalf. Authenticating only takes a few minutes!</p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default SequencesLanding;
