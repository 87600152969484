import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import FireflySubscription from '../misc/FireflySubscription';
import Error from '../misc/Error';
import createList from '../../actions/createList';
import ListForm from './listForm';
import { Page } from '../../styles/layout';

const ListNew = ({ history }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-3xl">
    <FireflySubscription>
      {({ isLoading, error, subscription }) => {
        if (error) {
          return <Error error={error} />
        }
        if (isLoading) {
          return <span />
        }
        if (!subscription) {
          return (
            <section>
              <div className="is-sign-in-up has-text-centered">
                <h2 className="title">Subscribe now to create media list</h2>
                <NavLink to="/pricing" className="is-pricing-button is-centered">See pricing</NavLink>
              </div>
            </section>
          )
        }
        if (subscription.plan === 3 || subscription.plan === 30 || subscription.plan === 4 || subscription.plan === 40 || subscription.plan === 5 || subscription.plan === 50 || subscription.plan === 6 || subscription.plan === 60 || subscription.plan === 7 || subscription.plan === 70) {
          return (
            <div className="columns is-mobile is-centered">
              <div className="column">
                <ListForm
                  onSubmit={values => createList(values).then(list => history.push("/journalist-dashboard"))}
                />
              </div>
            </div>
          )
        }
        return  (
          <div className="columns is-mobile is-centered">
            <div className="column">
              <Link to="/pricing"><button className="button is-pricing-button">Upgrade to create list</button></Link>
            </div>
          </div>
        )
      }}
    </FireflySubscription>
  </div>
)

export default ListNew;
