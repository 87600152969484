import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Helmet} from "react-helmet";
import Footer from './footer';
import Hero from '../../assets/requests-hero.svg';
import MobileHero from '../../assets/hero-mobile.svg';
import Nav from '../layout/navigation';
import Users from '../../assets/howler-users.svg';
import Media from '../../assets/media-outlets-prod.svg';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  Stats,
} from 'react-instantsearch-dom';
import searchClient from '../Dashboard/algoliaCred';
import TrialSearchResult from '../search/FreeHeavenSearchResult';
import TrialPodcastSearchResult from '../search/FreeHeavenPodcastResult';
import FreeInfluencerResult from '../search/FreeHeavenInfluencerResult';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      searchTerm: "",
      isJournalistActive: false,
      isPodcastActive: false,
      isInfluencerActive: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleJournalistNav = () => {
     this.setState({
       isJournalistActive: true,
       isPodcastActive: false,
       isInfluencerActive: false
     })
   }
   togglePodcastNav = () => {
     this.setState({
       isPodcastActive: true,
       isJournalistActive: false,
       isInfluencerActive: false,
     })
   }
   toggleInfluencerNav = () => {
     this.setState({
       isPodcastActive: false,
       isJournalistActive: false,
       isInfluencerActive: true,
     })
   }

  render() {
    return (
      <div>
        <Helmet>
          <title>Influencer database complete with 300,000 influencers 🚀</title>
          <meta name="description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />

          <meta itemprop="name" content="Influencer database complete with 300,000 influencers 🚀" />
          <meta itemprop="description" content="Press Hunt's influencer database is a comprehensive influencer database complete with almost 300,000 influencers to share your business 🚀" />
          <meta itemprop="image" content="https://i.imgur.com/uOpUy46.png" />

          <meta property="og:url" content="https://presshunt.co/influencers" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Influencer database complete with 300,000 influencers 🚀" />
          <meta property="og:description" content="Press Hunt's influencer database is a comprehensive influencer database complete with almost 300,000 influencers to share your business 🚀" />
          <meta property="og:image" content="https://i.imgur.com/uOpUy46.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Influencer database complete with 300,000 influencers 🚀" />
          <meta name="twitter:description" content="Press Hunt's influencer database is a comprehensive influencer database complete with almost 300,000 influencers to share your business 🚀" />
          <meta name="twitter:image" content="https://i.imgur.com/uOpUy46.png" />

          <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

        </Helmet>

        <Nav />

        { this.state.width > 900 ? <img
          src={ Hero }
          className="is-overlay"
          alt="desktop hero"
        /> : <img
          src={ MobileHero }
          className="is-overlay"
          alt="mobile hero"
        />}

        <section className="section has-bg-image is-centered has-text-centered" style={{ height: "28em" }}>
        <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>

          <div className="columns">
            <div className="column">
              <div className="container is-overlay has-padding-bottom">
                <div className="hero-div-width">
                  <h1 className="title has-text-white has-text-weight-bold is-hero-title-center is-size-3">
                    Use Press Hunt's database of ~300k verified influencers to promote your business
                  </h1>
                  <h2 className="subtitle has-text-white has-text-weight-normal is-hero-title-center is-size-4" style={{opacity:'.75'}}>
                    Our influencer database saves you hundreds of hours by aggregating data on almost 300k verified twitter profiles in one place, complete with influencer score, description, location, and contact info
                  </h2>
                   <br />
                </div>

                <a className="button is-stripe-hero-exclamation" href="/signup">Get started for free&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
                <AnchorLink href="#pricing" className={ this.state.isActive ? 'button is-hiw-hero' : 'button is-hiw-hero-exclamation'}>Learn more</AnchorLink>
                <p className="has-text-white is-size-6 is-hero-sub">

                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns has-text-centered is-centered">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2 has-padding-bottom">
                  <span role="img" aria-label="dart">🎯</span>
                </div>
                <div className="title is-size-4 para-title">
                  ~300,000 influencers to pitch your business to today
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="magnifying glass">🔎</span>
                </div>
                <div className="title is-size-4 para-title">
                  Database complete with bios, twitter link, and 25k emails
                </div>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="doc">🗃</span>
                </div>
                <div className="title is-size-4 para-title">
                  Create lists of your favorites, email in-app, and export to CSV
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section is-paddingless">
          <img
            src={ Media }
            style={{width:'100%',zIndex:'0'}}
            alt="media logos"
          />
        </section>

        <section className="section has-background-white" id="search">
          <div className="container is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered" id="pricing">The most powerful media database on the internet</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">You can try using Press Hunt for free below. Premium data is redacted until you subscribe.</p>
            </div>
            <div className="has-text-centered">
              <div className="trial-search-wrapper is-centered">
              <div className="tabs">
                <ul>
                  <li className={this.state.isJournalistActive ? "is-trial-search-tab-active" : "is-trial-search-tab-inactive"}>
                    <a onClick={this.toggleJournalistNav}>
                      <span>Journalists</span>
                    </a>
                  </li>
                  <li className={this.state.isPodcastActive ? "is-trial-search-tab-active" : "is-trial-search-tab-inactive"}>
                    <a onClick={this.togglePodcastNav}>
                      <span>Podcasts</span>
                    </a>
                  </li>
                  <li className={this.state.isInfluencerActive ? "is-trial-search-tab-active" : "is-trial-search-tab-inactive"}>
                    <a onClick={this.toggleInfluencerNav}>
                      <span>Influencers</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className={this.state.isJournalistActive ? "trial-search-results-container is-centered" : "not-displayed"}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName="journalists-test"
                >
                  <SearchBox
                    autofocus
                    translations={{ placeholder: "Try searching our database for free…" }}
                    defaultRefinement={this.state.searchTerm}
                  />

                  <Configure hitsPerPage={3} />
                  <Hits hitComponent={TrialSearchResult} />
                  <Stats className="has-text-weight-semibold" />
                </InstantSearch>
              </div>
              <div className={this.state.isInfluencerActive ? "trial-search-results-container is-centered" : "not-displayed"}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName="verified_twitter"
                >
                  <SearchBox
                    autofocus
                    translations={{ placeholder: "Try searching our database for free…" }}
                    defaultRefinement={this.state.searchTerm}
                  />

                  <Configure hitsPerPage={3} />
                  <Hits hitComponent={FreeInfluencerResult} />
                  <Stats className="has-text-weight-semibold" />
                </InstantSearch>
              </div>
              <div className={this.state.isPodcastActive ? "trial-search-results-container is-centered" : "not-displayed"}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName="podcasts"
                >
                  <SearchBox
                    autofocus
                    translations={{ placeholder: "Try searching our database for free…" }}
                    defaultRefinement={this.state.searchTerm}
                  />

                  <Configure hitsPerPage={3} />
                  <Hits hitComponent={TrialPodcastSearchResult} />
                  <Stats className="has-text-weight-semibold" />
                </InstantSearch>
              </div>
              </div>
            </div>

          </div>
        </section>

        <section className="section" id="howitworks">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">How it works</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Getting started with Press Hunt only takes a few seconds!</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="magnifying glass">🔎</span>
                </div>
                <div className="title is-size-4 para-title">
                  1. Find relevant influencers
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  Search through our constantly evolving database of 300k verified Twitter influencers
                </p>

              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="star">⭐️</span>
                </div>
                <div className="title is-size-4 para-title">
                  2. Save your favorite influencers to your own media lists
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  Save the best influencers to your media lists so you can pitch them when you’re ready
                </p>
              </div>
              <div className="column is-hiw-card">
                <div className="circle-icon is-size-2">
                  <span role="img" aria-label="letter">💌</span>
                </div>
                <div className="title is-size-4 para-title">
                  3. Start pitching!
                </div>
                <p className="subtitle is-size-5 is-hiw-para">
                  Send emails to influencers in Press Hunt, or export their data to CSV and send them pitches with your own email system
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="has-text-centered is-centered">
            <p className="is-size-6 has-text-weight-normal used-by">Our database has been used to run PR campaigns for companies like:</p>
            <img
              src={ Users }
              style={{width:'100%',zIndex:'0'}}
              alt="User logos"
            />
            <a href="https://www.producthunt.com/posts/press-hunt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=123406&theme=dark"} alt="Press Hunt - Find the best journalists to cover you with our DB of 500k 🎉 | Product Hunt Embed"  className="mx-auto" style={{width: "250px", height: "54px", marginTop:'2em'}} />
            </a>
            <a href="https://www.producthunt.com/posts/press-hunt-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt-2-0" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=141052&theme=dark"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
            </a>
            <a href="https://www.producthunt.com/posts/pr-requests?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pr-requests" target="_blank" rel="noopener noreferrer">
              <img src={"https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180815&theme=dark&period=daily"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
            </a>
          </div>
        </section>

        <section className="section has-background-white">
          <div className="container has-text-centered is-centered clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered" id="pricing">What do you get with Press Hunt's influencer database?</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub"></p>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="dart">🎯</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">~300,000 verified influencers at your fingertips, made searchable</p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="globe">🌍</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Our influencers come from all over the world and speak dozens of languages</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="magnifying glass">🔎</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Filter through influencers by their location & if we have their email address</p>
                </div>
              </div>
              <div className="column is-feature-col">
                <div className="has-text-centered">
                  <div className="is-size-2">
                    <span role="img" aria-label="email">💌</span>
                  </div>
                  <p className="title has-text-weight-bold is-size-4">Build lists of your favorite journalists, email them in-app, or export their contact info to CSV</p>
                </div>
              </div>
            </div>
            <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
          </div>
        </section>

        <section className="section">
          <div className="container clear-section-card">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">Our users love us</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">Our database has been used by dozens of companies from all over the world</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "Press Hunt has a seemingly endless database and tons of little details on each journalist - feels like clearbit for PR. Rounded off with a simple UI, this product is brilliant for anyone trying to get their name out there to relevant journalists."
                </div>
                <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> Founder of ConcreteCapital</p>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "We’ve been using this to reach out to journalists about our hackathons and startup competition at NYU. Much better than researching reporters on Google and finding their emails manually."
                </div>
                <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> President of NYU Innovention Society</p>
              </div>
              <div className="column is-hiw-card">
                <div className="subtitle is-size-5 has-text-weight-normal">
                  "With Press Hunt you can market to the right audience by choosing journalists who write for the people you're trying to reach."
                </div>
                <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> Bitrix24 Tool of the Week</p>
              </div>
            </div>
            <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
              <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </section>


        <section className="section" id="FAQ">
          <div className="container clear-section-card-writing-samples">
            <div className="pricing-head has-text-centered is-centered has-text-black">
              <h2 className="title has-text-centered is-centered">FAQ</h2>
              <p className="subtitle has-text-centered is-centered pricing-sub">If our FAQ doesn't answer your questions, please reach out to us in our live chat in the bottom right.</p>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What kind of influencers do you have in this database?
                </div>
                <p className="is-size-6">We have hundreds of thousands of verified Twitter profiles in this database. We have about 25k emails. Verified Twitter profiles consist of public figures, media outlets, businesses, and reporters.</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What do I do with the journalist emails?
                </div>
                <p className="is-size-6">You can email each journalist one-by-one; or you can add them to your own media list, then export their emails to CSV. You can then take your CSV and upload to mass emailing software like MixMax or Reply.io.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  What is a media list?
                </div>
                <p className="is-size-6">A media list is simply a list of journalists created for a specific purpose. If you’re announcing a new product launch, you can put together a list of a few hundred journalists in your field that you think will be interested in covering your launch (based on the writing interests tags and other data we’ve provided for you). When you launch, you can send them all emails!</p>
              </div>
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  Why can’t I see influencer emails?
                </div>
                <p className="is-size-6">Our basic package doesn’t come with access to influencer emails––just their social media links. If you’re on a plan that includes emails, and you don’t see some, it is likely because we don’t have emails on record for that journalist or media outlet. This can be due to many reasons, from GDPR or privacy regulations to them not having their email publicly accessible.</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-hiw-card">
                <div className="title is-size-5">
                  How often do you update this database?
                </div>
                <p className="is-size-6">We update this journalist database usually daily to include the most recent contact information and labels.</p>
              </div>
              <div className="column is-hiw-card" style={{opacity:'0'}}>
                <div className="title is-size-5">

                </div>
                <p className="is-size-6"></p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default HomePage;
