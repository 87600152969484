import React from 'react';
import FireflySubscription from '../misc/FireflySubscription';
import AddToListButton from './addToListButton';
import Placeholder from '../../assets/placeholder.png';
import TwitterBadge from '../../assets/Twitter_Verified_Badge.svg';

const SearchResult = ({ hit }) => (
  <div className="container search-list is-centered">
    <FireflySubscription>
      {({ isLoading, subscription, plan }) => {
        if (isLoading) {
          return <span />
        } else {
          return (
            <div className="heaven-journalist-card journalist-card-dash">
              <div className="journalist-card-content">
                <div className="journalist-card-content-header">
                  <div className="heaven-contact-card-location">
                    <img src={hit.country_flag_url} className="heaven-contact-card-flag"/>
                    <div className="heaven-avatar-wrapper">
                      <img src={hit.avatar.replace("_normal","")} alt="" className="image journalist-card-picture" />
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" style={{ color: "#eff3f7" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </div>
                  <div className="py-2 px-4">
                    <div className="journalist-card-name-and-link">
                      <p className="journalist-card-name">{hit.name}</p>
                      <img src={TwitterBadge} alt="verified" className="image twitter-verified-badge" />
                      {/*checks to see if there's a journalist url*/}
                      {(hit.website) ? (
                        <a href={hit.website} target="_blank" rel="noopener noreferrer">
                          <div className="heaven-contact-card-url-tag">
                            <i className="fas fa-external-link-alt journalist-card-url-link"/>
                            <p className="heaven-content-card-url-text">{hit.website.replace("http://","").replace("www.","").replace("https://","").split("/",1)}</p>
                          </div>
                        </a>
                      ) : (hit.domain) ? (
                        <a href={hit.domain} target="_blank" rel="noopener noreferrer">
                          <div className="heaven-contact-card-url-tag">
                            <i className="fas fa-external-link-alt journalist-card-url-link"/>
                            <p className="heaven-content-card-url-text">{hit.domain.replace("http://","").replace("www.","").replace("https://","").split("/",1)}</p>
                          </div>
                        </a>
                      ) : <span /> }
                    </div>
                    {/*checks to see if there is outlet_text conditional*/}
                    <div className="journalist-card-type-outlet">
                    {hit.twitter_location}
                    </div>
                  </div>
                </div>
                {/*twitter bio*/}
                <div className="journalist-card-bio">
                  {hit.twitter_bio ? <p style={{marginBottom:'8px'}}>{hit.twitter_bio}</p> : <span /> }
                </div>
                <div className="journalist-card-tags-wrapper" style={{display:'flex'}}>
                  <div className="heaven-contact-card-tag">
                    <i className="fas fa-star heaven-nav-link-icon"/>
                    <p className="journalist-card-tag-text">{hit.press_hunt_score}</p>
                  </div>
                  <div className="heaven-contact-card-tag">
                    <i className="fas fa-user-friends heaven-nav-link-icon"/>
                    <p className="journalist-card-tag-text">{hit.followers_count} followers</p>
                  </div>
                </div>
                <div className="journalist-card-tags-wrapper" style={{display:'flex'}}>
                  <div className="heaven-contact-card-tag">
                    <i className="fas fa-chart-line heaven-nav-link-icon"/>
                    <p className="journalist-card-tag-text">{ hit.statuses_count ? hit.statuses_count.replace("lower","less active").replace("higher","more active") : ""}</p>
                  </div>
                  <div className="heaven-contact-card-tag">
                    <i className="fas fa-chart-bar heaven-nav-link-icon"/>
                    <p className="journalist-card-tag-text">{hit.followers_percentile_text ? hit.followers_percentile_text.replace("lower","less followers").replace("higher","more followers") : ""}</p>
                  </div>
                </div>
                {/*<div className="press-hunt-score">
                  <img src={PHScoreIcon} className="journalist-card-tag-icon" />
                  <p className="press-hunt-score-number">{hit.press_hunt_score}</p>
                </div>*/}
              </div>

              <div className="heaven-card-footer">
                <div className="columns heaven-card-footer-columns">
                  <div className="column is-one-quarters">
                    <div className="heaven-card-footer-buttons">
                      <div className="dropdown is-hoverable is-left">
                        <div className="dropdown-trigger">
                          <a className="heaven-card-white-button is-pulled-left"><i className="fas fa-address-card heaven-nav-button-icon"/>Contact<i className="fas fa-chevron-down heaven-nav-button-icon-right"/></a>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                          <ul className="dropdown-content heaven-card-dropdown-content">
                            {((hit.email) && (subscription.plan === 1 || subscription.plan === 10)) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                              </a>
                            ) : (hit.email && subscription.plan !== 1 && subscription.plan !== 10) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"mailto:" + hit.email} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope heaven-nav-link-icon"/>{hit.email}
                              </a>
                            ) : <span /> }
                            {((hit.secondary_email) && (subscription.plan === 1 || subscription.plan === 10)) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href="/pricing" target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                              </a>
                            ) : (hit.secondary_email) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"mailto:" + hit.secondary_email} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-envelope heaven-nav-link-icon"/>{hit.secondary_email}
                              </a>
                            ) : <span /> }
                            {(hit.phone && (subscription.plan === 3 || subscription.plan === 30 || subscription.plan === 4 || subscription.plan === 40 || subscription.plan === 5 || subscription.plan === 50 || subscription.plan === 6 || subscription.plan === 60 || subscription.plan === 7 || subscription.plan === 70)) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" style={{cursor: 'auto'}}>
                                <i className="fas fa-phone heaven-nav-link-icon"/>{hit.phone.replace("@","")}
                              </a>
                            ) : (hit.phone && subscription.plan !==3 && subscription.plan !==30 && subscription.plan !== 4 && subscription.plan !== 40 && subscription.plan !==5 && subscription.plan !==50 && subscription.plan !== 6 && subscription.plan !== 60 && subscription.plan !==7 && subscription.plan !==70 ) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href="/pricing">
                                <i className="fas fa-phone heaven-nav-link-icon"/>Upgrade to access<i className="fas fa-lock heaven-nav-link-icon-lock" />
                              </a>
                            ) : <span /> }
                            {(hit.twitter_name && hit.twitter_name.includes("://")) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"https://www.twitter.com/" + hit.twitter_name.substr(hit.twitter_name.lastIndexOf('/') + 1)} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter heaven-nav-link-icon"/>Twitter
                              </a>
                            ) : (hit.twitter_name) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={"https://twitter.com/" + hit.twitter_name} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter heaven-nav-link-icon"/>Twitter
                              </a>
                            ) : <span /> }
                            {(hit.linkedin_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.linkedin_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin heaven-nav-link-icon"/>LinkedIn
                              </a>
                            ) : <span /> }
                            {(hit.facebook_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.facebook_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook heaven-nav-link-icon"/>Facebook
                              </a>
                            ) : <span /> }
                            {(hit.instagram_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.instagram_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram heaven-nav-link-icon"/>Instagram
                              </a>
                            ) : <span /> }
                            {(hit.youtube_url) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.youtube_url} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-youtube heaven-nav-link-icon"/>YouTube
                              </a>
                            ) : <span /> }
                            {(hit.website) ? (
                              <a className="dropdown-item heaven-card-dropdown-item" href={hit.website} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-link heaven-nav-link-icon"/>Website
                              </a>
                            ) : <span /> }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="heaven-card-footer-buttons-right">
                      <AddToListButton profile={hit} dataType="influencer" />
                      {((hit.secondary_email) && (!subscription || subscription.plan === 1 || subscription.plan === 10)) ? (
                        <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fas fa-envelope heaven-nav-button-icon"/>Email<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
                      ) : ((hit.email) && (!subscription || subscription.plan === 1 || subscription.plan === 10)) ? (
                        <a className="heaven-card-white-button is-pulled-right" href="/pricing"><i className="fas fa-envelope heaven-nav-button-icon"/>Email<i className="fas fa-lock heaven-nav-link-icon-lock"/></a>
                      ) : ((hit.secondary_email) && (subscription && subscription.plan !== 1 && subscription.plan !== 10)) ? (
                        <a className="heaven-card-white-button is-pulled-right" href={"mailto:" + hit.secondary_email}><i className="fas fa-envelope heaven-nav-button-icon"/>Email</a>
                      ) : ((hit.email) && (subscription && subscription.plan !== 1 && subscription.plan !== 10)) ? (
                        <a className="heaven-card-white-button is-pulled-right" href={"mailto:" + hit.email}><i className="fas fa-envelope heaven-nav-button-icon"/>Email</a>
                      ) : (subscription && !hit.email && !hit.secondary_email && hit.twitter_name) ? (
                        <a className="heaven-card-white-button is-pulled-right" href={"https://twitter.com/" + hit.twitter_name} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter heaven-nav-button-icon"/>Tweet</a>
                      ) : <span /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }}
    </FireflySubscription>
  </div>
)

export default SearchResult;
