import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import FirebaseUser from '../misc/FirebaseUser';
import Error from '../misc/Error';
import StripeCheckout from '../subscriptions/StripeCheckout';
import StripeBillingPortal from '../subscriptions/StripeBillingPortal';
import HeroPricing from '../../assets/hero-pricing.svg';
import MobileHeroPricing from '../../assets/hero-pricing-mobile.svg';
import Nav from '../layout/navigation';
import FixedComponents from './fixedComponents';

const tiers = [
  {
    startup: true,
    name: '🛩️ Startup',
    href: '#',
    priceMonthly: 249,
    priceAnnually: 208.25,
    monthlyId: 'startupMonthly',
    annualId: 'startupAnnual',
    ctaButton: 'Buy now!',
    includedFeatures: [
      'Full access to database of 580k+ journalists, 200k+ podcasts, and ~300k influencers',
      'Full access to news monitoring database with thousands of news articles',
      'Monitor 10 million news articles per day',
      'Ability to bulk select 1,000 journalists at a time',
      'Unlimited journalist contact export',
      'Full access to hourly PR requests'
      
    ],
  },
  {
    premium: true,
    name: '🚀 Premium',
    href: '#',
    priceMonthly: 499,
    priceAnnually: 416.58,
    monthlyId: 'premiumMonthly',
    annualId: 'premiumAnnual',
    ctaButton: 'Buy now!',
    includedFeatures: [
      'Everything in Startup',
      'Two press release distributions per month included',
      'Automation-powered PR outreach'
    ],
  },
  {
    guarantee: true,
    name: 'Press Coverage Guarantee',
    href: '#',
    priceMonthly: 500,
    priceAnnually: 500,
    monthlyId: 'guarantee',
    annualId: 'guarantee',
    ctaButton: 'Buy now!',
    includedFeatures: [
      'Get you guarantee placement like Time, Entreprenuer, New York Daily News, and Boston Herald',
      '$500 per placement, guarantee 10 of them',
      'Charge 5 placements upfront',
    ],
  },
  {
    agency: true,
    name: '⭐️ PR Agency ⭐️',
    href: 'https://westvesey.pipedrive.com/scheduler/xZ15W6T0/15-minute-call-with-matt-ph',
    description: "Don't want to mess with a DIY platform and just want us to get you press coverage for you?",
    ctaButton: 'Book a call!',
    includedFeatures: [
      'Work with a trained PR team who has secured thousands of placements globally at outlets like WSJ, New York Times, Forbes, and TechCrunch',
      'Campaigns ran by a trained team of publicists with personal connections at elite outlets like the New York Times',
      'Transparent outreach processes and reporting',
    ],
  },
]

class StartupPricing extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.state = {
      checked: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleToggle() {
    let currentState = this.state.checked;
    this.setState({ checked: !currentState });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Press Hunt Pricing | An affordable journalist database 🚀</title>
          <meta name="description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta itemprop="name" content="Press Hunt is a database of 580,000 journalists 🚀" />
          <meta itemprop="description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta itemprop="image" content="https://i.imgur.com/uOpUy46.png" />
          <meta property="og:url" content="https://presshunt.co" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Press Hunt is a database of 580,000 journalists 🚀" />
          <meta property="og:description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta property="og:image" content="https://i.imgur.com/uOpUy46.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Press Hunt is a database of 580,000 journalists 🚀" />
          <meta name="twitter:description" content="Press Hunt is a comprehensive journalist database complete with almost 580,000 journalist emails to feature your business 🚀" />
          <meta name="twitter:image" content="https://i.imgur.com/uOpUy46.png" />
          <script type="text/javascript" src="https://load.fomo.com/api/v1/ksXVzd4vYzGtJY7N0vc1eQ/load.js" async></script>
        </Helmet>
        <div className="relative bg-blue-1000 sm:bg-gray-100 overflow-hidden">
          <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
              <img src={HeroPricing} />
          </div>
          <Nav />
          <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center">
              <h1 className="text-3xl font-extrabold text-gray-100 sm:text-center z-10">An affordable way to get your business in the news <span>🎯</span></h1>
              <p className="mt-5 text-xl text-gray-200 sm:text-center z-10">
                Press Hunt plans let businesses of all sizes access the media industry. Upgrade or cancel anytime.
              </p>
              <div className="relative self-center mt-6 bg-gray-200 rounded-lg p-0.5 flex sm:mt-8">
                <button
                  type="button"
                  onClick={this.state.checked ? this.handleToggle : null}
                  className={this.state.checked ? "relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-500 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8 m-1" : "m-1 relative w-1/2 bg-white border-gray-300 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8"}
                >
                  Monthly billing
                </button>
                <button
                  type="button"
                  onClick={!this.state.checked ? this.handleToggle : null}
                  className={this.state.checked ? "relative w-1/2 bg-white border-gray-300 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8 m-1" : "m-1 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-500 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8"}
                >
                  Yearly billing
                </button>
              </div>
            </div>
            <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
              {tiers.map((tier) => (
                <div key={tier.name} className="border border-gray-300 rounded-lg shadow-sm divide-y divide-gray-200 bg-white z-10">
                  <div className="text-center p-6">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                    {!tier.agency ? (
                      <div>
                        <p className="mt-8">
                          <span className="text-4xl font-extrabold text-gray-900">${this.state.checked ? tier.priceAnnually : tier.priceMonthly}</span>{' '}
                          <span className="text-base font-medium text-gray-600">{tier.guarantee ? '/placement' : '/mo'}</span>
                        </p>
                        <FirebaseUser>
                          {
                            ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {

                            if (isLoading)
                              return <button className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75">Buy now!</button>
                            if (error)
                              return <Error error={error} />
                            if (!auth)
                              return <Link to="/signup"><button className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75">Buy now!</button></Link>
                            if (subscribed)
                              return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                            if (this.state.checked)
                              return <StripeCheckout plan={tier.annualId} stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                            return <StripeCheckout plan={tier.monthlyId} stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                          }}
                        </FirebaseUser>
                        <p className="mt-4 text-sm text-gray-600">payments are processed securely through Stripe</p>
                      </div>
                  ) : (
                    <div>
                      <p className="mt-4 text-md text-gray-600">{tier.description}</p>
                      <a
                        href={tier.href}
                        className="mt-8 block w-full bg-blue-1000 rounded-md py-2 text-sm font-semibold text-white text-center hover:text-white hover:opacity-75"
                      >
                        {tier.ctaButton}
                      </a>
                    </div>
                  ) }
                  
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {tier.includedFeatures.map((feature) => (
                        <li key={feature} className="flex space-x-3">
                          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="text-sm text-gray-600">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <FixedComponents />
      </div>
    );
  }
}

export default StartupPricing;
