import { db } from '../api/firebase';
import { prepareDocForUpdate } from './helpers/firestoreHelpers';

const clearList = async listId => {
	var docRef = db.collection("lists").doc(listId);

	await clearEntries(listId);
	await updateIndex(docRef);
};

const clearEntries = listId => {
	console.log("Clearing entries")

  return db.collection(`lists/${listId}/entries`).get()
	  .then(snap => {
	    snap.docs.map(doc => doc.ref.delete());
	  })
    .catch( error => {
      alert(`Whoops, couldn't clear the list: ${error.message}`)
    })
};

const updateIndex = docRef => {
	console.log("Entering updateIndex function");
	let values = {};
	values.emailIndex = [];
  values.journalistIndex = [];
  values.podcastIndex = [];
  values.broadcasterIndex = [];
  values.influencerIndex = [];
	return docRef
	.update(prepareDocForUpdate(values))
  .catch( error => {
    alert(`Whoops, couldn't clear index: ${error.message}`)
  })
};

export default clearList;
