import Firebase from 'firebase/app';
import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateSequence = (sequenceId, values) => {
  return Firebase.firestore()
    .collection('sequences')
    .doc(sequenceId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Sorry, couldn't edit this sequence: ${error.message}`)
    })
}

export default updateSequence;
