import React from 'react';
import { NavLink } from 'react-router-dom';
import createSequence from '../../actions/sequence/createSequence';
import SequenceForm from './sequenceForm';

const SequenceNew = ({ history, auth, subscription, plan }) => (
  <div className="border-b border-gray-200 py-5 mt-10 mb-20 mx-auto max-w-5xl">
  {(!subscription || plan < 5 || (plan > 9 && plan < 50 )) ? (
    <div className="loading has-text-centered is-centered">
      <p>You must upgrade to create sequences</p>
      <NavLink to="/pricing" className="heaven-card-cta-button">Get Started</NavLink>
    </div>
  ) : (
    <section>
      <div className="columns">
        <div className="column is-one-thirds">
          <div className="is-dash-left-sequence-card-top">
            <p className="is-dash-left-sequence-card-title">Notes on creating sequences:</p>
            <p className="is-8 is-action-card-para">1. You can only build sequences that trigger on keywords in articles on the Agency and Enterprise plans.</p>
            <p className="is-8 is-action-card-para">2. Sequences won’t trigger on the same journalist more than once.</p>
            <p className="is-action-card-para">3. If you have multiple sequences set to send emails from the same email address, once one of them has been triggered, we will wait 1 week before a sequence from the same email address will be triggered by the same journalist again.</p>
          </div>
          <div className="is-dash-left-sequence-card-bottom">
            {(subscription.plan === 5 || subscription.plan === 50) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Sequences</span> plan with a limit of 10 sequences and a sending limit of 50 emails daily and 500 monthly.<br /><br /><a href="/account" className="is-link">Upgrade here for higher limits</a></p>
          ) : (subscription.plan === 6 || subscription.plan === 60) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Agency</span> plan with a limit of 100 sequences and a sending limit of 150 emails daily and 1,500 monthly.<br /><br /><a href="/account" className="is-link">Upgrade here for higher limits</a></p>
          ) : (subscription.plan === 7 || subscription.plan === 70) ? (
              <p className="is-action-card-para">You're on the <span className="is-strong">Enterprise</span> plan with a limit of 500 sequences and a sending limit of 300 emails daily and 3,000 monthly.</p>
          ) : (subscription.plan < 5 || subscription.plan === 10 || subscription.plan === 20 || subscription.plan === 30 || subscription.plan === 40) ? (
              <p className="is-action-card-para"><a href="/account" className="is-link">Upgrade today</a> to create your own sequences</p>
            ) : <p className="is-action-card-para"><a href="/pricing" className="is-link">Subscribe today</a> to create your own sequences</p>
            }
          </div>
        </div>
        <div className="column is-two-thirds">
          <SequenceForm
            onSubmit={values => createSequence(values).then(sequence => history.push("/sequence-dashboard"))}
          />
        </div>
      </div>
    </section>
  )}
  </div>
)

export default SequenceNew;
