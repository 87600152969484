import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import { withRouter } from 'react-router-dom';

import Loading from '../misc/Loading';
import Error from '../misc/Error';

import activateDomain from '../../actions/sequence/activateDomain';
import UnverifiedIcon from '../../assets/alert-octagon.svg';
import TxtRecIcon from '../../assets/txt-record.svg';
import DomainIcon from '../../assets/blue-globe.svg';
import ActDomainIcon from '../../assets/act-domain.svg';
import DomainActivatedIcon from '../../assets/domain-activated.svg';
import CheckStatsIcon from '../../assets/check-stats.svg';

class Domains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Status: false,
      loading: false,
    }
  }

  preActivateDomain = (domainId, domain) => {
    this.setState({
      loading: true,
    })
    activateDomain(domainId, domain)
      setTimeout(() => {
        this.setState({ loading: false });
      }, 8000);
  }

  render() {
    return (
      <FirestoreCollection
        path={`domains`}
        filter={['createdBy', '==', this.props.uid]}
      >
        { ({ error, isLoading, data }) => {
          if (error) {
            return <Error error={error} />
          }
          if (isLoading) {
            return <Loading />
          }
          if (!isLoading && data.length === 0) {
            return (
              <div className="is-sequence-card-empty">
                <div className="sequence-card-header" style={{borderBottom:'0'}}>
                  <div className="sequence-card-header-icon-and-title">
                    <img alt="" src={DomainIcon} className="sequence-card-icon-header" style={{opacity:'.5'}}></img>
                    <p className="sequence-card-header-title" style={{opacity:'.5'}}>tesla.com</p>
                  </div>
                </div>
                <div className="sequence-card-footer">
                  <div className="sequence-card-footer-button">
                    <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img alt="" src={DomainActivatedIcon} className="sequence-card-icon-standard"></img><span className="domain-auth-text">Authenticated</span></div>
                  </div>
                  <div className="sequence-card-footer-button">
                    <div className="sequence-card-footer-button-standard" style={{opacity:'.5', cursor:'default'}}><img alt="" src={CheckStatsIcon} className="sequence-card-icon-standard"></img><span className="sequence-card-footer-button-standard-text">Check stats</span></div>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div>
              {data.map(domain => (
                <div key={domain.id}>
                { domain.archived ? <span /> : <div className="is-sequence-card">
                    {domain.state === "active" ? <div className="sequence-card-header" style={{borderBottom:'0px'}}>
                      <div className="sequence-card-header-icon-and-title">
                        <img alt="" src={DomainIcon} className="sequence-card-icon-header"></img>
                        <p className="sequence-card-header-title">{domain.userDomain.replace("pr.","")}</p>
                      </div>
                    </div> : <div className="sequence-card-header">
                      <div className="sequence-card-header-icon-and-title">
                        <img alt="" src={DomainIcon} className="sequence-card-icon-header"></img>
                        <p className="sequence-card-header-title">{domain.userDomain.replace("pr.","")}</p>
                      </div>
                    </div>
                  }
                    <div className="domain-card-content">
                    { domain.state !== "active" ? <div className="domain-txt-record-wrapper">
                      <div className="domain-txt-record-item">
                        <div className="txt-rec-card-line">
                          <img alt="" src={TxtRecIcon} className="sequence-card-content-icon" />
                          <p className="sequence-card-content-text"><span className="is-strong">TXT Record</span></p>
                        </div>
                        <div className="txt-record-text">
                          <div className="txt-record-first">
                            <p className="txt-record-label">Hostname:</p>
                            <p style={{ margin:'0' }}>pr</p>
                          </div>
                          <div className="txt-record-value">
                            <p className="txt-record-label">Enter this value: </p>
                            <p style={{ margin:'0' }}>{domain.sending_dns_records ? domain.sending_dns_records[0].value : "v=spf1 include:mailgun.org ~all"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="domain-txt-record-item">
                        <div className="txt-rec-card-line">
                          <img alt="" src={TxtRecIcon} className="sequence-card-content-icon" />
                          <p className="sequence-card-content-text"><span className="is-strong">TXT Record</span></p>
                        </div>
                          <div className="txt-record-text">
                            <div className="txt-record-first">
                              <p className="txt-record-label">Hostname:</p>
                              <p style={{ margin:'0' }}>{domain.sending_dns_records ? domain.sending_dns_records[1].name.split(".pr.").shift() + ".pr" : "k1._domainkey"}</p>
                            </div>
                            <div className="txt-record-value">
                              {domain.sending_dns_records ? (<div><p className="txt-record-label">Enter this value: </p><p style={{ margin:'0' }}>{domain.sending_dns_records[1].value}</p></div>) : "Loading TXT Record…"}
                            </div>
                          </div>
                        </div>
                        <div className="domain-txt-record-item-last">
                          <div className="txt-rec-card-line">
                            <img alt="" src={TxtRecIcon} className="sequence-card-content-icon" />
                            <p className="sequence-card-content-text"><span className="is-strong">CNAME Record</span></p>
                          </div>
                          <div className="txt-record-text">
                            <div className="txt-record-first">
                              <p className="txt-record-label">Hostname:</p>
                              <p style={{ margin:'0' }}>email.pr</p>
                            </div>
                            <div className="txt-record-value">
                              <p className="txt-record-label">Enter this value: </p>
                              <p style={{ margin:'0' }}>{domain.sending_dns_records ? domain.sending_dns_records[2].value : "mailgun.org"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                     : <span />
                    }
                    </div>
                    {domain.state === "active" ? (
                      <div className="sequence-card-footer">
                        <div className="sequence-card-footer-button">
                          <div className="sequence-card-footer-button-archive" style={{cursor:'default',textTransform:'capitalize'}}>
                            <img alt="" src={DomainActivatedIcon} className="sequence-card-icon-standard" /><span className="active-domain-text">{domain.state}</span>
                          </div>
                        </div>
                        <div
                          className="sequence-card-footer-button"
                          // onClick={ () => this.handleStatsCheck(domain.id, domain)}
                          onClick={ () => this.props.history.push("/domain/stats/" + domain.id)}
                        >
                          <div className="sequence-card-footer-button-standard">
                            <img alt="" src={CheckStatsIcon} className="sequence-card-icon-standard" />
                            <span className="sequence-card-footer-button-standard-text">Check stats</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="sequence-card-footer">
                        <div className="sequence-card-footer-button">
                          <div className="sequence-card-footer-button-archive" style={{cursor:'default',textTransform:'capitalize'}}>
                            <img alt="" src={UnverifiedIcon} className="sequence-card-icon-standard" /><span className="domain-unverified-text">Unverifed</span>
                          </div>
                        </div>
                        {this.state.loading ? (
                          <div
                            className="sequence-card-footer-button"
                          >
                            <div className="sequence-card-footer-button-standard footer-button-onclick-styling">
                              <img alt="" src={ActDomainIcon} className="sequence-card-icon-standard" />
                              <span className="sequence-card-footer-button-standard-text footer-button-onclick-styling">Checking…</span>
                            </div>
                          </div> ) : (
                          <div
                            className="sequence-card-footer-button"
                            onClick={ () => this.preActivateDomain(domain.id, domain)}
                          >
                            <div className="sequence-card-footer-button-standard footer-button-onclick-styling">
                              <img alt="" src={ActDomainIcon} className="sequence-card-icon-standard" />
                              <span className="sequence-card-footer-button-standard-text footer-button-onclick-styling">Activate domain</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }
                  </div>
                }
                </div>
              ))}
            </div>
          )
        }}
      </FirestoreCollection>
    )
  }
}

export default withRouter(Domains);
