import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
// import googleLogIn from '../../actions/googleLogIn';
import Firebase from 'firebase/app';

const SignInPage = ({ history }) =>
  <section className="section">
    <div className="is-sign-in-up">
      <SignInForm
        history={history}
      />
    </div>
  </section>

class SignInForm extends Component {
  constructor(props) {
    super(props);
  }

  googleLogIn = () => {
    let provider = new Firebase.auth.GoogleAuthProvider()
    return Firebase.auth().signInWithPopup(provider)
    .then( () => {
      this.props.history.push("/dashboard");
    }).catch( error => {
      console.error('could not sign in', error)
      this.props.history.push("/");
    })
  }
  render() {
    return (
      <button onClick={this.googleLogIn} className="is-google-button"><i className="fab fa-google is-left-button-icon"></i>Sign in via Google</button>
    );
  }
}

const SignInLink = () =>
  <p className="is-sign-in-text">
    Aleady have an account?
    {' '}
    <Link to="/signin" className="is-link">Sign in!</Link>
  </p>


export default withRouter(SignInPage);

export {
  SignInForm,
  SignInLink,
};
