import React from 'react';

const MailBreadcrumb = ({ children }) => (
  <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 bg-white">
    <div className="flex-1 min-w-0">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">{children}</ol>
      </nav>
    </div>
  </div>
);

const MailBreadcrumbItem = ({ children }) => (
  <li>
    <div className="flex items-center">{children}</div>
  </li>
);

MailBreadcrumb.Item = MailBreadcrumbItem;

export default MailBreadcrumb;
