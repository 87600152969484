import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class UpgradeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatBoxOpen: false,
    };
  }

  handleCrispClick () {
    this.setState({
      chatBoxOpen: true,
    })
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "99788d98-6b5f-41ad-9705-497d0dbff310";
    window.$crisp.push(['do', 'chat:open']);

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  render() {
  	return (
		  <div className="heaven-dash-cta-box">
		    <p className="heaven-cta-box-title"><i className="fas fa-star heaven-cta-box-icon"/>Upgrade today for full access</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Emails, phone numbers, and social media profiles</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Search filters</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Save 1,000 journalists at a time</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Create lists & export them to CSV</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Send emails from your own email address</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Reply to source requests</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Set alerts when journalists mention keywords</p>
		    <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Send automated emails when journalists mention keywords</p>
        <p className="heaven-cta-box-item"><i className="fas fa-check-circle heaven-cta-box-icon green-icon"/>Podcast audience & engagement metrics</p>
		    <div className="heaven-cta-box-buttons">
		      <button className="heaven-card-page-white-button" style={{ padding:'6px 9px', cursor:'pointer', fontSize:'14px', height: '32px' }} onClick={ ()=> this.handleCrispClick() }><i className="fas fa-comment heaven-nav-button-icon" />Questions?</button>
		      <NavLink to="/pricing" className="heaven-page-cta-button" style={{ padding:'6px 9px', fontSize:'14px', height: '32px' }}><i className="fas fa-star heaven-nav-button-icon" />Upgrade</NavLink>
		    </div>
		  </div>
  	)
  }
}

export default UpgradeCard;
