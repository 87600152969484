import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { subMenu } from "../../path";
import MenuSideBar from "../../menuSideBar";
import SubMenuSideBar from "../../subMenuSideBar";
import MailBreadcrumb from "../../breadcrumb";
import Transition from "../../../../styles/Transition.js";
import { getConnectMailUri } from "../../../../api/mail";

const SettingsEmailSync = () => {
  const { pathname } = useLocation();

  const [mailAccount, setMailAccount] = useState("");
  const [mailAccountModalOn, setMailAccountModalOn] = useState(false);
  const [syncedConfirmModalOn, setSyncedConfirmModalOn] = useState(false);

  return (
    <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
      <div className="min-h-0 flex-1 flex overflow-hidden">
        <MenuSideBar pathname={pathname} />

        <SubMenuSideBar pathname={pathname} path={subMenu.settings} />

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <MailBreadcrumb>
            <MailBreadcrumb.Item>
              <>
                <NavLink to="/" className="text-gray-400 hover:text-gray-500">
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </NavLink>
              </>
            </MailBreadcrumb.Item>

            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>

                <span
                  to="/mail/new"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Settings
                </span>
              </>
            </MailBreadcrumb.Item>
            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Email sync
                </span>
              </>
            </MailBreadcrumb.Item>
          </MailBreadcrumb>

          {/* Main Content */}
          <>
            {/* <div
              className={`${
                mailAccountModalOn ? "block z-10" : "hidden"
              } fixed inset-0 overflow-y-auto`}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                  show={mailAccountModalOn}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                  >
                    <div className="mt-3 text-center sm:mt-5">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        Connect email account
                      </h3>
                    </div>

                    <div className="px-24 py-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          value={mailAccount}
                          onChange={event => setMailAccount(event.target.value)}
                          type="email"
                          name="email"
                          id="email"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="you@example.com"
                          aria-describedby="email-description"
                        />
                      </div>
                    </div>

                    <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <span className="flex w-full mt-3 rounded-lg shadow-sm sm:ml-3 sm:w-auto">
                        <button
                          type="button"
                          onClick={async () => {
                            await checkConnectMail(mailAccount);
                            await setMailAccount("");
                            await setMailAccountModalOn(false);
                            // await setSyncedConfirmModalOn(true);
                          }}
                          disabled={mailAccount === ""}
                          className={`${
                            mailAccount === "" ? "opacity-50 " : ""
                          }inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:opacity-75 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                        >
                          Continue
                        </button>
                      </span>
                      <span className="mt-3 flex w-full rounded-lg shadow-sm sm:mt-0 sm:w-auto">
                        <button
                          type="button"
                          onClick={() => setMailAccountModalOn(false)}
                          className="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </Transition>
              </div>
            </div> */}

            {/* Which emails should be synced? */}
            <div
              className={`${
                syncedConfirmModalOn ? "block z-10" : "hidden"
              } fixed inset-0 overflow-y-auto`}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                  show={syncedConfirmModalOn}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                  >
                    <div className="mt-3 text-center sm:mt-5">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        Which emails should be synced?
                      </h3>
                    </div>

                    {/* <div className="px-16 py-4">
                      <p className="py-4 text-xs text-gray-600 border-b border-gray-200">
                        You're about to connect milletbard@gmail.com with
                        Pipedrive.
                      </p>

                      <div className="bg-white rounded-md -space-y-px">
                        <h1 className="text-sm py-4 font-bold">
                          Sync emails with:
                        </h1>

                        {defSyncEmailsWith.map((item) => (
                          <label
                            key={item.label}
                            className="border-gray-200 rounded-tl-md rounded-tr-md relative border p-2 flex cursor-pointer"
                          >
                            <input
                              type="radio"
                              name="defSyncEmailsWith"
                              value="Public access"
                              className="h-4 w-4 my-auto cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                              aria-labelledby="privacy-setting-0-label"
                              aria-describedby="privacy-setting-0-description"
                            />
                            <div className="ml-3 flex flex-col">
                              <span
                                id="privacy-setting-0-label"
                                className="text-gray-900 block text-xs font-medium"
                              >
                                {item.label}
                              </span>
                            </div>
                          </label>
                        ))}

                        <h1 className="text-sm py-4 font-bold">
                          Sync starting from:
                        </h1>

                        {defSyncStartingFromDate.map((item) => (
                          <label
                            key={item.label}
                            className="border-gray-200 rounded-tl-md rounded-tr-md relative border p-2 flex cursor-pointer"
                          >
                            <input
                              type="radio"
                              name="defSyncStartingFromDate"
                              value="Public access"
                              className="h-4 w-4 my-auto cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                              aria-labelledby="privacy-setting-1-label"
                              aria-describedby="privacy-setting-1-description"
                            />
                            <div className="ml-3 flex flex-col">
                              <span
                                id="privacy-setting-1-label"
                                className="text-gray-900 block text-xs font-medium"
                              >
                                {item.label}
                              </span>
                            </div>
                          </label>
                        ))}

                        <h1 className="text-sm py-4 font-bold">
                          Sync past emails sent to and received from:
                        </h1>

                        {defSyncPastEmailsSentToAndReceivedFrom.map((item) => (
                          <label
                            key={item.label}
                            className="border-gray-200 rounded-tl-md rounded-tr-md relative border p-2 flex cursor-pointer"
                          >
                            <input
                              type="radio"
                              name="defSyncPastEmailsSentToAndReceivedFrom"
                              value="Public access"
                              className="h-4 w-4 my-auto cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                              aria-labelledby="privacy-setting-1-label"
                              aria-describedby="privacy-setting-1-description"
                            />
                            <div className="ml-3 flex flex-col">
                              <span
                                id="privacy-setting-1-label"
                                className="text-gray-900 block text-xs font-medium"
                              >
                                {item.label}
                              </span>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div> */}

                    <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <span className="flex w-full mt-3 rounded-lg shadow-sm sm:ml-3 sm:w-auto">
                        <button
                          type="button"
                          onClick={async () => {
                            await setSyncedConfirmModalOn(false);
                          }}
                          className="inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                        >
                          Start syncing
                        </button>
                      </span>
                      <span className="mt-3 flex w-full rounded-lg shadow-sm sm:mt-0 sm:w-auto">
                        <button
                          type="button"
                          onClick={() => setSyncedConfirmModalOn(false)}
                          className="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>

            <div className="mt-8 max-w-100 mx-auto grid grid-cols-1 sm:px-6 lg:grid-flow-col-dense">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                {/* Description list */}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-56 py-24">
                      <h2
                        id="applicant-information-title"
                        className="text-4xl leading-6 font-medium text-gray-900 font-bold"
                      >
                        Email sync
                      </h2>
                    </div>

                    <div className="border-t border-gray-200 px-64 py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                          <dt className="text-lg text-sm font-medium text-gray-800">
                            Unlock your Sales Inbox
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            Tab switching and manually forwarding emails to
                            Pipedrive is a thing of the past. When unlocked, you
                            can use your Sales Inbox to send email directly from
                            Pipedrive while automatically linking them to
                            related deals and contacts.
                          </dd>

                          <section className="pl-12 py-4">
                            <div className="flex">
                              <CheckIcon />
                              <span>
                                Sync Pipedrive emails with any major email
                                provider
                              </span>
                            </div>
                            <div className="flex">
                              <CheckIcon />
                              <span>Track email opens and clicks</span>
                            </div>
                            <div className="flex">
                              <CheckIcon />
                              <span>
                                Save time by making use of customizable
                                templates
                              </span>
                            </div>
                            <div className="flex">
                              <CheckIcon />
                              <span>
                                Customize your signature for a more professional
                                look
                              </span>
                            </div>
                          </section>

                          <div className="px-12 py-2 flex justify-end">
                            <button
                              onClick={
                                () =>
                                  getConnectMailUri('gmail').then(res => {
                                    window.location.href = res.data.connectUrl;
                                  })
                                // setMailAccountModalOn(!mailAccountModalOn)
                              }
                              type="button"
                              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                            >
                              <span>Add new account</span>
                            </button>
                          </div>

                          {/* <div className="flex is-align-items-center p-12 py-4 border-gray-400 border">
                            <GoogleIcon className="m-4" />
                            <div>
                              <div>milletbard@gmail.com</div>
                              <div>Ready to connect</div>
                            </div>

                            <button className="ml-auto mr-4  inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:opacity-75 focus:outline-none transition ease-in-out duration-150">
                              Connect
                            </button>
                          </div> */}
                        </div>
                      </dl>
                    </div>

                    {/* <div className="border-t border-gray-200 px-64 py-5">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                          <dt className="text-lg text-sm font-medium text-gray-800">
                            Smart Email BCC
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            When sending emails, include your unique company
                            email address (i.e. milletbard@pipedrivemail.com),
                            to the BCC field and these conversations will be
                            sent to your Pipedrive mail; they'll automatically
                            be linked to the relevant contacts and deals when
                            applicable.
                          </dd>

                          <div className="px-12 py-2 flex justify-end">
                            <button
                              type="button"
                              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                            >
                              <span>Use Smart BCC</span>
                            </button>
                          </div>
                        </div>
                      </dl>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </>
        </main>
      </div>
    </div>
  );
};

export default SettingsEmailSync;

const GoogleIcon = ({ className }) => (
  <svg
    className={className}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    {/* <!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch --> */}
    <title>gmail</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M5.3432748e-05,3.14316198 L4.02929442,0.0146375584 C4.99088224,2.76721299 7.61029879,4.74181818 10.6909091,4.74181818 C12.2247923,4.74181818 13.6443365,4.25227462 14.8018254,3.42090376 L18.7358212,6.59129843 C16.6074986,8.51546866 13.7860486,9.68727273 10.6909091,9.68727273 C6.02815348,9.68727273 1.98652855,7.0278961 5.3432748e-05,3.14316198 Z"
        id="path-1"
      />
      <path
        d="M1.36065878,0.0154328679 L5.38668495,3.10967132 C5.10138264,3.87596238 4.94545455,4.70522625 4.94545455,5.57090909 C4.94545455,6.41837079 5.09488768,7.2309305 5.36884177,7.98367606 L1.31740558,11.0429238 C0.475246416,9.40214109 0,7.54202496 0,5.57090909 C0,3.56629539 0.491536673,1.67648624 1.36065878,0.0154328679 Z"
        id="path-3"
      />
      <path
        d="M4.04850313,9.53876223 L0.0224769599,6.44452378 C2.02674369,2.61399871 6.03901488,0 10.6618182,0 C13.9856718,0 16.9938932,1.35138262 19.1670215,3.53468721 L15.6827677,7.0445292 C14.4036529,5.74861903 12.6265301,4.94545455 10.6618182,4.94545455 C7.63138315,4.94545455 5.04724088,6.85626103 4.04850313,9.53876223 Z"
        id="path-5"
      />
    </defs>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="gmail">
        <g id="Google">
          <g
            id="Group-5"
            transform="translate(12.072727, 9.512727)"
            fill="#4285F4"
          >
            <path
              d="M7.97308888,11.3904811 L4.04232189,8.21793308 C5.17052163,7.40636857 6.04932446,6.26987385 6.54172473,4.94545459 L-3.55271368e-15,4.94545455 L-3.55271368e-15,0 L11.6691743,0 C11.8383155,0.802465055 11.9272727,1.63448036 11.9272727,2.48727273 C11.9272727,2.90851864 11.9055674,3.324695 11.8632142,3.73474444 C11.5504666,6.76265994 10.1118408,9.45648948 7.97308888,11.3904811 Z"
              id="Combined-Shape"
              transform="translate(5.963636, 5.695241) rotate(2.000000) translate(-5.963636, -5.695241) "
            />
          </g>
          <g id="Group-5" transform="translate(1.309091, 14.312727)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <use id="Combined-Shape" fill="#34A853" xlinkHref="#path-1" />
          </g>
          <g id="Group-5" transform="translate(0.000000, 6.429091)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3" />
            </mask>
            <use id="Combined-Shape" fill="#FBBC05" xlinkHref="#path-3" />
          </g>
          <g id="Group-5" transform="translate(1.338182, 0.000000)">
            <mask id="mask-6" fill="white">
              <use xlinkHref="#path-5" />
            </mask>
            <use id="Combined-Shape" fill="#EA4335" xlinkHref="#path-5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-green-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);
