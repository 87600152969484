import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useLocation, Link } from 'react-router-dom';
import { subMenu } from '../path';
import MenuSideBar from '../menuSideBar';
import SubMenuSideBar from '../subMenuSideBar';
import MailBreadcrumb from '../breadcrumb';
import EditorComponent from '../Editor';
import Transition from "../../../styles/Transition";

const MailInfo = () => {
  const location = useLocation();

  const [mailInfo, setMailInfo] = useState([]);
  const [mailInfoList, setMailInfoList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [target, setTarget] = useState('/mail/Inbox');

  const [body, setBody] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [mailSendSuccessModalOn, setMailSendSuccessModalOn] = useState('');

  const createContent = (body) => ({ __html: body });
  useEffect(() => {
    if (location.state?.item) {
      setMailInfo(location.state.item);
      setMailInfoList([location.state.item]);
    }
    if (location.state?.offset) {
      setOffset(location.state.offset);
    }
    if (location.state?.target) {
      setTarget(location.state.target)
    }
  }, []);

  console.log(mailInfo);
  return (
    <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
      <div className="min-h-0 flex-1 flex overflow-hidden">

        <SuccessModal
          modalOpen={mailSendSuccessModalOn}
          setModalOpen={setMailSendSuccessModalOn}
          target={target}
          offset={offset}
        />

        <Sending
          isSending={isSending}
          setIsSending={setIsSending}
        />

        <SubMenuSideBar pathname={location.pathname} path={subMenu.mail} />

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <MailBreadcrumb>
            <MailBreadcrumb.Item>
              <>
                <Link to="/mail/inbox" className="text-gray-400 hover:text-gray-500">
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </Link>
              </>
            </MailBreadcrumb.Item>
            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  to="/mail/new"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Info
                </span>
              </>
            </MailBreadcrumb.Item>
          </MailBreadcrumb>

          <div className="mt-8 max-w-100 mx-auto grid grid-cols-1 sm:px-6 lg:grid-flow-col-dense">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-4">
                    <div className="flex is-justify-content-space-between">
                      <Link
                        to={`${target}?offset=${offset}`}
                        type="button"
                        className="m-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-600 bg-white hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Back
                      </Link>

                      <Link
                        to="/settings/emailsync"
                        type="button"
                        className="m-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-600 bg-white hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                        style={{ display: 'none' }}
                      >
                        <SettingIcon />
                      </Link>
                    </div>

                    {/*  mail content */}
                    {mailInfoList.map((mailInfo) => {
                      return (
                        <div className="my-2 p-3 bg-white shadow rounded-sm">
                          <div className="m-4">
                            <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                              <div>
                                <h1 className="text-2xl font-bold text-gray-900">
                                  {mailInfo.subject}
                                </h1>
                                <div className="md:flex flex-wrap truncate mt-2 text-sm text-gray-500">
                                  <h3 className="pr-2">
                                    To:
                                  </h3>
                                  <ToList mailInfo={mailInfo} />
                                </div>
                                <div className="md:flex flex-wrap truncate mt-2 text-sm text-gray-500">
                                  <h3 className="pr-2">
                                    Sent At:
                                  </h3>
                                  <Moment className="font-medium text-gray-900" date={mailInfo.sent_at} format="MM/DD/YYYY HH:mm" />
                                </div>

                              </div>
                              <div className="mt-4 flex space-x-3 md:mt-0">
                                <button
                                  type="button"
                                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                  style={{ display: 'none' }}
                                >
                                  <svg
                                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                    x-description="Heroicon name: solid/pencil"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                  <span>Edit</span>
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                                  style={{ display: 'none' }}
                                >
                                  <svg
                                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                    x-description="Heroicon name: solid/bell"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                                  </svg>
                                  <span>Subscribe</span>
                                </button>
                              </div>
                            </div>
                            <aside className="mt-8 xl:hidden" style={{ display: 'none' }}>
                              <h2 className="sr-only">Details</h2>
                              <div className="space-y-5">
                                <div className="flex items-center space-x-2">
                                  <svg
                                    className="h-5 w-5 text-green-500"
                                    x-description="Heroicon name: solid/lock-open"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                                  </svg>
                                  <span className="text-green-700 text-sm font-medium">
                                    Open Issue
                                  </span>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <svg
                                    className="h-5 w-5 text-gray-400"
                                    x-description="Heroicon name: solid/chat-alt"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="text-gray-900 text-sm font-medium">
                                    4 comments
                                  </span>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <svg
                                    className="h-5 w-5 text-gray-400"
                                    x-description="Heroicon name: solid/calendar"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="text-gray-900 text-sm font-medium">
                                    Created on
                                    {' '}
                                    <time dateTime="2020-12-02">Dec 2, 2020</time>
                                  </span>
                                </div>
                              </div>
                              <div className="mt-6 border-t border-b border-gray-200 py-6 space-y-8">
                                <div>
                                  <h2 className="text-sm font-medium text-gray-500">
                                    Assignees
                                  </h2>
                                  <ul className="mt-3 space-y-3">
                                    <li className="flex justify-start">
                                      <a
                                        href="#"
                                        className="flex items-center space-x-3"
                                      >
                                        <div className="flex-shrink-0">
                                          <img
                                            className="h-5 w-5 rounded-full"
                                            src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=8&amp;w=256&amp;h=256&amp;q=80"
                                            alt=""
                                          />
                                        </div>
                                        <div className="text-sm font-medium text-gray-900">
                                          Eduardo Benz
                                        </div>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  <h2 className="text-sm font-medium text-gray-500">
                                    Tags
                                  </h2>
                                  <ul className="mt-2 leading-8">
                                    <li className="inline">
                                      <a
                                        href="#"
                                        className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                      >
                                        <div className="absolute flex-shrink-0 flex items-center justify-center">
                                          <span
                                            className="h-1.5 w-1.5 rounded-full bg-rose-500"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <div className="ml-3.5 text-sm font-medium text-gray-900">
                                          Bug
                                        </div>
                                      </a>
                                      {/* <!-- space --> */}
                                    </li>
                                    <li className="inline">
                                      <a
                                        href="#"
                                        className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                      >
                                        <div className="absolute flex-shrink-0 flex items-center justify-center">
                                          <span
                                            className="h-1.5 w-1.5 rounded-full bg-indigo-500"
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <div className="ml-3.5 text-sm font-medium text-gray-900">
                                          Accessibility
                                        </div>
                                      </a>
                                      {/* <!-- space --> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </aside>

                            <div className="py-3 xl:pt-6 xl:pb-0">
                              <h2 className="sr-only">Description</h2>
                              <div className="prose max-w-none">
                                <div dangerouslySetInnerHTML={createContent(mailInfo?.body)} />
                              </div>
                            </div>
                          </div>
                        </div>
                    )}
                  )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MailInfo;

// const DsaIcon = () =>
const SettingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
      clipRule="evenodd"
    />
  </svg>
);

const FromList = ({mailInfo}) => {
  return mailInfo.from.map(function(from, index) {
    if (mailInfo.from.length == index + 1) {
      return (
        <>
          <a href="#" className="font-medium text-gray-900">
            {from.name}
          </a>
          {`<${from.email}>`}
        </>
      );
    }
    return (
      <>
        <a href="#" className="font-medium text-gray-900">
          {from.name}
        </a>
        {`<${from.email}>`}
        <p>, </p>
      </>
    );
  });
}

const ToList = ({mailInfo}) => {
  return mailInfo.to.map(function(to, index) {
    if (mailInfo.to.length == index + 1) {
      return (
        <>
          <a href="#" className="font-medium text-gray-900">
            {to.name}
          </a>
          {`<${to.email}>`}
        </>
      );
    }
    return (
      <>
        <a href="#" className="font-medium text-gray-900">
          {to.name}
        </a>
        {`<${to.email}>`}
        <p>, </p>
      </>
    );
  });
}

const SuccessModal = ({ modalOpen = "", setModalOpen, target, offset }) => {
  if (!modalOpen) return null;

  return (
    <div
      className={`${
        modalOpen ? "block z-10" : "hidden"
      } fixed inset-0 overflow-y-auto`}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={modalOpen}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Send successful
                    </h3>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <Link to={`${target}?offset=${offset}`} className="text-gray-400 hover:text-gray-500">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

const Sending = ({ isSending = "", setIsSending }) => {
  if (!isSending) return null;

  return (
    <div>
      <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" style={{ "display": "flex", "align-items": "center", "justify-content": "center" }}>
        <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ "top": "50%;"}}>
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
    </div>
  );
};
