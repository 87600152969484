import React, { Component } from 'react';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  RefinementList,
  Configure,
  ScrollTo,
} from 'react-instantsearch-dom';
import searchClient from './algoliaCred';
import CustomHits from './customHits';
import CustomHitsPerPage from './customHitsPerPage';
import SearchResult from '../search/HeavenBroadcastResult';
import TrialSearchResult from '../search/FreeHeavenBroadcastResult';
import UpgradeCard from './upgradeCard';

import Loading from '../misc/Loading';
import NoAuth from '../misc/noAuth';

const HeavenSearchFilters = ({ locked }) => (
  <div className="heaven-search-filters">
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-id-badge heaven-nav-link-icon"></i>Type<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="type"
        className="journalist-filter-refinement-list"
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-broadcast-tower heaven-nav-link-icon"></i>Outlet Type<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="type_of_media"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-id-badge heaven-nav-link-icon"></i>Job Titles<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="contact_title"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-globe-americas heaven-nav-link-icon"></i>Countries<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="country"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-city heaven-nav-link-icon"></i>Areas<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="area"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-clipboard-list heaven-nav-link-icon"></i>Beats<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="outlet_topic"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-tags heaven-nav-link-icon"></i>Topics<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="contact_topic"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-broadcast-tower heaven-nav-link-icon"></i>Media Outlets<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="outlet"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <p className="heaven-search-filter-subtitle"><i className="fas fa-link heaven-nav-link-icon"></i>Domains<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="website"
        className="journalist-filter-refinement-list"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section" style={{borderBottom:'none'}}>
      <p className="heaven-search-filter-subtitle"><i className="fas fa-venus-mars heaven-nav-link-icon"></i>Gender<i className={locked ? "fas fa-lock heaven-nav-link-icon-lock" : ""} /></p>
      <RefinementList
        attribute="gender"
        className="journalist-filter-refinement-list"
      />
    </div>
  </div>
)

class BroadcastDashboard extends Component {

  render() {
    const { auth, subscription, plan, status, lists } = this.props;

    return (
      <div className="max-w-6xl mx-auto py-10 px-4 sm:px-0 lg:px-0">
        {!auth ? (
          <NoAuth />
        ) : (subscription && subscription.tempStripePaymentTokenId) ? (
          <Loading />
        ) : ((auth && !subscription) || (auth && subscription && status !== "active")) ? (
          <section>
            <InstantSearch
              searchClient={searchClient}
              indexName="broadcast"
            >
              <div className="columns">
                <a className="column is-one-thirds heaven-cover-filters" href="/pricing" />
                <div className="column is-one-thirds">
                  <UpgradeCard />
                  <HeavenSearchFilters locked />
                </div>
                <div className="column is-two-thirds">
                  <div className="mb-5 float-right hidden md:flex">
                    <CustomHitsPerPage
                      defaultRefinement={3}
                      items={[
                        { value: 3, label: '3 contacts' },
                      ]}
                    />
                    <div className="journalist-search-add-all-div">
                      <a className="heaven-page-cta-button" aria-haspopup="true" aria-controls="dropdown-menu3" href="/pricing">
                        <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-lock heaven-nav-link-icon-lock" style={{ color:'white',opacity:'.5' }}/>
                      </a>
                    </div>
                  </div>
                  <SearchBox
                    autofocus
                    translations={{ placeholder: "Search through journalists…" }}
                    className="heaven-search-box"
                  />
                  <Configure hitsPerPage={3} />
                  <Hits hitComponent={TrialSearchResult} />
                </div>
              </div>
            </InstantSearch>
          </section>
        ) : (subscription && status === "active") ? (
          <section>
            <InstantSearch
              searchClient={searchClient}
              indexName="broadcast"
            >
              <div className="columns">
                <div className="column is-one-thirds">
                  <HeavenSearchFilters />
                </div>
                <div className="column is-two-thirds">
                  <div className="mb-5 float-right hidden md:flex">
                    <CustomHitsPerPage
                      defaultRefinement={20}
                      items={[
                        { value: 20, label: '20 contacts' },
                        { value: 50, label: '50 contacts' },
                      ]}
                    />
                    {(plan === 2 || plan === 20 || plan === 3 || plan === 30 || plan === 4 || plan === 40 || plan === 5 || plan === 50 || plan === 6 || plan === 60 || plan === 7 || plan === 70) ? (
                      <Pagination
                        className="heaven-top-pagination"
                        translations={{
                          previous: 'Back',
                          next: 'Next',
                        }}
                        padding={0}
                        showLast={false}
                        showFirst={false}
                      />
                    ) : <span /> }
                    {(plan === 2 || plan === 20 || plan === 3 || plan === 30 || plan === 4 || plan === 40 || plan === 5 || plan === 50 || plan === 6 || plan === 60 || plan === 7 || plan === 70) ? (
                      <CustomHits dataType="broadcaster"/>
                    ) : (
                      <div className="journalist-search-add-all-div">
                        <a className="heaven-page-cta-button" aria-haspopup="true" aria-controls="dropdown-menu3" href="/pricing">
                          <i className="fas fa-plus-circle heaven-nav-button-icon"></i>Save page<i className="fas fa-lock heaven-nav-link-icon-lock" style={{color:'white',opacity:'.5'}} />
                        </a>
                      </div>
                    )}
                  </div>
                  <SearchBox
                    translations={{ placeholder: "Search through journalists…" }}
                    className="heaven-search-box"
                    submit={<img src="/submit.png" alt=""/>}
                    focusShortcuts={['s']}
                  />
                  <Hits hitComponent={SearchResult} />
                  <div className="heaven-bottom-pag-div">
                    <Pagination
                      className="heaven-bottom-pagination"
                      translations={{
                        previous: 'Back',
                        next: 'Next',
                      }}
                      padding={2}
                      showLast={false}
                      showFirst={false}
                    />
                  </div>
                </div>
              </div>
            </InstantSearch>
          </section>
        ) : (
          <NoAuth />
        )}
      </div>
    );
  }
}

export default BroadcastDashboard;
