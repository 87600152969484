import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { subMenu } from '../path';
import MenuSideBar from '../menuSideBar';
import SubMenuSideBar from '../subMenuSideBar';
import MailBreadcrumb from '../breadcrumb';

const people = [
  {
    name: 'Jane Cooper',
    title: 'Regional Paradigm Technician',
    phone: '240-707-3884',
    email: 'jane.cooper@example.com',
  },
  {
    name: 'Cody Fisher',
    title: 'Product Directives Officer',
    phone: '240-707-3884',
    email: 'cody.fisher@example.com',
  },
  {
    name: 'Jane Cooper',
    title: 'Regional Paradigm Technician',
    phone: '240-707-3884',
    email: 'jane.cooper@example.com',
  },
  {
    name: 'Cody Fisher',
    title: 'Product Directives Officer',
    phone: '240-707-3884',
    email: 'cody.fisher@example.com',
  },
  {
    name: 'Jane Cooper',
    title: 'Regional Paradigm Technician',
    phone: '240-707-3884',
    email: 'jane.cooper@example.com',
  },
  {
    name: 'Cody Fisher',
    title: 'Product Directives Officer',
    phone: '240-707-3884',
    email: 'cody.fisher@example.com',
  },
];

const ContactsPersons = () => {
  const { pathname } = useLocation();
  const [markDropdownOn, setMarkDropdownOn] = useState(false);

  return (
    <div className="h-screen overflow-hidden bg-gray-100 flex flex-col">
      <div className="min-h-0 flex-1 flex overflow-hidden">
        <MenuSideBar pathname={pathname} />

        <SubMenuSideBar pathname={pathname} path={subMenu.contacts} />

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <MailBreadcrumb>
            <MailBreadcrumb.Item>
              <>
                <NavLink to="/mail/box" className="text-gray-400 hover:text-gray-500">
                  <svg
                    className="flex-shrink-0 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </NavLink>
              </>
            </MailBreadcrumb.Item>

            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>

                <span
                  to="/mail/new"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Contacts
                </span>
              </>
            </MailBreadcrumb.Item>
            <MailBreadcrumb.Item>
              <>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current="page"
                >
                  Persons
                </span>
              </>
            </MailBreadcrumb.Item>
          </MailBreadcrumb>

          {/* Main Content */}
          <div className="">
            <div className="px-4 mt-4 flex is-justify-content-space-between">
              <div className="relative z-0 inline-flex shadow-sm rounded-md m-1">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-400 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:opacity-75 focus:outline-none transition ease-in-out duration-150"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Send</span>
                </button>
              </div>
              <div className="relative z-0 inline-flex shadow-sm rounded-md m-1">
                <label className="my-auto mx-4">1 Persons</label>
                <OutsideClickHandler
                  onOutsideClick={() => (markDropdownOn
                      ? setMarkDropdownOn(!markDropdownOn)
                      : () => {})}
                >
                  <div>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                      id="options-menu"
                      aria-expanded={markDropdownOn}
                      aria-haspopup="true"
                      onClick={() => setMarkDropdownOn(!markDropdownOn)}
                    >
                      User
                      {/* <!-- Heroicon name: solid/chevron-down --> */}
                      <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  <div
                    className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                    style={{ display: markDropdownOn ? '' : 'none' }}
                  >
                    <div className="py-1" role="none" />
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
            <PersonsTable />
          </div>
        </main>
      </div>
    </div>
  );
};

export default ContactsPersons;

const PersonsTable = () => (
  <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Label
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {people.map((person, personIdx) => (
                <tr
                  key={person.email}
                  className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {person.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {person.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {person.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {person.phone}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);
