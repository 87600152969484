import Firebase from 'firebase/app';
import { prepareDocForCreate } from '../helpers/firestoreHelpers';

const createDomain = values => {
  return Firebase.firestore()
    .collection(`domains`)
    .add(prepareDocForCreate(values))
    .then(() => values)
    .catch( error => {
      alert(`Sorry, couldn't create the sequence: ${error.message}`)
    })
}

export default createDomain;
