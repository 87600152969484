import React from 'react';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';

import deleteEntry from '../../actions/deleteEntry';
import clearList from '../../actions/clearList';
import Loading from '../misc/Loading';
import EmptyList from '../misc/emptyList';
import CSV from './csv';
import ListHookForm from './listHookForm';

import '../../styles/tailwind.output.css';
import Placeholder from '../../assets/placeholder.png';
import Transition from '../../styles/Transition.js';


class InfiniteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	dbItems: [],
    	itemsPerPage: 100,
      pageNumber: 0,
    	totalItemCount: 0,
      listEntries:[],
    	searchTerm: '',
      list: {},
      isLoading: true,
      modalOn: false,
      warningModalOn: false,
    };
    this.getDocument = this.getDocument.bind(this);
    this.activateModal = this.activateModal.bind(this);
    this.activateWarningModal = this.activateWarningModal.bind(this);
    this.reDirect = this.reDirect.bind(this);
  }

  componentDidMount() {
    this.getDocument();
	}

  activateModal() {
    this.setState(prevState => ({
      modalOn: !prevState.modalOn,
    }));
  }

  activateWarningModal() {
    this.setState(prevState => ({
      warningModalOn: !prevState.warningModalOn,
    }));
  }

  reDirect() {
    this.props.history.push("/lists");
  }

  getDocument = () => {
    var db = Firebase.firestore();
    var listData = db.collection('lists')
      .where('createdBy', '==', this.props.subscription.user).where('slug', '==', this.props.match.params.slug)
      .get()
      .then(documentSnapshots => {
        documentSnapshots.forEach(doc => {
          this.setState({
            list: doc.data(),
            listId: doc.id,
            listEntries: db.collection('lists').doc(doc.id).collection('entries'),
          })
        })
      })
      .then( () => this.getFirstItems())
  };

  getFirstItems = () => {
    var listSize = this.state.listEntries
      .get()
      .then(snap => {
        this.setState({
          totalItemCount: snap.size,
          listEntriesAll: snap.docs.map(doc => doc)
        })
      })
    var listEntries = this.state.listEntries
      .orderBy("name")
      .startAt(0)
      .limit(this.state.itemsPerPage)
      .get()
      .then( documentSnapshots => {
        var pageNumber = 1;
      	var firstVisible = documentSnapshots.docs[0];
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc);
        return this.setItems(items, firstVisible, lastVisible, pageNumber)
      })
  }

  showNextPage = () => {
    var listEntries = this.state.listEntries
      .orderBy("name")
      .startAfter(this.state.lastVisible)
      .limit(this.state.itemsPerPage)
      .get()
      .then( documentSnapshots => {
        var pageNumber = this.state.pageNumber += 1;
      	var firstVisible = documentSnapshots.docs[0]
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc)
        return this.setItems(items, firstVisible, lastVisible, pageNumber)
      })
  };

  showPreviousPage = () => {
    var listEntries = this.state.listEntries
      .orderBy("name")
      .endBefore(this.state.firstVisible)
      .limitToLast(this.state.itemsPerPage)
      .get()
      .then( documentSnapshots => {
        var pageNumber = this.state.pageNumber -= 1;
      	var firstVisible = documentSnapshots.docs[0]
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc)
        return this.setItems(items, firstVisible, lastVisible, pageNumber)
      })
  };

  findSearchResult = () => {
    var searchTerm = this.state.searchTerm.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    var listEntries = this.state.listEntries
    	.where("name", "==", searchTerm)
      .get()
      .then( documentSnapshots => {
      	var firstVisible = documentSnapshots.docs[0]
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc)
        return this.setItems(items, firstVisible, lastVisible)
      })
  }

  setItems = (items, firstVisible, lastVisible, pageNumber) => {
    this.setState({
      isLoading: false,
      dbItems: items,
      firstVisible,
      lastVisible,
      pageNumber,
    })
    console.log(this.state.pageNumber)
  }

  handleChange = event => {
    this.setState({
    	searchTerm: event.target.value,
    })
  };

  handleEnter = async (event) => {
    if (event.keyCode === 13) {
      if (this.state.searchTerm === "" || this.state.searchTerm === " ") {
      	this.getFirstItems()
      } else {
        this.findSearchResult()
      }
    }
  };

  render() {
    const { auth, subscription, plan, status, lists } = this.props;
    const { list, listId, isLoading, totalItemCount, warningModalOn, modalOn, listEntriesAll, dbItems } = this.state;

    if (isLoading) {
      return <Loading />
    } else {
      return (
        <section className="max-w-6xl mx-auto mt-20 px-4">
          <div>
            <div className={`${warningModalOn ? 'block z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                  show={warningModalOn}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                </Transition>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <Transition
                  show={warningModalOn}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            Clear list
                          </h3>
                          <div className="mt-2">
                            <p className="text-sm leading-5 text-gray-500">
                              Are you sure you want to clear this list? All of your data will be permanently removed. This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <span className="flex w-full mt-3 rounded-lg shadow-sm sm:ml-3 sm:w-auto">
                        <button
                          type="button"
                          onClick={ async () => {
                            await this.setState({ isLoading: true, warningModalOn: false, });
                            await clearList(listId);
                            await this.getFirstItems();
                          }}
                          className="inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-red-1000 text-base leading-6 font-medium text-white shadow-sm hover:opacity-75 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Clear list
                        </button>
                      </span>
                      <span className="mt-3 flex w-full rounded-lg shadow-sm sm:mt-0 sm:w-auto">
                        <button
                          type="button"
                          onClick={this.activateWarningModal}
                          className="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
            <div className={`${modalOn ? 'block z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                  show={modalOn}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                </Transition>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <Transition
                  show={modalOn}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <ListHookForm
                    reDirect={this.reDirect}
                    activateModal={this.activateModal}
                    getDocument={this.getDocument}
                    fromDash={false}
                    listId={listId}
                    list={list}
                    isUpdate={true}
                    plan={plan}
                  />
                </Transition>
              </div>
            </div>
            <div className="container mx-auto mb-5">
              <div className="border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
                <div className="lg:max-w-lg">
                  <div className="flex">
                    <h3 className="text-2xl leading-6 font-bold text-gray-900 mr-2 capitalize">{list.name}</h3>
                    <button onClick={this.activateModal} className="h-3">
                      <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    <div className="flex ml-4">
                    {(plan === 2 || plan === 20) ? (
                      <p className="px-3 py-1 inline-flex text-xs text-left leading-5 font-semibold rounded-full bg-white text-gray-700 border border-gray-200">{totalItemCount}/200 CONTACTS</p>
                    ) : <p className="px-3 py-1 ininline-flex text-xs text-left leading-5 font-semibold rounded-full bg-white text-gray-700 border border-gray-200">{totalItemCount} CONTACTS</p>}
                    </div>
                  </div>
                  {list.description ? (
                    <p className="pt-4 text-sm font-small text-left text-gray-500">{list.description}</p>
                  ) : <span style={{ display:'none' }} /> }
                </div>
                <div>
                  <span className="shadow-sm rounded-lg">
                    <button
                      onClick={this.activateWarningModal}
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-5 font-medium rounded-lg shadow-sm text-white bg-red-1000 focus:outline-none hover:opacity-75 transition duration-150 ease-in-out"
                    >
                      <svg className="opacity-50 fill-current outline-current h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                      <span className="ml-2 font-bold">Clear list</span>
                    </button>
                  </span>
                  <span className="shadow-sm rounded-lg ml-3">
                    <CSV entries={listEntriesAll} plan={plan} />
                  </span>
                  <NavLink
                    to="/mail/new"
                    className="inline-flex items-center px-4 py-2 ml-3 border border-transparent text-base leading-5 font-medium rounded-lg shadow-sm text-white bg-blue-1000 focus:outline-none hover:opacity-75 transition duration-150 ease-in-out"
                  >
                    <svg className="opacity-50 h-5 w-5 inline text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span className="ml-2 font-bold text-white">Email contacts</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        { totalItemCount > 0 ? (
  	      <div className="mx-auto mb-24">
          <div className="rounded-lg bg-white">
            <div className="mb-12">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Search by exact name of journalist…"
                  className="form-input block w-full rounded-none rounded-lg shadow-sm border border-gray-200 pl-10 py-2 transition ease-in-out duration-150"
                  value={this.state.searchTerm}
                  onChange={this.handleChange}
                  onKeyDown={this.handleEnter}
                />
              </div>
            </div>
          </div>
            <div className="flex flex-col">
              <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="border border-gray-200 align-middle inline-block min-w-full shadow-sm overflow-hidden rounded-lg border-b border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200 bg-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-3 border-r border-b border-gray-00 bg-gray-200"></th>
                        <th className="px-6 py-3 border-r border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 border-r border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                          Outlet
                        </th>
                        <th className="px-6 py-3 border-r border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                          Type
                        </th>
                        <th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                          Added
                        </th>
                        {/*<th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Add notes
                        </th>*/}
                        {/*<th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">Date Added</th>*/}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {this.state.dbItems.length > 0 ? (
                      this.state.dbItems.map((entry, index) => (

                      <tr key={index}>
                        <td className="px-4 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium align-middle">
                          <button className="align-middle" onClick={() => deleteEntry(listId, entry.id, entry.data().dataType, entry.data().objectID).then(() => this.getFirstItems())}>
                            <svg className="h-5 w-5 text-red-1000 hover:text-red-400 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                          </button>
                        </td>
                        <td>
                          <NavLink to={entry.data().objectID ? `/list/${list.slug}/${entry.data().objectID}` : `/list/${list.slug}`} className="flex px-4 py-4">
                          <div className="flex items-center">

                            <div className="flex-shrink-0 h-10 w-10">
                              <img className="h-10 w-10 rounded-full" src={entry.data().avatar ? entry.data().avatar : entry.data().img_url ? entry.data().img_url : Placeholder } alt="" />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm align-middle text-left leading-5 font-bold text-gray-900">
                                {(entry.data().dataType === "broadcaster" && entry.data().first_name !== "") ? entry.data().first_name + " " + entry.data().last_name : entry.data().name}
                              </div>
                              <div className="text-sm align-middle text-left leading-5 text-gray-500">
                                {entry.data().email}
                              </div>
                            </div>

                          </div>
                          </NavLink>
                        </td>

                        <td className="align-middle">
                          <NavLink to={entry.data().objectID ? `/list/${list.slug}/${entry.data().objectID}` : `/list/${list.slug}`}>
                            <div className="px-4 py-4 items-center">
                              <div className="text-sm text-left leading-5 text-gray-900">
                                {(entry.data().outlet_text && entry.data().outlet_text.length > 0) ? entry.data().outlet_text : entry.data().email_domain ? entry.data().email_domain : entry.data().outlet}
                              </div>
                              <div className="text-sm text-left leading-5 text-gray-500">{entry.data().csvUpload ? entry.data().contactTitle : entry.data().kind_of_journalist ? entry.data().kind_of_journalist : entry.data().type}</div>
                            </div>
                          </NavLink>
                        </td>
                        <td className="align-middle">
                          <NavLink to={entry.data().objectID ? `/list/${list.slug}/${entry.data().objectID}` : `/list/${list.slug}`} className="flex px-4 py-4 align-middle whitespace-nowrap">
                            <span className={`${entry.data().dataType === "journalist" ? 'bg-green-200 text-green-500' : 'bg-green-200 text-green-500'} px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                              <svg className="h-3 w-3 mr-1 align-middle mt-1 opacity-75" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                              </svg>
                              {entry.data().dataType ? entry.data().dataType.toUpperCase() : entry.data().mediaType ?entry.data().mediaType.toUpperCase() : ""}
                            </span>
                          </NavLink>
                        </td>
                        <td className="align-middle whitespace-nowrap">
                          <NavLink to={entry.data().objectID ? `/list/${list.slug}/${entry.data().objectID}` : `/list/${list.slug}`} className="flex px-4 py-4 align-middle whitespace-nowrap">
                            {entry.data().addedOn ? (
                              <span className="flex text-xs text-left leading-5 bg-blue-100 text-blue-600 px-2 rounded-full font-bold uppercase">
                                <svg className="h-3 w-3 mr-1 align-middle mt-1 opacity-75" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                                </svg>
                                <Moment fromNow ago>{entry.data().addedOn.toDate()}</Moment><span>&nbsp;ago</span>
                              </span>
                            ) : <span /> }
                          </NavLink>
                        </td>

                      </tr>

                      ))
                    ) : <tr /> }
                    </tbody>
                  </table>
                  <nav className="bg-white px-4 py-3 flex items-center border-t border-gray-200 sm:px-6">
                    <div className="hidden sm:block">
                      <p className="text-sm leading-5 text-gray-700">
                        Showing
                        <span className="font-bold"> {(this.state.pageNumber-1)*this.state.itemsPerPage + 1} </span>
                        to
                        <span className="font-bold"> {this.state.pageNumber*this.state.itemsPerPage < this.state.totalItemCount ? this.state.pageNumber*this.state.itemsPerPage : this.state.totalItemCount } </span>
                        of
                        <span className="font-bold"> {this.state.totalItemCount} </span>
                        results
                      </p>
                    </div>
                    <div className="ml-10">
                    {this.state.pageNumber > 1 ? (
                      <button onClick={this.showPreviousPage} className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-lg text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                        Previous
                      </button>
                    ) : <span /> }
                    {this.state.pageNumber < this.state.totalItemCount/this.state.itemsPerPage ? (
                      <button onClick={this.showNextPage} className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-lg text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                        Next
                      </button>
                    ) : <span /> }
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        ) : <EmptyList /> }
  	    </section>
      );
    };
  }
}

export default InfiniteList;
