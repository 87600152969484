import React from 'react';
import Footer from '../layout/footer';
import Users from '../../assets/howler-users.svg';
import Media from '../../assets/media-outlets-prod.svg';

const FixedComponents = () =>
  <div>
    <section className="section">
      <div className="container">
        <div className="columns has-text-centered is-centered">
          <div className="column is-hiw-card">
            <div className="circle-icon is-size-2 has-padding-bottom">
              <span role="img" aria-label="dart">🎯</span>
            </div>
            <div className="title is-size-4 para-title">
              580,000+ journalists to pitch your business to today
            </div>
          </div>
          <div className="column is-hiw-card">
            <div className="circle-icon is-size-2">
              <span role="img" aria-label="magnifying glass">🔎</span>
            </div>
            <div className="title is-size-4 para-title">
              Database complete with emails, phone numbers and 6 other attributes
            </div>
          </div>
          <div className="column is-hiw-card">
            <div className="circle-icon is-size-2">
              <span role="img" aria-label="doc">🗃</span>
            </div>
            <div className="title is-size-4 para-title">
              Create lists of your favorites and export their contacts to CSV
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section" id="howitworks">
      <div className="container clear-section-card-writing-samples">
        <div className="pricing-head has-text-centered is-centered has-text-black">
          <h2 className="title has-text-centered is-centered">How it works</h2>
          <p className="subtitle has-text-centered is-centered pricing-sub">Getting started with Press Hunt only takes a few seconds!</p>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="circle-icon is-size-2">
              <span role="img" aria-label="magnifying glass">🔎</span>
            </div>
            <div className="title is-size-4 para-title">
              1. Find relevant journalists
            </div>
            <p className="subtitle is-size-5 is-hiw-para">
              Search through our constantly evolving database of journalists categorized by what they’re most likely to write about next
            </p>

          </div>
          <div className="column is-hiw-card">
            <div className="circle-icon is-size-2">
              <span role="img" aria-label="star">⭐️</span>
            </div>
            <div className="title is-size-4 para-title">
              2. Save your favorite journalists to your own media lists
            </div>
            <p className="subtitle is-size-5 is-hiw-para">
              Save the best journalists to your media lists so you can pitch them when you’re ready
            </p>
          </div>
          <div className="column is-hiw-card">
            <div className="circle-icon is-size-2">
              <span role="img" aria-label="letter">💌</span>
            </div>
            <div className="title is-size-4 para-title">
              3. Start pitching!
            </div>
            <p className="subtitle is-size-5 is-hiw-para">
              Export your media lists to CSV, import the journalist contact info into your favorite email system, and start sending them your pitches
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="has-text-centered is-centered">
        <p className="is-size-6 has-text-weight-normal used-by">Our database has been used to run PR campaigns for companies like:</p>
        <img
          src={ Users }
          style={{width:'100%',zIndex:'0'}}
          alt="User logos"
        />
        <a href="https://www.producthunt.com/posts/press-hunt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt" target="_blank" rel="noopener noreferrer">
          <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=123406&theme=dark"} alt="Press Hunt - Find the best journalists to cover you with our DB of 500k 🎉 | Product Hunt Embed"  className="mx-auto" style={{width: "250px", height: "54px", marginTop:'2em'}} />
        </a>
        <a href="https://www.producthunt.com/posts/press-hunt-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-press-hunt-2-0" target="_blank" rel="noopener noreferrer">
          <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=141052&theme=dark"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
        </a>
        <a href="https://www.producthunt.com/posts/pr-requests?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pr-requests" target="_blank" rel="noopener noreferrer">
          <img src={"https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180815&theme=dark&period=daily"} className="mx-auto" style={{width: "250px", height: "54px", marginTop:'1em'}} />
        </a>
      </div>
    </section>


    <section className="section has-background-white">
      <div className="container has-text-centered is-centered clear-section-card">
        <div className="pricing-head has-text-centered is-centered has-text-black">
          <h2 className="title has-text-centered is-centered" id="pricing">What do you get with Press Hunt?</h2>
          <p className="subtitle has-text-centered is-centered pricing-sub"></p>
        </div>
        <div className="columns">
          <div className="column is-feature-col">
            <div className="has-text-centered">
              <div className="is-size-2">
                <span role="img" aria-label="dart">🎯</span>
              </div>
              <p className="title has-text-weight-bold is-size-4">580,000+ journalists at your fingertips</p>
              <p className="subtitle is-size-6 is-feature-para">We match you with hundreds of journalists based on what they’ve written about before, what they've tweeted about, and where they write</p>
            </div>
          </div>
          <div className="column is-feature-col">
            <div className="has-text-centered">
              <div className="is-size-2">
                <span role="img" aria-label="globe">🌍</span>
              </div>
              <p className="title has-text-weight-bold is-size-4">Our journalists come from all over the world and every major media outlet</p>
              <p className="subtitle is-size-6 is-feature-para">Our journalists cover stories on every continent except Antarctica</p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-feature-col">
            <div className="has-text-centered">
              <div className="is-size-2">
                <span role="img" aria-label="magnifying glass">🔎</span>
              </div>
              <p className="title has-text-weight-bold is-size-4">Search through journalists by their writing focuses and 7 other attributes</p>
              <p className="subtitle is-size-6 is-feature-para">Search through journalist interests, industry focuses, their parent media outlet, social media accounts and more! Press Hunt has millions of data points.</p>
            </div>
          </div>
          <div className="column is-feature-col">
            <div className="has-text-centered">
              <div className="is-size-2">
                <span role="img" aria-label="write">📝</span>
              </div>
              <p className="title has-text-weight-bold is-size-4">Journalists categorized by what they’re likely to write about next</p>
              <p className="subtitle is-size-6 is-feature-para">Journalist tags are derived from data about where they write, what they’ve written about before, and what they've tweeted about so you can find the journalists most likely to cover you</p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-feature-col">
            <div className="has-text-centered">
              <div className="is-size-2">
                <span role="img" aria-label="doc">🗃</span>
              </div>
              <p className="title has-text-weight-bold is-size-4">Build lists of your favorite journalists and export their contact info to CSV</p>
              <p className="subtitle is-size-6 is-feature-para">With Press Hunt you can create media lists of up to 200 journalists and export their emails to CSV so you can pitch them your story</p>
            </div>
          </div>
          <div className="column is-feature-col">
            <div className="has-text-centered">
              <div className="is-size-2">
                <span role="img" aria-label="celebrate">🎉</span>
              </div>
              <p className="title has-text-weight-bold is-size-4">Our journalists cover everything from startups and crypto to fashion, art, and photography</p>
              <p className="subtitle is-size-6 is-feature-para">Our journalists cover everything: sports, fashion, food, startups, rockets, flowers, SAAS, ICOs, kickstarter campaigns, photography, and more!</p>
            </div>
          </div>
        </div>
        <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
      </div>
    </section>

    <section className="section">
      <div className="container clear-section-card">
        <div className="pricing-head has-text-centered is-centered has-text-black">
          <h2 className="title has-text-centered is-centered">Our users love us</h2>
          <p className="subtitle has-text-centered is-centered pricing-sub">Our database has been used by dozens of companies from all over the world</p>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="subtitle is-size-5 has-text-weight-normal">
              "Press Hunt has a seemingly endless database and tons of little details on each journalist - feels like clearbit for PR. Rounded off with a simple UI, this product is brilliant for anyone trying to get their name out there to relevant journalists."
            </div>
            <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> Founder of ConcreteCapital</p>
          </div>
          <div className="column is-hiw-card">
            <div className="subtitle is-size-5 has-text-weight-normal">
              "We’ve been using this to reach out to journalists about our hackathons and startup competition at NYU. Much better than researching reporters on Google and finding their emails manually."
            </div>
            <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> President of NYU Innovention Society</p>
          </div>
          <div className="column is-hiw-card">
            <div className="subtitle is-size-5 has-text-weight-normal">
              "With Press Hunt you can market to the right audience by choosing journalists who write for the people you're trying to reach."
            </div>
            <p className="is-size-6 has-text-weight-semibold is-marginless"><span role="img" aria-label="heart">❤️</span> Bitrix24 Tool of the Week</p>
          </div>
        </div>
        <div className="is-centered has-text-centered" style={{marginTop:'2em'}}>
          <a className="button is-stripe-hero" href="/pricing">Subscribe now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i></a>
        </div>
      </div>
    </section>

    <section className="section" id="FAQ">
      <div className="container clear-section-card-writing-samples">
        <div className="pricing-head has-text-centered is-centered has-text-black">
          <h2 className="title has-text-centered is-centered">FAQ</h2>
          <p className="subtitle has-text-centered is-centered pricing-sub">If our FAQ doesn't answer your questions, please reach out to us in our live chat in the bottom right.</p>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              What kind of journalists do you have in this database?
            </div>
            <p className="is-size-6">Press Hunt has hundreds of thousands of journalists that cover every field you can imagine: sports journalists, fashion journalists, tech journalists, local new reporters, startups, science, crypto, art, theater, etc. Our journalists cover everywhere from the USA to China and Europe to Australia––we have journalists on every continent except Antarctica.</p>
          </div>
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              What do I do with the journalist emails?
            </div>
            <p className="is-size-6">You can email each journalist one-by-one; or you can add them to your own media list, then export their emails to CSV. You can then take your CSV and upload to mass emailing software like MixMax or Reply.io.</p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              What is a media list?
            </div>
            <p className="is-size-6">A media list is simply a list of journalists created for a specific purpose. If you’re announcing a new product launch, you can put together a list of a few hundred journalists in your field that you think will be interested in covering your launch (based on the writing interests tags and other data we’ve provided for you). When you launch, you can send them all emails!</p>
          </div>
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              Why can’t I see journalist emails?
            </div>
            <p className="is-size-6">Our basic package doesn’t come with access to journalist emails––just their social media links. If you’re on a plan that includes emails, and you don’t see some, it is likely because we don’t have emails on record for that journalist or media outlet. This can be due to many reasons, from GDPR or privacy regulations to them not having their email publicly accessible.</p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              Where do you get these contacts from?
            </div>
            <p className="is-size-6">We’ve manually built and labeled a lot of the data. We’ve also used some data science tricks to scan the web and overlay relevant data to output a complete journalist profile.</p>
          </div>
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              Are these contacts legit?
            </div>
            <p className="is-size-6">Yes. Through our testing we’ve found that 80% of these emails are valid and still make it to the intended recipient. 20% of the emails are outdated due to journalists changing jobs. We’re constantly filtering those out though, so if you find some, please let us know.</p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              How often do you update this database?
            </div>
            <p className="is-size-6">We update this journalist database usually daily to include the most recent contact information and labels.</p>
          </div>
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              What do the journalist tags represent?
            </div>
            <p className="is-size-6">Tags for each journalist represent what they’re likely to want to write about, based on what they’ve written about before, outlets they’ve written for, and their tweets.</p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-hiw-card">
            <div className="title is-size-5">
              Are there duplicate journalists?
            </div>
            <p className="is-size-6">Some journalists work for multiple outlets or blogs and have multiple contacts for each. in this case, we do have multiple records for a few journalists. We’re working on reducing the amount of duplicates however, so if you see some, please let us know in our live chat in the bottom right.</p>
          </div>
          <div className="column is-hiw-card" style={{opacity:'0'}}>
            <div className="title is-size-5">

            </div>
            <p className="is-size-6"></p>
          </div>
        </div>
      </div>
    </section>

    <section className="section is-paddingless">
      <img
        src={ Media }
        style={{width:'100%',zIndex:'0'}}
        alt="media"
      />
    </section>

    <Footer />
  </div>

export default FixedComponents;
